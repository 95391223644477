import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const createLabelFn = async (data) => {
  try {
    const response = await axios(`/label`, {
      method: 'POST',
      data: data.data,
    });
    return response.data;
  } catch (error) {
    logError(error, 5);
    return error.response.data;
  }
};

const useCreateLabel = () => {
  const [createLabelMutation, {status, data, error, isLoading}] = useMutation(
    createLabelFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('labels');
      },
    },
  );
  return {createLabelMutation, isLoading, status, error};
};

export default useCreateLabel;
