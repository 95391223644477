import ExcelJS from 'exceljs';
import React, {useEffect, useState} from 'react';

const SonyAtv = (props) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const {data, multiple, selectedIds} = props;
  const [albumTracks, setAlbumTracks] = useState([]);

  const gettracks = (getAlbumDetail) => {
    const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
    if (multiple) {
      const filterTracks = tracks.filter((item) => {
        if (selectedIds.includes(item.upc)) {
          return item;
        }
      });
      setAlbumTracks(filterTracks);
    } else {
      // tracks.forEach((track) => {
      //   // Convert singers array to string
      //   const singers = track.singers || [];
      //   track.singers = singers.toString();
      //   const music_director = track.music_director || [];
      //   track.music_director = music_director.toString();
      // });
      setAlbumTracks(
        tracks.filter((track) => track.album_id === getAlbumDetail?.id),
      );
    }
  };
  useEffect(() => {
    gettracks(data);
  }, [data]);

  if (!data) return null;
  const date_release = new Date(data.album_release_date);

  const header = [
    'TEMPO Contract Code',
    'TEMPO Contract Name',
    "Client's Songcode",
    'Share Territory Controlled',
    'Song Title',
    'Version of [song code]',
    'Writer Role',
    'Writer (Last Name)',
    'Writer (First Name)',
    'Writer (Other Name)',
    'Writer (Professional Name)',
    'PN Preferred?',
    'Writer Society Affiliation',
    'Writer IPI Number',
    'Writer Contrib. %',
    'Writer Direct Collect Mech %,',
    'Writer Direct Collect Perf %',
    'Writer Direct Collect Synch %',
    'Writer Ctrl?',
    'Publisher Name',
    'Publisher IPI Number',
    'Publisher Control %',
    'Publisher Direct Collect Mech %',
    'Publisher Direct Collect Perf %',
    'Publisher Direct Collect Synch %',
    'Publ Ctrl?',
    'Publ Ctrl? (Local)',
    'Publ Ctrl?(ROW)',
    'Local Reg. Date',
    '© Year',
    'ISWC',
    'International Notes',
    'Set up Instructions to GCA',
    'Artist',
    'Label',
    'ISRC',
    '1st Release Date',
  ];

  // For every track, create a row with the track's data
  const rows = albumTracks.map((track) => {
    const lyricists = track.lyricists || [];
    const directors = track.music_director || [];
    // compare lyricists and directors to get common elements
    const common = lyricists.filter((value) => directors.includes(value));
    if (common.length > 0) {
      track.writer_role = 'C';
    } else {
      track.writer_role = 'A';
    }
    return [
      '',
      '',
      '',
      'World Wide',
      track.track_name,
      '',
      track.writer_role,
      track.lyricists.toString(),
      track.lyricists.toString(),
      '',
      '',
      '',
      '',
      '',
      '50',
      '0',
      '25',
      '0',
      'yes',
      'DIVO TV PRIVATE LIMITED',
      '00824558815',
      '50',
      '50',
      '25',
      '50',
      'yes',
      '',
      '',
      '',
      date_release.getFullYear(),
      '',
      '',
      '',
      track.singers,
      'DIVO TV PRIVATE LIMITED',
      track.isrc,
      ('0' + date_release.getDate()).slice(-2) +
        '-' +
        months[date_release.getMonth()] +
        '-' +
        date_release.getFullYear().toString().substring(2, 4),
    ];
  });

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.addRows([header, ...rows]);

    worksheet.getRow(1).font = {bold: true, size: 12, color: {argb: 'FFFFFF'}};
    // Add border to header
    worksheet.getRow(1).border = {
      top: {style: 'thick'},
      left: {style: 'thin'},
      bottom: {style: 'thick'},
      right: {style: 'thin'},
    };
    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c0c0c0'},
    };

    worksheet.getCell('B1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c0c0c0'},
    };

    worksheet.getCell('C1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('D1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('E1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('F1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('G1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('H1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('I1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('J1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('K1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('L1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('M1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('N1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('O1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('P1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('Q1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('R1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('S1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('T1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('U1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('V1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('W1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('X1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('Y1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('Z1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('AA1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('AB1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c55a11'},
    };

    worksheet.getCell('AC1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('AD1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('AE1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('AF1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c0c0c0'},
    };

    worksheet.getCell('AG1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'c0c0c0'},
    };

    worksheet.getCell('AH1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('AI1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('AJ1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };

    worksheet.getCell('AK1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'f4b083'},
    };
    worksheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center'};
    worksheet.getRow(1).height = 53;
    // each column width is 30
    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    worksheet.columns.forEach((column) => {
      column.alignment = {vertical: 'middle', horizontal: 'center'};
    });

    worksheet.getRow(rows.length + 2).font = {bold: true};
    worksheet.getRow(rows.length + 2).height = 30;

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sonyAtv.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button className="button" onClick={handleDownload}>
      SONY ATV
    </button>
  );
};

export default SonyAtv;
