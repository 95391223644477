export const dspData = {
  dsp_name: {
    Header: 'DSP Name',
    accessor: 'dsp_name',
  },
  dsp_type: {
    Header: 'DSP Type',
    accessor: 'dsp_type',
  },
  point_of_contact: {
    Header: 'Point of Contact',
    accessor: 'point_of_contact',
  },
  email_poc: {
    Header: 'Email',
    accessor: 'email_poc',
  },
  phone_poc: {
    Header: 'Phone',
    accessor: 'phone_poc',
  },
  delivery_type: {
    Header: 'Delivery Method',
    accessor: 'delivery_type',
  },
  created_at: {
    Header: 'Created On',
    accessor: 'created_at',
  },
};
