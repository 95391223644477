import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {MdCancel, MdEdit} from 'react-icons/md';

import {capitalizeFirstLetter} from '../../../helpers/capitalizeFirstLetter';
import useGetAllCountries from '../../../hooks/Countries/useGetAllCountries';
import TerritoryDialouge from './TerritoryDialouge';

const TerritoryAlbumCreate = (props) => {
  const {
    excludeValue,
    setExcludeValue,
    includeValue,
    setIncludeValue,
    selectedValue,
    setSelectedValue,
  } = props;

  const [showAll, setShowAll] = useState(false);
  const [showDialouge, setShowDialouge] = useState(false);

  const {countries, isLoading} = useGetAllCountries();
  const extraContent = excludeValue.slice(0, 8);
  const extraContent1 = includeValue.slice(0, 8);

  // useEffect(() => {
  //   if (selectedValue === 'worldwide') {
  //     setInitialState({
  //       ...initialState,
  //       territory: selectedValue,
  //       countries: [],
  //     });
  //   } else if (selectedValue === 'exclude') {
  //     setInitialState({
  //       ...initialState,
  //       territory: selectedValue,
  //       countries: excludeValue,
  //     });
  //   } else if (selectedValue === 'include') {
  //     setInitialState({
  //       ...initialState,
  //       territory: selectedValue,
  //       countries: includeValue,
  //     });
  //   } else {
  //     return null;
  //   }
  // }, [selectedValue]);

  const handleChange = (e) => {
    const {value} = e.target;
    setSelectedValue(value);
    if (value === 'exclude' || value === 'include') {
      setShowDialouge(true);
    }
    if (value === 'exclude') {
      setIncludeValue([]);
    }
    if (value === 'include') {
      setExcludeValue([]);
    }
    if (value === 'worldwide') {
      setExcludeValue([]);
      setIncludeValue([]);
    }
  };
  const deleteItemFromExclude = (id) => {
    const val = excludeValue.filter((item, index) => index !== id);
    setExcludeValue(val);
  };
  const deleteItemFromInclude = (id) => {
    const val = includeValue.filter((item, index) => index !== id);
    setIncludeValue(val);
  };

  return (
    <div>
      {showDialouge ? (
        <TerritoryDialouge
          data={countries ? countries : []}
          setShowDialouge={setShowDialouge}
          setValue={
            selectedValue === 'exclude' ? setExcludeValue : setIncludeValue
          }
          value={selectedValue === 'exclude' ? excludeValue : includeValue}
          type={selectedValue}
          setShowAll={setShowAll}
          showAll={showAll}
        />
      ) : (
        <RadioList>
          <h3>
            Can this release be sold worldwide
            <span style={{color: 'red'}}>*</span>
          </h3>
          <div className="radio-list">
            <label>
              <input
                type="radio"
                value="worldwide"
                name="radio"
                checked={selectedValue === 'worldwide'}
                onChange={handleChange}
              />
              <p>Sell worldwide</p>
            </label>
            <label>
              <input
                type="radio"
                value="exclude"
                name="radio"
                checked={selectedValue === 'exclude'}
                onChange={handleChange}
              />
              <p>Sell worldwide, but exclude some countries/territories</p>
            </label>
            {excludeValue.length > 0 && (
              <div className="sold">
                <p>Sell the album worldwide, but exclude:</p>
                <button onClick={() => setShowDialouge(true)}>
                  Edit <MdEdit />
                </button>
              </div>
            )}
            {excludeValue.length > 0 && (
              <div className="tags">
                {extraContent.map((item, index) => (
                  <p key={item.id}>
                    {capitalizeFirstLetter(item.country)}
                    <span onClick={() => deleteItemFromExclude(index)}>
                      <MdCancel />
                    </span>
                  </p>
                ))}
                {excludeValue.length > 8 && (
                  <div className="read-more">
                    <a
                      className="read-more-link"
                      onClick={() => {
                        setShowAll(true);
                        setShowDialouge(true);
                      }}
                    >
                      Show All..
                    </a>
                  </div>
                )}
              </div>
            )}
            <label>
              <input
                type="radio"
                value="include"
                name="radio"
                checked={selectedValue === 'include'}
                onChange={handleChange}
              />
              <p>Sell only in selected countries/territories</p>
            </label>
            {includeValue.length > 0 && (
              <div className="sold">
                <p>This album will be sold in:</p>
                <button
                  onClick={() => {
                    setShowDialouge(true);
                    setShowAll(false);
                  }}
                >
                  Edit <MdEdit />
                </button>
              </div>
            )}
            {includeValue.length > 0 && (
              <div className="tags">
                {extraContent1.map((item, index) => (
                  <p key={item.id}>
                    {capitalizeFirstLetter(item.country)}
                    <span onClick={() => deleteItemFromInclude(index)}>
                      <MdCancel />
                    </span>
                  </p>
                ))}
                {includeValue.length > 8 && (
                  <div className="read-more">
                    <a
                      className="read-more-link"
                      onClick={() => {
                        setShowDialouge(true);
                        setShowAll(true);
                      }}
                    >
                      Show All..
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <CustomSelectComp /> */}
        </RadioList>
      )}
    </div>
  );
};
const RadioList = styled.div`
  color: #000000;
  padding-left: 20px;
  padding-bottom: 56px;
  h3 {
    font-style: normal;
    font-size: 14px;
    padding-left: 6px;
  }
  .radio-list {
    .tags {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      margin-left: 34px;
      flex-wrap: wrap;
      p {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 10px;
        background: rgba(210, 25, 25, 0.1);
        border-radius: 30px;
        color: #d21919;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
      span {
        display: flex;
        padding-left: 5px;
        cursor: pointer;
      }
      .read-more {
        a {
          font-size: 14px;
          border-bottom: 1px solid #666;

          font-color: #ccc;
          color: #666;
        }
      }
    }
    .sold {
      display: flex;
      font-size: 14px;
      font-style: normal;

      align-items: center;
      margin-left: 34px;
      padding-bottom: 20px;
      margin-top: -8px;
      p {
        margin: 0;
      }
      button {
        border: none;
        background: #d21919;
        border-radius: 60.1469px;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        padding: 4px 8px;
        margin-left: 10px;
        cursor: pointer;
        svg {
          font-size: 12px;
          padding-left: 2px;
        }
      }
    }
    label {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      p {
        margin: 0;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        padding-left: 12px;
      }
      input[type='radio'] {
        -webkit-appearance: none;
        width: 13px;
        height: 13px;
        border: 1px solid darkgray;
        border-radius: 50%;
        outline: none;
      }
      input[type='radio']:before {
        content: '';
        display: block;
        width: 70%;
        height: 70%;
        margin: 16% auto;
        border-radius: 50%;
      }
      input[type='radio']:checked:before {
        background: #d21919;
      }
    }
  }
`;
export default TerritoryAlbumCreate;
