import {createTheme} from 'baseui';
import {Theme} from 'baseui/theme';

import {customLightThemePalette, CustomThemeType} from './colors';

const defaultLightThemePalette = {
  primaryFontFamily: 'DM Sans',
  primary: customLightThemePalette.primary,
};
const defaultLightThemePaletteOverrides = {
  colors: {
    backgroundPrimary: customLightThemePalette.contentBg,
    buttonPrimaryHover: customLightThemePalette.accent600,
    buttonPrimaryActive: customLightThemePalette.accent700,
    contentPrimary: customLightThemePalette.textColor,
    inputFillDisabled: customLightThemePalette.primary100,
    inputFill: customLightThemePalette.bg,
    inputBorder: customLightThemePalette.textColor,
    inputFillActive: '#fff',
    buttonDisabledFill: customLightThemePalette.primary100,
  },
};

//  create a theme object with default color overrides
const defaultLightTheme = createTheme(
  defaultLightThemePalette,
  defaultLightThemePaletteOverrides,
);

//  create a custom theme colors within the default theme object
const customLightTheme: Theme & CustomThemeType = {
  ...defaultLightTheme,
  // add custom colors here and access it through $theme
  themeButtonBg: customLightThemePalette.primary600,
  bg: customLightThemePalette.bg,
  textColor: customLightThemePalette.textColor,
  menuHover: customLightThemePalette.menuHover,
  sidebarMenuText: customLightThemePalette.sidebarMenuText,
  sidebarMenuInactiveText: customLightThemePalette.sidebarMenuInactiveText,
  arrowBg: customLightThemePalette.arrowBg,
  divoBg: customLightThemePalette.divoBg,
  tableBorderColor: customLightThemePalette.tableBorderColor,
  tableOddRowBg: customLightThemePalette.tableOddRowBg,
  divoRed: customLightThemePalette.divoRed,
  tabInactiveBorderColor: customLightThemePalette.tabInactiveBorderColor,
  tabInactiveBg: customLightThemePalette.tabInactiveBg,
  inputContainerBg: customLightThemePalette.inputContainerBg,
  tableEvenRowBg: customLightThemePalette.tableEvenRowBg,
  bgDefault: customLightThemePalette.bgDefault,
  inputBg: customLightThemePalette.inputBg,
  fileUploadBg: customLightThemePalette.fileUploadBg,
  rowHover: customLightThemePalette.rowHover,
  rowHoverText: customLightThemePalette.rowHoverText,
  transparentTableGradient: customLightThemePalette.transparentTableGradient,
  opaqueTableGradient: customLightThemePalette.opaqueTableGradient,
  upcBg: customLightThemePalette.upcBg,
  tabTextActive: customLightThemePalette.tabTextActive,
  tabTextInActive: customLightThemePalette.tabTextInActive,
  buttonActiveTextColor: customLightThemePalette.buttonActiveTextColor,
  buttonInactiveTextColor: customLightThemePalette.buttonInactiveTextColor,
  buttonInActiveBg: customLightThemePalette.buttonInActiveBg,
  buttonActiveBg: customLightThemePalette.buttonActiveBg,
  tableHeadBg: customLightThemePalette.tableHeadBg,
  tableHeadColor: customLightThemePalette.tableHeadColor,
  contentBg: customLightThemePalette.contentBg,
  placeholderColor: customLightThemePalette.placeholderColor,
  removeBtnBg: customLightThemePalette.removeBtnBg,
};

export default customLightTheme;
