import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Select} from 'baseui/select';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {IoIosArrowBack} from 'react-icons/io';
import {MdAddCircle, MdCancel, MdCheckCircle} from 'react-icons/md';

import {capitalizeFirstLetter} from '../../../helpers/capitalizeFirstLetter';
import Footer from './FooterComp';

const CustomLabel = (props) => {
  const [css, theme] = useStyletron();
  const {
    value,
    setValue,
    data,
    type,
    showAll,
    setHide,
    hide,
    setFieldTouched,
  } = props;

  const btnAddOveride = (theme) => {
    return {
      ControlContainer: {
        style: {
          border: 'none',
          outline: 'none',
          background: '#EFF1F4',
          borderLeftRadius: '5px',
          borderRightRadius: '5px',
          borderTopRadius: '5px',
          borderBottomRadius: '5px',

          marginLeft: '28px',
          width: '364px',
        },
      },
      Tag: {
        props: {
          overrides: {
            Root: {
              component: () => null,
            },
          },
        },
      },
      Dropdown: {
        style: {
          borderLeftRadius: '5px',
          borderRightRadius: '5px',
          borderTopRadius: '5px',
          borderBottomRadius: '5px',
          overflow: 'auto',
          maxHeight: '241px',
        },
      },
      OptionContent: {
        style: {
          display: 'flex',
        },
        component: (data) => (
          <>
            <p>{data.children.props.children}</p>
            <Button>
              {type === 'exclude' ? (
                <button className={data.$selected ? 'excluded' : 'exclude'}>
                  {data.$selected ? 'Excluded' : 'Exclude'}
                  {data.$selected ? <MdCheckCircle /> : <MdAddCircle />}
                </button>
              ) : (
                <button className={data.$selected ? 'excluded' : 'exclude'}>
                  {data.$selected ? 'Included' : 'Include'}
                  {data.$selected ? <MdCheckCircle /> : <MdAddCircle />}
                </button>
              )}
            </Button>
          </>
        ),
      },
      DropdownListItem: {
        style: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #ECEAEA',
          borderLeftRadius: '5px',
          borderRightRadius: '5px',
          borderTopRadius: '5px',
          borderBottomRadius: '5px',
          paddingTop: '0px',
          paddingBottom: '0px',
          paddingLeft: '10px',
          paddingRight: '10px',
        },
      },
      Popover: {
        props: {
          overrides: {
            Body: {
              style: ({$theme}) => ({
                zIndex: '2',
              }),
            },
          },
        },
      },
    };
  };

  const getLabel = ({option}: any) => {
    return (
      <React.Fragment>{capitalizeFirstLetter(option.country)}</React.Fragment>
    );
  };
  const deleteItem = (id) => {
    const val = value.filter((item, index) => index !== id);
    setValue(val);
  };
  const onBlur = () => {
    setHide(true);
  };
  const onFocus = () => {
    setHide(false);
  };
  return (
    <>
      {!showAll && (
        <Select
          options={data}
          labelKey="country"
          valueKey="id"
          onChange={(options) => {
            setValue(options.value);
            if (setFieldTouched) {
              setFieldTouched('territory', true);
            }
          }}
          value={value}
          placeholder="Select Countries"
          overrides={btnAddOveride(theme)}
          getOptionLabel={getLabel}
          getValueLabel={getLabel}
          closeOnSelect={false}
          onBlur={onBlur}
          onFocus={onFocus}
          multi
          filterOutSelected={false}
        />
      )}
      {!showAll && value.length > 0 && hide && (
        <p className="placeholder">Select Countries..</p>
      )}
      <div className={showAll ? 'show' : 'sale'}>
        <p className={showAll ? 'sell' : 'selling'}>
          {' '}
          {type === 'exclude' && value.length > 0
            ? 'Sell the album worldwide, but exclude:'
            : type === 'include' && value.length > 0
            ? 'This album will be sold in:'
            : ''}
        </p>
        {value.length > 0 && !showAll && (
          <button onClick={() => setValue([])}>
            Reset <MdCancel />{' '}
          </button>
        )}
      </div>
      <div className={!showAll ? 'tags' : 'all-tags'}>
        {value.map((item, id) => (
          <p key={item.id}>
            {capitalizeFirstLetter(item.country)}{' '}
            <span onClick={() => deleteItem(id)}>
              <MdCancel />
            </span>
          </p>
        ))}
      </div>
    </>
  );
};

const TerritoryDialouge = (props) => {
  const {
    setShowDialouge,
    data,
    setValue,
    value,
    type,
    setShowAll,
    showAll,
    setFieldTouched,
  } = props;
  const [hide, setHide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (hide === false) {
        setHide(true);
      }
    }, 250);
  }, [setHide]);
  return (
    <div>
      <DialougeComponent>
        {!showAll && (
          <div className="title">
            <button onClick={() => setShowDialouge(false)}>
              <IoIosArrowBack />
            </button>
            <p>
              {' '}
              {type === 'exclude'
                ? 'What countries/territories do you want to exclude from the release'
                : 'In what countries/territories do you want to make this release available?'}
            </p>
          </div>
        )}
        <CustomLabel
          value={value}
          data={data}
          setValue={setValue}
          type={type}
          showAll={showAll}
          setHide={setHide}
          hide={hide}
          setFieldTouched={setFieldTouched}
        />
        {value.length === 0 ? (
          <div className="info-cont">
            Continue typing to add more <span>countries/territories</span>
          </div>
        ) : null}
        <Footer
          lable="Done"
          setShowDialouge={setShowDialouge}
          setShowAll={setShowAll}
          value={value}
          setHide={setHide}
        />
      </DialougeComponent>
    </div>
  );
};

const Button = styled.div`
  button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 5px;
  }
  .exclude {
    background: #e7e7e7;
    color: #5c5c5c;
    svg {
      color: #5c5c5c;
      padding-left: 4px;
    }
  }
  .excluded {
    background: rgba(210, 25, 25, 0.1);
    color: #d21919;
    svg {
      color: #d21919;
      padding-left: 4px;
    }
  }
`;
const DialougeComponent = styled.div`
  width: 420px;
  height: 300px;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  animation: flip_horz 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-style: preserve-3d;
  &.alert {
    animation: shake 0.5s;
  }

  .title {
    // width:100%;
    padding: 10px;
    font-size: 14px;

    display: flex;
    // align-items:center;
    align-content: center;
    p {
      margin: 0;
      padding-left: 10px;
    }
    button {
      width: 10px;
      height: 20px;
      border-radius: 50%;
      background: #d21919;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      // margin-right: 5px;
      margin-top: 4px;
      cursor: pointer;
    }
  }
  .placeholder {
    margin: 0;
    position: absolute;
    top: 76px;
    left: 40px;
    font-size: 16px;

    color: #757575;
  }
  .show {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-style: normal;

    // margin-top: -33px;
    margin-left: 12px;
    // margin-bottom: 20px;

    button {
      background: transparent;
      display: flex;
      align-items: center;
      text-align: center;
      color: #d21919;
      font-size: 14px;
      border: none;
      padding: 0 30px 0 0;
      cursor: pointer;
      svg {
        padding-left: 2px;
      }
    }
    .sell {
      // margin: 0;
      padding: 0 0 0 18px;
    }
  }
  .sale {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-style: normal;

    margin-top: 20px;
    margin-bottom: 5px;

    button {
      background: transparent;
      display: flex;
      align-items: center;
      text-align: center;
      color: #d21919;
      font-size: 14px;
      border: none;
      padding: 0 30px 0 0;
      cursor: pointer;
      svg {
        padding-left: 2px;
      }
    }
    .selling {
      margin: 0;
      padding: 0 0 0 30px;
    }
  }

  .all-tags {
    display: flex;
    align-items: center;
    margin-left: 28px;
    flex-wrap: wrap;
    overflow-y: scroll;
    // z-index: 9999999999;
    max-height: 170px;
    p {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 10px;
      background: rgba(210, 25, 25, 0.1);
      border-radius: 30px;
      margin-top: 10px;
      color: #d21919;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      margin-right: 5px;
    }
    span {
      display: flex;
      padding-left: 5px;
      cursor: pointer;
    }
  }
  .tags {
    display: flex;
    align-items: center;
    margin-left: 28px;
    flex-wrap: wrap;
    overflow-y: scroll;
    // z-index: 9999999999;
    max-height: 100px;
    p {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 10px;
      background: rgba(210, 25, 25, 0.1);
      border-radius: 30px;
      margin-top: 10px;
      color: #d21919;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      margin-right: 5px;
    }
    span {
      display: flex;
      padding-left: 5px;
      cursor: pointer;
    }
  }
  .info-cont {
    display: flex;
    font-size: 11px;
    color: #c2c2c2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export default TerritoryDialouge;
