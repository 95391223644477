/** @format */

import axios from 'axios';
import {useContext} from 'react';
import {queryCache, useMutation} from 'react-query';

import {GlobalContext} from '../../Contexts/GlobalProvider';
import {logError} from '../../errorlogger/logError';
const BASE_URL = process.env.REACT_APP_Base_URL;

const createTrack = async (data) => {
  try {
    // Store track in local storage for future use
    const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
    data.id = tracks.length + 1;
    const albumData = JSON.parse(localStorage.getItem('albumData'))
    // Find the album
    const album = albumData.find((album) => album.id === data.album_id);
    if(album.maxDirectors){
      if(album.maxDirectors < data.music_director.length){
        album.maxDirectors = data.music_director.length
      }
    }
    else{
      album.maxDirectors = data.music_director.length
    }
    // update album in local storage
    localStorage.setItem('albumData', JSON.stringify(albumData));
    // get only tracks of the album
    const Newtracks = tracks.filter((track) => track.album_id === data.album_id);
    data.track_order = Newtracks.length + 1;
    tracks.push(data);
    localStorage.setItem('tracks', JSON.stringify(tracks));
    window.location.reload()
    return data;
  } catch (error) {
    logError(error, 12);
    return error.response?.data;
  }
};

const useCreateTrack = () => {
  const {state, dispatch}: any = useContext(GlobalContext);

  const [
    createTrackMutation,
    {status, data, error, isLoading, isSuccess},
  ] = useMutation(createTrack, {
    onSuccess: () => {
      queryCache.invalidateQueries('singleAlbum');
      dispatch({
        type: 'SET_TRACK_UPDATED',
        payload: {
          isTrackUpdated: true,
        },
      });
    },
  });

  return {createTrackMutation, isLoading, status, error};
};

export default useCreateTrack;
