import styled from '@emotion/styled';
import {Skeleton} from 'baseui/skeleton';
import React from 'react';

const TableSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .row1 {
    justify-content: space-between;
    padding-left: 30px;
    padding-top: 10px;
    padding-right: 30px;
  }
  .row2 {
    justify-content: flex-end;
    padding-right: 25px;
  }
  .row4 {
    flex-direction: column;
    height: 500px;
    justify-content: space-between;
  }
`;

export default function SkeletonLoading() {
  return (
    <TableSkeleton>
      <div className="row row1">
      </div>
      <div className="row row2">
        <Skeleton overrides={{
            Root: {
              style: {
                borderRadius: '60px',
              },
            },
          }} width="150px" height="38px" animation />
      </div>
      <div className="row row3">
        <Skeleton width="100%" height="60px" animation />
      </div>

      <div className="row row4">
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
      </div>
    </TableSkeleton>
  );
}

const TableRowSkeletonContainer = styled.div`
  display: flex;
  background-color: #fff;
  box-shadow: -4px 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  .sub-container {
    display: flex;
    width: 98%;
    justify-content: space-between;
  }
`;

const TableRowSkelton = () => {
  return (
    <TableRowSkeletonContainer>
      <div className="sub-container">
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
      </div>
    </TableRowSkeletonContainer>
  );
};
