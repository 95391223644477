import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';

const updateAlbumFn = async (request) => {
  const {id, data} = request;
  // trigger pipeline
  try {
    const response = await axios({
      method: 'PUT',
      url: `/album/update-status/${id}`,
      data: data,
    });
    return response.data;
  } catch (error) {
    return error.response?.data;
  }
};

const useUpdateAlbum = () => {
  const [updateAlbumMutation, {status, isLoading, error}] = useMutation(
    updateAlbumFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('albums');
        queryCache.invalidateQueries('album');
        queryCache.invalidateQueries('albums');
      },
    },
  );
  return {updateAlbumMutation, isLoading, status, error};
};

export default useUpdateAlbum;
