import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Accordion, Panel} from 'baseui/accordion';
import React, {useState} from 'react';
import {BsFillCaretLeftFill, BsFillCaretRightFill} from 'react-icons/bs';
import {
  FaSort,
  FaSortAlphaDown,
  FaSortAlphaDownAlt,
  FaSortAmountDown,
  FaSortAmountDownAlt,
  FaSortNumericDown,
  FaSortNumericUpAlt,
} from 'react-icons/fa';
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {FixedSizeList} from 'react-window';

import {NoRecords} from '../..';
import {TAGS} from '../../../constants/tags';
import useGetScrollBarWidth from '../../../hooks/reusable/useGetScrollBarWidth';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import {useScroll} from '../../../hooks/reusable/useScroll';
import SkeletonLoading from '../../../pages/SkeletonPage';
import TableRowSkeletonLoading from '../TableRowSkeleton/TableRowSkeleton';
import TableFilter from './TableFilter';

const TablePagination = (props) => {
  const {
    columns,
    data,
    tag,
    mainActionHandler,
    upcCount,
    isFetching,
    setSearchStr,
    onClickRow,
    setSidebarData,
    setPage,
    resolvedData,
    isLoading,
    isError,
    sError,
    sIsFetching,
    sIsLoading,
    actionBtn,
    actors,
    actress,
    director,
    albumName,
    artist,
    label,
    setAllData,
    allData,
    setDataFilter,
    dataFilter,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'created_at',
            desc: true,
          },
          {
            id: 'track_order',
            desc: false,
          },
        ],
      },
    },
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.useInstanceBeforeDimensions.push(({headerGroups}) => {
        // fix the parent group of the selection button to not be resizable
        let lastItem = headerGroups[0].headers.length - 1;
        // selectionGroupHeader.canResize = false;
      });
    },
  );
  const scrollWrapper = React.useRef();
  const {scrollY} = useScroll();

  const [showLeftChevron, setShowLeftChevron] = React.useState(false);
  const [showRightChevron, setShowRightChevron] = React.useState(true);
  const tbodyRef = React.useRef();
  const [fixedBarWidth, setFixedBarWidth] = useState(null);
  const [projectId, setProjectId] = useLocalStorage('projectId', null);
  const [searchString, setSearchString] = useState(null);
  const [css, theme] = useStyletron();

  const {scrollbarWidth} = useGetScrollBarWidth();

  React.useEffect(() => {
    if (scrollWrapper.current) {
      setFixedBarWidth(scrollWrapper.current?.clientWidth);
    }
  }, [scrollWrapper]);

  React.useEffect(() => {
    trackScroll();
  }, []);

  const scrollRight = (number) => {
    scrollWrapper.current.scrollBy(number, 0);
  };
  const scrollLeft = (number) => {
    scrollWrapper.current.scrollBy(number, 0);
  };

  const trackScroll = () => {
    const bodyWidth = tbodyRef.current?.clientWidth;
    const scrollLeft = scrollWrapper.current?.scrollLeft;
    let scrollWidth = scrollWrapper.current?.clientWidth;

    if (scrollWrapper.current?.scrollLeft > 0) {
      setShowLeftChevron(true);
    } else {
      setShowLeftChevron(false);
    }

    if (bodyWidth > scrollLeft + scrollWidth) {
      setShowRightChevron(true);
    } else {
      setShowRightChevron(false);
    }
  };

  const getHeaderProps = (column) => {
    return {
      onClick: () => (column.canSort ? column.toggleSortBy() : null),
    };
  };

  const getCellProps = (cell) => {
    return {
      onClick: () => {
        if (
          cell.column?.id === 'selection' ||
          tag === TAGS.UPC ||
          tag === TAGS.ISRC ||
          tag === TAGS.ROLE ||
          tag === TAGS.PRODUCT_HISTORY ||
          tag === TAGS.BULK_ADMIN ||
          tag === TAGS.TYPE ||
          tag === 'admin-uploads'
        ) {
          return null;
        } else if (tag !== 'request-edit') {
          if (tag === TAGS.TRACK_APPROVAL) {
            // cell.row?.toggleRowSelected(false);
            cell.column?.preFilteredRows.map((data) => {
              data.toggleRowSelected(false);
            });
          }
          onClickRow(true);
        } else {
          onClickRow(cell);
        }
      },
    };
  };

  const getRowProps = (row) => {
    return {
      onClick: () => {
        // store the clicked row data
        if (row?.original?.project_id) {
          setProjectId(row?.original?.project_id);
        }
        if (row) {
          if (
            tag === TAGS.USER ||
            tag === TAGS.TRACKS ||
            tag === TAGS.DSP ||
            tag === TAGS.LABEL ||
            tag === TAGS.ALBUMS ||
            tag === TAGS.ALBUM_APPROVAL ||
            tag === TAGS.TRACK_APPROVAL
          ) {
            setSidebarData((prevData) => ({prevData, ...row}));
          }
        }
      },

      style: {
        textAlign: 'right',
      },
    };
  };

  const renderSortIconDesc = (header) => {
    switch (header) {
      case 'Roles':
        return <FaSortNumericDown />;
      case 'Created On':
        return <FaSortAmountDown />;
      case 'Album Release Date':
        return <FaSortAmountDown />;
      case '':
        return null;
      default:
        return <FaSortAlphaDownAlt />;
    }
  };

  const renderSortIconAsc = (header) => {
    switch (header) {
      case 'Roles':
        return <FaSortNumericUpAlt />;
      case 'Created On':
        return <FaSortAmountDownAlt />;
      case 'Album Release Date':
        return <FaSortAmountDownAlt />;
      case '':
        return null;
      default:
        return <FaSortAlphaDown />;
    }
  };

  const renderSort = (column) => {
    return (
      <span className="sort" {...column.getSortByToggleProps()}>
        {column.isSorted ? (
          column.isSortedDesc ? (
            renderSortIconDesc(column.Header)
          ) : (
            renderSortIconAsc(column.Header)
          )
        ) : column.Header === '' || column.id === 'selection' ? null : (
          <FaSort />
        )}
      </span>
    );
  };

  const renderNoRecordState = () => {
    if (data.length === 0 || resolvedData?.totalPage === 0) {
      return <NoRecords btnLabel={null} onClick={null} page="approval" />;
    }
  };

  const RenderRow = React.useCallback(
    ({index, style}) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div style={{...style}}>
          <div
            {...row.getRowProps(getRowProps(row))}
            className={
              row?.original?.is_disabled || row?.original?.is_complete
                ? 'tr disabled'
                : 'tr'
            }
          >
            {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps(getCellProps(cell))} className="td">
                  <p>{cell.render('Cell')}</p>
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows],
  );

  return (
    <TableContainer tag={tag} theme={theme} fixedBarWidth={fixedBarWidth}>
      {isLoading ? (
        <SkeletonLoading />
      ) : isError || sError ? (
        <NoRecords btnLabel={null} onClick={null} page="approval" />
      ) : (
        <>
          <div className="table-container">
            {columns?.length > 2 && showLeftChevron && (
              <div
                onClick={() => scrollLeft(-175)}
                className="table-left-chevron-container"
              >
                <BsFillCaretLeftFill />
              </div>
            )}
            <TableFilter
              upc={upcCount && upcCount}
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              tag={tag}
              mainActionHandler={mainActionHandler}
              isFetching={isFetching}
              setSearchStr={setSearchStr}
              setSearchString={setSearchString}
              actionBtn={actionBtn}
              actors={actors}
              actress={actress}
              director={director}
              albumName={albumName}
              artist={artist}
              label={label}
              setAllData={setAllData}
              allData={allData}
              setDataFilter={setDataFilter}
              dataFilter={dataFilter}
              setPage={setPage}
            />

            <div
              className="table-style-wrapper"
              ref={scrollWrapper}
              onScroll={trackScroll}
            >
              <div {...getTableProps()} className="table">
                <div className="thead">
                  {headerGroups.map((headerGroup) => (
                    <div
                      {...headerGroup.getHeaderGroupProps({})}
                      className="tr"
                    >
                      {headerGroup.headers.map((column) => (
                        <div
                          {...column.getHeaderProps([getHeaderProps(column)])}
                          className="th"
                        >
                          <span>{column.render('Header')}</span>
                          {/* Use column.getResizerProps to hook up the events correctly */}
                          {column.canResize && (
                            <div
                              {...column.getResizerProps()}
                              className={`resizer ${
                                column.isResizing ? 'isResizing' : ''
                              }`}
                            />
                          )}
                          {renderSort(column)}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <div className="tbody" {...getTableBodyProps()} ref={tbodyRef}>
                  {columns?.length > 4 && showRightChevron && (
                    <div
                      onClick={() => scrollRight(175)}
                      className="table-right-chevron-container"
                    >
                      <BsFillCaretRightFill />
                    </div>
                  )}
                  {renderNoRecordState()}
                  <FixedSizeList
                    useIsScrolling
                    height={
                      tag === 'track_approval' || tag === 'tracks'
                        ? rows.length * 65 + 80
                        : tag === 'albums'
                        ? rows.length * 65 + 10
                        : rows.length * 65
                    }
                    itemCount={rows.length}
                    itemSize={65}
                    width="100%"
                  >
                    {RenderRow}
                  </FixedSizeList>
                </div>
              </div>
            </div>

            {isFetching || sIsLoading || sIsFetching ? (
              <TableRowSkeletonLoading />
            ) : null}
          </div>
        </>
      )}

      {/* {scrollY > 92 ? <TableFixedHeader /> : null} */}
    </TableContainer>
  );
};

const TableContainer = styled.div`
  .footer-tab {
    position: fixed;
    width: ${(props) => props.fixedBarWidth && props.fixedBarWidth - 30}px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    bottom: 0;
    padding-right: 30px;
    box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.1);
  }
  .table-style-wrapper {
    display: block;
    overflow: auto;
    background: ${(props) => props.theme.bg && props.theme.bg};
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .table-container {
    position: relative;
    overflow: hidden;
    .table-right-chevron-container,
    .table-left-chevron-container {
      position: absolute;
      height: 100%;
      cursor: pointer;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in;
      z-index: 1;
    }
    .table-right-chevron-container {
      background: linear-gradient(
        to right,
        ${(props) =>
            props.theme.transparentTableGradient &&
            props.theme.transparentTableGradient}
          0%,
        ${(props) =>
            props.theme.opaqueTableGradient && props.theme.opaqueTableGradient}
          180%
      );
      top: 110px;
      bottom: 0;
      right: 0;
    }
    .table-left-chevron-container {
      background: linear-gradient(
        to right,
        ${(props) =>
            props.theme.opaqueTableGradient &&
            props.theme.opaqueTableGradient} -80%,
        ${(props) =>
            props.theme.transparentTableGradient &&
            props.theme.transparentTableGradient}
          100%
      );
      top: 110px;
      bottom: 0;
      left: 0;
    }
  }
  .table {
    font-size: 14px;
    border-spacing: 0;
    border-radius: 5px;
    color: ${(props) => props.theme.textColor && props.theme.textColor};
    width: 100%;
    .tr:hover {
      cursor: pointer;
      // background: ${(props) => props.theme.rowHover} !important;
      box-shadow: -4px 2px 10px rgba(0, 0, 0, 0.15);
      color: ${(props) => props.theme.rowHoverText} !important;
    }

    .tbody {
      .tr {
        overflow-x: hidden;
        overflow-y: hidden;
        height: 64px;
      }
    }
    .List {
      background-color: ${(props) => props.theme.listBg};
      > div {
        background: white;
      }
    }
  }
  .disabled {
    opacity: 0.4;
  }
  .thead {
    background: ${(props) =>
      props.theme?.tableHeadBg && props.theme?.tableHeadBg} !important;
    color: ${(props) =>
      props.theme?.tableHeadColor && props.theme?.tableHeadColor} !important;
    text-transform: uppercase;
    border: none !important;
    .tr {
      border-bottom: none;
    }
    .tr:hover {
      box-shadow: none;
      background: ${(props) =>
        props.theme.tableHeadBg && props.theme.tableHeadBg} !important;
      color: ${(props) =>
        props.theme.tableHeadColor && props.theme.tableHeadColor} !important;
    }
    .th {
      text-align: center;
      font-weight: bold;
      text-align: ${(props) =>
        props.tag === 'type' || props.tag === 'role' ? `left` : `left`};
    }
  }
  .th,
  .td {
    margin: 0;
    padding: 0.8rem;
    :last-child {
      border-right: 0;
    }
    .sort {
      display: inline-block;
      text-align: 'center';
      margin-left: 2px;
    }
    .resizer {
      right: 0;
      background: ${(props) =>
        props.theme.tableBorderColor && props.theme.tableBorderColor};
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      touch-action: none;
      &.isResizing {
        background: red;
      }
    }
  }
  .selection {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .th[role='columnheader'] {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tr[role='row'] {
    border-bottom: 1px solid
      ${(props) =>
        props.theme?.tableBorderColor && props.theme?.tableBorderColor};
    align-items: center;
    height: 65px;
  }
  .td[role='cell'] {
    margin: 0;
    padding: 0.8rem;
    text-align: ${(props) =>
      props.tag === 'type' || props.tag === 'role' ? `left` : `left`};
    vertical-align: middle;
    transition: all 0.15s linear;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      max-height: 65px;
      line-height: 1.65 !important;
      margin: 0;
      > div {
        max-height: 45px;
      }
    }
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }
`;

export default TablePagination;
