import {ClassNames} from '@emotion/core';
/** @jsx jsx */
import {jsx} from '@emotion/core';
import styled from '@emotion/styled';
import {NavLink} from 'react-router-dom';

import Tooltip from '../../../components/reusable/ToolTip/ToolTip';
import Icon from '../../../styles/ui-component/Icon';

const SidebarLinkComponent = ({
  item,
  wrapperClassName,
  sidebarMenuInactiveText,
  iconColor,
  toggleSidebar,
}) => {
  return (
    <Tooltip
      isOpen={toggleSidebar}
      content={item.menuTitle}
      direction="right"
      page="sidemenu"
    >
      <SidebarLink
        toggleSidebar={toggleSidebar}
        activeClassName={wrapperClassName}
        iconColor={iconColor}
        sidebarMenuInactiveText={sidebarMenuInactiveText}
        to={item.route}
        state={
          item?.route === '/order-management'
            ? {canNavigateToBulkOrders: false}
            : {}
        }
        end
      >
        <Icon name={item.iconName} size={20} />
        <SidebarMenuItemLabel
          toggleSidebar={toggleSidebar}
          sidebarMenuInactiveText={sidebarMenuInactiveText}
        >
          {item.menuTitle}
        </SidebarMenuItemLabel>
      </SidebarLink>
    </Tooltip>
  );
};

const SidebarMenuItem = ({
  item,
  i,
  iconColor,
  menuHover,
  sidebarMenuText,
  sidebarMenuInactiveText,
  toggleSidebar,
}) => {
  return (
    <SidebarItem
      css={{
        '&:hover': {
          background: menuHover,
        },
      }}
      key={i}
    >
      <ClassNames>
        {({css, cx}) => (
          <SidebarLinkComponent
            toggleSidebar={toggleSidebar}
            iconColor={iconColor}
            sidebarMenuInactiveText={sidebarMenuInactiveText}
            item={item}
            wrapperClassName={css({
              background: menuHover,
              borderRadius: '10px',
              svg: {fill: `${sidebarMenuText} !important`},
              p: {
                color: sidebarMenuText,
              },
            })}
          />
        )}
      </ClassNames>
    </SidebarItem>
  );
};

const SidebarItem = styled.li`
  display: flex;
  height: 40px;
  alignitems: center;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 90%;
`;

const SidebarLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  transition: ${(props) =>
    props.toggleSidebar ? `all 0.25s ease-in-out` : `none`};
  padding-left: ${(props) => (props.toggleSidebar ? `17px` : `20px`)};
  svg {
    fill: ${(props) =>
      props.sidebarMenuInactiveText && props.sidebarMenuInactiveText};
  }
`;

const SidebarMenuItemLabel = styled.p`
  color: ${(props) =>
    props.sidebarMenuInactiveText && props.sidebarMenuInactiveText};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  transition: ${(props) =>
    props.toggleSidebar ? `all 0.25s ease-in-out` : `none`};
  display: ${(props) => (props.toggleSidebar ? `none` : `block`)};
  opacity: ${(props) => (props.toggleSidebar ? `0` : `1`)};
  text-align: left;
  padding: 12px 0px;
  margin-left: 20px;
`;

export default SidebarMenuItem;
