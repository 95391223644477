import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';

const UPCMessageContainer = styled.div`
  color: rgb(171, 19, 0);
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

export const UpcErrorMessage = ({message}) => {
  return <UPCMessageContainer>{message}</UPCMessageContainer>;
};

const useShowCodeErrorMessage = () => {
  const [message, setMessage] = useState(null);
  const [element, setElement] = useState(null);

  useEffect(() => {
    if (message) {
      const errorElement = <UpcErrorMessage message={message} />;
      setElement(errorElement);
    } else {
      setElement(null);
    }
  }, [message]);

  return {setMessage, element, message};
};

export default useShowCodeErrorMessage;
