import {useState} from 'react';

const useToast = () => {
  const [toastMsg, setToastMsg] = useState({
    type: 'none',
    message: {error: '', message: ''},
  });

  return {setToastMsg, toastMsg};
};

export default useToast;
