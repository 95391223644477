import stringSimilarity from 'string-similarity';

import {ColumnHeaders} from '../../validation/CreateAlbum/FilmType';

const useStringCompare = () => {
  const checkString = (str) => {
    const allValues = [];
    ColumnHeaders.map((header, i) => {
      const accuracy = stringSimilarity.compareTwoStrings(header, str);
      const obj = {accuracy, value: ColumnHeaders[i]};
      allValues.push(obj);
    });
    const acuurateValue = allValues.reduce((p, c) =>
      p.accuracy > c.accuracy ? p : c,
    );
    return acuurateValue;
  };
  return {
    checkString,
  };
};

export default useStringCompare;
