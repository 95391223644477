import styled from '@emotion/styled';
import React from 'react';

import image from '../../../src/assets/images/no-data.svg';
const DashBoard = (props) => {
  return (
    <Container>
      <div className="container">
        <div>
          <img src={image} height="150px" />
        </div>
        <span>Page under construction !</span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 250px;
    width: 250px;
  }
`;

export default DashBoard;
