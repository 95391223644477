import React from 'react';

const useTablePageState = () => {
  const [newFormTag, setNewFormTag] = React.useState<string | boolean>('');
  const [selectedData, setSelectedData] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);

  return {
    isOpen,
    setIsOpen,
    selectedData,
    newFormTag,
    setSelectedData,
    setNewFormTag,
  };
};

export default useTablePageState;
