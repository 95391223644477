/** @format */

import React from 'react';

import {formatDate} from '../../helpers/covertDate';
import {labelData} from './labelTable';

export const labelColumnMeta = [
  {
    Header: labelData.label_name.Header,
    accessor: labelData.label_name.accessor,
  },
  {
    Header: labelData.contact_name.Header,
    accessor: labelData.contact_name.accessor,
  },
  {
    Header: labelData.contact_email.Header,
    accessor: labelData.contact_email.accessor,
  },
  {
    Header: labelData.contact_phone.Header,
    accessor: labelData.contact_phone.accessor,
  },
  {
    Header: labelData.created_at.Header,
    accessor: labelData.created_at.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
];
