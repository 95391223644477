import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import * as React from 'react';
import {useRef} from 'react';
import {useState} from 'react';
import {AiFillCloseCircle} from 'react-icons/ai';

import {TAGS} from '../../../constants/tags';
import {logError} from '../../../errorlogger/logError';
import useLockBodyScroll from '../../../hooks/reusable/useLockBodyScroll';
import useOnClickOutside from '../../../hooks/reusable/useOnClickOutside';
import AlbumDetailForm from '../../../pages/AlbumsManagment/Albums/forms/AlbumDetailForm';
import AlbumForm from '../../../pages/AlbumsManagment/Albums/forms/AlbumForm';
import EditAlbumForm from '../../../pages/AlbumsManagment/Albums/forms/EditAlbumForm';
import TrackApprovalForm from '../../../pages/AlbumsManagment/Albums/forms/TrackApprovalForm';
import AlbumApprovalForm from '../../../pages/AlbumsReviewManagement/AlbumsReview/forms/ApprovalManagementForms';
import DSPForm from '../../../pages/DSPManagement/forms/DSPForm';
import LabelForm from '../../../pages/Label-ArtistManagement/LabelManagement/forms/LabelForm';
import UserManagementForms from '../../../pages/UserManagement/forms/UserManagementForms';
import {CustomThemeType} from '../../../styles/theme/colors';
import DownloadTemplateBtn from '../../BulkUpload/DownloadTemplateBtn';
import BulkUploadForm from '../../CreateProject/BulkUpload/BulkUploadForm';
import ManualUploadForm from '../../CreateProject/BulkUpload/ManualUploadForm';
import RevertModal from '../../CreateProject/RevertModal';

const FlyMenu = (props) => {
  const {
    isOpen,
    setIsOpen,
    selectedData,
    tag,
    newFormTag,
    setSelectedData,
    setNewFormTag,
    refetch,
    setIsAlbumClick = null,
    setDynamicTag = null,
    closeable = true,
  } = props;

  const [css, theme] = useStyletron();
  const sidebarRef = useRef();
  const apiCalledRef = useRef(false);
  const upcRef = useRef(null);
  const isrcRef = useRef(null);

  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
  const [isrcCode, setIsrcCode] = useState(null);
  const [upcCode, setUpcCode] = useState(null);
  const [trackCreated, setTrackCreated] = useState(false);
  const [isAlbumCreated, setIsAlbumCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useLockBodyScroll();

  const onCloseHandler = () => {
    if (closeable) {
      mainCloseHandler();
    } else {
      return;
    }
  };

  const mainCloseHandler = () => {
    if (setDynamicTag) {
      if (tag === TAGS.MANUAL_UPLOAD || tag === TAGS.BULK_UPLOAD) {
        setDynamicTag(TAGS.ALBUMS);
      }
      if (tag === TAGS.TRACKS) {
        setDynamicTag(TAGS.TRACKS);
      }
    }

    if (setIsAlbumClick) {
      setIsAlbumClick(false);
    }

    if ((isrcCode && !trackCreated) || (upcCode && !isAlbumCreated)) {
      setIsRevertModalOpen(true);
    } else {
      setNewFormTag(true);
      setIsOpen(false);
      setSelectedData({});
    }
  };

  useOnClickOutside(sidebarRef, () => onCloseHandler());

  const renderTitle = () => {
    switch (tag) {
      case TAGS.USER:
        return 'Manage User';
      case TAGS.DSP:
        return 'Manage DSP';
      case TAGS.LABEL:
        return 'Manage Label';
      case TAGS.BULK_UPLOAD:
        return 'Bulk Upload';
      case TAGS.MANUAL_UPLOAD:
        return 'Manual Upload';
      case TAGS.ISRC:
        return 'Generate ISRC';
      default:
        return null;
    }
  };

  return (
    <CustomFlyMenuContainer theme={theme} tag={tag}>
      <div
        ref={sidebarRef}
        className={`${isOpen ? 'flymenu flymenuopen' : 'flymenu'}`}
      >
        <div className="header-sidebar">
          <div className="close">
            <div className="wrapper">
              <div className="close-icon" onClick={mainCloseHandler}>
                <AiFillCloseCircle />
              </div>
              <div className="title">{renderTitle()}</div>
            </div>
            {tag === TAGS.BULK_UPLOAD && <DownloadTemplateBtn />}
          </div>
        </div>
        <div className="form-container">
          {selectedData && tag === TAGS.USER && (
            <UserManagementForms
              refetch={refetch}
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              setSelectedData={setSelectedData}
            />
          )}

          {selectedData && tag === TAGS.DSP && (
            <DSPForm
              refetch={refetch}
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              setSelectedData={setSelectedData}
            />
          )}

          {selectedData && tag === TAGS.LABEL && (
            <LabelForm
              refetch={refetch}
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              setSelectedData={setSelectedData}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
            />
          )}

          {selectedData && tag === TAGS.TRACKS && (
            <AlbumDetailForm
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              newFormTag={newFormTag}
              setNewFormTag={setNewFormTag}
              setIsModalOpen={setIsRevertModalOpen}
              setIsrcCode={setIsrcCode}
              setTrackCreated={setTrackCreated}
              refetchAlbum={refetch}
              isrcRef={isrcRef}
            />
          )}

          {selectedData && tag === TAGS.ALBUMS && (
            <AlbumForm
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              refetch={refetch}
            />
          )}

          {selectedData && tag === TAGS.EDIT_ALBUMS && (
            <EditAlbumForm
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              refetch={refetch}
              setIsAlbumClick={setIsAlbumClick}
            />
          )}

          {selectedData && tag === TAGS.ALBUM_APPROVAL && (
            <AlbumApprovalForm selectedData={selectedData?.original} />
          )}

          {selectedData && tag === TAGS.TRACK_APPROVAL && (
            <TrackApprovalForm
              sidebarRef={sidebarRef}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
            />
          )}
          {tag === TAGS.BULK_UPLOAD && (
            <BulkUploadForm
              setIsOpen={setIsOpen}
              sidebarRef={sidebarRef}
              setDynamicTag={setDynamicTag}
              tag={TAGS.BULK_UPLOAD}
            />
          )}
          {tag === TAGS.MANUAL_UPLOAD && (
            <ManualUploadForm
              setIsOpen={setIsOpen}
              setIsAlbumCreated={setIsAlbumCreated}
              setUpcCode={setUpcCode}
              upcRef={upcRef}
              apiCalledRef={apiCalledRef}
            />
          )}
        </div>
      </div>

      {isRevertModalOpen && (
        <RevertModal
          isOpen={isRevertModalOpen}
          close={() => setIsRevertModalOpen(false)}
          isAlbum={upcCode}
          isTrack={isrcCode}
          isLoading={isLoading}
        />
      )}
    </CustomFlyMenuContainer>
  );
};

const CustomFlyMenuContainer = styled.div<{
  theme: Theme & CustomThemeType;
  tag;
}>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  bottom: 0;
  left: 0;
  z-index: 2;

  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);

  .flymenu {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 550px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 100%;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    animation: slideleft 0.25s cubic-bezier(0.2, 0.8, 0.4, 1);
    padding: 0px;
    margin: 0px;
    transform: translateX(550px);
    background-color: ${(props) =>
      props.theme.bgDefault && props.theme.bgDefault};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px;

    .header-sidebar {
      height: 50px;
      position: sticky;
      top: 0;
      z-index: 99;

      .close {
        height: 50px;
        display: flex;
        align-items: center;
        background-color: #df3732;
        justify-content: space-between;

        .wrapper {
          display: flex;
          align-items: center;
        }
        .title {
          margin-left: 10px;
          font-weight: bold;
          color: white;
          font-size: 18px;
        }
        .close-icon {
          background: #c7241f;
          padding: 12.5px;
          display: flex;
          svg {
            font-size: 25px;
            cursor: pointer;
            margin-top: 0px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .form-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  .flymenuopen {
    transform: translateX(0px);
  }

  @keyframes slideleft {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

export default FlyMenu;
