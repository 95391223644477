import {useState} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

export const fetchAllCountries = async () => {
  return [
    {
        "id": 256,
        "country": "afghanistan",
        "country_iso_code": "af",
        "created_at": "2021-06-15T09:52:15.966Z",
        "user_id": null
    },
    {
        "id": 262,
        "country": "åland islands",
        "country_iso_code": "ax",
        "created_at": "2021-06-15T09:52:16.332Z",
        "user_id": null
    },
    {
        "id": 255,
        "country": "albania",
        "country_iso_code": "al",
        "created_at": "2021-06-15T09:52:15.783Z",
        "user_id": null
    },
    {
        "id": 332,
        "country": "algeria",
        "country_iso_code": "dz",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 276,
        "country": "american samoa",
        "country_iso_code": "as",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 259,
        "country": "andorra",
        "country_iso_code": "ad",
        "created_at": "2021-06-15T09:52:15.966Z",
        "user_id": null
    },
    {
        "id": 251,
        "country": "angola",
        "country_iso_code": "ao",
        "created_at": "2021-06-15T09:52:15.604Z",
        "user_id": null
    },
    {
        "id": 261,
        "country": "anguilla",
        "country_iso_code": "ai",
        "created_at": "2021-06-15T09:52:16.139Z",
        "user_id": null
    },
    {
        "id": 301,
        "country": "antarctica",
        "country_iso_code": "aq",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 254,
        "country": "antigua and barbuda",
        "country_iso_code": "ag",
        "created_at": "2021-06-15T09:52:15.783Z",
        "user_id": null
    },
    {
        "id": 264,
        "country": "argentina",
        "country_iso_code": "ar",
        "created_at": "2021-06-15T09:52:16.332Z",
        "user_id": null
    },
    {
        "id": 250,
        "country": "armenia",
        "country_iso_code": "am",
        "created_at": "2021-06-15T09:52:15.427Z",
        "user_id": null
    },
    {
        "id": 336,
        "country": "aruba",
        "country_iso_code": "aw",
        "created_at": "2021-06-15T09:52:18.638Z",
        "user_id": null
    },
    {
        "id": 412,
        "country": "australia",
        "country_iso_code": "au",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 265,
        "country": "austria",
        "country_iso_code": "at",
        "created_at": "2021-06-15T09:52:16.332Z",
        "user_id": null
    },
    {
        "id": 278,
        "country": "azerbaijan",
        "country_iso_code": "az",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 279,
        "country": "bahamas",
        "country_iso_code": "bs",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 440,
        "country": "bahrain",
        "country_iso_code": "bh",
        "created_at": "2021-06-15T09:52:19.211Z",
        "user_id": null
    },
    {
        "id": 306,
        "country": "bangladesh",
        "country_iso_code": "bd",
        "created_at": "2021-06-15T09:52:18.425Z",
        "user_id": null
    },
    {
        "id": 314,
        "country": "barbados",
        "country_iso_code": "bb",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 445,
        "country": "belarus",
        "country_iso_code": "by",
        "created_at": "2021-06-15T09:52:19.225Z",
        "user_id": null
    },
    {
        "id": 307,
        "country": "belgium",
        "country_iso_code": "be",
        "created_at": "2021-06-15T09:52:18.425Z",
        "user_id": null
    },
    {
        "id": 311,
        "country": "belize",
        "country_iso_code": "bz",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 280,
        "country": "benin",
        "country_iso_code": "bj",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 286,
        "country": "bermuda",
        "country_iso_code": "bm",
        "created_at": "2021-06-15T09:52:17.328Z",
        "user_id": null
    },
    {
        "id": 346,
        "country": "bhutan",
        "country_iso_code": "bt",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 309,
        "country": "bolivia (plurinational state of)",
        "country_iso_code": "bo",
        "created_at": "2021-06-15T09:52:18.425Z",
        "user_id": null
    },
    {
        "id": 313,
        "country": "bonaire, sint eustatius and saba",
        "country_iso_code": "bq",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 283,
        "country": "bosnia and herzegovina",
        "country_iso_code": "ba",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 300,
        "country": "botswana",
        "country_iso_code": "bw",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 323,
        "country": "bouvet island",
        "country_iso_code": "bv",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 408,
        "country": "brazil",
        "country_iso_code": "br",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 388,
        "country": "british indian ocean territory",
        "country_iso_code": "io",
        "created_at": "2021-06-15T09:52:18.928Z",
        "user_id": null
    },
    {
        "id": 372,
        "country": "brunei darussalam",
        "country_iso_code": "bn",
        "created_at": "2021-06-15T09:52:18.833Z",
        "user_id": null
    },
    {
        "id": 392,
        "country": "bulgaria",
        "country_iso_code": "bg",
        "created_at": "2021-06-15T09:52:18.935Z",
        "user_id": null
    },
    {
        "id": 331,
        "country": "burkina faso",
        "country_iso_code": "bf",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 312,
        "country": "burundi",
        "country_iso_code": "bi",
        "created_at": "2021-06-15T09:52:18.425Z",
        "user_id": null
    },
    {
        "id": 340,
        "country": "cabo verde",
        "country_iso_code": "cv",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 345,
        "country": "cambodia",
        "country_iso_code": "kh",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 374,
        "country": "cameroon",
        "country_iso_code": "cm",
        "created_at": "2021-06-15T09:52:18.838Z",
        "user_id": null
    },
    {
        "id": 308,
        "country": "canada",
        "country_iso_code": "ca",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 461,
        "country": "cayman islands",
        "country_iso_code": "ky",
        "created_at": "2021-06-15T09:52:19.319Z",
        "user_id": null
    },
    {
        "id": 477,
        "country": "central african republic",
        "country_iso_code": "cf",
        "created_at": "2021-06-15T09:52:19.423Z",
        "user_id": null
    },
    {
        "id": 284,
        "country": "chad",
        "country_iso_code": "td",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 275,
        "country": "chile",
        "country_iso_code": "cl",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 415,
        "country": "china",
        "country_iso_code": "cn",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 329,
        "country": "christmas island",
        "country_iso_code": "cx",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 304,
        "country": "cocos (keeling) islands",
        "country_iso_code": "cc",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 452,
        "country": "colombia",
        "country_iso_code": "co",
        "created_at": "2021-06-15T09:52:19.287Z",
        "user_id": null
    },
    {
        "id": 347,
        "country": "comoros",
        "country_iso_code": "km",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 269,
        "country": "congo",
        "country_iso_code": "cg",
        "created_at": "2021-06-15T09:52:16.527Z",
        "user_id": null
    },
    {
        "id": 322,
        "country": "congo, democratic republic of the",
        "country_iso_code": "cd",
        "created_at": "2021-06-15T09:52:18.525Z",
        "user_id": null
    },
    {
        "id": 444,
        "country": "cook islands",
        "country_iso_code": "ck",
        "created_at": "2021-06-15T09:52:19.225Z",
        "user_id": null
    },
    {
        "id": 378,
        "country": "costa rica",
        "country_iso_code": "cr",
        "created_at": "2021-06-15T09:52:18.840Z",
        "user_id": null
    },
    {
        "id": 310,
        "country": "côte d'ivoire",
        "country_iso_code": "ci",
        "created_at": "2021-06-15T09:52:18.425Z",
        "user_id": null
    },
    {
        "id": 335,
        "country": "croatia",
        "country_iso_code": "hr",
        "created_at": "2021-06-15T09:52:18.638Z",
        "user_id": null
    },
    {
        "id": 325,
        "country": "cuba",
        "country_iso_code": "cu",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 441,
        "country": "curaçao",
        "country_iso_code": "cw",
        "created_at": "2021-06-15T09:52:19.211Z",
        "user_id": null
    },
    {
        "id": 330,
        "country": "cyprus",
        "country_iso_code": "cy",
        "created_at": "2021-06-15T09:52:18.527Z",
        "user_id": null
    },
    {
        "id": 317,
        "country": "czechia",
        "country_iso_code": "cz",
        "created_at": "2021-06-15T09:52:18.525Z",
        "user_id": null
    },
    {
        "id": 437,
        "country": "denmark",
        "country_iso_code": "dk",
        "created_at": "2021-06-15T09:52:19.201Z",
        "user_id": null
    },
    {
        "id": 344,
        "country": "djibouti",
        "country_iso_code": "dj",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 416,
        "country": "dominica",
        "country_iso_code": "dm",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 427,
        "country": "dominican republic",
        "country_iso_code": "do",
        "created_at": "2021-06-15T09:52:19.131Z",
        "user_id": null
    },
    {
        "id": 320,
        "country": "ecuador",
        "country_iso_code": "ec",
        "created_at": "2021-06-15T09:52:18.522Z",
        "user_id": null
    },
    {
        "id": 319,
        "country": "egypt",
        "country_iso_code": "eg",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 403,
        "country": "el salvador",
        "country_iso_code": "sv",
        "created_at": "2021-06-15T09:52:19.015Z",
        "user_id": null
    },
    {
        "id": 414,
        "country": "equatorial guinea",
        "country_iso_code": "gq",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 328,
        "country": "eritrea",
        "country_iso_code": "er",
        "created_at": "2021-06-15T09:52:18.527Z",
        "user_id": null
    },
    {
        "id": 318,
        "country": "estonia",
        "country_iso_code": "ee",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 289,
        "country": "eswatini",
        "country_iso_code": "sz",
        "created_at": "2021-06-15T09:52:17.521Z",
        "user_id": null
    },
    {
        "id": 443,
        "country": "ethiopia",
        "country_iso_code": "et",
        "created_at": "2021-06-15T09:52:19.225Z",
        "user_id": null
    },
    {
        "id": 362,
        "country": "falkland islands (malvinas)",
        "country_iso_code": "fk",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 373,
        "country": "faroe islands",
        "country_iso_code": "fo",
        "created_at": "2021-06-15T09:52:18.833Z",
        "user_id": null
    },
    {
        "id": 490,
        "country": "fiji",
        "country_iso_code": "fj",
        "created_at": "2021-06-15T09:52:19.496Z",
        "user_id": null
    },
    {
        "id": 389,
        "country": "finland",
        "country_iso_code": "fi",
        "created_at": "2021-06-15T09:52:18.935Z",
        "user_id": null
    },
    {
        "id": 450,
        "country": "france",
        "country_iso_code": "fr",
        "created_at": "2021-06-15T09:52:19.242Z",
        "user_id": null
    },
    {
        "id": 339,
        "country": "french guiana",
        "country_iso_code": "gf",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 469,
        "country": "french polynesia",
        "country_iso_code": "pf",
        "created_at": "2021-06-15T09:52:19.376Z",
        "user_id": null
    },
    {
        "id": 366,
        "country": "french southern territories",
        "country_iso_code": "tf",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 383,
        "country": "gabon",
        "country_iso_code": "ga",
        "created_at": "2021-06-15T09:52:18.838Z",
        "user_id": null
    },
    {
        "id": 454,
        "country": "gambia",
        "country_iso_code": "gm",
        "created_at": "2021-06-15T09:52:19.297Z",
        "user_id": null
    },
    {
        "id": 395,
        "country": "georgia",
        "country_iso_code": "ge",
        "created_at": "2021-06-15T09:52:18.938Z",
        "user_id": null
    },
    {
        "id": 381,
        "country": "germany",
        "country_iso_code": "de",
        "created_at": "2021-06-15T09:52:18.840Z",
        "user_id": null
    },
    {
        "id": 358,
        "country": "ghana",
        "country_iso_code": "gh",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 491,
        "country": "gibraltar",
        "country_iso_code": "gi",
        "created_at": "2021-06-15T09:52:19.499Z",
        "user_id": null
    },
    {
        "id": 338,
        "country": "greece",
        "country_iso_code": "gr",
        "created_at": "2021-06-15T09:52:18.637Z",
        "user_id": null
    },
    {
        "id": 413,
        "country": "greenland",
        "country_iso_code": "gl",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 411,
        "country": "grenada",
        "country_iso_code": "gd",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 326,
        "country": "guadeloupe",
        "country_iso_code": "gp",
        "created_at": "2021-06-15T09:52:18.527Z",
        "user_id": null
    },
    {
        "id": 480,
        "country": "guam",
        "country_iso_code": "gu",
        "created_at": "2021-06-15T09:52:19.423Z",
        "user_id": null
    },
    {
        "id": 453,
        "country": "guatemala",
        "country_iso_code": "gt",
        "created_at": "2021-06-15T09:52:19.297Z",
        "user_id": null
    },
    {
        "id": 429,
        "country": "guernsey",
        "country_iso_code": "gg",
        "created_at": "2021-06-15T09:52:19.131Z",
        "user_id": null
    },
    {
        "id": 376,
        "country": "guinea",
        "country_iso_code": "gn",
        "created_at": "2021-06-15T09:52:18.840Z",
        "user_id": null
    },
    {
        "id": 321,
        "country": "guinea-bissau",
        "country_iso_code": "gw",
        "created_at": "2021-06-15T09:52:18.525Z",
        "user_id": null
    },
    {
        "id": 334,
        "country": "guyana",
        "country_iso_code": "gy",
        "created_at": "2021-06-15T09:52:18.638Z",
        "user_id": null
    },
    {
        "id": 324,
        "country": "haiti",
        "country_iso_code": "ht",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 391,
        "country": "heard island and mcdonald islands",
        "country_iso_code": "hm",
        "created_at": "2021-06-15T09:52:18.935Z",
        "user_id": null
    },
    {
        "id": 290,
        "country": "holy see",
        "country_iso_code": "va",
        "created_at": "2021-06-15T09:52:17.696Z",
        "user_id": null
    },
    {
        "id": 463,
        "country": "honduras",
        "country_iso_code": "hn",
        "created_at": "2021-06-15T09:52:19.322Z",
        "user_id": null
    },
    {
        "id": 423,
        "country": "hong kong",
        "country_iso_code": "hk",
        "created_at": "2021-06-15T09:52:19.118Z",
        "user_id": null
    },
    {
        "id": 356,
        "country": "hungary",
        "country_iso_code": "hu",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 271,
        "country": "iceland",
        "country_iso_code": "is",
        "created_at": "2021-06-15T09:52:16.705Z",
        "user_id": null
    },
    {
        "id": 385,
        "country": "india",
        "country_iso_code": "in",
        "created_at": "2021-06-15T09:52:18.926Z",
        "user_id": null
    },
    {
        "id": 348,
        "country": "indonesia",
        "country_iso_code": "id",
        "created_at": "2021-06-15T09:52:18.638Z",
        "user_id": null
    },
    {
        "id": 482,
        "country": "iran (islamic republic of)",
        "country_iso_code": "ir",
        "created_at": "2021-06-15T09:52:19.423Z",
        "user_id": null
    },
    {
        "id": 253,
        "country": "iraq",
        "country_iso_code": "iq",
        "created_at": "2021-06-15T09:52:15.783Z",
        "user_id": null
    },
    {
        "id": 380,
        "country": "ireland",
        "country_iso_code": "ie",
        "created_at": "2021-06-15T09:52:18.840Z",
        "user_id": null
    },
    {
        "id": 260,
        "country": "isle of man",
        "country_iso_code": "im",
        "created_at": "2021-06-15T09:52:16.139Z",
        "user_id": null
    },
    {
        "id": 258,
        "country": "israel",
        "country_iso_code": "il",
        "created_at": "2021-06-15T09:52:15.966Z",
        "user_id": null
    },
    {
        "id": 492,
        "country": "italy",
        "country_iso_code": "it",
        "created_at": "2021-06-15T09:52:19.499Z",
        "user_id": null
    },
    {
        "id": 287,
        "country": "jamaica",
        "country_iso_code": "jm",
        "created_at": "2021-06-15T09:52:17.521Z",
        "user_id": null
    },
    {
        "id": 434,
        "country": "japan",
        "country_iso_code": "jp",
        "created_at": "2021-06-15T09:52:19.141Z",
        "user_id": null
    },
    {
        "id": 268,
        "country": "jersey",
        "country_iso_code": "je",
        "created_at": "2021-06-15T09:52:16.527Z",
        "user_id": null
    },
    {
        "id": 365,
        "country": "jordan",
        "country_iso_code": "jo",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 405,
        "country": "kazakhstan",
        "country_iso_code": "kz",
        "created_at": "2021-06-15T09:52:19.019Z",
        "user_id": null
    },
    {
        "id": 315,
        "country": "kenya",
        "country_iso_code": "ke",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 296,
        "country": "kiribati",
        "country_iso_code": "ki",
        "created_at": "2021-06-15T09:52:18.071Z",
        "user_id": null
    },
    {
        "id": 419,
        "country": "korea (democratic people's republic of)",
        "country_iso_code": "kp",
        "created_at": "2021-06-15T09:52:19.108Z",
        "user_id": null
    },
    {
        "id": 397,
        "country": "korea, republic of",
        "country_iso_code": "kr",
        "created_at": "2021-06-15T09:52:18.938Z",
        "user_id": null
    },
    {
        "id": 382,
        "country": "kuwait",
        "country_iso_code": "kw",
        "created_at": "2021-06-15T09:52:18.840Z",
        "user_id": null
    },
    {
        "id": 272,
        "country": "kyrgyzstan",
        "country_iso_code": "kg",
        "created_at": "2021-06-15T09:52:16.704Z",
        "user_id": null
    },
    {
        "id": 294,
        "country": "lao people's democratic republic",
        "country_iso_code": "la",
        "created_at": "2021-06-15T09:52:17.883Z",
        "user_id": null
    },
    {
        "id": 467,
        "country": "latvia",
        "country_iso_code": "lv",
        "created_at": "2021-06-15T09:52:19.333Z",
        "user_id": null
    },
    {
        "id": 421,
        "country": "lebanon",
        "country_iso_code": "lb",
        "created_at": "2021-06-15T09:52:19.111Z",
        "user_id": null
    },
    {
        "id": 433,
        "country": "lesotho",
        "country_iso_code": "ls",
        "created_at": "2021-06-15T09:52:19.141Z",
        "user_id": null
    },
    {
        "id": 291,
        "country": "liberia",
        "country_iso_code": "lr",
        "created_at": "2021-06-15T09:52:17.696Z",
        "user_id": null
    },
    {
        "id": 337,
        "country": "libya",
        "country_iso_code": "ly",
        "created_at": "2021-06-15T09:52:18.638Z",
        "user_id": null
    },
    {
        "id": 468,
        "country": "liechtenstein",
        "country_iso_code": "li",
        "created_at": "2021-06-15T09:52:19.334Z",
        "user_id": null
    },
    {
        "id": 353,
        "country": "lithuania",
        "country_iso_code": "lt",
        "created_at": "2021-06-15T09:52:18.732Z",
        "user_id": null
    },
    {
        "id": 479,
        "country": "luxembourg",
        "country_iso_code": "lu",
        "created_at": "2021-06-15T09:52:19.423Z",
        "user_id": null
    },
    {
        "id": 393,
        "country": "macao",
        "country_iso_code": "mo",
        "created_at": "2021-06-15T09:52:18.938Z",
        "user_id": null
    },
    {
        "id": 493,
        "country": "madagascar",
        "country_iso_code": "mg",
        "created_at": "2021-06-15T09:52:19.521Z",
        "user_id": null
    },
    {
        "id": 499,
        "country": "malatheev",
        "country_iso_code": "me",
        "created_at": "2021-07-15T04:11:29.767Z",
        "user_id": null
    },
    {
        "id": 341,
        "country": "malawi",
        "country_iso_code": "mw",
        "created_at": "2021-06-15T09:52:18.639Z",
        "user_id": null
    },
    {
        "id": 457,
        "country": "malaysia",
        "country_iso_code": "my",
        "created_at": "2021-06-15T09:52:19.308Z",
        "user_id": null
    },
    {
        "id": 351,
        "country": "maldives",
        "country_iso_code": "mv",
        "created_at": "2021-06-15T09:52:18.732Z",
        "user_id": null
    },
    {
        "id": 285,
        "country": "mali",
        "country_iso_code": "ml",
        "created_at": "2021-06-15T09:52:17.328Z",
        "user_id": null
    },
    {
        "id": 460,
        "country": "malta",
        "country_iso_code": "mt",
        "created_at": "2021-06-15T09:52:19.322Z",
        "user_id": null
    },
    {
        "id": 471,
        "country": "marshall islands",
        "country_iso_code": "mh",
        "created_at": "2021-06-15T09:52:19.396Z",
        "user_id": null
    },
    {
        "id": 470,
        "country": "martinique",
        "country_iso_code": "mq",
        "created_at": "2021-06-15T09:52:19.395Z",
        "user_id": null
    },
    {
        "id": 417,
        "country": "mauritania",
        "country_iso_code": "mr",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 394,
        "country": "mauritius",
        "country_iso_code": "mu",
        "created_at": "2021-06-15T09:52:18.939Z",
        "user_id": null
    },
    {
        "id": 458,
        "country": "mayotte",
        "country_iso_code": "yt",
        "created_at": "2021-06-15T09:52:19.309Z",
        "user_id": null
    },
    {
        "id": 367,
        "country": "mexico",
        "country_iso_code": "mx",
        "created_at": "2021-06-15T09:52:18.788Z",
        "user_id": null
    },
    {
        "id": 327,
        "country": "micronesia (federated states of)",
        "country_iso_code": "fm",
        "created_at": "2021-06-15T09:52:18.526Z",
        "user_id": null
    },
    {
        "id": 396,
        "country": "moldova, republic of",
        "country_iso_code": "md",
        "created_at": "2021-06-15T09:52:18.938Z",
        "user_id": null
    },
    {
        "id": 333,
        "country": "monaco",
        "country_iso_code": "mc",
        "created_at": "2021-06-15T09:52:18.580Z",
        "user_id": null
    },
    {
        "id": 436,
        "country": "mongolia",
        "country_iso_code": "mn",
        "created_at": "2021-06-15T09:52:19.201Z",
        "user_id": null
    },
    {
        "id": 379,
        "country": "montenegro",
        "country_iso_code": "me",
        "created_at": "2021-06-15T09:52:18.840Z",
        "user_id": null
    },
    {
        "id": 368,
        "country": "montserrat",
        "country_iso_code": "ms",
        "created_at": "2021-06-15T09:52:18.829Z",
        "user_id": null
    },
    {
        "id": 349,
        "country": "morocco",
        "country_iso_code": "ma",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 398,
        "country": "mozambique",
        "country_iso_code": "mz",
        "created_at": "2021-06-15T09:52:18.938Z",
        "user_id": null
    },
    {
        "id": 462,
        "country": "myanmar",
        "country_iso_code": "mm",
        "created_at": "2021-06-15T09:52:19.322Z",
        "user_id": null
    },
    {
        "id": 435,
        "country": "namibia",
        "country_iso_code": "na",
        "created_at": "2021-06-15T09:52:19.190Z",
        "user_id": null
    },
    {
        "id": 407,
        "country": "nauru",
        "country_iso_code": "nr",
        "created_at": "2021-06-15T09:52:19.029Z",
        "user_id": null
    },
    {
        "id": 352,
        "country": "nepal",
        "country_iso_code": "np",
        "created_at": "2021-06-15T09:52:18.732Z",
        "user_id": null
    },
    {
        "id": 350,
        "country": "netherlands",
        "country_iso_code": "nl",
        "created_at": "2021-06-15T09:52:18.688Z",
        "user_id": null
    },
    {
        "id": 451,
        "country": "new caledonia",
        "country_iso_code": "nc",
        "created_at": "2021-06-15T09:52:19.242Z",
        "user_id": null
    },
    {
        "id": 497,
        "country": "new zealand",
        "country_iso_code": "nz",
        "created_at": "2021-06-15T09:52:19.586Z",
        "user_id": null
    },
    {
        "id": 377,
        "country": "nicaragua",
        "country_iso_code": "ni",
        "created_at": "2021-06-15T09:52:18.840Z",
        "user_id": null
    },
    {
        "id": 481,
        "country": "niger",
        "country_iso_code": "ne",
        "created_at": "2021-06-15T09:52:19.423Z",
        "user_id": null
    },
    {
        "id": 363,
        "country": "nigeria",
        "country_iso_code": "ng",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 466,
        "country": "niue",
        "country_iso_code": "nu",
        "created_at": "2021-06-15T09:52:19.331Z",
        "user_id": null
    },
    {
        "id": 439,
        "country": "norfolk island",
        "country_iso_code": "nf",
        "created_at": "2021-06-15T09:52:19.211Z",
        "user_id": null
    },
    {
        "id": 401,
        "country": "northern mariana islands",
        "country_iso_code": "mp",
        "created_at": "2021-06-15T09:52:18.993Z",
        "user_id": null
    },
    {
        "id": 303,
        "country": "north macedonia",
        "country_iso_code": "mk",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 496,
        "country": "norway",
        "country_iso_code": "no",
        "created_at": "2021-06-15T09:52:19.524Z",
        "user_id": null
    },
    {
        "id": 387,
        "country": "oman",
        "country_iso_code": "om",
        "created_at": "2021-06-15T09:52:18.926Z",
        "user_id": null
    },
    {
        "id": 486,
        "country": "pakistan",
        "country_iso_code": "pk",
        "created_at": "2021-06-15T09:52:19.476Z",
        "user_id": null
    },
    {
        "id": 399,
        "country": "palau",
        "country_iso_code": "pw",
        "created_at": "2021-06-15T09:52:18.940Z",
        "user_id": null
    },
    {
        "id": 498,
        "country": "palestine, state of",
        "country_iso_code": "ps",
        "created_at": "2021-06-15T09:52:19.597Z",
        "user_id": null
    },
    {
        "id": 438,
        "country": "panama",
        "country_iso_code": "pa",
        "created_at": "2021-06-15T09:52:19.209Z",
        "user_id": null
    },
    {
        "id": 400,
        "country": "papua new guinea",
        "country_iso_code": "pg",
        "created_at": "2021-06-15T09:52:18.940Z",
        "user_id": null
    },
    {
        "id": 473,
        "country": "paraguay",
        "country_iso_code": "py",
        "created_at": "2021-06-15T09:52:19.400Z",
        "user_id": null
    },
    {
        "id": 426,
        "country": "peru",
        "country_iso_code": "pe",
        "created_at": "2021-06-15T09:52:19.131Z",
        "user_id": null
    },
    {
        "id": 430,
        "country": "philippines",
        "country_iso_code": "ph",
        "created_at": "2021-06-15T09:52:19.135Z",
        "user_id": null
    },
    {
        "id": 448,
        "country": "pitcairn",
        "country_iso_code": "pn",
        "created_at": "2021-06-15T09:52:19.231Z",
        "user_id": null
    },
    {
        "id": 420,
        "country": "poland",
        "country_iso_code": "pl",
        "created_at": "2021-06-15T09:52:19.108Z",
        "user_id": null
    },
    {
        "id": 431,
        "country": "portugal",
        "country_iso_code": "pt",
        "created_at": "2021-06-15T09:52:19.133Z",
        "user_id": null
    },
    {
        "id": 488,
        "country": "puerto rico",
        "country_iso_code": "pr",
        "created_at": "2021-06-15T09:52:19.492Z",
        "user_id": null
    },
    {
        "id": 432,
        "country": "qatar",
        "country_iso_code": "qa",
        "created_at": "2021-06-15T09:52:19.141Z",
        "user_id": null
    },
    {
        "id": 364,
        "country": "réunion",
        "country_iso_code": "re",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 402,
        "country": "romania",
        "country_iso_code": "ro",
        "created_at": "2021-06-15T09:52:19.017Z",
        "user_id": null
    },
    {
        "id": 474,
        "country": "russian federation",
        "country_iso_code": "ru",
        "created_at": "2021-06-15T09:52:19.400Z",
        "user_id": null
    },
    {
        "id": 495,
        "country": "rwanda",
        "country_iso_code": "rw",
        "created_at": "2021-06-15T09:52:19.522Z",
        "user_id": null
    },
    {
        "id": 342,
        "country": "saint barthélemy",
        "country_iso_code": "bl",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 370,
        "country": "saint helena, ascension and tristan da cunha",
        "country_iso_code": "sh",
        "created_at": "2021-06-15T09:52:18.829Z",
        "user_id": null
    },
    {
        "id": 464,
        "country": "saint kitts and nevis",
        "country_iso_code": "kn",
        "created_at": "2021-06-15T09:52:19.322Z",
        "user_id": null
    },
    {
        "id": 465,
        "country": "saint lucia",
        "country_iso_code": "lc",
        "created_at": "2021-06-15T09:52:19.322Z",
        "user_id": null
    },
    {
        "id": 456,
        "country": "saint martin (french part)",
        "country_iso_code": "mf",
        "created_at": "2021-06-15T09:52:19.308Z",
        "user_id": null
    },
    {
        "id": 369,
        "country": "saint pierre and miquelon",
        "country_iso_code": "pm",
        "created_at": "2021-06-15T09:52:18.829Z",
        "user_id": null
    },
    {
        "id": 487,
        "country": "saint vincent and the grenadines",
        "country_iso_code": "vc",
        "created_at": "2021-06-15T09:52:19.492Z",
        "user_id": null
    },
    {
        "id": 305,
        "country": "samoa",
        "country_iso_code": "ws",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 263,
        "country": "san marino",
        "country_iso_code": "sm",
        "created_at": "2021-06-15T09:52:16.332Z",
        "user_id": null
    },
    {
        "id": 266,
        "country": "sao tome and principe",
        "country_iso_code": "st",
        "created_at": "2021-06-15T09:52:16.331Z",
        "user_id": null
    },
    {
        "id": 371,
        "country": "saudi arabia",
        "country_iso_code": "sa",
        "created_at": "2021-06-15T09:52:18.829Z",
        "user_id": null
    },
    {
        "id": 267,
        "country": "senegal",
        "country_iso_code": "sn",
        "created_at": "2021-06-15T09:52:16.331Z",
        "user_id": null
    },
    {
        "id": 478,
        "country": "serbia",
        "country_iso_code": "rs",
        "created_at": "2021-06-15T09:52:19.423Z",
        "user_id": null
    },
    {
        "id": 446,
        "country": "seychelles",
        "country_iso_code": "sc",
        "created_at": "2021-06-15T09:52:19.224Z",
        "user_id": null
    },
    {
        "id": 357,
        "country": "sierra leone",
        "country_iso_code": "sl",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 355,
        "country": "singapore",
        "country_iso_code": "sg",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 281,
        "country": "sint maarten (dutch part)",
        "country_iso_code": "sx",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 257,
        "country": "slovakia",
        "country_iso_code": "sk",
        "created_at": "2021-06-15T09:52:15.966Z",
        "user_id": null
    },
    {
        "id": 489,
        "country": "slovenia",
        "country_iso_code": "si",
        "created_at": "2021-06-15T09:52:19.492Z",
        "user_id": null
    },
    {
        "id": 360,
        "country": "solomon islands",
        "country_iso_code": "sb",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 404,
        "country": "somalia",
        "country_iso_code": "so",
        "created_at": "2021-06-15T09:52:19.019Z",
        "user_id": null
    },
    {
        "id": 475,
        "country": "south africa",
        "country_iso_code": "za",
        "created_at": "2021-06-15T09:52:19.400Z",
        "user_id": null
    },
    {
        "id": 386,
        "country": "south georgia and the south sandwich islands",
        "country_iso_code": "gs",
        "created_at": "2021-06-15T09:52:18.926Z",
        "user_id": null
    },
    {
        "id": 425,
        "country": "south sudan",
        "country_iso_code": "ss",
        "created_at": "2021-06-15T09:52:19.130Z",
        "user_id": null
    },
    {
        "id": 485,
        "country": "spain",
        "country_iso_code": "es",
        "created_at": "2021-06-15T09:52:19.425Z",
        "user_id": null
    },
    {
        "id": 409,
        "country": "sri lanka",
        "country_iso_code": "lk",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 418,
        "country": "sudan",
        "country_iso_code": "sd",
        "created_at": "2021-06-15T09:52:19.090Z",
        "user_id": null
    },
    {
        "id": 274,
        "country": "suriname",
        "country_iso_code": "sr",
        "created_at": "2021-06-15T09:52:16.908Z",
        "user_id": null
    },
    {
        "id": 494,
        "country": "svalbard and jan mayen",
        "country_iso_code": "sj",
        "created_at": "2021-06-15T09:52:19.522Z",
        "user_id": null
    },
    {
        "id": 483,
        "country": "sweden",
        "country_iso_code": "se",
        "created_at": "2021-06-15T09:52:19.427Z",
        "user_id": null
    },
    {
        "id": 277,
        "country": "switzerland",
        "country_iso_code": "ch",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 406,
        "country": "syrian arab republic",
        "country_iso_code": "sy",
        "created_at": "2021-06-15T09:52:19.025Z",
        "user_id": null
    },
    {
        "id": 354,
        "country": "taiwan, province of china",
        "country_iso_code": "tw",
        "created_at": "2021-06-15T09:52:18.736Z",
        "user_id": null
    },
    {
        "id": 273,
        "country": "tajikistan",
        "country_iso_code": "tj",
        "created_at": "2021-06-15T09:52:16.908Z",
        "user_id": null
    },
    {
        "id": 297,
        "country": "tanzania, united republic of",
        "country_iso_code": "tz",
        "created_at": "2021-06-15T09:52:18.253Z",
        "user_id": null
    },
    {
        "id": 447,
        "country": "thailand",
        "country_iso_code": "th",
        "created_at": "2021-06-15T09:52:19.231Z",
        "user_id": null
    },
    {
        "id": 343,
        "country": "timor-leste",
        "country_iso_code": "tl",
        "created_at": "2021-06-15T09:52:18.640Z",
        "user_id": null
    },
    {
        "id": 270,
        "country": "togo",
        "country_iso_code": "tg",
        "created_at": "2021-06-15T09:52:16.704Z",
        "user_id": null
    },
    {
        "id": 476,
        "country": "tokelau",
        "country_iso_code": "tk",
        "created_at": "2021-06-15T09:52:19.423Z",
        "user_id": null
    },
    {
        "id": 293,
        "country": "tonga",
        "country_iso_code": "to",
        "created_at": "2021-06-15T09:52:17.883Z",
        "user_id": null
    },
    {
        "id": 484,
        "country": "trinidad and tobago",
        "country_iso_code": "tt",
        "created_at": "2021-06-15T09:52:19.425Z",
        "user_id": null
    },
    {
        "id": 384,
        "country": "tunisia",
        "country_iso_code": "tn",
        "created_at": "2021-06-15T09:52:18.891Z",
        "user_id": null
    },
    {
        "id": 442,
        "country": "turkey",
        "country_iso_code": "tr",
        "created_at": "2021-06-15T09:52:19.224Z",
        "user_id": null
    },
    {
        "id": 282,
        "country": "turkmenistan",
        "country_iso_code": "tm",
        "created_at": "2021-06-15T09:52:17.092Z",
        "user_id": null
    },
    {
        "id": 422,
        "country": "turks and caicos islands",
        "country_iso_code": "tc",
        "created_at": "2021-06-15T09:52:19.118Z",
        "user_id": null
    },
    {
        "id": 455,
        "country": "tuvalu",
        "country_iso_code": "tv",
        "created_at": "2021-06-15T09:52:19.298Z",
        "user_id": null
    },
    {
        "id": 428,
        "country": "uganda",
        "country_iso_code": "ug",
        "created_at": "2021-06-15T09:52:19.130Z",
        "user_id": null
    },
    {
        "id": 298,
        "country": "ukraine",
        "country_iso_code": "ua",
        "created_at": "2021-06-15T09:52:18.253Z",
        "user_id": null
    },
    {
        "id": 252,
        "country": "united arab emirates",
        "country_iso_code": "ae",
        "created_at": "2021-06-15T09:52:15.604Z",
        "user_id": null
    },
    {
        "id": 459,
        "country": "united kingdom of great britain and northern ireland",
        "country_iso_code": "gb",
        "created_at": "2021-06-15T09:52:19.322Z",
        "user_id": null
    },
    {
        "id": 295,
        "country": "united states minor outlying islands",
        "country_iso_code": "um",
        "created_at": "2021-06-15T09:52:17.883Z",
        "user_id": null
    },
    {
        "id": 390,
        "country": "united states of america",
        "country_iso_code": "us",
        "created_at": "2021-06-15T09:52:18.935Z",
        "user_id": null
    },
    {
        "id": 288,
        "country": "uruguay",
        "country_iso_code": "uy",
        "created_at": "2021-06-15T09:52:17.521Z",
        "user_id": null
    },
    {
        "id": 375,
        "country": "uzbekistan",
        "country_iso_code": "uz",
        "created_at": "2021-06-15T09:52:18.833Z",
        "user_id": null
    },
    {
        "id": 449,
        "country": "vanuatu",
        "country_iso_code": "vu",
        "created_at": "2021-06-15T09:52:19.242Z",
        "user_id": null
    },
    {
        "id": 299,
        "country": "venezuela (bolivarian republic of)",
        "country_iso_code": "ve",
        "created_at": "2021-06-15T09:52:18.253Z",
        "user_id": null
    },
    {
        "id": 316,
        "country": "viet nam",
        "country_iso_code": "vn",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 292,
        "country": "virgin islands (british)",
        "country_iso_code": "vg",
        "created_at": "2021-06-15T09:52:17.883Z",
        "user_id": null
    },
    {
        "id": 410,
        "country": "virgin islands (u.s.)",
        "country_iso_code": "vi",
        "created_at": "2021-06-15T09:52:19.040Z",
        "user_id": null
    },
    {
        "id": 472,
        "country": "wallis and futuna",
        "country_iso_code": "wf",
        "created_at": "2021-06-15T09:52:19.396Z",
        "user_id": null
    },
    {
        "id": 361,
        "country": "western sahara",
        "country_iso_code": "eh",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    },
    {
        "id": 302,
        "country": "yemen",
        "country_iso_code": "ye",
        "created_at": "2021-06-15T09:52:18.424Z",
        "user_id": null
    },
    {
        "id": 424,
        "country": "zambia",
        "country_iso_code": "zm",
        "created_at": "2021-06-15T09:52:19.116Z",
        "user_id": null
    },
    {
        "id": 359,
        "country": "zimbabwe",
        "country_iso_code": "zw",
        "created_at": "2021-06-15T09:52:18.735Z",
        "user_id": null
    }
]
};

const useGetAllCountries = () => {
  const [countries, setCountries] = useState([]);
  const {data: listCountries, isLoading} = useQuery(
    'countries',
    fetchAllCountries,
    {
      onSuccess: (data) => {
        const flattenedArr = data.flat();
        setCountries(flattenedArr);
      },
    },
  );

  return {
    countries,
    isLoading,
  };
};

export default useGetAllCountries;
