import React, {useState} from 'react';

import Tab from '../../../components/reusable/Tab/Tab';
import Label from './Label';
const LabelManagement = () => {
  const [activeKey, setActiveKey] = useState<string | number>('0');

  return (
    <>
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: 'Labels',
            component: <Label />,
          },
        ]}
      />
    </>
  );
};

export default LabelManagement;
