export const getTrackOrder = (tracks): number => {
  let numberTracksInAlbum;
  if (tracks) {
    numberTracksInAlbum = tracks.length;
    return numberTracksInAlbum || numberTracksInAlbum !== 0
      ? numberTracksInAlbum + 1
      : 1;
  }
  return 1;
};
