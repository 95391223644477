import styled from '@emotion/styled';
import {render} from '@testing-library/react';
import {FormControl} from 'baseui/form-control';
import {Input} from 'baseui/input';
import {Theme} from 'baseui/theme';
import {ErrorMessage, Field} from 'formik';
import React, {useState} from 'react';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';

import TextError from './TextError';

const ToggleBtn = (props) => {
  const [toggle, setToggle] = useState(false);

  const toggleHide = () => {
    setToggle(!toggle);
  };
  const renderIcon = (value) => {
    if (value) {
      return <AiFillEye />;
    } else {
      return <AiFillEyeInvisible />;
    }
  };

  return (
    <ToggleContainer onClick={toggleHide}>{renderIcon(toggle)}</ToggleContainer>
  );
};

const ToggleContainer = styled.div`
  border: 1px solid red;
`;

export const Label = ({data, isRequired = true}) => {
  return (
    <LabelStyle>
      <div>{data.children}</div>
      {isRequired && <span>*</span>}
    </LabelStyle>
  );
};

export const LabelStyle = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 4px 0;
  color: #333333;
  font-weight: bold;

  span {
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-left: 5px;
    color: #dc3832;
    font-weight: bold;
  }
`;

const BaseInput = (props) => {
  const {
    label,
    name,
    initialValue,
    setFieldValue,
    startEnhancer,
    type,
    disabled,
    placeholder,
    isRequired,
    setFieldTouched,
    ...rest
  } = props;
  const [value, setValue] = React.useState(initialValue);

  const onChangeHandler = (event) => {
    setValue(event.target.value);
    setFieldValue(name, event.target.value);
    if (setFieldTouched) {
      setFieldTouched(name, true);
    }
  };

  return (
    <div
      style={{
        marginBottom: '15px',
      }}
    >
      <Field id={name} name={name} {...rest}>
        {({field, form}) => (
          <FormControl
            overrides={{
              ControlContainer: {
                style: {
                  marginBottom: '5px',
                },
              },
              Label: (data) => <Label data={data} isRequired={isRequired} />,
            }}
            label={`${label}`}
          >
            <Input
              disabled={disabled}
              placeholder={placeholder}
              type={type}
              startEnhancer={startEnhancer ? startEnhancer : null}
              overrides={{
                Root: {
                  style: {
                    borderBottomWidth: '0px',
                    borderTopWidth: '0px',
                    borderRightWidth: '0px',
                    borderLeftWidth: '0px',
                    borderTopLeftRadius: '5px',
                    borderBottomLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  },
                },

                Input: {
                  style: ({$theme}: any) => {
                    return {
                      borderBottomWidth: '0px',
                      borderTopWidth: '0px',
                      borderRightWidth: '0px',
                      borderLeftWidth: '0px',
                      backgroundColor: $theme.inputBg,
                      borderTopLeftRadius: '5px',
                      borderBottomLeftRadius: '5px',
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    };
                  },
                },
              }}
              {...field}
              value={value}
              onChange={(e) => onChangeHandler(e)}
            />
          </FormControl>
        )}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default BaseInput;
