import {useQuery} from 'react-query';

const fetchAlbumDetails = async (key, {albumId}) => {
  if (albumId) {
    // get Album details from local storage by albumId
    const albumDetails = JSON.parse(localStorage.getItem('albumData'));
    const albumDetail = albumDetails.find((album) => album.id == albumId);
    return albumDetail;
  } else {
    console.log('albumId not found');
  }
};

const useGetAlbumsData = (props) => {
  const {albumId} = props;
  const {data: getAlbumDetail, refetch, isFetching, isLoading} = useQuery(
    ['albumDetails', {albumId}],
    fetchAlbumDetails,
  );

  return {getAlbumDetail, refetch, isFetching, isLoading};
};

export default useGetAlbumsData;
