/** @format */

export const filmZone = [
  {id: 'Bhojiwood', value: 'Bhojiwood'},
  {id: 'Bollywood', value: 'Bollywood'},
  {id: 'Chhollywood', value: 'Chhollywood'},
  {id: 'Chinawood', value: 'Chinawood'},
  {id: 'Classical', value: 'Classical'},
  {id: 'Coastalwood', value: 'Coastalwood'},
  {id: 'Devotional', value: 'Devotional'},
  {id: 'Gollywood', value: 'Gollywood'},
  {id: 'Görliwood', value: 'Görliwood'},
  {id: 'Hallyuwood', value: 'Hallyuwood'},
  {id: 'Hogawood', value: 'Hogawood'},
  {id: 'Hollywood', value: 'Hollywood'},
  {id: 'Jollywood', value: 'Jollywood'},
  {id: 'Kollywood', value: 'Kollywood'},
  {id: 'Malaysian Cinema', value: 'Malaysian Cinema'},
  {id: 'Mollywood', value: 'Mollywood'},
  {id: 'Ollywood', value: 'Ollywood'},
  {id: 'Pollywood', value: 'Pollywood'},
  {id: 'Sandalwood', value: 'Sandalwood'},
  {id: 'Tollywood', value: 'Tollywood'},
  {id: 'Trollywood', value: 'Trollywood'},
];

export const nonFilmZone = [
  {id: 'Indie', value: 'Indie'},
  {id: 'Devotional', value: 'Devotional'},
  {
    id: 'Classical',
    value: 'Classical',
  },
];

export const albumType = [
  {key: 'Film', value: 'Film'},
  {key: 'Non-film', value: 'Non-Film'},
];

export const albumReleaseType = [
  {key: 'Single', value: 'Single'},
  {key: 'Album', value: 'Album'},
];

export const genre = [
  {id: 'Alternative', value: 'Alternative'},
  {id: 'Americana', value: 'Americana'},
  {id: 'Big Band', value: 'Big Band'},
  {id: 'Blues', value: 'Blues'},
  {id: 'Carnatic', value: 'Carnatic'},
  {id: "Children's Music", value: "Children's Music"},
  {id: 'Christian/Gospel', value: 'Christian/Gospel'},
  {id: 'Classical', value: 'Classical'},
  {id: 'Comedy', value: 'Comedy'},
  {id: 'Country', value: 'Country'},
  {id: 'C-Pop', value: 'C-Pop'},
  {id: 'Dance', value: 'Dance'},
  {id: 'Devotional', value: 'Devotional'},
  {id: 'Electronic', value: 'Electronic'},
  {id: 'Fitness & Workout', value: 'Fitness & Workout'},
  {id: 'Folk', value: 'Folk'},
  {id: 'French Pop', value: 'French Pop'},
  {id: 'Fusion', value: 'Fusion'},
  {id: 'German Folk', value: 'German Folk'},
  {id: 'German Pop', value: 'German Pop'},
  {id: 'Ghazals', value: 'Ghazals'},
  {id: 'Heavy Metal', value: 'Heavy Metal'},
  {id: 'Hip Hop', value: 'Hip Hop'},
  {id: 'Hip Hop/Rap', value: 'Hip Hop/Rap'},
  {id: 'Holiday', value: 'Holiday'},
  {id: 'Indian', value: 'Indian'},
  {id: 'Instrumental', value: 'Instrumental'},
  {id: 'J Pop', value: 'J Pop'},
  {id: 'Jazz', value: 'Jazz'},
  {id: 'K Pop', value: 'K Pop'},
  {id: 'Karaoke', value: 'Karaoke'},
  {id: 'Latin', value: 'Latin'},
  {id: 'Mappila', value: 'Mappila'},
  {id: 'Melody', value: 'Melody'},
  {id: 'Patriotic', value: 'Patriotic'},
  {id: 'Pop', value: 'Pop'},
  {id: 'Qawwali', value: 'Qawwali'},
  {id: 'R&B', value: 'R&B'},
  {id: 'R&B/Soul', value: 'R&B/Soul'},
  {id: 'Reggae', value: 'Reggae'},
  {id: 'Rock', value: 'Rock'},
  {id: 'Singer/Songwriter', value: 'Singer/Songwriter'},
  {id: 'Soul', value: 'Soul'},
  {id: 'Soundtrack', value: 'Soundtrack'},
  {id: 'Spoken Word', value: 'Spoken Word'},
  {id: 'Sufi', value: 'Sufi'},
  {id: 'Vocal', value: 'Vocal'},
  {id: 'World', value: 'World'},
];

export const musicals = [
  {id: true, value: 'Yes'},
  {id: false, value: 'No'},
];

export const track_musicals = [
  {id: true, value: 'Yes'},
  {id: false, value: 'No'},
];

export const track_genre = [
  {id: 'Americana', value: 'Americana'},
  {id: 'Big Band', value: 'Big Band'},
  {id: 'Blues', value: 'Blues'},
  {id: "Children's Music", value: "Children's Music"},
  {id: 'Christian/Gospel', value: 'Christian/Gospel'},
  {id: 'Comedy', value: 'Comedy'},
  {id: 'Country', value: 'Country'},
  {id: 'Dance', value: 'Dance'},
  {id: 'Electronic', value: 'Electronic'},
  {id: 'Folk', value: 'Folk'},
  {id: 'Fitness & Workout', value: 'Fitness & Workout'},
  {id: 'Country', value: 'Country'},
  {id: 'French Pop', value: 'French Pop'},
  {id: 'German Folk', value: 'German Folk'},
  {id: 'German Pop', value: 'German Pop'},
  {id: 'Heavy Metal', value: 'Heavy Metal'},
  {id: 'Hip Hop/Rap', value: 'Hip Hop/Rap'},
  {id: 'Holiday', value: 'Holiday'},
  {id: 'Indian', value: 'Indian'},
  {id: 'Instrumental', value: 'Instrumental'},
  {id: 'J Pop', value: 'J Pop'},
  {id: 'Jazz', value: 'Jazz'},
  {id: 'K Pop', value: 'K Pop'},
  {id: 'Karaoke', value: 'Karaoke'},
  {id: 'Latin', value: 'Latin'},
  {id: 'Patriotic', value: 'Patriotic'},
  {id: 'Pop', value: 'Pop'},
  {id: 'R&B/Soul', value: 'R&B/Soul'},
  {id: 'R, B', value: 'R, B'},
  {id: 'R&B', value: 'R&B'},
  {id: 'Soul', value: 'Soul'},
  {id: 'Reggae', value: 'Reggae'},
  {id: 'Rock', value: 'Rock'},
  {id: 'Singer/Songwriter', value: 'Singer/Songwriter'},
  {id: 'Soundtrack', value: 'Soundtrack'},
  {id: 'Spoken Word', value: 'Spoken Word'},
  {id: 'Vocal', value: 'Vocal'},
  {id: 'World', value: 'World'},
  {id: 'Alternative', value: 'Alternative'},
  {id: 'Fusion', value: 'Fusion'},
  {id: 'Classical', value: 'Classical'},
  {id: 'Sufi', value: 'Sufi'},
  {id: 'Mappila', value: 'Mappila'},
  {id: 'Ghazals', value: 'Ghazals'},
  {id: 'Qawwali', value: 'Qawwali'},
  {id: 'Devotional', value: 'Devotional'},
  {id: 'C-Pop', value: 'C-Pop'},
];

export const territoryOptions = [
  {key: 'Worldwide', value: 'Wworldwide'},
  {key: 'Exclude', value: 'exclude'},
  {key: 'Include', value: 'include'},
];
