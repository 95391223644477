import {PLACEMENT, toaster, ToasterContainer} from 'baseui/toast';
import React, {useContext, useEffect, useState} from 'react';
import {useQueryCache} from 'react-query';

import {downloadFromAnchor} from '../helpers/downloadFromAnchor';
import {GlobalContext} from './GlobalProvider';

export const NotificationContext = React.createContext({
  showToast: (message, status) => {
    return null;
  },
});

export default function NotificationProvider({children}) {
  const {state, dispatch} = useContext(GlobalContext);
  const queryCache = useQueryCache();

  const [processingKey, setProcessingKey] = useState(1);

  useEffect(() => {
    if (state.uploadStatus === 2) {
      toaster.show('Processing...please wait', {
        autoFocus: true,
        closeable: false,
        autoHideDuration: 0,
        key: processingKey,
      });
    } else if (state.uploadStatus === 1) {
      if (state.valid_upc_count) {
        queryCache.invalidateQueries('upc');
        toaster.show(`We have processed ${state.valid_upc_count} UPC's`, {
          autoFocus: true,
          closeable: false,
          autoHideDuration: 5000,
          kind: 'positive',
        });
      }

      toaster.clear(processingKey);
    } else if (state.uploadStatus === 3) {
      if (state.duplicate_upc.length > 0) {
        toaster.show(`${state.duplicate_upc?.length} ${state.error}`, {
          autoHideDuration: 5000,
          kind: 'negative',
        });
      }

      if (state.invalid_upc.length > 0) {
        toaster.show(`${state.invalid_upc?.length} ${state.error}`, {
          autoHideDuration: 5000,
          kind: 'negative',
        });
      }
      if (state.error) {
        toaster.show(`${state.error}`, {
          autoHideDuration: 5000,
          kind: 'negative',
        });
      }
      toaster.clear(processingKey);
    }
  }, [state]);

  useEffect(() => {
    if (state.resource_link) {
      downloadFromAnchor(state.resource_link);
      toaster.show(`Exported Sucessfully`, {
        autoFocus: true,
        closeable: false,
        autoHideDuration: 5000,
        kind: 'positive',
      });
    }
  }, [state.resource_link]);

  const [toastKey, setToastKey] = useState(null);

  const showToast = (msg, status) => {
    switch (status) {
      case 'success':
        return setToastKey(toaster.positive(msg));
      case 'error':
        return setToastKey(toaster.negative(msg));
      case 'warning':
        return setToastKey(toaster.warning(msg));
      default:
        return setToastKey(toaster.info(msg));
    }
  };
  const contextValue = {
    showToast: (message, status) => showToast(message, status),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      <ToasterContainer
        overrides={{
          Root: {
            style: {
              zIndex: 9999,
            },
          },
        }}
        placement={PLACEMENT.topRight}
        autoHideDuration={3000}
      >
        {children}
      </ToasterContainer>
    </NotificationContext.Provider>
  );
}
