import styled from '@emotion/styled';
import React from 'react';

function FooterComponent(props) {
  const {lable, setShowDialouge, value, setShowAll, setHide} = props;

  return (
    <div>
      <Container>
        <button
          onClick={() => {
            setShowDialouge(false);
            setShowAll(false);
          }}
          disabled={value.length === 0}
          className={value.length === 0 ? 'disable' : 'enable'}
        >
          {lable}
        </button>
      </Container>
    </div>
  );
}
const Container = styled.div`
  .disable {
    cursor: not-allowed;
    // pointer-events:none;
    color: #666666;
    background: #cccccc;
  }
  background: #f8f8f8;
  width: 380px;
  padding: 8px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 99;
  button {
    padding: 6px 25px;
    background: #d21919;
    border-radius: 100px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
  }
`;
export default FooterComponent;
