import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';
import useLocalStorage from '../reusable/useLocalStorage';

const updateTrackFn = async (request) => {
  const {id, data} = request;
  try {
    return await axios({
      method: 'PUT',
      url: `/track/update-status/${id}`,
      data: data,
    });
  } catch (error) {
    logError(error, 4);
  }
};

const updateAlbumFn = async (request) => {
  const {id, data} = request;

  try {
    return await axios({
      method: 'PUT',
      url: `/album/update-status/${id}`,
      data: data,
    });
  } catch (error) {
    logError(error, 4);

    return error.response?.error;
  }
};

const useChangeStatus = () => {
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const [
    updateTrackMutation,
    {status: trackStatus, isLoading: trackLoading, error: trackError},
  ] = useMutation(updateTrackFn, {
    onSuccess: () => {
      queryCache.invalidateQueries('track');
      queryCache.invalidateQueries('album');
      queryCache.invalidateQueries('albums');
      queryCache.invalidateQueries('singleAlbum');
    },
  });

  const [
    updateAlbumMutation,
    {status: albumStatus, isLoading: albumLoading, error: albumError},
  ] = useMutation(updateAlbumFn, {
    onSuccess: () => {
      queryCache.invalidateQueries('album');
      queryCache.invalidateQueries('albums');
      queryCache.invalidateQueries('track');
      queryCache.invalidateQueries('singleAlbum');
    },
  });

  const updateStatus = async (data) => {
    const trackIds = [...data.trackData];
    const trackData = {
      status: data.status,
      updated_by: currentUser.id,
    };
    const albumData = {
      id: data.albumId,
      data: {
        status: data.status,
        updated_by: currentUser.id,
      },
    };

    const resalbum = await updateAlbumMutation(albumData);

    trackIds.forEach(async (id) => {
      try {
        const params = {id: id.trackId, data: trackData};
        const restrack = await updateTrackMutation(params);
      } catch (error) {
        logError(error, 4);
      }
    });
  };

  return {updateStatus, albumLoading, trackLoading};
};

export default useChangeStatus;
