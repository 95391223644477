import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Tab, Tabs} from 'baseui/tabs-motion';
import {Form, Formik} from 'formik';
import React from 'react';

import Button from '../../../components/reusable/Button/Button';
import ConfirmModal from '../../../components/reusable/ConfirmModal/ConfirmModal';
import FormikControl from '../../../components/reusable/Formik/FormikControl';
import SkeletonLoading from '../../../components/reusable/SkeletonLoading/SkeletonLoading';
import {SubmitButton} from '../../../components/reusable/SubmitButton/SubmitButton';
import useNotification from '../../../Contexts/useNotification';
import useModal from '../../../hooks/Modal/useModal';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import useDisableUser from '../../../hooks/UserManagement/useDisableUser';
import useUserForm from '../../../hooks/UserManagement/useUserForm';
import {usermanagementValidationSchema} from '../../../validation/UserManagement/usermanagementForm';

const UserManagementFormContainer = styled.div`
  margin: 0 -30px;

  .disable {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export default function UserManagementForms(props) {
  const {
    selectedData,
    setIsOpen,
    setSelectedData,
    useOnClickOutside,
    sidebarRef,
    onCloseHandler,
    refetch,
  } = props;
  const {disableUserMutation, isLoading: isDisableLoading} = useDisableUser();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {isOpen, close, setIsOpen: modalSetter} = useModal();
  const {showToast} = useNotification();
  const [css, theme] = useStyletron();

  const {
    initialValues,
    onSubmit,
    getSingle,
    isCreateUserLoading,
    userLoading,
    isUpdateUserLoading,
  } = useUserForm({selectedData, setIsOpen, setSelectedData, refetch});
  const [tabActiveKey, setTabActiveKey] = React.useState<React.Key>('0');

  const disableUserHandler = async () => {
    if (selectedData?.id) {
      if (selectedData.id === currentUser.id) {
        showToast('User cannot disable self', 'error');
      } else {
        const data = {
          updated_by: currentUser.id,
        };
        const res = await disableUserMutation({id: selectedData.id, data});
        if (res?.status === 204) {
          showToast('User disabled successfully', 'success');
        }
      }
      refetch();
      setIsOpen(false);
      setSelectedData({});
    }
  };

  useOnClickOutside(sidebarRef, () => onCloseHandler());

  return (
    <UserManagementFormContainer>
      <Tabs
        activeKey={tabActiveKey}
        onChange={({activeKey}) => {
          setTabActiveKey(activeKey);
        }}
        activateOnFocus
        overrides={{
          TabHighlight: {
            style: {
              backgroundColor: '#DC3831',
            },
          },
        }}
      >
        <Tab
          title="Details"
          overrides={{
            Tab: {
              style: {
                background: 'white',
              },
            },
          }}
        >
          {selectedData && !selectedData.is_disabled ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {/* <Button
                label="Disable"
                onClick={() => modalSetter(true)}
                size="mini"
              /> */}
            </div>
          ) : null}
          <div
            className={selectedData?.is_disabled ? 'disable' : ''}
            style={{
              padding: '16px',
            }}
          >
            {!userLoading ? (
              <Formik
                initialValues={initialValues}
                validationSchema={usermanagementValidationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div
                        style={
                          getSingle && {pointerEvents: 'none', opacity: '0.7'}
                        }
                      >
                        <FormikControl
                          disabled={getSingle && getSingle.is_disabled}
                          control="baseinput"
                          type="text"
                          label="Name"
                          name="name"
                          initialValue={formik.initialValues.name}
                          setFieldValue={formik.setFieldValue}
                          placeholder="e.g. John"
                        />
                      </div>
                      <FormikControl
                        disabled={getSingle && getSingle.is_disabled}
                        control="baseinput"
                        type="text"
                        label="Phone"
                        name="phone"
                        initialValue={formik.initialValues.phone}
                        setFieldValue={formik.setFieldValue}
                        startEnhancer="+91"
                        placeholder="e.g. 9999999999"
                        isRequired={false}
                      />
                      <div
                        style={
                          getSingle && {pointerEvents: 'none', opacity: '0.7'}
                        }
                      >
                        <FormikControl
                          disabled={getSingle && getSingle.is_disabled}
                          control="baseinput"
                          type="email"
                          label="Email ID"
                          name="email"
                          placeholder="e.g. example@divomusic.com"
                          initialValue={formik.initialValues.email}
                          setFieldValue={formik.setFieldValue}
                        />
                      </div>

                      {!getSingle?.is_disabled ? (
                        <SubmitButton
                          disabled={
                            !formik.isValid ||
                            (Object.keys(formik.touched).length === 0 &&
                              formik.touched.constructor === Object)
                          }
                          type="submit"
                          label={`${
                            selectedData
                              ? isUpdateUserLoading
                                ? 'Updating User'
                                : 'Update User'
                              : isCreateUserLoading
                              ? 'Adding User'
                              : 'Add User'
                          } `}
                          isLoading={isCreateUserLoading || isUpdateUserLoading}
                        />
                      ) : null}
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <SkeletonLoading />
            )}
          </div>
        </Tab>
      </Tabs>
      {isOpen && (
        <ConfirmModal
          sidebarRef={sidebarRef}
          isOpen={isOpen}
          close={close}
          title="Are you sure ?"
          renderBtn={
            <Button
              onClick={() => disableUserHandler()}
              label={`Disable ${getSingle?.name}`}
              size="default"
              isLoading={isDisableLoading}
            />
          }
        />
      )}
    </UserManagementFormContainer>
  );
}
