import {useStyletron} from 'baseui';
import {StatefulTabs as Tabs, Tab} from 'baseui/tabs';
import React from 'react';

import {
  rootStyle,
  TabBar,
  TabContent,
  TabCustomComponent,
} from './TabOverrides';

const TabComponent = ({activeKey, setActiveKey, content}) => {
  const [css, theme] = useStyletron();
  return (
    <Tabs
      initialState={{activeKey: activeKey}}
      onChange={({activeKey}) => {
        setActiveKey(activeKey);
      }}
      overrides={{
        Tab: {
          component: (data) => <TabCustomComponent data={data} theme={theme} />,
        },
        Root: rootStyle,
        TabBar,
        TabContent,
      }}
    >
      {content.map((item, i) => {
        return (
          <Tab key={i} title={item.title}>
            {item.component}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default TabComponent;
