/** @format */

import React from 'react';
import {Notification, KIND} from 'baseui/notification';
function DetailsError(props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: 0,
      }}
    >
      <Notification
        overrides={{
          InnerContainer: {
            style: {
              display: 'flex',
              width: '100%',
              margin: 0,
              backgroundColor: 'none',
            },
          },
          Body: {
            style: {
              display: 'flex',
              width: '100%',
              margin: '0 0 !important',
              backgroundColor: 'none',
            },
          },
        }}
        kind={KIND.negative}
        autoHideDuration={0 || props.hideDuration}
      >
        {() => props.children}
      </Notification>
    </div>
  );
}

export default DetailsError;
