export const labelData = {
  label_name: {
    Header: 'Label Name',
    accessor: 'label_name',
  },
  contact_name: {
    Header: 'Contact Name',
    accessor: 'contact_name',
  },
  contact_email: {
    Header: 'Contact Email',
    accessor: 'contact_email',
  },
  contact_phone: {
    Header: 'Contact Phone',
    accessor: 'contact_phone',
  },
  created_at: {
    Header: 'Created On',
    accessor: 'created_at',
  },
};
