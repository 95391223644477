export const userData = {
  name: {
    Header: 'Name',
    accessor: 'name',
  },
  email: {
    Header: 'Email',
    accessor: 'email',
  },
  phone: {
    Header: 'Mobile',
    accessor: 'phone',
  },
  created_at: {
    Header: 'Created On',
    accessor: 'created_at',
  },
};
