import styled from '@emotion/styled';
import {Modal, ModalBody, ModalHeader, SIZE} from 'baseui/modal';
import React from 'react';
import {RiErrorWarningFill} from 'react-icons/ri';

import GridTable from '../CreateProject/BulkUpload/GridTable';

export default function ErrorMessageModal({isOpen, close, msg}) {
  return (
    <Modal
      size={SIZE.auto}
      closeable={true}
      isOpen={isOpen}
      onClose={close}
      overrides={{
        Dialog: {
          style: {
            display: 'flex',
            flexDirection: 'column',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            backgroundColor: 'white',
          },
        },
        Root: {
          style: {
            zIndex: 100,
          },
        },
      }}
    >
      {msg.msg || msg.fields?.length ? (
        <>
          <ModalHeader>{msg.msg}</ModalHeader>

          <ModalBody>
            <InfoContainer>
              <RiErrorWarningFill />
              <small>
                Please rectify the input file to restart the process
              </small>
            </InfoContainer>

            {msg.fields?.length && (
              <ErrorBodyContainer>
                <GridTable data={msg.fields} code={msg.msg} />
              </ErrorBodyContainer>
            )}
          </ModalBody>
        </>
      ) : (
        <ModalHeader>{msg}</ModalHeader>
      )}
    </Modal>
  );
}

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  svg {
    margin-right: 5px;
  }
`;

const ErrorBodyContainer = styled.div`
  position: relative;
  max-height: 60vh;
  overflow-y: auto;

  > div {
    margin: 0px;
  }
`;
