import styled from '@emotion/styled';
import {FileUploader} from 'baseui/file-uploader';
import React, {useEffect} from 'react';

import fileIcon from '../../assets/icons/fileicon.svg';

const DragAndDrop = ({data}) => {
  return (
    <FileComponent>
      <div className="file-icon">
        <img src={fileIcon} alt="icon" />
      </div>
      <div className="content">
        Drag And Drop or{' '}
        <span onClick={data?.children[0]?.props.children[2].props.onClick}>
          Browse
        </span>
      </div>
    </FileComponent>
  );
};
const TrackFileUpload = (props) => {
  const {onDropHandler, isrcCode, setFieldTouched, progressInfo} = props;
  useEffect(() => {
    if (progressInfo === 100) {
      setFieldTouched(true);
    }
  }, [progressInfo]);
  return (
    <div>
      <FileUploader
        multiple={false}
        accept="audio/wav,audio/x-wav"
        onChange={(file) => {
          onDropHandler(file, isrcCode);
          // setFieldTouched(true);
        }}
        onDrop={(file) => {
          onDropHandler(file, isrcCode);
          // setFieldTouched(true);
        }}
        overrides={{
          FileDragAndDrop: {
            component: (data) => <DragAndDrop data={data} />,
          },
        }}
      />
    </div>
  );
};

const FileComponent = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
  width: 100%;
  background: rgba(220, 56, 50, 0.1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: dashed 2px #d21919;
  padding: 20px 0;
  margin-bottom: 10px;
  .file-icon {
    img {
      width: 42px;
    }
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 10px;
    span {
      text-decoration: underline;
      color: #d21919;
      cursor: pointer;
    }
  }
`;
export default TrackFileUpload;
