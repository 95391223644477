import axios from 'axios';
import {useState} from 'react';
import {useContext} from 'react';
import {queryCache} from 'react-query';
import {v4 as uuidv4} from 'uuid';
import * as XLSX from 'xlsx';

import {TOKEN, token} from '../config/Axios';
import languages from '../constants/languages';
import {GlobalContext} from '../Contexts/GlobalProvider';
import useLocalStorage from '../hooks/reusable/useLocalStorage';

const BASE_URL = process.env.REACT_APP_Base_URL;

const useUploadMetaFile = (props) => {
  const {metadata} = props;
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const [source, setSource] = useState(null);
  const CancelToken = axios.CancelToken;
  const {state, dispatch} = useContext(GlobalContext);
  const MAIN_TOKEN = token ? TOKEN : 'bearer '.concat(state?.token);

  const uploadFile = async (
    file,
    project_id,
    label_id,
    path_name,
    onUploadProgress,
  ) => {
    let path = '';
    let otherParam;
    const fileID = uuidv4();

    path_name === 'excel'
      ? (path = `${project_id}/${fileID}/input.xlsx`)
      : path_name === 'upc'
      ? (path = `${fileID}/${file.name}`)
      : project_id === ''
      ? (path = `${path_name}/${file.name}`)
      : (path = `${project_id}/${path_name}/${file.name}`);

    const type =
      metadata?.type === 'bulk-upload'
        ? 'bulk-upload'
        : project_id === ''
        ? 'upc'
        : 'manual-upload';

    const fileType = path_name === 'excel' ? 'input' : 'others';

    const data = {
      file_id: fileID, //uuid
      project_id: project_id,
      user_id: currentUser.id,
      filename: file.name,
      type: type, //upc or bulk_upload
    };

    const upcParams = {
      key: path,
      expireTime: 3600,
      type,
      fileType,
    };

    if (path_name === 'excel' && metadata?.territory && metadata?.country) {
      otherParam = {
        key: path,
        expireTime: 3600,
        type,
        fileType,
        territory: metadata.territory,
        country: JSON.stringify(metadata.country),
        access_token: MAIN_TOKEN,
      };
    } else {
      if (!metadata) {
        otherParam = {
          key: path,
          expireTime: 3600,
          type,
          fileType,
        };
      } else {
        otherParam = {
          key: path,
          expireTime: 3600,
          type,
          fileType,
          album_id: metadata.album_id,
          user_id: metadata.user_id,
          action: metadata.action,
          access_token: MAIN_TOKEN,
          track_id: metadata.track_id,
        };
      }
    }

    const params = project_id === '' ? upcParams : otherParam;

    if (path_name === 'excel' || path_name === 'upc') {
      if (path_name === 'excel') {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, {type: 'buffer'});
          const wsname = wb.SheetNames[0];

          const worksheet = wb.Sheets[wsname];

          // Store the column Header meta
          let columnHeaders = [];

          for (let key in worksheet) {
            let regEx = new RegExp('^(\\w)(1){1}$');
            if (regEx.test(key) === true) {
              let data = {col: key, value: worksheet[key].v};
              columnHeaders.push(data);
            }
          }
          const data = XLSX.utils.sheet_to_json(worksheet);

          // return array from string of comma separated values
          const splitString = (str) => {
            return str.split(',').map((item) => item.trim());
          };

          let albumData = JSON.parse(localStorage.getItem('albumData')) || [];
          const getTrackData = JSON.parse(localStorage.getItem('tracks')) || [];
          function removeDuplicates(array) {
            let uniq = {};
            return array.filter(
              (obj) => !uniq[obj.upc] && (uniq[obj.upc] = true),
            );
          }
          function removeDuplicatesTracks(array) {
            let uniq = {};
            return array.filter(
              (obj) => !uniq[obj.isrc] && (uniq[obj.isrc] = true),
            );
          }

          // add each data to local storage
          data.forEach((item) => {
            // convert all letters to small case
            // convert date to timestamp
            const date = new Date(item[columnHeaders[4].value]);

            const territory = metadata?.territory;

            let newData;
            if (
              item[columnHeaders[3].value] === 'Film' ||
              item[columnHeaders[3].value] === 'film'
            ) {
              // Find language id from language name
              const language_id =
                languages.find(
                  (lang) =>
                    lang.language ===
                    item[columnHeaders[7].value].toLowerCase(),
                )?.id || 13;
              newData = {
                uploadName: project_id,
                user_id: currentUser.email,
                id: item[columnHeaders[0].value],
                upc: item[columnHeaders[0].value],
                album_name: item[columnHeaders[1].value],
                product_type: item[columnHeaders[2].value],
                album_type: item[columnHeaders[3].value],
                album_release_date: date,
                actors_name: splitString(item[columnHeaders[5].value]),
                actress_name: splitString(item[columnHeaders[6].value]),
                language_id: language_id,
                film_zone: item[columnHeaders[8].value],
                album_director_name: splitString(item[columnHeaders[9].value]),
                album_producer_name: splitString(item[columnHeaders[10].value]),
                banner_production_name: splitString(
                  item[columnHeaders[11].value],
                ),
                isrc: item[columnHeaders[12].value],
                song_order: item[columnHeaders[13].value],
                song_name: item[columnHeaders[14].value],
                artist: item[columnHeaders[15].value],
                lyricist: item[columnHeaders[16].value],
                composer: item[columnHeaders[17].value],
                label_c_name: item[columnHeaders[18].value],
                p_line_name: item[columnHeaders[19].value],
                genre: item[columnHeaders[20].value],
                musical_instrument: item[columnHeaders[21].value],
                duration: item[columnHeaders[22].value],
                label_id: label_id,
                territory: territory,
                Track: [],
              };
            } else {
              // Find language id from language name
              const language_id =
                languages.find(
                  (lang) =>
                    lang.language ===
                    item[columnHeaders[5].value].toLowerCase(),
                )?.id || 13;
              newData = {
                uploadName: project_id,
                user_id: currentUser.email,
                id: item[columnHeaders[0].value],
                upc: item[columnHeaders[0].value],
                album_name: item[columnHeaders[1].value],
                product_type: item[columnHeaders[2].value],
                album_type: item[columnHeaders[3].value],
                album_release_date: date,
                film_zone: item[columnHeaders[6].value],
                isrc: item[columnHeaders[7].value],
                song_order: item[columnHeaders[8].value],
                song_name: item[columnHeaders[9].value],
                artist: item[columnHeaders[10].value],
                lyricist: item[columnHeaders[11].value],
                composer: item[columnHeaders[12].value],
                label_c_name: item[columnHeaders[13].value],
                label_id: label_id,
                language_id: language_id,
                p_line_name: item[columnHeaders[14].value],
                territory: territory,
                genre: item[columnHeaders[15].value],
                musical_instrument: item[columnHeaders[16].value],
                duration: item[columnHeaders[17].value],
                Track: [],
              };
            }
            albumData.push(newData);

            const obj = {
              track_file_name: newData.isrc,
              lyricists: [newData.lyricist],
              music_director: [newData.composer],
              singers: [newData.artist],
              instruments: newData.musical_instrument,
              track_name: newData.song_name,
              album_id: newData.id,
              track_genre: newData.genre,
              track_upc: newData.upc,
              isrc: newData.isrc,
              track_order: newData.song_order,
              user_id: newData.user_id,
              duration: newData.duration,
              id: uuidv4(),
              language_id: newData.language_id,
              album_name: newData.album_name,
              p_line_name: newData.p_line_name,
              label_c_name: newData.label_c_name,
              upc: newData.upc,
              album_release_date: newData.album_release_date,
              album_type: newData.album_type,
              product_type: newData.product_type,
              territory: newData.territory,
              album_director_name: newData.album_director_name,
              album_producer_name: newData.album_producer_name,
              label_id: newData.label_id,
            };
            getTrackData.push(obj);
            // window.location.reload();
          });

          localStorage.setItem(
            'albumData',
            JSON.stringify(removeDuplicates(albumData)),
          );
          localStorage.setItem(
            'tracks',
            JSON.stringify(removeDuplicatesTracks(getTrackData)),
          );
        };
      }
      // return response as status 200
      return {
        status: 200,
      };
    } else {
      try {
        const response = await axios(`${BASE_URL}/project/upload-url`, {
          method: 'GET',
          params,
        });

        if (response.data?.statusCode === 200) {
          const url = response.data?.data?.url;

          const source = CancelToken.source();
          setSource(source);
          const res = await axios.put(url, file, {
            onUploadProgress,
            cancelToken: source.token,
          });

          if (res.status === 200) {
            queryCache.invalidateQueries('download_link');
          }

          return res;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return error.message;
        }
        if (error?.response?.status === 500) {
          console.log('There was a problem with the server');
        } else {
          console.log(error?.response?.data?.msg);
        }
        return error;
      }
    }
  };

  const cancel = () => {
    if (source?.cancel) {
      source.cancel('error');
    }
  };

  return {
    uploadFile,
    cancel,
  };
};

export default useUploadMetaFile;
