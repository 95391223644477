/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Button} from 'baseui/button';
import {ErrorMessage, Field, FieldArray} from 'formik';
import React from 'react';
import {AiFillCloseCircle} from 'react-icons/ai';
import {FaInfoCircle} from 'react-icons/fa';
import {RiAddCircleFill} from 'react-icons/ri';
import Tooltip from '../ToolTip/ToolTip';
import DetailsError from './DetailsError';
import {LabelInAlbumCreate} from './DetailsSelect';

const btnAddOveride = (theme) => {
  return {
    BaseButton: {
      style: {
        // backgroundColor: theme.tabInactiveBorderColor,
        display: 'flex',
        alignItems:"center",
        color: '#fff',
        // marginTop: '0.5rem',
        marginRight: '0.5rem',
        // marginLeft: '0.5rem',
        marginBottom: '0.5rem',
        // minWidth: '5rem',
        // maxWidth: '7.5rem',
        width: "68px",
        // height: '1.5rem',
        position:"absolute",
        right:"34px",
        height:"33px",
        lineHeight: '1.5rem',
        paddingTop: '0.3rem !important',
        paddingBottom: '0.3rem !important',
        paddingLeft: '0.3rem !important',
        paddingRight: '0.3rem !important',
        borderRadius: '0 5px 5px 0',
        borderBottomLeftRadius: '7px',
        borderBottomRightRadius: '7px',
        borderTopRightRadius: '7px',
        borderTopLeftRadius: '7px',
        background: "#DF3732",
        fontSize: '0.75rem',
        fontWeight: '400',
        boxShadow: '3px 3px 5px 0px rgba(230,230,230, 0.75)',
        ':hover': {
          background: "#C7241F",
          // color: theme.bg,
        },
      },
    },
  };
};

function Input(props) {
  const [css, theme] = useStyletron();
  const {label, name, buttonName, isRequired, helpText = null, ...rest} = props;

  return (
    <Input.Container
      borderColor={theme.tabInactiveBorderColor}
      bgColor={theme.inputBg}
      textColor={theme.textColor}
    >
      <div className="input_item">
        <div className="left">
          <label htmlFor={name}>
            <LabelInAlbumCreate
              data={{children: label}}
              isRequired={isRequired}
            />
          </label>
        </div>
        <div className="right">
          <FieldArray name={name}>
            {(fieldArrayProps) => {
              const {push, remove, form} = fieldArrayProps;
              const {values} = form;
              return (
                <div className="field">
                  {values[name] &&
                    values[name].map((item, index) => (
                      <div className="input-wrapper" key={index}>
                        <Field name={`${name}[${index}]`} {...rest} />
                        {helpText && (
                          <Tooltip
                            isOpen={true}
                            content={helpText}
                            direction="right"
                          >
                            <div className="info">
                              <FaInfoCircle />
                            </div>
                          </Tooltip>
                        )}

                        {index > 0 && (
                          <span
                            className="remove"
                            onClick={() => remove(index)}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    ))}
                  <Button
                    onClick={() => push('')}
                    type="button"
                    overrides={btnAddOveride(theme)}
                  >
                    {buttonName}     <RiAddCircleFill />
                  </Button>
                </div>
              );
            }}
          </FieldArray>
        </div>
      </div>
      <div className="input_item">
        <div className="left"></div>
        <div className="right error">
          <ErrorMessage name={name} component={DetailsError} />
        </div>
      </div>
    </Input.Container>
  );
}

Input.Container = styled.div`
  display: flex;
  flex-direction: column;
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
  .input_item {
    margin: 0.3rem 0.75rem;
    justify-content: center;
    align-items: center;


    .left {
      flex: 1;
      text-align: right;
      align-self: flex-start;
      label {
        margin: 0;
      }
    }

    .right {
      flex: 2;

      .field {
        display: flex;
        flex-direction: column;
      svg{
        padding-left:5px;
      }
        .input-wrapper {
          display: flex;
          align-items: center;
        }

        .remove {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          width: 25px;
          height: 25px;
          margin-bottom: 10px;
          color: #5f5f5f;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  label {
    margin: 0 1.5rem;
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.textColor};
  }

  input {
    font-size: 0.85rem;
    border-radius: 5px 0 0 5px;
    padding: 0.85rem;
    margin-bottom: 0.5rem;
    background: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: none;
    width: 420px;

    &:focus {
      outline: none;
    }
  }
`;

export default Input;
