import styled from '@emotion/styled';
import React, {useState} from 'react';

const StepProgressComp = (props) => {
  const {setIsOpen, dataArr, data, step} = props;

  return (
    <div className="step-progress">
      <StepProgressComponent>
        <div className="container">
          <div className="step1">
            <h2>{data.title1}</h2>
            <p>{data.subtitle1}</p>
            <p className="hint">{data.desc}</p>
          </div>
          <div className="step2">
            <h2>{data.title2}</h2>
            <p>{data.subtitle2}</p>
          </div>
        </div>
        <div className="dots">
          {dataArr.map((item, i) => (
            <span className={`dot1 ${i + 1 === step ? 'active' : ''}`}></span>
          ))}
        </div>
      </StepProgressComponent>
    </div>
  );
};

const StepProgressComponent = styled.div`
  margin: 0 -30px;
  background: #f8f8f8;
  padding: 0 30px;
  .container {
    display: flex;
    width: 100%;
    line-height: 10px;
    padding-top: 20px;
    .step1 {
      flex-basis: 50%;
      h2 {
        font-weight: bold;
        font-size: 16px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
      }
      .hint {
        font-size: 10px;
        color: rgba(51, 51, 51, 0.5);
        width: 121%;
      }
    }
    .step2 {
      flex-basis: 50%;
      text-align: end;
      color: rgba(51, 51, 51, 0.5);
      align-self: center;
      h2 {
        font-size: 14px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  .dothide {
    display: none;
  }
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    span {
      width: 6px;
      height: 6px;
      background: #9e9e9e;
      border-radius: 50%;
      margin-right: 10px;
    }
    .active {
      background: #d21919;
    }
  }
`;
export default StepProgressComp;
