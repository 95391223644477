/** @format */

import styled from '@emotion/styled';
import React, {useContext, useEffect, useState} from 'react';

import {GlobalContext} from '../../../Contexts/GlobalProvider';
import useNotification from '../../../Contexts/useNotification';
// import FileUploadNewNew from '../../../FileUploadNewNew';
import useUploadMetaFile from '../../../helpers/useUploadMetaFile';
import useValidateInputFile from '../../../hooks/CreateAlbum/useValidateExcel';
import useModal from '../../../hooks/Modal/useModal';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import ExcelFileModal from '../../BulkUpload/ExcelFileModal';
import ErrorMessageModal from '../../CreateAlbum/ErrorMessageModal';
import LoadingSpinnerTransparent from '../../reusable/LoadingSpinnerTransparent/LoadingSpinnerTransparent';
import {SuccessMessage} from '../../reusable/SuccessMessage/SuccessMessage';
import FileUploadNewNew from './FileUploadNewNew';
import {UploadContainer} from './MessageContainer';

const BulkUploadExcelNew = (props) => {
  const {
    handleNext,
    project,
    setEnable,
    metadata,
    setIsOpen: setModalOpen,
  } = props;

  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const {cancel, uploadFile} = useUploadMetaFile({metadata});
  const [message, setMessage] = useState('');
  const {readExcel} = useValidateInputFile();

  // TEST

  const [file, setFile] = useState(null);
  const [complete, setComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploadStarted, setIsUploadStarted] = useState(false);
  const [progressInfo, setProgressInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [localValue, setValue] = useState({
    label: '',
    percent: 0,
  });
  const {setIsOpen, isOpen, close} = useModal();
  const {state, dispatch}: any = useContext(GlobalContext);

  useEffect(() => {
    if (state.uploadStatus === 2) {
      setValue({
        label: 'Processing',
        percent: 50,
      });
    } else if (state.uploadStatus === 0) {
      setValue({
        label: 'Uploading',
        percent: 15,
      });
    } else if (state.uploadStatus === 1) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [state]);

  const {showToast} = useNotification();

  const cancelUpload = () => {
    cancel();
    setProgressInfo(0);
  };

  const upload = async (f) => {
    setIsUploadStarted(true);
    try {
      if (f) {
        const project_id = project.project_name;
        const label_id = project.label_id;
        if (project_id && currentUser && f) {
          const response = await uploadFile(
            f,
            project_id,
            label_id,
            `excel`,
            (event) => {
              setProgressInfo(Math.round((100 * event.loaded) / event.total));
            },
          );

          if (response?.status === 200) {
            setComplete(true);
            setMessage('File uploaded successfully');
            setIsDownloadModalOpen(false);
          } else {
            setIsUploadStarted(false);
            showToast('Could not upload the file', 'error');
            setProgressInfo(0);
          }
        }
      }
    } catch (error) {
      setIsUploadStarted(false);
    }
  };

  const handleUpload = async (f) => {
    try {
      setLoading(true);
      await readExcel(f);
      setIsDownloadModalOpen(true);
      setLoading(false);
      upload(f);
      setModalOpen(false);
      window.location.reload();
    } catch (error) {
      setLoading(true);
      setMsg(error);
      setLoading(false);
      setIsOpen(true);
    }
  };

  const onDropHandler = (file) => {
    setFile(file[0]);
    setComplete(false);
    setIsUploadStarted(false);
    setValue({
      label: '',
      percent: 0,
    });
    dispatch({
      type: 'CHANGE_STATUS',
      payload: {
        uploadStatus: null,
        duplicate_upc: null,
        duplicate_upc_count: null,
        duplicate_isrc: null,
        error: null,
        valid_upc_count: null,
        invalid_upc: null,
        type: null,
      },
    });
    handleUpload(file[0]);
  };

  const onRemoveFileHandler = () => {
    setFile(null);
  };

  const onCancelHandler = () => {
    console.log('Cancelled');
  };

  return (
    <UploadContainer>
      {loading && <LoadingSpinnerTransparent />}

      {state.uploadStatus === 1 ? (
        <SuccessMessage count={null} tag="input" />
      ) : (
        <FileUploadNewNew
          accept=".xlsx"
          isMultiple={false}
          onCancel={onCancelHandler}
          onChange={onDropHandler}
          errorMessage={errorMessage}
          file={file}
          onRemoveFileHandler={onRemoveFileHandler}
          tag="input"
          onClickUpload={handleUpload}
          progressInfo={progressInfo}
          isUploadStarted={isUploadStarted}
          complete={complete}
          value={localValue}
          label="XLSX"
        />
      )}

      {isOpen && !loading && (
        <ErrorMessageModal isOpen={isOpen} close={close} msg={msg} />
      )}

      {isDownloadModalOpen && (
        <ExcelFileModal
          isOpen={isDownloadModalOpen}
          label="XLSX"
          file={file}
          onRemoveFileHandler={onRemoveFileHandler}
          progressInfo={progressInfo}
          isUploadStarted={isUploadStarted}
          complete={complete}
          value={localValue}
          tag="input"
          close={null}
        />
      )}
    </UploadContainer>
  );
};

BulkUploadExcelNew.Message = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem;
  border-radius: 5px;
  border: #e5e5e5 solid 1px;
  padding: 1.5rem 3rem;
  box-shadow: 0px 0px 7px 0px rgba(91, 91, 91, 0.35);
`;

BulkUploadExcelNew.Container = styled.div`
  .next_button {
    display: flex;
    justify-content: flex-end;
    margin: 2rem;
  }
`;

export default BulkUploadExcelNew;
