import {useEffect, useState} from 'react';

import useNotification from '../../Contexts/useNotification';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import useCreateUser from '../../hooks/UserManagement/useCreateUser';
import useGetSingleUser from '../../hooks/UserManagement/useGetUser';
import useUpdateUser from '../../hooks/UserManagement/useUpdateUser';

const useUserForm = (props) => {
  const {selectedData, setIsOpen, refetch, setSelectedData} = props;

  const {showToast} = useNotification();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {getSingle, isLoading} = useGetSingleUser({
    id: selectedData ? selectedData.id : null,
  });

  const [userLoading, setUserLoading] = useState(true);
  const {
    createUserMutation,
    isLoading: isCreateUserLoading,
    error: createUserError,
  } = useCreateUser();

  const {
    updateUserMutation,
    isLoading: isUpdateUserLoading,
    error: updateUserError,
  } = useUpdateUser();

  useEffect(() => {
    setUserLoading(true);
    if (!isLoading) {
      setUserLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (updateUserError || createUserError) {
      setIsOpen(true);
    }
  }, [updateUserError, createUserError]);

  const initialValues = {
    type: selectedData ? [selectedData?.type] : [],
    name: selectedData ? selectedData?.name : '',
    phone: selectedData ? selectedData?.phone : '',
    email: selectedData ? selectedData?.email : '',
  };

  const onSubmit = async (userFormData) => {
    const data = {
      email: userFormData.email,
      name: userFormData.name,
      phone: userFormData.phone,
      user_id: currentUser.email,
      updated_by: currentUser && currentUser.email,
    };

    try {
      if (selectedData?.email) {
        const id = selectedData.email;
        const res = await updateUserMutation({data, id});
        if (res?.error && res.error?.length > 0) {
          showToast(res?.error, 'error');
        }
        closeModalOnSuccess(res);
      } else {
        delete data.updated_by;
        const res = await createUserMutation({data});
        if (res?.error && res.error?.length > 0) {
          showToast(res?.error, 'error');
        }
        closeModalOnSuccess(res);
      }
    } catch (error) {
      console.log({error});
    }
  };

  const closeModalOnSuccess = (res) => {
    if (res?.statusCode === 200 || res?.statusCode === 201) {
      refetch();
      setSelectedData({});
      setIsOpen(false);
      showToast(
        `User ${selectedData ? `updated` : `added`} successfully`,
        'success',
      );
    }
  };

  return {
    isLoading,
    initialValues,
    onSubmit,
    getSingle,
    userLoading,
    isCreateUserLoading,
    isUpdateUserLoading,
  };
};

export default useUserForm;
