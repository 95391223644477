import styled from '@emotion/styled';
import React, {useContext, useEffect, useState} from 'react';

import Button from '../../../components/reusable/Button/Button';
import {useNavigate} from 'react-router';
import {GlobalContext} from '../../../Contexts/GlobalProvider';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import MusicIcons from '../../../pages/AlbumsManagment/AlbumDetail/divo/Frame 564.svg';
import BulkUploadTerritory from '../../BulkUpload/BulkUploadTerritory';
import SelectLabelComponent from '../../CreateProject/SelectLabelComponent';
import BulkUploadExcelNew from './BulkUploadExcelNew';
import StepProgressComp from './StepProgressComp';

const data = [
  {
    title1: 'STEP 1',
    title2: 'STEP 2',
    subtitle1: 'Choose Territory',
    desc: 'Choose Territory to sell your albums',
    subtitle2: 'Upload Excel Files',
    step: '1',
  },
  {
    title1: 'STEP 2',
    title2: 'SUMMARY',
    subtitle1: 'Upload Excel Files',
    desc: 'Drag and drop  / select excel file to upload',
    subtitle2: 'summary of your uploads',
    step: '2',
  },
];
const BulkUploadForm = (props) => {
  const {setIsOpen, setDynamicTag, tag} = props;
  const [project, setProject] = useState(null);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const [enable, setEnable] = useState(false);
  const [step, setStep] = useState(1);
  const [stepperData, setStepperData] = useState(data[0]);
  const navigate = useNavigate();
  const {state, dispatch}: any = useContext(GlobalContext);
  const [selectedValue, setSelectedValue] = useState('worldwide');
  const [excludeValue, setExcludeValue] = useState([]);
  const [includeValue, setIncludeValue] = useState([]);
  const [metadata, setMetadata] = useState(null);

  const goToNextHandler = () => {
    if (step === 1) {
      console.log('metadata', project);
      const territory = selectedValue;
      const country =
        selectedValue === 'exclude'
          ? excludeValue.map((item) => item.id)
          : selectedValue === 'include'
          ? includeValue.map((item) => item.id)
          : [];
      const metadata = {
        territory: territory,
        country: country,
      };
      setMetadata(metadata);
    }
    setStep(step + 1);
  };
  const goToPreviousHandler = () => {
    if (step === 1) {
      window.location.reload();
    } else {
      setStep(step - 1);
    }
  };
  useEffect(() => {
    if (step === 1) {
      setEnable(true);
    } else if (step === 2) {
      setEnable(false);
      setStepperData(data[1]);
    }
  }, [step]);

  React.useEffect(() => {
    return () => {
      dispatch({
        type: 'CHANGE_STATUS',
        payload: {
          uploadStatus: null,
          duplicate_upc: null,
          duplicate_upc_count: null,
          duplicate_isrc: null,
          error: null,
          valid_upc_count: null,
          invalid_upc: null,
          type: null,
        },
      });
    };
  }, []);

  const renderForm = (currentStep) => {
    switch (currentStep) {
      case 1:
        return (
          <BulkUploadTerritory
            selectedValue={selectedValue}
            setExcludeValue={setExcludeValue}
            excludeValue={excludeValue}
            setSelectedValue={setSelectedValue}
            includeValue={includeValue}
            setIncludeValue={setIncludeValue}
          />
        );
      case 2:
        return (
          <BulkUploadExcelNew
            project={project}
            setEnable={setEnable}
            handleNext={() => handleNext()}
            metadata={metadata}
            setIsOpen={setIsOpen}
          />
        );
      default:
        return;
    }
  };

  const dis = () => {
    return selectedValue === 'worldwide' ||
      (selectedValue === 'exclude' && excludeValue.length > 0) ||
      (selectedValue === 'include' && includeValue.length > 0)
      ? false
      : true;
  };

  return (
    <BulkUploadFormContainer className="bulkuploadcontainer">
      <div className="bulkupload-Content">
        <StepProgressComp step={step} dataArr={data} data={stepperData} />

        <div className="container">
          <SelectLabelComponent setProject={setProject} tag={tag} />
        </div>
        <div className="group-container">
          <div className={`container-mid ${project ? '' : 'disable'}`}>
            {renderForm(step)}
          </div>
        </div>
      </div>

      <div className={`container-2 `}>
        <Button label="Previous" onClick={goToPreviousHandler} />
        <div className={` ${project && enable ? '' : 'disable'}`}>
          <Button label="Next" onClick={goToNextHandler} disabled={dis()} />
        </div>
      </div>
    </BulkUploadFormContainer>
  );
};

const BulkUploadFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  .bulkupload-Content {
    max-height: calc(100% - 70px);
    overflow: auto;
    margin: 0 -30px -30px;
    padding: 0 30px 30px;
  }
  .container-mid {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
    height: calc(50vh - 50px);
    .info {
      position: relative;
      display: flex;
      left: -36px;
      top: -138px;
      color: #333333;
      font-size: 18px;
    }
  }
  .disable {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
  }
  .group-container {
    margin: 0 -30px;
    border-top: 1px solid #ececec;
    // height: calc(55vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .container {
    background: url('${MusicIcons}');
    background-position: 100% 0;
    background-size: auto;
    form {
      display: flex;
      flex-direction: column;
    }
    .tooltip {
      position: absolute;
      margin-top: -75px;
      margin-left: -15px;
    }

    .line {
      width: 100%;
      height: 0.5px;
      background: #eff1f4;
      margin-left: -30px;
      padding-left: 60px;
      margin-bottom: 90px;
    }
  }

  .container-2 {
    background: #f8f8f8;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    margin-left: -10px;
    bottom: 0;
    z-index: 99;
    button {
      width: 80px;
      // height: 35px;
      margin-right: 20px;
      background: #d21919;
      border-radius: 100px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      outline: none;
      border: none;
      cursor: pointer;
    }
    button:disabled,
    button[disabled] {
      background-color: #cccccc;
      color: #666666;
    }
    .disable {
      filter: grayscale(1);
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`;

export default BulkUploadForm;

function handleNext() {
  throw new Error('Function not implemented.');
}
