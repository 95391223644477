/** @format */

import React from 'react';

import BaseInput from '../Formik/BaseInput';
import BaseSelect from '../Formik/BaseSelect';
import BaseDatePicker from './BaseDatePicker';
import BaseDescField from './BaseDescField';
import BaseMultiSelect from './BaseMultiSelect';
import CheckboxGroup from './CheckboxGroup';
import DetailsInput from './DetailsInput';
import DetailsMultiSelect from './DetailsMultiSelect';
import DetailsRadio from './DetailsRadio';
import DetailsSelect from './DetailsSelect';
import DrawerInputWButton from './DrawerInputWButton';
import DynamicInput from './DynamicInput';

import InputWithButton from './InputWithButton';
import MultipleInput from './MultipleInput';
import MultiSelect from './MultiSelect';
import RadioButtons from './RadioButtons';
import TerritoryRadio from './TerritoryRadio';
import Textarea from './Textarea';
import TransferListMultiSelect from './TransferListMultiSelect';

function FormikControl(props) {
  const {control, ...rest} = props;
  switch (control) {
    case 'baseinput':
      return <BaseInput {...rest} />;
    case 'textarea':
      return <Textarea {...rest} />;

    case 'baseselect':
      return <BaseSelect {...rest} />;

    case 'baseMultiSelect':
      return <BaseMultiSelect {...rest} />;
    case 'radio':
      return <RadioButtons {...rest} />;
    case 'radioButtons':
      return <DetailsRadio {...rest} />;
    case 'checkbox':
      return <CheckboxGroup {...rest} />;
    case 'inputWithButton':
      return <InputWithButton {...rest} />;
    case 'multipleInput':
      return <MultipleInput {...rest} />;
    case 'dynamicInput':
      return <DynamicInput {...rest} />;
    case 'detailsInput':
      return <DetailsInput {...rest} />;
    case 'detailsSelect':
      return <DetailsSelect {...rest} />;
    case 'detailsMultiSelect':
      return <DetailsMultiSelect {...rest} />;
    case 'newMultiSelect':
      return <MultiSelect {...rest} />;

    case 'baseDatePicker':
      return <BaseDatePicker {...rest} />;

    case 'baseDesc':
      return <BaseDescField {...rest} />;

    case 'drawerInputWButton':
      return <DrawerInputWButton {...rest} />;

    case 'territoryRadio':
      return <TerritoryRadio {...rest} />;
    case 'transferList':
      return <TransferListMultiSelect {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
