/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {ErrorMessage, Field} from 'formik';
import React from 'react';

import {Label} from './BaseInput';
import DetailsError from './DetailsError';

export const LabelInAlbumCreate = ({data, isRequired = true}) => {
  return (
    <LabelInAlbumCreateStyle>
      <div>{data.children}</div>
      {isRequired && <span>*</span>}
    </LabelInAlbumCreateStyle>
  );
};

export const LabelInAlbumCreateStyle = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 4px 0;
  color: #333333;
  font-weight: 500;

  margin-right: 10px;
  div {
    font-weight: bold;
  }
  span {
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-left: 5px;
    color: #dc3832;
    font-weight: bold;
  }
`;
function Select(props) {
  const [css, theme] = useStyletron();

  const {label, name, options, placeholder, isRequired, ...rest} = props;

  return (
    <Select.Container
      borderColor={theme.tabInactiveBorderColor}
      bgColor={theme.inputBg}
      textColor={theme.textColor}
    >
      <div className="input_item">
        <div className="left">
          <label htmlFor={name}>
            <LabelInAlbumCreate
              data={{children: label}}
              isRequired={isRequired}
            />
          </label>
        </div>
        <div className="right">
          <Field as="select" id={name} name={name} {...rest}>
            <option key="" value="" defaultValue>
              {placeholder}
            </option>
            {options?.map((option) => {
              return (
                <option key={option.value} value={option.id}>
                  {option.value}
                </option>
              );
            })}
          </Field>
        </div>
      </div>
      <div className="input_item">
        <div className="left"></div>
        <div className="right">
          <ErrorMessage name={name} component={DetailsError} />
        </div>
      </div>
    </Select.Container>
  );
}

Select.Container = styled.div`
  display: flex;
  flex-direction: column;

  .input_item {
    margin: 0.3rem 0.75rem;
    justify-content: center;
    align-items: center;

    .left {
      flex: 1;
      text-align: right;
      label {
        margin: 0;
      }
    }

    .right {
      flex: 2;
    }
  }

  .upc_code {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0 1.5rem;
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.textColor};
  }

  option {
    background-color: ${(props) => props.bgColor};
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    color: ${(props) => props.textColor};

    &:hover {
      background-color: ${(props) => props.bgColor};
      color: #000;
      box-shadow: inset 20px 20px #00f;
    }

    &:focus {
      border-color: gray;
      outline: none;
    }
  }

  select {
    font-size: 0.85rem;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding-left: 0.85rem;
    padding-right: 0.85rem;
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: none;
    width: 94%;

    -webkit-appearance: none;
    -moz-appearance: none;
    /* background: transparent; */
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    background-position: top 5px right 4px;

    &:focus {
      outline: none;
    }
  }
`;

export default Select;
