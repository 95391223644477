/* eslint-disable react-hooks/exhaustive-deps */
import {FormControl} from 'baseui/form-control';
import {Select} from 'baseui/select';
import {ErrorMessage, Field} from 'formik';
import React from 'react';

import {
  borderNone,
  setBorderRadius,
} from '../../../styles/base-ui-overrides/common';
import {Label} from './BaseInput';
import TextError from './TextError';

const BaseMultiSelect = (props) => {
  const {
    label,
    name,
    options,
    initialValue,
    setFieldValue,
    labelKey,
    valueKey,
    disabled,
    isRequired,
    ...rest
  } = props;

  const [value, setValue] = React.useState(initialValue);

  return (
    <div>
      <Field id={name} name={name} {...rest}>
        {({field, form}) => (
          <FormControl
            label={`${label}`}
            overrides={{
              Label: (data) => <Label data={data} isRequired={isRequired} />,
            }}
          >
            <Select
              disabled={disabled}
              overrides={{
                ControlContainer: {
                  style: ({$theme}) => {
                    return {
                      ...borderNone,
                      backgroundColor: $theme.inputBg,
                      ...setBorderRadius(5),
                    };
                  },
                },
              }}
              multi
              placeholder={label}
              options={options}
              labelKey={labelKey}
              valueKey={valueKey}
              onChange={({value}) => {
                setValue(value);
                setFieldValue(name, value);
              }}
              value={value ? value : initialValue}
            />
          </FormControl>
        )}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default BaseMultiSelect;
