/** @format */

import React, {useState} from 'react';
import {useNavigate} from 'react-router';

import {formatDate} from '../../helpers/covertDate';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {TextWrap} from '../components/TableComponents';
import {
  sortAlphabeticalOrderWithoutCase,
  sortStatusAlphabeticalOrderWithoutCase,
} from './albumColumnHelper';
import {albumData} from './albumTable';
import {
  AlbumNameWrapper,
  ButtonContainer,
} from './styled-components/TableComponents';
function useAlbumsColumnMetaData() {
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const handleSelect = (framework: any) => {
    const isSelected = selectedIds.includes(framework);
    const newSelection = isSelected
      ? selectedIds.filter((currentTech) => currentTech !== framework)
      : [...selectedIds, framework];
    setSelectedIds(newSelection);
  };
  const albumsColumnMeta = [
    {
      Header: (row) => {
        const isSelected =
          row.data.length === 0
            ? false
            : row.data.length === selectedIds.length;
        const upcList = row.data.map((item) => item.upc);
        const selectAll = () => {
          if (isSelected) {
            setSelectedIds([]);
          } else {
            setSelectedIds(upcList);
          }
        };
        return (
          <input
            type="checkbox"
            style={{width: '20px', height: '20px', marginLeft: '20px'}}
            checked={isSelected}
            onChange={selectAll}
          />
        );
      },
      Cell: ({row}) => {
        const isSelected = selectedIds.includes(row.original.upc);
        return (
          <input
            type="checkbox"
            checked={isSelected}
            style={{width: '20px', height: '20px', marginLeft: '20px'}}
            onChange={() => handleSelect(row.original.upc)}
          />
        );
      },
      id: 'selection',
    },
    {
      Header: albumData.album_name.Header,
      accessor: albumData.album_name.accessor,
      sortType: (rowA, rowB) => {
        return sortAlphabeticalOrderWithoutCase(
          rowA.original.album_name,
          rowB.original.album_name,
        );
      },
      Cell: (data) => {
        return (
          <AlbumNameWrapper>
            <div> {data && data.value} </div>
          </AlbumNameWrapper>
        );
      },
    },
    {
      Header: albumData.upc.Header,
      accessor: albumData.upc.accessor,
    },
    {
      Header: albumData.label.Header,
      accessor: albumData.label.accessor,
      Cell: (data) => {
        return <span> {data && data.row?.original?.label_id} </span>;
      },
      sortType: (rowA, rowB) => {
        return sortStatusAlphabeticalOrderWithoutCase(
          rowA.original.label?.label_id,
          rowB.original.label?.label_id,
        );
      },
    },
    {
      Header: albumData.product_type.Header,
      accessor: albumData.product_type.accessor,
      Cell: (data) => {
        return <TextWrap> {data && data.value} </TextWrap>;
      },
      sortType: (rowA, rowB) => {
        return sortAlphabeticalOrderWithoutCase(
          rowA.original.product_type,
          rowB.original.product_type,
        );
      },
    },
    {
      Header: albumData.album_type.Header,
      accessor: albumData.album_type.accessor,
      Cell: (data) => {
        return <TextWrap> {data && data.value} </TextWrap>;
      },
      sortType: (rowA, rowB) => {
        return sortAlphabeticalOrderWithoutCase(
          rowA.original.album_type,
          rowB.original.album_type,
        );
      },
    },
    {
      Header: albumData.album_release_date.Header,
      accessor: albumData.album_release_date.accessor,
      Cell: (data) => {
        return <span> {data && formatDate(data.value)} </span>;
      },
    },
    {
      Header: albumData.action_btn.Header,
      accessor: albumData.action_btn.accessor,
      Cell: (data) => {
        const navigate = useNavigate();
        const [projectId, setProjectId] = useLocalStorage('projectId', null);
        const [albumData, setAlbumData] = useLocalStorage(
          'track_album_data',
          null,
        );

        const onClickDetail = (data) => {
          navigate(`${data?.row?.original?.id}`);
          setProjectId(data?.row?.original?.project_id);

          setAlbumData({
            album_id: data?.row?.original?.id,
            track_upc: data?.row?.original?.upc,
            user_id: data?.row?.original?.user_id,
            project_id: data?.row?.original?.project_id,
          });
        };
        return (
          <ButtonContainer>
            <button onClick={() => onClickDetail(data)}>View Tracks</button>
          </ButtonContainer>
        );
      },
      disableSortBy: true,
    },
  ];
  return {
    albumsColumnMeta,
    selectedIds,
  };
}

export default useAlbumsColumnMetaData;
