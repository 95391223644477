import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';

const updateTrackFn = async (request) => {
  const {id, data} = request;
  try {
    return await axios({
      method: 'PUT',
      url: `/track/update-status/${id}`,
      data: data,
    });
  } catch (error) {
    console.log('updateTrackFn -> error', error);
  }
};

const useUpdateTrack = () => {
  const [updateTrackMutation, {status, isLoading, error}] = useMutation(
    updateTrackFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('track');
        queryCache.invalidateQueries('singleAlbum');
        queryCache.invalidateQueries('albums');
      },
    },
  );
  return {updateTrackMutation, isLoading, status, error};
};

export default useUpdateTrack;
