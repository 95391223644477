/** @format */

import axios from '../../config/Axios';
import {queryCache, useMutation} from 'react-query';

import {logError} from '../../errorlogger/logError';

const connectIsrc = async (data) => {
  try {
    const response = await axios(`/track/connect_track/${data?.track_id}`, {
      method: 'POST',
      data: data?.data,
    });

    return response.data;
  } catch (error) {
    logError(error, 3);
    return error.response.data;
  }
};

const useConnectIsrc = () => {
  const [
    sendIsrc,
    {status, data, error, isLoading: reuseIsrcLoad, isSuccess},
  ] = useMutation(connectIsrc);

  return {sendIsrc, reuseIsrcLoad, status, error};
};

export default useConnectIsrc;
