import {useContext} from 'react';

import {NotificationContext} from './NotificationProvider';

function useNotification() {
  const {showToast} = useContext(NotificationContext);
  return {showToast};
}

export default useNotification;
