export const subtractFromTwoArray = (full, minus, isNot) => {
  if (isNot) {
    return full.filter((objFromA) => {
      return !minus.find((objFromB) => {
        return objFromA.id === objFromB.id;
      });
    });
  } else {
    return full.filter((objFromA) => {
      return minus.find((objFromB) => {
        return objFromA.id === objFromB.id;
      });
    });
  }
};
