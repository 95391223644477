import {useEffect} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchSingleUser = async (key, userId) => {
  if (userId) {
    try {
      const res = await axios(`/user/${userId}`, {
        method: 'GET',
      });

      return res.data?.data;
    } catch (error) {
      logError(error, 2);

      return error.response?.data;
    }
  }
};

const useGetSingleUser = (props) => {
  const {id} = props;
  const {data: getSingle, isLoading, error, isError} = useQuery(
    ['singleUser', id],
    fetchSingleUser,
    {
      enabled: id,
    },
  );

  return {
    getSingle,
    isLoading,
    error,
    isError,
  };
};

export default useGetSingleUser;
