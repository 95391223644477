import {queryCache, useMutation} from 'react-query';

import {logError} from '../../errorlogger/logError';

const updateTrackFn = async (params) => {
  const {id, data} = params;
  try {
    const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
    // Find the track 
    const track = tracks.find((track) => track.id === id);
    // Update the track
    const updatedTrack = {...track, ...data};
    // Update the track in the local storage
    const updatedTracks = tracks.map((track) =>
      track.id === id ? updatedTrack : track,
    );
    localStorage.setItem('tracks', JSON.stringify(updatedTracks));
    window.location.reload()
    return data
  } catch (error) {
    logError(error, 12);

    if (error.response) {
      return error.response.data;
    }
  }
};

const useUpdateTrack = () => {
  const [
    updateTrackMutation,
    {status: trackStatus, isLoading: trackLoading, error: trackError},
  ] = useMutation(updateTrackFn, {
    onSuccess: () => {
      queryCache.invalidateQueries('track');
      queryCache.invalidateQueries('album');
      queryCache.invalidateQueries('albums');
      queryCache.invalidateQueries('singleAlbum');
    },
  });

  return {
    updateTrackMutation,

    trackLoading,
    trackError,
  };
};

export default useUpdateTrack;
