import ExcelJS from 'exceljs';
import React, {useEffect, useState} from 'react';

import useListLanguages from '../hooks/Language/useListLanguage';

const Iprs = (props) => {
  const {data, multiple, selectedIds} = props;
  const [albumTracks, setAlbumTracks] = useState([]);
  const {listLanguages} = useListLanguages();

  const gettracks = (getAlbumDetail) => {
    const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
    if (multiple) {
      const filterTracks = tracks.filter((item) => {
        if (selectedIds.includes(item.upc)) {
          return item;
        }
      });
      setAlbumTracks(filterTracks);
    } else {
      setAlbumTracks(
        tracks.filter((track) => track.album_id === getAlbumDetail?.id),
      );
    }
  };

  useEffect(() => {
    gettracks(data);
  }, [data]);

  if (!data) return null;

  function formatDuration(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  const reUseableHeader = [
    'Membership Number',
    'FIRST NAME OF Author',
    'LAST NAME OF Author',
    'If Non Member Use Code 099 / if Member 036',
    'X',
    'Use A for Author',
    '25: 25 to Author & Composer and if Instrumental / Background then total 50 to Composer',
    '',
    '',
  ];

  const header = [
    'Serial Number for the tracks as maintained by the Member',
    'Unique work identifier Provided by the Publisher',
    'Generated by Society after registeration This should be left blank',
    'Song Title',
    'If there is no sublanguage leave the cell blank',
    '',
    'Duration of the work as per actuals. The format of HH:MM:SS must be maintained',
    'Use UNCLASSIFIED -  for All Works. UNC',
    'Works are further sub catagorised as per the list provided .  For 1) Films songs- Features.2) For film background - Background 3) For Non film / album or popular song - POP. 4) For devotional - Sacred Music 5) For serial background - Background',
    'Identify Songs as Vocal or Instrumental. VOCAL / INSTRUMENTAL - VOC / INS',
    'For Vocal use BOTH / For Instrumental use MUSIC / For Dialogue - Recitation use TEXT - BTH / MUS/TXT',
    'ORIGINAL for unique works / MODIFIED for Modified work ( Remix/ Reprise / Adaptation etc) ORI / MOD',
    'NAME OF SINGER',
    'Membership Number',
    'FIRST NAME OF COMPOSER ',
    'LAST NAME OF COMPOSER',
    'If Non Member Use Code 099 / if Member 036',
    'X',
    'Use C for Composer',
    '25: 25 to Composer & Author and if Instrumental / background then total 50 to Composer',
    '',
    '',
    ...reUseableHeader,
    'Membership Number',
    'NAME OF PUBLISHER',
    'X',
    'If Non Member Use Code 099 / if Member 036',
    'X',
    'Use E for Publisher',
    'Performance Share 50',
    'MechanicalShare 50',
    'Synchronisation Share 50',
    'For Film Use Code F / for Non Film Use Code NF ( Includes Albums /Television / Web Series etc)',
    'Name of Film / Album / TV Serial / Web Series',
    'Alternative titles by which the song may also be referred to',
    'The year the track was released. It is available on the streaming platform where the song is being played',
    'The Composer / Author or the Publisher who submits the data for registeration',
    'You-tube / Streaming Platform where the song has been uploaded provides an ID number for the work',
    'The link for the page where the credits for the track are available',
  ];

  const header1 = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'If there is more than one singer add the names with commas between them ( , ). As many names as there are can be added',
    '',
    'If there is more than one Composer continue to next column set',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'If there are two Composers, Author Details will be filled in this column set. In this manner insert / add Column Sets for additional entries of Composers /Authors',
    '',
    '',
    '',
    '',
    '',
    '',
  ];

  if (data.maxDirectors > 2) {
    for (let i = 2; i < data.maxDirectors; i++) {
      // replicate  header items from 22 to 30 after that
      header.splice(22 + (i - 1) * 9, 0, 'Membership Number');
      header.splice(23 + (i - 1) * 9, 0, 'FIRST NAME OF DIRECTOR');
      header.splice(24 + (i - 1) * 9, 0, 'LAST NAME OF DIRECTOR');
      header.splice(
        25 + (i - 1) * 9,
        0,
        'If Non Member Use Code 099 / if Member 036',
      );
      header.splice(26 + (i - 1) * 9, 0, 'X');
      header.splice(27 + (i - 1) * 9, 0, 'Use A for Author, C for Composer');
      header.splice(
        28 + (i - 1) * 9,
        0,
        '25: 25 to Composer & Author and if Instrumental / background then total 50 to Composer',
      );
      header.splice(29 + (i - 1) * 9, 0, '');
      header.splice(30 + (i - 1) * 9, 0, '');

      // replicate  header1 items from 22 to 30 after that
      header1.splice(22 + (i - 1) * 9, 0, '');
      header1.splice(23 + (i - 1) * 9, 0, '');
      header1.splice(24 + (i - 1) * 9, 0, '');
      header1.splice(25 + (i - 1) * 9, 0, '');
      header1.splice(26 + (i - 1) * 9, 0, '');
      header1.splice(27 + (i - 1) * 9, 0, '');
      header1.splice(28 + (i - 1) * 9, 0, '');
      header1.splice(29 + (i - 1) * 9, 0, '');
      header1.splice(30 + (i - 1) * 9, 0, '');
    }
  }

  // For every track, create a row with the track's data
  const rows = albumTracks.map((track) => {
    // mapping language id to language name
    const language = listLanguages?.find(
      (lang) => lang.id === track.language_id,
    )?.language;
    const date_release = new Date(track.album_release_date);

    const directors = track?.music_director?.join(',')?.split(',');
    const row = [
      track.track_order,
      track.isrc,
      '',
      track.track_name,
      language,
      '',
      formatDuration(track.duration),
      'UNCLASSIFIED',
      track.album_type,
      'VOCAL',
      'BOTH',
      'ORIGINAL',
      track.singers,
      track.member,
      directors[0],
      directors[0],
      '',
      '',
      'C',
      '25',
      '25',
      '25',
      '',
      '',
      '',
      '',
      '',
      'A',
      '25',
      '25',
      '25',
      '824558815',
      'DIVO TV PRIVATE LIMITED',
      '',
      '036',
      '',
      'E',
      '50',
      '50',
      '50',
      track.album_type === 'Film' ? 'F' : 'NF',
      track.album_name,
      '',
      date_release.getFullYear(),
      'DIVO TV PRIVATE LIMITED',
      '',
      '',
    ];

    // If there are multiple directors, add a new column set for each one after the first
    if (directors.length > 1) {
      for (let i = 1; i < directors.length; i++) {
        if (i === 1) {
          row[13 + i * 9] = '';
          row[14 + i * 9] = directors[i];
          row[15 + i * 9] = directors[i];
          row[16 + i * 9] = '';
          row[17 + i * 9] = '';
          row[18 + i * 9] = 'C';
          row[19 + i * 9] = '25';
          row[20 + i * 9] = '25';
          row[21 + i * 9] = '25';
        } else {
          row.splice(13 + i * 9, 0, '');
          row.splice(14 + i * 9, 0, directors[i]);
          row.splice(15 + i * 9, 0, directors[i]);
          row.splice(16 + i * 9, 0, '');
          row.splice(17 + i * 9, 0, '');
          row.splice(18 + i * 9, 0, 'A');
          row.splice(19 + i * 9, 0, '25');
          row.splice(20 + i * 9, 0, '25');
          row.splice(21 + i * 9, 0, '25');
        }
      }
      // If there are less directors than the max, add empty columns for the remaining directors
      if (data.maxDirectors !== directors.length) {
        for (let j = directors.length; j < data.maxDirectors; j++) {
          for (let k = 22; k <= 30; k++) {
            row.splice(k + (j - 1) * 9, 0, '');
          }
        }
      }
    } else {
      if (data.maxDirectors >= 2) {
        for (let i = 2; i < data.maxDirectors; i++) {
          for (let j = 22; j <= 30; j++) {
            row.splice(j + (i - 1) * 9, 0, '');
          }
        }
      }
    }
    return row;
  });

  let lastLetter = 31;

  const columnToLetter = (column) => {
    let temp,
      letter = '';
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = (column - temp - 1) / 26;
    }
    return letter;
  };

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.addRows([header, header1, ...rows]);
    worksheet.mergeCells('A1:A2');
    worksheet.mergeCells('B1:B2');
    worksheet.mergeCells('C1:C2');
    worksheet.mergeCells('D1:D2');
    worksheet.mergeCells('E1:E2');
    worksheet.mergeCells('F1:F2');
    worksheet.mergeCells('G1:G2');
    worksheet.mergeCells('H1:H2');
    worksheet.mergeCells('I1:I2');
    worksheet.mergeCells('J1:J2');
    worksheet.mergeCells('K1:K2');
    worksheet.mergeCells('L1:L2');
    // First Director
    worksheet.mergeCells('O2:V2');
    worksheet.mergeCells('T1:V1');

    // Second Director
    worksheet.mergeCells('W2:AE2');
    worksheet.mergeCells('AC1:AE1');

    // For subsequent directors, add a new set of merged cells
    for (let i = 3; i <= data.maxDirectors; i++) {
      // Calculate the column index for the first column of the director's data
      const firstColumn = 14 + (i - 1) * 9;
      // Calculate the column index for the last column of the director's data
      const lastColumn = 22 + (i - 1) * 9;
      lastLetter = lastColumn;

      // Merge the cells
      worksheet.mergeCells(
        `${columnToLetter(firstColumn)}2:${columnToLetter(lastColumn)}2`,
      );
      worksheet.mergeCells(
        `${columnToLetter(lastColumn - 2)}1:${columnToLetter(lastColumn)}1`,
      );
    }

    // Merge the Remaining cells
    for (let i = 1; i <= 10; i++) {
      const nextColumn = columnToLetter(lastLetter + i + 6);
      worksheet.mergeCells(`${nextColumn}1:${nextColumn}2`);
    }

    worksheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center'};
    worksheet.getRow(1).height = 30;
    // each column width is 30
    worksheet.columns.forEach((column) => {
      column.width = 30;
    });

    worksheet.columns.forEach((column) => {
      column.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
    });

    // Make row 2 font red
    worksheet.getRow(1).font = {color: {argb: '0b5ab2'}, bold: true};
    worksheet.getRow(2).font = {color: {argb: 'FF0000'}, bold: true};

    worksheet.getCell('AK1').font = {color: {argb: '000000'}};

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'iprs.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button className="button" onClick={handleDownload}>
      IPRS
    </button>
  );
};
export default Iprs;
