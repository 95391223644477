import styled from '@emotion/styled';
import React, {useState} from 'react';
import {useParams} from 'react-router';

import DownloadZipModal from '../../../../components/AlbumsInReview/DownloadZipModal';
import DownloadZipList from '../../../../components/AlbumsManagement/DownloadZipList';
import useAlbumForm from '../../../../hooks/AlbumsManagement/useAlbumForm';
import useGetAllCountries from '../../../../hooks/Countries/useGetAllCountries';
import ApprovedFrom from '../../../AlbumsReviewManagement/AlbumsReview/forms/ViewAlbumsInReviewForm';

const EditAlbumForm = (props) => {
  const {
    selectedData,
    setIsOpen,
    useOnClickOutside,
    sidebarRef,
    onCloseHandler: onClose,
    refetch,
    setIsAlbumClick,
  } = props;
  const {albumId} = useParams();
  const {countries} = useGetAllCountries();

  const {
    getAlbumDetail,
    theme,
    isOpen,
    close,
    setProgressInfo,
    setImageLoaded,
    isLoading,
    setIsOpenFn,
    progressInfo,
    imageLoaded,
    s3KeyGenerator,
    initialValues,
    onSubmit,
    languageOptions,
    albumLoading,
    excludeValue,
    setExcludeValue,
    includeValue,
    setIncludeValue,
    selectedValue,
    setSelectedValue,
  } = useAlbumForm({
    selectedData,
    setIsOpen,
    useOnClickOutside,
    sidebarRef,
    onCloseHandler: onClose,
    refetch,
    setIsAlbumClick,
  });

  const [open, setOpen] = useState(true);

  return (
    <AlbumDetailContainer theme={theme}>
      <ApprovedFrom selectedData={getAlbumDetail} />
      {!albumId && (
        <>
          <DownloadZipModal
            id={getAlbumDetail.id}
            showModal={isOpen}
            onCloseHandler={close}
            sidebarRef={sidebarRef}
          />
          <DownloadZipList
            label="View DSP Delivery Details"
            selectedData={getAlbumDetail}
            setIsOpenFn={setIsOpenFn}
            setIsOpen={setIsOpen}
          />
        </>
      )}
    </AlbumDetailContainer>
  );
};

export default EditAlbumForm;

const AlbumDetailContainer = styled.div`
  .edit-form {
    margin-top: 30px;
  }
  .view_tracks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    text-decoration: none;
    color: #dc3831;

    cursor: pointer;
    + .view_tracks {
      margin-top: 12px;
    }
  }
  .upload-container {
    position: absolute;
    display: flex;
    right: 150px;
    top: 36px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: auto;
  }
  .territory-head {
    margin: 0;
    margin-bottom: 5px;
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #333333;
    font-weight: bold;
    span {
      margin-left: 5px;
      color: #dc3832;
    }
  }
  .territory {
    height: 44px;
    background-color: rgb(239, 241, 243);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 13px;
    margin-bottom: 20px;
    .button {
      border: none;
      border-radius: 50px;
      background: #d21919;
      color: #ffffff;
      padding: 2px 10px;
      font-size: 14px;
    }
  }
  .album-art-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    margin: 40px 0px 26px 0;

    .upload-container {
      position: absolute;
      display: flex;
      right: 150px;
      top: 36px;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: auto;
    }
    .album-art {
      height: 200px;
      width: 200px;
      margin: 15px 0px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .progress {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
