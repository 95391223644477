import styled from '@emotion/styled';
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';

import CreateAlbumDetailsBody from '../../../components/CreateProject/AlbumUpload/AlbumDetails/CreateAlbumDetailBody';
import {GlobalContext} from '../../../Contexts/GlobalProvider';
import useNotification from '../../../Contexts/useNotification';
import useCreateAlbum from '../../../hooks/AlbumsManagement/useCreateAlbum';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import SelectLabelComponent from '../../CreateProject/SelectLabelComponent';
import Button from '../../reusable/Button/Button';
import StepProgressComp from './StepProgressComp';
import TerritoryAlbumCreate from './TerritoryAlbumCreate';

const data = [
  {
    title1: 'STEP 1',
    title2: 'STEP 2',
    subtitle1: 'Create Album',
    desc: 'Enter Album Details here',
    subtitle2: 'Territory',
    step: '1',
  },
  {
    title1: 'STEP 2',
    title2: 'FINISH',
    subtitle1: 'Territory',
    desc: 'Enter Territory details here',
    subtitle2: '',
    step: '2',
  },
];
const ManualUploadForm = (props) => {
  const {setIsAlbumCreated, setUpcCode, upcRef, apiCalledRef} = props;
  const [step, setStep] = useState(1);
  const [stepperData, setStepperData] = useState(data[0]);

  const [selectedValue, setSelectedValue] = useState('worldwide');
  const [disable, setDisable] = useState(true);
  const [excludeValue, setExcludeValue] = useState([]);
  const [includeValue, setIncludeValue] = useState([]);
  const [project, setProject] = useState(null);
  const [disabled, setDisabled] = React.useState(true);
  const {state}: any = useContext(GlobalContext);
  const [albumData, setAlbumData] = useLocalStorage('track_album_data', null);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [projectId, setProjectId] = useLocalStorage('projectId', null);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(null);

  const [initialState, setInitialState] = useState({
    album_type: '',
    product_type: '',
    album_name: '',
    upc: '',
    actors_name: [''],
    actress_name: [''],
    language_id: '',
    album_director_name: [''],
    album_producer_name: [''],
    banner_production_name: [''],
    label_c_name: '',
    p_line_name: '',
    film_zone: '',
    album_release_date: '',
    label_id: state.label_name,
  });

  useEffect(() => {
    if (step === 1) {
      setStepperData(data[0]);
    }
    if (step >= 2) {
      setStepperData(data[1]);
    }
  }, [step]);

  const {showToast} = useNotification();

  const {
    createAlbumMutation,
    isLoading: isCreateAlbumLoading,
  } = useCreateAlbum();

  const toggleFormDisplay = () => {
    setDisabled(false);
  };

  const dis = () => {
    if (selectedValue === 'worldwide') {
      return false;
    } else if (selectedValue === 'exclude' && excludeValue.length > 0) {
      return false;
    } else if (selectedValue === 'include' && includeValue.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onPrevious = () => {
    if (step === 1) {
      navigate('/dashboard/albums');
    } else {
      setStep(step - 1);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const user_id = currentUser.email;
    const countriesArr =
      selectedValue !== 'worldwide' && selectedValue === 'exclude'
        ? excludeValue.map((item) => item.id)
        : includeValue.map((item) => item.id);

    const {
      language_id: language,
      album_release_date: date,
      label_id,
      ...rest
    } = initialState;

    const album_release_date = new Date(date).toISOString();
    const language_id = Number(language);
    const labelId = state.label_name;
    const album_data = {
      user_id,
      language_id,
      project_id: project.id,
      album_release_date,
      label_id: labelId,
      territory: selectedValue,
      countries: countriesArr,
      ...rest,
    };

    const res = await createAlbumMutation(album_data);
    if (res) {
      setIsAlbumCreated(true);
      window.location.reload();
      setIsLoading(false);
    }

    if (res?.error && res?.error?.length > 0) {
      showToast(res?.error, 'error');
    }
  };

  return (
    <div>
      <Container>
        <StepProgressComp step={step} dataArr={data} data={stepperData} />
        <SelectLabelComponent
          project={project}
          setProject={setProject}
          handleClick={() => toggleFormDisplay()}
        />
      </Container>

      {step < 2 && (
        <CreateAlbumDetailsBody
          project={project}
          setStep={setStep}
          step={step}
          initialState={initialState}
          setInitialState={setInitialState}
          setUpcCode={setUpcCode}
          upcRef={upcRef}
        />
      )}
      {step > 1 && (
        <TerritoryContainer>
          <div className="group-container">
            <div className="container-mid">
              <TerritoryAlbumCreate
                excludeValue={excludeValue}
                setExcludeValue={setExcludeValue}
                includeValue={includeValue}
                setIncludeValue={setIncludeValue}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            </div>
          </div>

          <Container2>
            <Button
              label="Previous"
              onClick={onPrevious}
              isLoading={isCreateAlbumLoading || isLoading}
            />
            <Button
              label="Create Album"
              onClick={onSubmit}
              isLoading={isCreateAlbumLoading || isLoading}
              disabled={dis()}
            />
          </Container2>
        </TerritoryContainer>
      )}
    </div>
  );
};
const Container = styled.div``;
const TerritoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  // height: calc(100vh - 100px);
  height: calc(55vh - 50px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 -30px -30px;
  padding: 0 30px;
  .group-container {
    margin: 0 -30px;
    border-top: 1px solid #ececec;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    align-items: center;
    justify-content: center;
    .container-mid {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 35px;
    }
  }
`;

const Container2 = styled.div`
  background: #f8f8f8;
  width: 100%;
  height: 70px;
  margin-left: -10px;
  // padding-left: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // margin-bottom: -30px;
  position: fixed;
  bottom: 0;
  button {
    width: 100px;
    margin-right: 20px;
    background: #d21919;
    border-radius: 100px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    border: none;
  }
  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }
`;
export default ManualUploadForm;
