/** @format */

import {FormControl} from 'baseui/form-control';
import {Select} from 'baseui/select';
import {ErrorMessage, Field} from 'formik';
import React from 'react';

import {
  borderNone,
  setBorderRadius,
} from '../../../styles/base-ui-overrides/common';
import {Label} from './BaseInput';
import TextError from './TextError';

function BaseSelect(props) {
  const {
    label,
    name,
    options,
    initialValue,
    labelKey,
    valueKey,
    onChange,
    value,
    isSearchable,
    onChangeExplicit,
    isRequired,
    multi = false,
    ...rest
  } = props;

  return (
    <>
      <Field id={name} name={name} {...rest}>
        {({field, form}) => (
          <FormControl
            label={`${label}`}
            overrides={{
              Label: (data) => <Label data={data} isRequired={isRequired} />,
            }}
          >
            <Select
              overrides={{
                Root: {
                  style: {
                    ...borderNone,
                  },
                },
                ControlContainer: {
                  style: ({$theme}) => {
                    return {
                      ...borderNone,
                      backgroundColor: $theme.inputBg,
                      ...setBorderRadius(5),
                      marginBottom: '25px',
                    };
                  },
                },

                Dropdown: {
                  style: ({$theme}) => {
                    return {
                      maxHeight: '96.5vh',
                    };
                  },
                },
                Popover: {
                  props: {
                    overrides: {
                      Body: {
                        style: ({$theme}) => ({
                          zIndex: '3',
                        }),
                      },
                    },
                  },
                },
              }}
              options={options}
              labelKey={labelKey}
              valueKey={valueKey}
              clearable={false}
              searchable={isSearchable}
              // onChange={({value}) => onChangeHandler(value)}
              onChange={({value}) => onChange(name, value)}
              value={value}
              multi={multi}
              {...rest}
            />
          </FormControl>
        )}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}

export default BaseSelect;
