import {createTheme} from 'baseui';
import {Theme} from 'baseui/theme';

import {customDarkThemePalette, CustomThemeType} from './colors';

const defaultDarkThemePalette = {
  primary: customDarkThemePalette.textColor,
};

const defaultDarkThemePaletteOverrides = {
  colors: {
    backgroundPrimary: customDarkThemePalette.bg100,
    contentPrimary: customDarkThemePalette.textColor,
    inputFillDisabled: customDarkThemePalette.primary600,
    inputFill: customDarkThemePalette.primary500,
    inputFillActive: customDarkThemePalette.primary400,
    inputBorder: customDarkThemePalette.textColor,
    buttonPrimaryFill: customDarkThemePalette.accent,
    buttonPrimaryHover: customDarkThemePalette.accent600,
    buttonPrimaryActive: customDarkThemePalette.accent700,
    buttonTertiaryHover: customDarkThemePalette.primary600,
    buttonTertiaryActive: customDarkThemePalette.primary700,
    tagPrimarySolidBackground: customDarkThemePalette.primary600,
    buttonDisabledFill: customDarkThemePalette.primary50,
  },
};

const defaultDarkTheme = createTheme(
  defaultDarkThemePalette,
  defaultDarkThemePaletteOverrides,
);

const customDarkTheme: Theme & CustomThemeType = {
  ...defaultDarkTheme,
  // add custom colors here and access it through $theme
  themeButtonBg: customDarkThemePalette.primary200,
  bg: customDarkThemePalette.bg,
  textColor: customDarkThemePalette.textColor,
  menuHover: customDarkThemePalette.menuHover,
  sidebarMenuText: customDarkThemePalette.sidebarMenuText,
  sidebarMenuInactiveText: customDarkThemePalette.sidebarMenuInactiveText,
  arrowBg: customDarkThemePalette.arrowBg,
  divoBg: customDarkThemePalette.divoBg,
  tableBorderColor: customDarkThemePalette.tableBorderColor,
  tableOddRowBg: customDarkThemePalette.tableOddRowBg,
  divoRed: customDarkThemePalette.divoRed,
  tabInactiveBorderColor: customDarkThemePalette.tabInactiveBorderColor,
  tabInactiveBg: customDarkThemePalette.tabInactiveBg,
  inputContainerBg: customDarkThemePalette.inputContainerBg,
  tableEvenRowBg: customDarkThemePalette.tableEvenRowBg,
  bgDefault: customDarkThemePalette.bgDefault,
  inputBg: customDarkThemePalette.inputBg,
  fileUploadBg: customDarkThemePalette.fileUploadBg,
  rowHover: customDarkThemePalette.rowHover,
  rowHoverText: customDarkThemePalette.rowHoverText,
  transparentTableGradient: customDarkThemePalette.transparentTableGradient,
  opaqueTableGradient: customDarkThemePalette.opaqueTableGradient,
  upcBg: customDarkThemePalette.upcBg,
  tabTextActive: customDarkThemePalette.tabTextActive,
  tabTextInActive: customDarkThemePalette.tabTextInActive,
  buttonActiveTextColor: customDarkThemePalette.buttonActiveTextColor,
  buttonInactiveTextColor: customDarkThemePalette.buttonInactiveTextColor,
  buttonInActiveBg: customDarkThemePalette.buttonInActiveBg,
  buttonActiveBg: customDarkThemePalette.buttonActiveBg,
  tableHeadBg: customDarkThemePalette.tableHeadBg,
  tableHeadColor: customDarkThemePalette.tableHeadColor,
  contentBg: customDarkThemePalette.contentBg,
  placeholderColor: customDarkThemePalette.placeholderColor,
  removeBtnBg: customDarkThemePalette.removeBtnBg,
};

export default customDarkTheme;
