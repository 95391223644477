import styled from '@emotion/styled';
import {Form, Formik} from 'formik';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {Button} from '../../../../components';
import {
  genre,
  musicals,
} from '../../../../components/CreateProject/AlbumUpload/AlbumDetails/optionData';
import FormikControl from '../../../../components/reusable/Formik/FormikControl';
import useNotification from '../../../../Contexts/useNotification';
import {logError} from '../../../../errorlogger/logError';
import {getTrackOrder} from '../../../../helpers/getTrackOrder';
import useGetAlbumsData from '../../../../hooks/AlbumDetail/useGetAlbumsData';
import useReUpload from '../../../../hooks/AlbumDetail/useReUpload';
import useUpdateTrack from '../../../../hooks/AlbumDetail/useUpdateTrack';
import useCreateTrack from '../../../../hooks/AlbumsManagement/useCreateTrack';
import useLocalStorage from '../../../../hooks/reusable/useLocalStorage';
import {COLUMN_LABELS} from '../../../../validation/CreateAlbum/FilmType';
import {
  trackDetailsUpdateValidationSchema,
  trackDetailsValidationSchema,
} from '../../../../validation/ProjectManagement/trackDetails';
import TrackFileUpload from '../../../UploadComponent/TrackFileUpload';
import UploadCardTrack from '../../../UploadComponent/UploadCardTrack';
export default function TrackForm(props) {
  const {
    selectedData,
    setIsOpen,
    setIsrcCode,
    setTrackCreated,
    refetchAlbums,
    isrcRef,
    newFormTag,
  } = props;
  const {albumId} = useParams();

  const localAlbumData = JSON.parse(window.localStorage.getItem('albumData'));

  const albumData = localAlbumData?.filter(
    (item) => Number(item.id) === Number(albumId),
  )[0];

  const [currentISRC, setCurrentISRC] = useLocalStorage('currentISRC', null);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [isValidApiCalled, setIsValidApiCalled] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [duration, setDuration] = useState(null);
  const [newTrack, setNewTrack] = useState(null);
  const {getAlbumDetail, refetch} = useGetAlbumsData({
    albumId: albumId,
  });
  useEffect(() => {
    if (newFormTag) {
      refetchAlbums();
      refetch();
    }
  }, [newFormTag]);
  const [successFiles, setSuccessFiles] = useState([]);

  const {showToast} = useNotification();

  const {
    createTrackMutation,
    isLoading: isCreateTrackLoading,
    error: createTrackError,
  } = useCreateTrack();

  const {
    updateTrackMutation,
    trackLoading: isUpdateTrackLoading,
    trackError: updateTrackError,
  } = useUpdateTrack();

  const {setProgressInfo, progressInfo} = useReUpload();

  useEffect(() => {
    if (updateTrackError || createTrackError) {
      setIsOpen(true);
    }
  }, [updateTrackError, createTrackError]);

  const updateTrackData = async (data) => {
    const {track_id, user_id, album_id, track_order: track_num, ...rest} = data;
    const track_order = Number(track_num);
    const trackData = {
      id: track_id,
      data: {
        user_id: user_id,
        album_id: album_id,
        track_order,
        ...rest,
      },
    };

    try {
      const res = await updateTrackMutation(trackData);
      if (res.error && res.error?.length > 0) {
        showToast(res.error, 'error');
      }
      closeModalOnSuccess(res);
    } catch (error) {
      logError(error, 12);
      return error.response?.data;
    }
  };

  const createValues = {
    track_name: selectedData ? selectedData.track_name : '',
    isrc: '',
    singers: selectedData?.singers?.length ? selectedData.singers : [''],
    lyricists: selectedData?.lyricists?.length ? selectedData.lyricists : [''],
    music_director: selectedData?.music_director?.length
      ? selectedData.music_director
      : [''],
    track_genre: selectedData
      ? genre.filter((gen) => gen.id === selectedData.track_genre)
      : '',
    instruments: '',
  };

  const updateValues = {
    track_name: selectedData ? selectedData.track_name : '',
    track_order: selectedData ? selectedData.track_order : '',
    singers: selectedData?.singers?.length ? selectedData.singers : [''],
    lyricists: selectedData?.lyricists?.length ? selectedData.lyricists : [''],
    music_director: selectedData?.music_director?.length
      ? selectedData.music_director
      : [''],
    track_genre: selectedData
      ? genre.filter((gen) => gen.id === selectedData.track_genre)
      : '',
    instruments: selectedData
      ? musicals.filter((music) => music.id === selectedData.instruments)
      : '',
  };

  const makeResponseData = (type, values) => {
    let res;
    const album_id = selectedData ? selectedData?.album_id : albumData?.id;
    const user_id = selectedData ? selectedData?.user_id : albumData?.user_id;
    const track_upc = albumData?.upc;
    const track_id = selectedData?.id;
    const isrc = selectedData ? selectedData.isrc : values.isrc;
    const track_genre = values?.track_genre[0]?.value;
    const instruments = values?.instruments[0]?.id;
    const updated_by = currentUser.id;

    const commonValues = {
      track_file_name: values.isrc,
      lyricists: values.lyricists,
      music_director: values.music_director,
      singers: values.singers,
      instruments,
      track_name: values.track_name,
      album_id,
      track_genre,
      track_upc,
      isrc,
      language_id: albumData?.language_id,
      album_name: albumData?.album_name,
      p_line_name: albumData?.p_line_name,
      label_c_name: albumData?.label_c_name,
      upc: albumData?.upc,
      album_release_date: albumData?.album_release_date,
      album_type: albumData?.album_type,
      product_type: albumData?.product_type,
      territory: albumData?.territory,
      album_director_name: albumData?.album_director_name,
      album_producer_name: albumData?.album_producer_name,
      label_id: albumData?.label_id,
    };

    const createTrackData = {
      ...commonValues,
      track_order: getTrackOrder(getAlbumDetail.Track),
      user_id: user_id,
      duration,
    };

    const updateTrackData = {
      ...commonValues,
      track_id,
      track_order: values.track_order,
      updated_by,
    };

    if (type === 'create') {
      res = createTrackData;
    } else {
      res = updateTrackData;
    }
    return res;
  };

  const createTrackFn = (data) => {
    try {
      createTrackMutation(data);

      setTrackCreated(true);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTrackFn = async (data) => {
    try {
      const res = await updateTrackData(data);

      if (res?.error && res?.error.length > 0) {
        showToast(res?.error, 'error');
      }
      closeModalOnSuccess(res);
    } catch (error) {
      console.log(error);
    }
  };

  const closeModalOnSuccess = (res) => {
    setIsOpen(false);
  };

  const onSubmit = async (formikData) => {
    const type = selectedData?.id ? 'update' : 'create';

    const data = makeResponseData(type, formikData);
    data.duration = duration;
    if (selectedData?.id) {
      updateTrackFn(data);
    } else {
      createTrackFn(data);
    }
  };

  const setDisabled = (formik): boolean => {
    if (selectedData) {
      return Object.keys(formik.touched).length === 0 &&
        formik.touched.constructor === Object
        ? true
        : false;
    } else {
      return formik.dirty && formik.isValid && progressInfo === 100
        ? false
        : true;
    }
  };

  const setOrder = (formik) => {
    let order = 0;
    const tracks = JSON.parse(localStorage.getItem('tracks') || '[]');
    // Find the track with the same album id and add + 1 to the track order
    const track = tracks?.filter((track) => {
      return track?.album_id === albumData.id;
    });
    if (selectedData) {
      order = selectedData.track_order;
      setDuration(selectedData.duration);
      if (duration !== null) setDuration(duration);
    } else {
      if (track.length) {
        order = track[track.length - 1].track_order + 1;
      } else {
        order += 1;
      }
    }
    return order;
  };

  const onFileSelected = async (files, isrc) => {
    setSuccessFiles(files);
    const file = files[0];
    if (['audio/x-wav', 'audio/wav'].includes(file.type)) {
      // const new_file = rename_file(file, isrc);
      const new_file = new File([file], isrc.trim() + '.wav');
      // Calculate duration of track
      const audio = new Audio(URL.createObjectURL(new_file));
      audio.addEventListener('loadedmetadata', () => {
        const duration = audio.duration;
        // if(duration !== 0) setDuration(duration);
        setDuration(duration);
      });
      // Convert track to base64 string
      const reader = new FileReader();
      reader.readAsDataURL(new_file);
      reader.onload = async () => {
        const base64 = reader.result?.toString();
        // Store track in local storage
        if (base64) {
          setNewTrack(base64);
          setProgressInfo(100);
        }
      };
    }
  };

  return (
    <TrackFormWrapper>
      <Formik
        initialValues={selectedData ? updateValues : createValues}
        validationSchema={
          selectedData
            ? trackDetailsUpdateValidationSchema
            : trackDetailsValidationSchema
        }
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form style={{width: '100%'}}>
            <FormikControl
              control="baseinput"
              type="text"
              name="track_name"
              label={COLUMN_LABELS.SONG_NAME}
              placeholder="e.g Sound of music"
              initialValue={formik.initialValues.track_name}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />
            {selectedData && (
              <div style={{pointerEvents: 'none', opacity: '0.7'}}>
                <FormikControl
                  control="baseinput"
                  name="isrc"
                  label={COLUMN_LABELS.ISRC}
                  type="text"
                  initialValue={selectedData?.isrc}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                />
              </div>
            )}
            <div style={{pointerEvents: 'none', opacity: '0.7'}}>
              <FormikControl
                control="baseinput"
                name="track_order"
                label={COLUMN_LABELS.SONG_ORDER}
                type="text"
                initialValue={setOrder(formik)}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
              />
            </div>
            {!selectedData && (
              <div
                style={
                  progressInfo === 100 || progressInfo >= 1
                    ? {pointerEvents: 'none', opacity: '0.7'}
                    : {}
                }
              >
                <FormikControl
                  control="drawerInputWButton"
                  name="isrc"
                  label="ISRC *"
                  placeholder="Generate ISRC"
                  setIsrcCode={setIsrcCode}
                  value={formik.values.isrc}
                  setFieldValue={formik.setFieldValue}
                  setIsValid={setIsValid}
                  setIsValidApiCalled={setIsValidApiCalled}
                />
              </div>
            )}
            <FormikControl
              control="dynamicInput"
              name="singers"
              placeholder="e.g Jane Doe"
              label={COLUMN_LABELS.SINGER_ARTIST_NAME}
              buttonName="Add Singer"
              setFieldTouched={formik.setFieldTouched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikControl
              control="dynamicInput"
              name="lyricists"
              label={COLUMN_LABELS.LYRICIST_NAME}
              placeholder="e.g Jane Doe"
              buttonName="Add Lyricist"
              setFieldTouched={formik.setFieldTouched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikControl
              labelKey="value"
              valueKey="id"
              control="baseselect"
              label={COLUMN_LABELS.MUSICAL_INSTRUMENTS}
              isSearchable={false}
              name="instruments"
              placeholder="Select Musical Instruments"
              options={musicals}
              value={formik.values.instruments}
              onChange={(name, value) => {
                formik.setFieldTouched(name, true);
                formik.setFieldValue(name, value);
              }}
            />
            <FormikControl
              control="dynamicInput"
              buttonName="Add Director"
              placeholder="e.g John Doe"
              name="music_director"
              label="Music Director"
              type="text"
              setFieldTouched={formik.setFieldTouched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikControl
              labelKey="value"
              valueKey="id"
              control="baseselect"
              label={COLUMN_LABELS.SONG_GENRE}
              name="track_genre"
              placeholder="Select Genre"
              isSearchable={false}
              options={genre}
              value={formik.values.track_genre}
              onChange={(name, value) => {
                formik.setFieldTouched(name, true);
                formik.setFieldValue(name, value);
              }}
            />

            <p className="trackfile">
              Track File <span> *</span>
            </p>
            <TrackFileUpload
              onDropHandler={onFileSelected}
              setFieldTouched={formik.setFieldTouched}
              isrcCode={
                selectedData ? selectedData?.isrc : formik?.values?.isrc
              }
              progressInfo={progressInfo}
            />

            {successFiles.length
              ? successFiles.map((dataItem, i) => (
                  <UploadCardTrack
                    label="WAV"
                    name={
                      selectedData ? selectedData?.track_name : dataItem.name
                    }
                    key={i}
                    progressInfo={progressInfo}
                    page="tracks"
                    size={dataItem.size}
                  />
                ))
              : null}

            <Button
              disabled={setDisabled(formik)}
              // disabled={
              //   setDisabled(formik) ||
              //   (Object.keys(formik.touched).length === 0 &&
              //     formik.touched.constructor === Object)
              // }
              isLoading={isCreateTrackLoading || isUpdateTrackLoading}
              type="submit"
              label={selectedData ? 'Update Track' : 'Add Track'}
            />
          </Form>
        )}
      </Formik>
    </TrackFormWrapper>
  );
}

const TrackFormWrapper = styled.div`
  .audio-file-container {
    .audio-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;

      width: 100%;
    }
  }
  .trackfile {
    margin: 0;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin: 4px 0;
    color: #333333;
    font-weight: bold;
    span {
      margin-left: 5px;
      color: #dc3832;
    }
  }

  .audio-file-upload {
    margin: 8px 0;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    .row1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .sect1 {
      width: 60%;

      .icon {
        width: 50px;
        height: 50px;
        background: rgba(0, 202, 202, 0.25);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #00caca;
          font-size: 20px;
        }
      }
      .track-data {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        line-height: 18px;
        span {
          small {
            font-size: 12px;
            background: #f7e8cc;
            color: #d88e00;
            padding: 5px;
          }
          &:nth-of-type(1) {
            font-weight: bold;
          }
          &:nth-of-type(2) {
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }

    .sect1,
    .sect2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .sect2 {
      justify-content: flex-end;
      width: 40%;
    }
    .success {
      display: flex;
      width: 100%;
      background: #e1f1e1;
      color: #34a139;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      border-radius: 5px;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;
