import {useStyletron} from 'baseui';
import {useState} from 'react';

import {
  filmZone,
  nonFilmZone,
} from '../../components/CreateProject/AlbumUpload/AlbumDetails/optionData';
import useNotification from '../../Contexts/useNotification';
import {logError} from '../../errorlogger/logError';
import {capitalizeFirstLetter} from '../../helpers/capitalizeFirstLetter';
import useGetAlbumsData from '../AlbumDetail/useGetAlbumsData';
import useReUpload from '../AlbumDetail/useReUpload';
import useListLanguages from '../Language/useListLanguage';
import useModal from '../Modal/useModal';
import useLocalStorage from '../reusable/useLocalStorage';
import useS3Key from '../reusable/useS3key';
import useUpdateAlbum from './useUpdateAlbum';

const useAlbumForm = (props) => {
  const {
    selectedData,
    setIsOpen,
    useOnClickOutside,
    sidebarRef,
    onCloseHandler: onClose,
    refetch,
    setIsAlbumClick,
  } = props;

  const {
    getAlbumDetail,
    isLoading,
    refetch: albumDetailRefetch,
  } = useGetAlbumsData({
    albumId: selectedData?.id,
  });

  const [selectedValue, setSelectedValue] = useState(getAlbumDetail?.territory);
  const [excludeValue, setExcludeValue] = useState([]);
  const [includeValue, setIncludeValue] = useState([]);

  const {close, isOpen, setIsOpen: setIsOpenFn} = useModal();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {showToast} = useNotification();

  const {
    updateAlbumMutation,
    isLoading: albumLoading,
    error: albumError,
  } = useUpdateAlbum();
  const [css, theme] = useStyletron();

  const {s3KeyGenerator} = useS3Key({projectId: getAlbumDetail?.project_id});

  const {listLanguages} = useListLanguages();

  const {
    imageLoaded,
    progressInfo,
    setImageLoaded,
    setProgressInfo,
  } = useReUpload();

  const languageOptions = listLanguages
    ? listLanguages.map((language) => ({
        language: capitalizeFirstLetter(language.language),
        id: language.id,
      }))
    : [];

  useOnClickOutside(sidebarRef, () => onClose());

  const initialValues = {
    album_type: getAlbumDetail
      ? getAlbumDetail?.album_type?.toLowerCase() === 'film'
        ? [{id: 'Film', value: 'Film'}]
        : [{id: 'Non-film', value: 'Non-Film'}]
      : [],
    product_type: getAlbumDetail ? getAlbumDetail.product_type : '',
    album_name: getAlbumDetail ? getAlbumDetail.album_name : '',
    // artist_id: getAlbumDetail ? getAlbumDetail.artist_id : '',
    actors_name: getAlbumDetail?.actors_name?.length
      ? getAlbumDetail?.actors_name
      : [''],
    actress_name: getAlbumDetail?.actress_name?.length
      ? getAlbumDetail?.actress_name
      : [''],
    language_id:
      listLanguages && getAlbumDetail
        ? listLanguages.filter((lang) => lang.id === getAlbumDetail.language_id)
        : '',
    album_director_name: getAlbumDetail?.album_director_name?.length
      ? getAlbumDetail.album_director_name
      : [''],
    album_producer_name: getAlbumDetail?.album_producer_name?.length
      ? getAlbumDetail.album_producer_name
      : [''],
    banner_production_name: getAlbumDetail?.banner_production_name?.length
      ? getAlbumDetail.banner_production_name
      : [''],
    label_c_name: getAlbumDetail ? getAlbumDetail.label_c_name : '',
    p_line_name: getAlbumDetail ? getAlbumDetail.p_line_name : '',
    film_zone:
      getAlbumDetail?.album_type?.toLowerCase() === 'film'
        ? filmZone.filter(({value}) => value === getAlbumDetail.film_zone)
        : getAlbumDetail?.album_type?.toLowerCase() === 'non-film'
        ? nonFilmZone.filter(({value}) => value === getAlbumDetail.film_zone)
        : [''],
    album_release_date: getAlbumDetail
      ? getAlbumDetail?.album_release_date?.split('T')[0]
      : '',
    territory: getAlbumDetail ? getAlbumDetail?.territory : '',
    country: getAlbumDetail ? getAlbumDetail?.country : [],
  };

  const onSubmit = async (values) => {
    const {
      album_type,
      // product_type,
      language_id,
      film_zone,
      album_release_date,
      country,
      territory,
      ...rest
    } = values;

    const album_id = getAlbumDetail.id;
    const project_id = getAlbumDetail?.project_id
      ? getAlbumDetail?.project_id
      : undefined;

    const albumType = album_type[0].id;
    // const productType = product_type[0].id;
    const languageId = language_id[0].id;
    const filmZone = film_zone[0].value;

    const data = {
      id: album_id,
      project_id,
      album_type: albumType,
      // product_type: productType,
      language_id: languageId,
      film_zone: filmZone,
      updated_by: currentUser.id,
      album_release_date: new Date(album_release_date).toISOString(),
      label_id: getAlbumDetail.label_id,
      countries: country,
      territory,
      ...rest,
    };

    if (territory === 'worldwide') {
      delete data.countries;
    }
    try {
      const res: any = await updateAlbumMutation({
        ...data,
      });

      if (res?.statusCode === 200) {
        refetch();
        setIsOpen(false);
        showToast(`Album updated successfully`, 'success');
        if (setIsAlbumClick) {
          setIsAlbumClick(false);
        }
      }
      if (res?.error && res.error?.length > 0) {
        showToast(res?.error, 'error');
      }
    } catch (error) {
      logError(error, 4);
    }
  };

  return {
    getAlbumDetail,
    theme,
    isOpen,
    close,
    setProgressInfo,
    setImageLoaded,
    isLoading,
    setIsOpenFn,
    progressInfo,
    imageLoaded,
    s3KeyGenerator,
    initialValues,
    onSubmit,
    languageOptions,
    albumLoading,
    excludeValue,
    setExcludeValue,
    includeValue,
    setIncludeValue,
    selectedValue,
    setSelectedValue,
    albumDetailRefetch,
  };
};

export default useAlbumForm;
