import {toaster} from 'baseui/toast';
import React from 'react';

interface ToastProp {
  message: string;
  type: any;
}

const Toast = ({message, type}: ToastProp) => {
  const renderErrorToast = () => {
    return toaster.negative(<>{message}</>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: {width: '100%'},
        },
      },
    });
  };

  const renderSuccessToast = () => {
    return toaster.positive(<>{message}</>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: {width: '100%'},
        },
      },
    });
  };
  return <>{type === 'error' ? renderErrorToast() : renderSuccessToast()}</>;
};

export default Toast;
