import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchAllLabels = async () => {
  try {
    const res = await axios(`/label`, {
      method: 'GET',
    });

    return res.data;
  } catch (error) {
    logError(error, 5);
    return error.response.data;
  }
};

const useListLabels = () => {
  const {data: listLabels} = useQuery('labels', fetchAllLabels);

  return {
    listLabels,
  };
};

export default useListLabels;
