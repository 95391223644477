import {Modal, ModalBody, ModalFooter, ModalHeader} from 'baseui/modal';
import React from 'react';

const ConfirmModal = ({isOpen, close, renderBtn, title, sidebarRef}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      overrides={{
        Root: {
          style: {
            zIndex: 9999,
          },
        },
        Dialog: {
          style: {
            zIndex: 9999,
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
          },
        },
      }}
    >
      <div ref={sidebarRef}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody></ModalBody>
        <ModalFooter>{renderBtn}</ModalFooter>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
