/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {ErrorMessage, Field} from 'formik';
import React, {useEffect} from 'react';
import {FaInfoCircle} from 'react-icons/fa';

import Tooltip from '../ToolTip/ToolTip';
import DetailsError from './DetailsError';
import {LabelInAlbumCreate} from './DetailsSelect';

function Input(props) {
  const [css, theme] = useStyletron();
  const {
    label,
    name,
    isRequired,
    labelName,
    setFieldValue,
    helpText = null,
    ...rest
  } = props;

  useEffect(() => {
    if (labelName) {
      setFieldValue(name, labelName);
    }
  }, [labelName]);

  return (
    <Input.Container
      borderColor={theme.tabInactiveBorderColor}
      bgColor={theme.inputBg}
      textColor={theme.textColor}
    >
      <div className="input_item">
        <div className="left">
          <label htmlFor={name}>
            <LabelInAlbumCreate
              data={{children: label}}
              isRequired={isRequired}
            />
          </label>
        </div>
        <div className="right">
          <Field id={name} name={name} {...rest} />
          {helpText && (
            <Tooltip isOpen={true} content={helpText} direction="left">
              <div className="info">
                <FaInfoCircle />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="input_item">
        <div className="left"></div>
        <div className="right error">
          <ErrorMessage name={name} component={DetailsError} />
        </div>
      </div>
    </Input.Container>
  );
}

Input.Container = styled.div`
  display: flex;
  flex-direction: column;
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
  .input_item {
    margin: 0.3rem 0.75rem;
    justify-content: center;
    align-items: center;
    

    .left {
      flex: 1;
      text-align: right;

      label {
        margin: 0;
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex: 2;
      .css-1apmdcp{
        z-index:0;
      }
    }
    .css-3mw929{
      position:relative;
      z-index: 0;
    }
  }

  label {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.textColor};
  }

  input {
    font-size: 0.85rem;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0.85rem;
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: none;
    width: 94%;

    &:focus {
      outline: none;
    }
  }
`;

export default Input;
