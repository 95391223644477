import React from 'react';

export default function DownloadZipList({
  setIsOpenFn,
  selectedData,
  label,
  setIsOpen,
}) {
  return (
    <>
      {
      selectedData?.Album_dsp.length > 0 ? (
        <div
          className="view_tracks"
          onClick={() => {
            setIsOpenFn(true);
          }}
        >
          <div>
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0-361493)">
                <path
                  d="M20.125 11.5H16.5312L11.5 16.5312L6.46875 11.5H2.875L0 17.25V18.6875H23V17.25L20.125 11.5ZM0 20.125H23V21.5625H0V20.125ZM12.9375 7.1875V1.4375H10.0625V7.1875H5.03125L11.5 13.6562L17.9688 7.1875H12.9375Z"
                  fill="#C1C1C1"
                />
              </g>
              <defs>
                <clipPath id="clip0-361493">
                  <rect width="23" height="23" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span>{label}</span>
          <div>
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1.25L20 10.25L11 19.25M0 10.25H20H0Z"
                stroke="#DC3831"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      ) : null}
    </>
  );
}
