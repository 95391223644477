import styled from '@emotion/styled';
import {StatefulPopover} from 'baseui/popover';
import {PLACEMENT} from 'baseui/toast';
import React, {useEffect, useState} from 'react';
import {FaFileUpload} from 'react-icons/fa';
import {FaFileExcel} from 'react-icons/fa';
import {MdAddCircle} from 'react-icons/md';
import DownloadZipModal from '../../../components/AlbumsInReview/DownloadZipModal';

import {Button} from '../../../components/index';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import Table from '../../../components/reusable/Table/TablePagination';
import {TAGS} from '../../../constants/tags';
import useNotification from '../../../Contexts/useNotification';
import useCallDispatch from '../../../hooks/FIlter/useCallDispatch';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import useAlbumsColumnMetaData from '../../../tableColumns/AlbumsManagement/useAlbumsColumnMetaData';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';

const BulkContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 200px !important;
  margin-left: -65px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);

  ul {
    margin: 0;
    padding: 0px;
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 29px;
      &:hover {
        cursor: pointer;
      }
      padding: 15px 15px;
      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
      .export-option-container {
        transition: 0.5s all ease-in;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #333;
        &:hover {
          color: #dc3831;
          transition: none;
        }
        .icon {
          margin-left: 20px;
          display: flex;
          svg {
            font-size: 16px;
          }
        }
      }
      .label {
        font-size: 14px;
        line-height: 19px;
      }
      &:hover {
        background-color: #f4f4fb;
        cursor: pointer;
      }
    }
  }
`;

const BulkComponent = (props) => {
  const {close, setDynamicTag, setIsOpen} = props;

  const exportData = [
    {
      label: 'Bulk Upload',
      key: 'bulk',
      icon: <FaFileExcel />,
    },
    {
      label: 'Manual Upload',
      key: 'manual',
      icon: <FaFileUpload />,
    },
  ];

  const mainActionHandler = (keys) => {
    if (keys === 'bulk') {
      setDynamicTag(TAGS.BULK_UPLOAD);
    } else if (keys === 'manual') {
      setDynamicTag(TAGS.MANUAL_UPLOAD);
    } else {
      setDynamicTag(TAGS.ALBUMS);
    }
    setIsOpen(true);
    close();
  };

  return (
    <BulkContainer>
      <ul>
        {exportData.map((l) => (
          <li
            key={l.key}
            onClick={() => {
              mainActionHandler(l.key);
            }}
            className="list"
          >
            <label>
              <div className="export-option-container">
                <div className="label">{l.label}</div>
                <div className="icon">{l.icon}</div>
              </div>
            </label>
          </li>
        ))}
      </ul>
    </BulkContainer>
  );
};

export default function Albums(props) {
  const [searchStr, setSearchStr] = useState('');
  const [dynamicTag, setDynamicTag] = useState(TAGS.ALBUMS);
  const {callDispatch} = useCallDispatch();
  const [toggleDspModal, setToggleDspModal] = useState(false);
  const [actorsData, setActorsData] = useState([]);
  const [actressData, setActressData] = useState([]);
  const [directorData, setDirectorData] = useState([]);
  const [albumName, setAlbumName] = useState([]);
  const [artistName, setArtistName] = useState([]); //artist is singers
  const [labelName, setLableName] = useState([]);
  const [dataFilter, setDataFilter] = useState('all');
  const [allData, setAllData] = useState({
    actors: [],
    actress: [],
    director: [],
    artist: [],
    album: [],
    label: [],
    status: [],
    product_type: [],
    content_type: [],
  });
  // const {isLoading, albumsData} = useAlbumAdvanceFilter();
  // const albumsData = JSON.parse(localStorage.getItem('albumData')) || [];
  const [albumsData, setalbumsData] = useLocalStorage('albumData', null);
  const {showToast} = useNotification();
  useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      callDispatch('CHANGE_UPC_DROP', 'upc_id', []);
      callDispatch('CHANGE_START_DATE_DROP', 'order_start_date', '');
      callDispatch('CHANGE_END_DATE_DROP', 'order_end_date', '');
      callDispatch('CHANGE_START_RELEASE_DATE_DROP', 'start_release_date', '');
      callDispatch('CHANGE_END_RELEASE_DATE_DROP', 'end_release_date', '');
      if (window.location.pathname.indexOf('/albums') === -1) {
        window.localStorage.removeItem('tabArr');
      }
    };
  }, []);

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();
  const {albumsColumnMeta, selectedIds} = useAlbumsColumnMetaData();
  const albums = React.useMemo(() => albumsData, [albumsData]);
  const columnData = React.useMemo(() => albumsColumnMeta, [albumsColumnMeta]);

  return (
    <>
      {/* Red Danger text saying 'this data is stored in local storage */}
      <Table
        columns={columnData}
        data={albums ? albums : []}
        tag={TAGS.ALBUMS}
        setSidebarData={setSelectedData}
        onClickRow={setIsOpen}
        isLoading={false}
        setSearchStr={setSearchStr}
        actors={actorsData}
        actress={actressData}
        director={directorData}
        albumName={albumName}
        artist={artistName}
        label={labelName}
        setAllData={setAllData}
        allData={allData}
        setDataFilter={setDataFilter}
        dataFilter={dataFilter}
        actionBtn={
          <>
            <div
              style={{
                color: '#dc3831',
                fontSize: 'small',
                position: 'absolute',
                left: '1%',
              }}
            >
              <span>
                *This data is stored in local storage , data will be deleted
                after a week or logout.
              </span>
            </div>
            <Button
              margin="0px 10px"
              label="Download Metadata"
              onClick={() => {
                if (selectedIds.length) {
                  setToggleDspModal(true);
                } else {
                  showToast('Select albums', 'error');
                }
              }}
              shape="round"
            />
            <StatefulPopover
              dismissOnClickOutside
              focusLock={true}
              placement={PLACEMENT.bottomLeft}
              content={({close}) => (
                <BulkComponent
                  close={close}
                  setDynamicTag={setDynamicTag}
                  setIsOpen={setIsOpen}
                />
              )}
            >
              <div className={`export-btn-container`}>
                <Button
                  label="Create Album"
                  // onClick={mainActionHandler}
                  shape="round"
                  endEnhancer={() => <MdAddCircle />}
                />
              </div>
            </StatefulPopover>
          </>
        }
      />
      {isOpen && (
        <FlyMenu
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setDynamicTag={setDynamicTag}
          tag={dynamicTag}
          setNewFormTag={setNewFormTag}
          newFormTag={newFormTag}
          closeable={dynamicTag === TAGS.ALBUMS ? true : false}
        />
      )}
      {toggleDspModal && (
        <DownloadZipModal
          id={selectedIds}
          multiple={true}
          showModal={toggleDspModal}
          onCloseHandler={() => setToggleDspModal(false)}
          sidebarRef={null}
        />
      )}
    </>
  );
}
