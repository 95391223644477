import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchReuseIsrc = async (key, {searchStr}) => {
  console.log('request', searchStr);
  if (searchStr) {
    try {
      const res = await axios(
        `track/isrc_autocomplete?search_string=${searchStr}`,
        {
          method: 'GET',
        },
      );
      return res.data?.data;
    } catch (error) {
      logError(error, 17);
      return error.response?.data;
    }
  }
};

const useGetReuseIsrc = (props) => {
  const searchStr = props;
  const {
    data: reuseIsrc,
    refetch,
    isFetching,
    isLoading: searchLoad,
  } = useQuery(['reuseIsrc', searchStr], fetchReuseIsrc);

  return {reuseIsrc, refetch, isFetching, searchLoad};
};

export default useGetReuseIsrc;
