import React from "react";
import styled from "@emotion/styled";
import { useStyletron } from "baseui";
import { CustomThemeType } from "../../../styles/theme/colors";
import { Theme } from "baseui/theme";
import { AiFillInfoCircle } from "react-icons/ai";

const Error = styled.div<{ theme: Theme & CustomThemeType }>`
	color: ${(props) => props.theme.divoRed && props.theme.divoRed};
	display: flex;
	align-item: center;
	.content {
		margin-left: 5px;
		font-size: 14px;
		font-weight: bold;
	}
`;

function TextError(props) {
	const [css, theme] = useStyletron();
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				margin: 0,
				width: "100%",
			}}>
			<Error theme={theme}>
				<span>
					<AiFillInfoCircle />
				</span>
				<span className="content">{props.children}</span>
			</Error>
		</div>
	);
}

export default TextError;
