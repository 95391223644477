import styled from '@emotion/styled';
import {Skeleton} from 'baseui/skeleton';
import React from 'react';

const TableRowSkeletonLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .row4 {
    flex-direction: column;
    height: 500px;
    justify-content: space-between;
  }
`;

export default function TableRowSkeletonLoading() {
  return (
    <TableRowSkeletonLoadingContainer>
      <div className="row row4">
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
      </div>
    </TableRowSkeletonLoadingContainer>
  );
}

const TableRowSkeletonContainer = styled.div`
  display: flex;
  background-color: #fff;
  box-shadow: -4px 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  .sub-container {
    display: flex;
    width: 98%;
    justify-content: space-between;
  }
`;

const TableRowSkelton = () => {
  return (
    <TableRowSkeletonContainer>
      <div className="sub-container">
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
      </div>
    </TableRowSkeletonContainer>
  );
};
