import React from 'react';

import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import CheckBoxTable from '../../../components/reusable/Table/CheckBoxTable';

export default function Album(props) {
  const {
    columns,
    setIsOpen,
    setSelectedData,
    setNewFormTag,
    data,
    setTrackIsrc,
    tag,
    playing,
    handlePlayPause,
    duration,
    trackIsrc,
    productType,
    status,
    getAlbumDetail,
    noAddTrack,
    isFetching,
  } = props;
  const trackData = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => columns, [columns]);

  const openAddTrackHandler = () => {
    setIsOpen(true);
    setNewFormTag('add-track');
  };

  const openNewFormSidebarHandler = () => {
    setIsOpen(true);
    setNewFormTag(true);
  };

  return (
    <>
      {data?.length === 0 ? (
        <>
          <div
            style={{color: '#dc3831', fontSize: 'small', marginRight: 'auto'}}
          >
            <span>
              *This data is stored in local storage , data will be deleted after
              a week or logout.
            </span>
          </div>
          <NoRecords
            page="tracks"
            btnLabel="Add Track"
            onClick={openNewFormSidebarHandler}
          />
        </>
      ) : (
        <CheckBoxTable
          setSelectedValue={null}
          columns={columnData}
          data={trackData ? trackData : []}
          onClickRow={setIsOpen}
          setSidebarData={setSelectedData}
          tag={tag}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          hasNextPage={false}
          setSearchStr={null}
          setTrackIsrc={setTrackIsrc}
          playing={playing}
          duration={duration}
          trackIsrc={trackIsrc}
          handlePlayPause={handlePlayPause}
          getAlbumDetail={getAlbumDetail}
          noAddTrack={noAddTrack}
          isFetching={isFetching}
          mainActionHandler={[
            openAddTrackHandler,
            openNewFormSidebarHandler,
            status,
            productType,
          ]}
          footerComponent={null}
        />
      )}
    </>
  );
}
