export const borderNone = {
	borderTopWidth: 0,
	borderBottomWidth: 0,
	borderRightWidth: 0,
	borderLeftWidth: 0,
};

export const setBorderRadius = (value) => {
	return {
		borderBottomLeftRadius: `${value}px`,
		borderBottomRightRadius: `${value}px`,
		borderTopRightRadius: `${value}px`,
		borderTopLeftRadius: `${value}px`,
	};
};
