import styled from '@emotion/styled';
import React from 'react';

import useLockBodyScroll from '../../../hooks/reusable/useLockBodyScroll';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const LoadingSpinnerTransparent = () => {
  useLockBodyScroll();

  return (
    <LoadingStyledContainer className="cool">
      <div className="square">
        <LoadingSpinner />
        <span>Loading</span>
      </div>
    </LoadingStyledContainer>
  );
};

const LoadingStyledContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background: rgba(210, 25, 25, 0.2);
  backdrop-filter: blur(3px);

  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .square {
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      color: #333;
      font-size: 14px;
      user-select: none;
    }
  }
`;

export default LoadingSpinnerTransparent;
