import React, {useContext, useEffect, useRef, useState} from 'react';

import {GlobalContext} from '../../Contexts/GlobalProvider';

const useMusicPlayer = (props) => {
  const {state, dispatch}: any = useContext(GlobalContext);

  const {isrcCode} = props;
  const [url, setUrl] = useState(null);
  const [volume, setVolume] = useState(0.8);
  const [playing, setPlaying] = useState(false);
  const [seeking, setSeeking] = useState<boolean | number>(0);
  const [played, setPlayed] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');
  const [duration, setDuration] = useState(null);
  const [muted, setMuted] = useState(false);
  const playerRef = useRef(null);

  const msToTime = (s) => {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;
    return `${hrs ? `${hrs < 10 ? `0${hrs}` : hrs}:` : ''}${
      mins < 10 ? `0${mins}` : mins
    }:${secs < 10 ? `0${secs}` : secs}`;
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e));
    playerRef.current.seekTo(parseFloat(e));
  };

  const handleSeekMouseUp = () => {
    setSeeking(false);
  };
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleMute = () => {
    setMuted(!muted);
  };

  const handleStop = () => {
    setPlaying(false);
    setPlayed(0);
    playerRef.current.seekTo(0);
  };
  const handleVolumeChange = (e) => {
    setVolume(e.target.value);
  };

  return {
    url,
    volume,
    played,
    playing,
    duration,
    seeking,
    handlePlayPause,
    handleSeekMouseDown,
    handleStop,
    playerRef,
    handleSeekMouseUp,
    handleSeekChange,
    handleVolumeChange,
    msToTime,
    setDuration,
    setPlayed,
    setCurrentTime,
    currentTime,
    handleMute,
    muted,
  };
};

export default useMusicPlayer;
