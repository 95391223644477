/* eslint-disable react-hooks/exhaustive-deps */

import styled from '@emotion/styled';
import {Tab, Tabs} from 'baseui/tabs-motion';
import {Form, Formik} from 'formik';
import React from 'react';

import FormikControl from '../../../../components/reusable/Formik/FormikControl';
import {SubmitButton} from '../../../../components/reusable/SubmitButton/SubmitButton';
import useDisableLabel from '../../../../hooks/LabelManagement/useDisableLabel';
import useLabelForm from '../../../../hooks/LabelManagement/useLabelForm';
import useLocalStorage from '../../../../hooks/reusable/useLocalStorage';
import {labelDetailsValidationSchema} from '../../../../validation/LabelManagement/labelDetails';
const LabelFormContainer = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

export default function LabelForm(props) {
  const {
    selectedData,
    setIsOpen,
    useOnClickOutside,
    sidebarRef,
    onCloseHandler,
    setSelectedData,
    refetch,
  } = props;


  useOnClickOutside(sidebarRef, () => onCloseHandler());

  const [tabActiveKey, setTabActiveKey] = React.useState('0');

  const {
    initialValues,
    onSubmit,
    dspOptions,
    isUpdateLabelLoading,
    isCreateLabelLoading,
  } = useLabelForm({
    selectedData,
    setIsOpen,
    setSelectedData,
    refetch,
  });

  return (
    <LabelFormContainer>
      <Tabs
        activeKey={tabActiveKey}
        onChange={({activeKey}) => {
          setTabActiveKey(activeKey);
        }}
        activateOnFocus
        overrides={{
          TabHighlight: {
            style: {
              backgroundColor: '#DC3831',
            },
          },
        }}
      >
        <Tab
          title="Details"
          overrides={{
            Tab: {
              style: {
                background: 'white',
              },
            },
          }}
        >
          <div
            style={{
              padding: '16px',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={labelDetailsValidationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    <div
                      style={
                        selectedData && {pointerEvents: 'none', opacity: '0.7'}
                      }
                    >
                      <FormikControl
                        disabled={selectedData && selectedData.is_disabled}
                        control="baseinput"
                        type="text"
                        label="Label Name"
                        placeholder="e.g Divo"
                        name="label_name"
                        initialValue={formik.initialValues.label_name}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                      />
                    </div>

                    <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      control="baseinput"
                      type="text"
                      placeholder="e.g 40"
                      label="Percentage"
                      name="percentage"
                      initialValue={formik.initialValues.percentage}
                      setFieldValue={formik.setFieldValue}
                      setFieldTouched={formik.setFieldTouched}
                    />
                    <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      control="baseinput"
                      type="text"
                      placeholder="e.g. Jane Doe"
                      label="Contact Name"
                      name="contact_name"
                      initialValue={formik.initialValues.contact_name}
                      setFieldValue={formik.setFieldValue}
                      setFieldTouched={formik.setFieldTouched}
                      isRequired={false}
                    />

                    <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      control="baseinput"
                      type="text"
                      startEnhancer="+91"
                      label="Contact Phone"
                      name="contact_phone"
                      placeholder="e.g. 9999999999"
                      initialValue={formik.initialValues.contact_phone}
                      setFieldValue={formik.setFieldValue}
                      setFieldTouched={formik.setFieldTouched}
                      isRequired={false}
                    />

                    <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      control="baseinput"
                      type="email"
                      label="Contact Email"
                      name="contact_email"
                      placeholder="e.g. example@divomusic.com"
                      initialValue={formik.initialValues.contact_email}
                      setFieldValue={formik.setFieldValue}
                      setFieldTouched={formik.setFieldTouched}
                      isRequired={false}
                    />

                    <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      control="baseinput"
                      type="text"
                      label="Address"
                      name="address"
                      placeholder="e.g. St. John street"
                      initialValue={formik.initialValues.address}
                      setFieldValue={formik.setFieldValue}
                      setFieldTouched={formik.setFieldTouched}
                      isRequired={false}
                    />

                    <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      control="baseDatePicker"
                      type="date"
                      label="Start Date"
                      name="contract_start_date"
                      initialValue={formik.initialValues.contract_start_date}
                      setFieldValue={formik.setFieldValue}
                      setFieldTouched={formik.setFieldTouched}
                      isRequired={false}
                    />

                    <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      control="baseDatePicker"
                      type="date"
                      label="End Date"
                      name="contract_end_date"
                      initialValue={formik.initialValues.contract_end_date}
                      setFieldValue={formik.setFieldValue}
                      setFieldTouched={formik.setFieldTouched}
                      isRequired={false}
                    />

                    {/* <FormikControl
              disabled={selectedData && selectedData.is_disabled}
              labelKey="dsp_name"
              valueKey="id"
              control="detailsMultiSelect"
              label="DSPs"
              name="dsps"
              options={dspOptions}
              initialValue={formik.initialValues.dsps}
              setFieldValue={formik.setFieldValue}
            /> */}

                    {/* <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      labelKey="dsp_name"
                      valueKey="id"
                      control="newMultiSelect"
                      label="DSPs"
                      name="dsps"
                      values={formik.values}
                      options={dspOptions}
                      initialValue={formik.initialValues.dsps}
                      setFieldValue={formik.setFieldValue}
                    /> */}
                    <FormikControl
                      disabled={selectedData && selectedData.is_disabled}
                      labelKey="value"
                      valueKey="name"
                      control="transferList"
                      label="DSPs"
                      name="dsps"
                      values={formik.values}
                      options={dspOptions && dspOptions}
                      initialValue={formik.initialValues.dsps}
                      setFieldValue={formik.setFieldValue}
                      setFieldTouched={formik.setFieldTouched}
                    />

                    <SubmitButton
                      disabled={
                        !formik.isValid ||
                        (Object.keys(formik.touched).length === 0 &&
                          formik.touched.constructor === Object)
                      }
                      type="submit"
                      label={`${
                        selectedData
                          ? isUpdateLabelLoading
                            ? 'Updating Label'
                            : 'Update Label'
                          : isCreateLabelLoading
                          ? 'Adding Label'
                          : 'Add Label'
                      } `}
                      isLoading={isCreateLabelLoading || isUpdateLabelLoading}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Tab>
      </Tabs>
    </LabelFormContainer>
  );
}
