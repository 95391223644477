import styled from '@emotion/styled';

import image from '../../../assets/placeholder.jpg';

export const CoverArt = styled.div<{imgSrc}>`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => (props.imgSrc ? props.imgSrc : image)});
`;

export const AlbumNameWrapper = styled.div`
  display: -webkit-inline-box;
  align-items: center;
  margin-top: 6px;
  img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    margin-right: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    border: none;
    padding: 6px 12px;
    background-color: white;
    appearance: none;
    cursor: pointer;
    border: 1px solid #dc3831;
    color: #dc3831;
    font-size: 14px;
    outline: none;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  }
`;
