import {css, Global} from '@emotion/core';
import React from 'react';

const global = css`
  html {
    font-size: 16px;
    box-sizing: 'border-size';
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    overflow-y: auto;
    background-color: #eff1f4;
    font-family: 'DM Sans', sans-serif !important;
  }

  .header {
    grid-area: head;
    /* should change the below hard coded color value to a variable */
    box-shadow: 0 1px 0 0 #eaedf3;
    justify-content: center;
    align-content: center;
    z-index: unset;
  }

  .slide-left {
    opacity: 1 !important;
    transform: translateX(0px) !important;
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes flip_horz {
    0% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }
  .rotating {
    -webkit-animation: rotating 0.8s linear infinite;
    -moz-animation: rotating 0.8s linear infinite;
    -ms-animation: rotating 0.8s linear infinite;
    -o-animation: rotating 0.8s linear infinite;
    animation: rotating 0.8s linear infinite;
  }

  @media screen and (max-width: 411px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 72px 1fr;
      grid-template-areas:
        'head'
        'main';
    }
  }
`;

const GlobalStyle = (props) => {
  return <Global styles={global} {...props} />;
};

export default GlobalStyle;
