export const sortStatusAlphabeticalOrderWithoutCase = (row1, row2) => {
  if (row1 === 'waiting_approval') {
    row1 = 'in_review';
  }
  if (row2 === 'waiting_approval') {
    row2 = 'in_review';
  }
  return row1?.toLowerCase().localeCompare(row2?.toLowerCase());
};

export const sortAlphabeticalOrderWithoutCase = (row1, row2) => {
  return row1?.toLowerCase().localeCompare(row2?.toLowerCase());
};

export const sortUserNameAlphabeticalOrderWithoutCase = (row1, row2) => {
  return row1?.toLowerCase().localeCompare(row2?.toLowerCase());
};
