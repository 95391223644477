import useCallDispatch from '../FIlter/useCallDispatch';

const useS3Key = ({projectId}) => {
  const {state} = useCallDispatch();
  const s3KeyGenerator = (path, val1, val2) => {
    if (val1) {
      const ppl_uk = `${projectId}/${path}/${val1}/${val2}.csv`;
      const others = `${projectId}/${path}/${val1}/${val2}.xlsx`;
      const fuga = `${projectId}/${path}/${val1}/Fuga_Metadata.csv`;
      const sony = `${projectId}/${path}/${val1}/Sony_ATV_Metadata.xlsx`;

      switch (path) {
        case 'audio':
          return `${
            state?.existingProjectId
              ? state?.existingProjectId
              : val2
              ? val2
              : projectId
          }/${path}/${val1}.wav`;
        case 'zip':
          return `${projectId}/${path}/${val1}/${val2}.zip`;
        default:
          return val2 === 'ppl_uk'
            ? ppl_uk
            : val2 === 'sony_atv'
            ? sony
            : val2 === 'fuga'
            ? fuga
            : others;
      }
    }
  };
  return {
    s3KeyGenerator,
  };
};

export default useS3Key;
