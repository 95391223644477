import axios from 'axios';

export const token =
  localStorage.getItem('token') && localStorage.getItem('token').split('"')[1];
export const TOKEN = 'bearer '.concat(token);
if (TOKEN) {
  axios.defaults.headers.common['access_token'] = TOKEN;
}
axios.defaults.baseURL = process.env.REACT_APP_Base_URL;

export default axios;
