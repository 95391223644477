import styled from '@emotion/styled';
import {Skeleton} from 'baseui/skeleton';
import * as React from 'react';
const SkeletonLoading = () => {
  return (
    <Container>
      <Skeleton width="100px" height="20px" animation />
      <Skeleton width="490px" height="44px" animation />
      <Skeleton width="100px" height="20px" animation />
      <Skeleton width="490px" height="44px" animation />
      <Skeleton width="100px" height="20px" animation />
      <Skeleton width="490px" height="44px" animation />
      <Skeleton width="100px" height="20px" animation />
      <Skeleton width="490px" height="44px" animation />
      <Skeleton width="100px" height="20px" animation />
      <Skeleton width="490px" height="44px" animation />
      <Skeleton width="100px" height="20px" animation />
      <Skeleton width="490px" height="44px" animation />
      <Skeleton width="138px" height="44px" animation />
    </Container>
  );
};

const Container = styled.div`
  div {
    margin-bottom: 20px;
  }
`;

export default SkeletonLoading;
