import styled from '@emotion/styled';
import {FormControl} from 'baseui/form-control';
import {Textarea} from 'baseui/textarea';
import {ErrorMessage, Field} from 'formik';
import React from 'react';
const BaseDescField = (props) => {
  const {
    label,
    name,
    initialValue,
    setFieldValue,
    setFieldTouched,
    ...rest
  } = props;

  const [value, setValue] = React.useState(initialValue);
  const onChangeHandler = (event) => {
    setValue(event.target.value);
    setFieldValue(name, event.target.value);
    if (setFieldTouched) {
      setFieldTouched(name, true);
    }
  };
  return (
    <Container>
      <Field id={name} name={name} {...rest}>
        {({field, form}) => (
          <FormControl
            overrides={{
              ControlContainer: {
                style: {
                  marginBottom: '5px',
                  border: 'none',
                },
              },
            }}
            label={`${label}`}
          >
            <Textarea
              overrides={{
                Input: {
                  style: ({$theme}: any) => {
                    return {
                      borderBottomWidth: '0px',
                      borderTopWidth: '0px',
                      borderRightWidth: '0px',
                      borderLeftWidth: '0px',
                      backgroundColor: $theme.inputBg,
                      borderTopLeftRadius: '5px',
                      borderBottomLeftRadius: '5px',
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    };
                  },
                },
              }}
              value={value}
              onChange={(e) => onChangeHandler(e)}
              placeholder={label}
              clearOnEscape
            />
          </FormControl>
        )}
      </Field>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 15px;
  label {
    font-size: 14px;
    margin: 8px 0px;
    line-height: 16px;
    font-weight: bold;
  }
  .fp,
  .fq,
  .fr,
  .fs {
    border: none;
  }
`;
export default BaseDescField;
