import axios from 'axios';
import React from 'react';
import {usePaginatedQuery} from 'react-query';

import {logError} from '../../errorlogger/logError';
import usePaginatedData from '../reusable/usePaginatedData';
import useSearchUsers from './useSearchUsers';

const fetchUsers = async (page, limit) => {
  try {
    const response = await axios(`/user/page?page=${page}&limit=${limit}`, {
      method: 'GET',
    });
    return response.data?.data;
  } catch (error) {
    logError(error, 4);

    return error.response?.data;
  }
};

const useGetUsers = (props) => {
  const {searchStr} = props;
  const {
    page,
    setPage,
    data,
    isLastPage,
    totalCount,
    rangeA,
    rangeB,
    lastPage,
    limit,
    setLimit,
    setPaginationDataFn,
    isCustomLoading,
  } = usePaginatedData();

  const {
    resolvedData: sResolvedData,
    isLoading: sIsLoading,
    isError: sIsError,
    error: sError,
    isFetching: sIsFetching,
  } = useSearchUsers({
    str: searchStr,
    page,
    limit,
  });

  const {
    isLoading,
    isError,
    error,
    resolvedData,
    isFetching,
    refetch,
  } = usePaginatedQuery(['users', page, limit], () => fetchUsers(page, limit));

  React.useEffect(() => {
    if (resolvedData && !searchStr) {
      setPaginationDataFn(resolvedData);
    }
  }, [resolvedData, searchStr]);

  React.useEffect(() => {
    if (sResolvedData) {
      setPaginationDataFn(sResolvedData);
    }
    if (searchStr === '') {
      if (resolvedData) {
        setPaginationDataFn(resolvedData);
      }
    }
  }, [sResolvedData]);

  return {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    sIsLoading,
    sIsError,
    sError,
    sIsFetching,
    isCustomLoading,
    refetch,
  };
};

export default useGetUsers;
