import * as Sentry from '@sentry/react';
import _ from 'lodash';
// severity Level Definations
const severityLevel = {
  1: 'fatal',
  2: 'error',
  3: 'warning',
  4: 'info',
  5: 'debug',
};
//Custtom Tag Definitions (Module Name):
const moduleNameTag = {
  0: 'GENERAL',
  1: 'DASHBOARD',
  2: 'USER_MANAGEMENT',
  3: 'CREATE_ALBUM',
  4: 'ALBUMS',
  5: 'ARTIST_LABEL',
  6: 'REPORTS',
  7: 'UPC_ISRC_MANAGEMENT',
  8: 'DSP_MANAGEMENT',
  9: 'SINGLE_ALBUM',
  10: 'ALBUMS_AWAITING_APPROVAL',
  11: 'ALBUM_APPROVAL',
  12: 'TRACK',
  14: 'LANGUAGES',
  15: 'UPLOAD_STATUS',
  16: 'ORDER_MANAGEMENT',
  17: 'PRODUCT_UNLOCK_REQUEST',
};
//Function to Log Errors to Sentry Dashboard
export const logError = (
  errorMessage = 'Error Occured',
  moduleName = 0,
  level = 2,
) => {
  const user = JSON.parse(window.localStorage.getItem('currentUser'));
  if (!_.isEmpty(user)) {
    Sentry.configureScope((scope) =>
      scope
        .setLevel(severityLevel[level])
        .setUser(user)
        .setTag('Module Name', moduleNameTag[moduleName]),
    );

    return Sentry.captureException(errorMessage);
  } else {
    Sentry.configureScope((scope) =>
      scope
        .setLevel(severityLevel[level])
        .setTag('Module Name', moduleNameTag[moduleName]),
    );
    return Sentry.captureException(errorMessage);
  }
};
