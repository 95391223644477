import ExcelJS from 'exceljs';
import React, {useEffect, useState} from 'react';

import useListLanguages from '../hooks/Language/useListLanguage';

const Fuga = (props) => {
  const {data, multiple, selectedIds} = props;
  const [albumTracks, setAlbumTracks] = useState([]);
  const {listLanguages} = useListLanguages();

  const gettracks = (getAlbumDetail) => {
    const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
    if (multiple) {
      const filterTracks = tracks.filter((item) => {
        if (selectedIds.includes(item.upc)) {
          return item;
        }
      });
      setAlbumTracks(filterTracks);
    } else {
      setAlbumTracks(
        tracks.filter((track) => track.album_id === getAlbumDetail?.id),
      );
    }
  };
  useEffect(() => {
    gettracks(data);
  }, [data]);

  if (!data) return null;

  const header = [
    '//Field name:',
    'Album title',
    'Album version',
    'UPC',
    'Catalog number',
    'Primary artists',
    'Featuring artists',
    'Release date',
    'Main genre',
    'Main subgenre',
    'Alternate genre',
    'Alternate subgenre',
    'Label',
    'CLine year',
    'CLine name',
    'PLine year',
    'PLine name',
    'Parental advisory',
    'Recording year',
    'Recording location',
    'Album format',
    'Number of volumes',
    'Territories',
    'Excluded territories',
    'Language (Metadata)',
    'Catalog tier',
    'Track title',
    'Track version',
    'ISRC',
    'Track primary artists',
    'Track featuring artists',
    'Volume number',
    'Track main genre',
    'Track main subgenre',
    'Track alternate genre',
    'Track alternate subgenre',
    'Track language (Metadata)',
    'Audio language',
    'Lyrics',
    'Available separately',
    'Track parental advisory',
    'Preview start',
    'Preview length',
    'Track recording year',
    'Track recording location',
    'Contributing artists',
    'Composers',
    'Lyricists',
    'Remixers',
    'Performers',
    'Producers',
    'Writers',
    'Publishers',
    'Track sequence',
    'Track catalog tier',
    'Original file name',
    'Original release date',
  ];

  const common_item_finder = (arr) => {
    if (arr.length === 1) return arr[0];
    const mod = [];
    const result = {};
    const res = [];

    arr.forEach((e1, i) =>
      e1.forEach((e2) => {
        if (i === 0) {
          mod.push(e2);
          return;
        }
        mod.forEach((e3) => {
          if (!result[e3]) result[e3] = {pos: '', repeats: 0};
          if (e3 === e2 && result[e3].pos !== i) {
            result[e3].repeats += 1;
            result[e3].pos = i;
          }
        });
      }),
    );
    for (const keys in result) {
      if (result[keys].repeats === arr.length - 1) {
        res.push(keys);
      }
    }
    return res;
  };

  const md = albumTracks
    .map((items) => {
      const md_trim = items.music_director.map((ele) => ele.trim());
      return md_trim;
    })
    .join(',')
    .split(',');
  const music_director_consolidated = [...new Set(md)];
  const all_singers = albumTracks.map((items) => {
    if (!items.singers) return [];
    console.log(items.singers);
    const singers_trim = items.singers.map((ele) => ele.trim());
    return singers_trim;
  });

  const primary_artists = [
    ...new Set([
      ...music_director_consolidated,
      ...common_item_finder(all_singers),
    ]),
  ];

  const publisher_generator = (publisher, num) => {
    const result = [];
    for (let i = 0; i < num; i++) {
      result.push(publisher);
    }
    return result.join(' | ');
  };

  // For every track, create a row with the track's data
  const rows = albumTracks.map((track) => {
    const language = listLanguages?.find(
      (lang) => lang.id === track.language_id,
    )?.language_code;
    const lyricists = track.lyricists || [];
    const directors = track.music_director || [];
    const {upc} = track;
    const composers = [...new Set(track.music_director)];
    let territories_excel_excluded; // territories to be excluded in the excel (col X)
    const date_release = new Date(track.album_release_date);
    const c_name = track.label_c_name;
    const p_line = track.p_line_name;
    const music_dir_lyricist = [
      ...new Set([...music_director_consolidated, ...track.lyricists]),
    ];
    const publisher =
      p_line === 'Manorama Music'
        ? publisher_generator('Manorama Music', music_dir_lyricist.length)
        : p_line === 'Kosmik Music'
        ? publisher_generator('Kosmik Music', music_dir_lyricist.length)
        : publisher_generator(
            'Divo Tv Private Limited',
            music_dir_lyricist.length,
          );
    // compare lyricists and directors to get common elements
    const common = lyricists.filter((value) => directors.includes(value));
    if (common.length > 0) {
      track.writer_role = 'C';
    } else {
      track.writer_role = 'A';
    }
    return [
      '',
      track.album_name,
      '',
      upc,
      upc,
      primary_artists.length > 4
        ? 'Various Artists'
        : primary_artists.join(' | '),
      '',
      date_release.getFullYear() +
        '-' +
        ('0' + (date_release.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date_release.getDate()).slice(-2),
      track.album_type === 'Film' ? 'Soundtrack' : track.track_genre,
      '',
      '',
      '',
      track.label_id,
      date_release.getFullYear(),
      c_name,
      date_release.getFullYear(),
      p_line,
      'N',
      date_release.getFullYear(),
      'India',
      track.product_type,
      1,
      track.territory,
      territories_excel_excluded,
      'EN',
      'Front',
      track.track_name,
      '',
      track.isrc,
      track.singers?.toString(),
      '',
      1,
      track.album_type === 'Film' ? 'Soundtrack' : track.track_genre,
      '',
      '',
      '',
      'EN',
      language,
      '',
      'Y',
      'N',
      30,
      30,
      date_release.getFullYear(),
      'India',
      '',
      composers.length > 4 ? 'Various Artists' : composers.join(' | '),
      track.lyricists?.toString(),
      '',
      track.singers?.toString(),
      track.album_producer_name?.toString(),
      track.music_director?.toString(),
      publisher,
      track.track_order,
      'Front',
      `${track.isrc}.wav`,
      date_release.getFullYear() +
        '-' +
        ('0' + (date_release.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date_release.getDate()).slice(-2),
    ];
  });

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.addRows([header, ...rows]);

    worksheet.getRow(1).font = {bold: true, size: 12, color: {argb: '000000'}};
    // Add border to header
    worksheet.getRow(1).border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'},
    };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'bec0bf'},
    };

    worksheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center'};
    // each column width is 30
    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    worksheet.columns.forEach((column) => {
      column.alignment = {vertical: 'middle', horizontal: 'center'};
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'fuga.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button className="button" onClick={handleDownload}>
      FUGA
    </button>
  );
};

export default Fuga;
