import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import {PLACEMENT, ToasterContainer} from 'baseui/toast';
import React, {useState} from 'react';
import GoogleLogin from 'react-google-login';

import equaliser from '../../assets/images/equaliser-bg.svg';
import google from '../../assets/images/google-login.svg';
import logo from '../../assets/images/logo-red.svg';
import {Toast} from '../../components';
import {imgData} from './imagesData';
import {clientId} from '../../hooks/reusable/useLogin';
import {CustomThemeType} from '../../styles/theme/colors';

const CIRCLE_SIZE = 75;

const Login = ({onFailure, toastMsg, onSuccess, currentUser}) => {
  const [images, setImages] = useState(imgData.images);
  const [css, theme] = useStyletron();
  const [light, setLight] = useState(true);

  return (
    <LoginContainer
      theme={theme}
      onDoubleClick={() => setLight(!light)}
      className={`perspective ${light ? 'light' : 'dark'}`}
    >
      {toastMsg?.type === 'error' && (
        <ToasterContainer autoHideDuration={5000} placement={PLACEMENT.bottom}>
          <Toast message={toastMsg.message?.error} type={toastMsg.type} />
        </ToasterContainer>
      )}

      <div className="login">
        <div className="card">
          <div className="section1">
            <div className="row1">
              <div className="title-container">
                <span>Welcome To</span>
                <div>
                  <img src={logo} alt="" />
                </div>
                <span>Music Metadata Generator</span>
              </div>
            </div>
            <div className="login-main-container">
              <img className="equaliser" src={equaliser} alt="" />
              <div className="login-container">
                <div className="big-circle">
                  <div className="small-circle">
                    <Container>
                      <GoogleLogin
                        clientId={clientId}
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="smallest-circle"
                          >
                            <div>
                              <img src={google} alt="" />
                            </div>
                          </button>
                        )}
                        buttonText="Login"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                        style={{marginTop: '100px'}}
                        isSignedIn={currentUser ? true : false}
                      />
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="reel-container">
        <div className="avatar-reel-col">
          {images.map((d, i) => (
            <div key={i} className="reel-item">
              <img
                alt={`artist-${i}`}
                src={`${process.env.REACT_APP_login_artists_images}/${
                  i + 1
                }.jpeg`}
              />
            </div>
          ))}
        </div>
      </div> */}
    </LoginContainer>
  );
};

const LoginContainer = styled.div<{theme: Theme & CustomThemeType}>`
  // background: #032034;
  background: ${(props) => props.theme.bg && props.theme.bg};
  perspective: 1000px;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  .login {
    content: '';
    right: 0;
    width: calc(100% - 50px);
    position: absolute;
    top: 0;
    z-index: 9;
    bottom: 0;
    padding: 50px;
    display: flex;
    justify-content: center;
    background: linear-gradient(
      to left,
      ${(props) => props.theme.bg && props.theme.bg} 65%,
      transparent 85%
    );
  }
  .login .card {
    background: ${(props) => props.theme.bg && props.theme.bg};
    flex: 1 0 0;
    max-width: 30%;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);

    position: relative;
    .section1 {
      margin-top: 45px;
      .row1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        span {
          color: #313540;
          font-weight: bold;
        }
      }

      .login-main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        @media only screen and (max-width: 991px) {
          top: -90px;
        }

        .equaliser {
          position: absolute;
        }
      }
      .login-container {
        position: relative;
        width: 100%;
        display: flex;
        height: 65px;
        align-items: center;
        justify-content: center;
        // z-index: 3;
        left: 0;
        top: 0;
        background-color: #e0e5ec;
        .title {
          position: absolute;
          width: 100%;
          font-weight: bold;
          font-size: 22px;
          color: #565656;
          span {
            padding-left: 25px;
          }
        }
        .big-circle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: ${CIRCLE_SIZE * 2}px;
          height: ${CIRCLE_SIZE * 2}px;
          background-color: #e0e5ec;
          border-radius: ${(CIRCLE_SIZE * 2) / 2}px;
          .small-circle {
            display: flex;
            transition: all 0.5s ease-in;
            align-items: center;
            justify-content: center;
            width: ${CIRCLE_SIZE}px;
            height: ${CIRCLE_SIZE}px;
            border-radius: 50%;
            background-color: #e0e5ec;
            box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
              -9px -9px 16px rgba(255, 255, 255, 0.5);

            .smallest-circle {
              position: relative;
              z-index: 2;
              cursor: pointer;
              border: none;
              outline: none;
              display: flex;
              align-items: center;
              justify-content: center;
              background: linear-gradient(
                135deg,
                #ffffff 16.67%,
                rgba(255, 255, 255, 0) 145.2%
              );
              width: ${CIRCLE_SIZE - 6}px;
              height: ${CIRCLE_SIZE - 6}px;
              border-radius: 50px;
              &:active {
                background: radial-gradient(
                  106.7% 106.7% at 50% 50%,
                  #ffffff 0%,
                  #e0e5ec 100%
                );
                box-shadow: inset -6px -6px 4px rgba(255, 255, 255, 0.25),
                  inset 6px 6px 2px rgba(224, 229, 236, 0.15);
              }
              div {
                padding-left: 5px;
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
  }
  .reel-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    transform: rotateY(20deg) scale(1.5) translateX(-6.25vw);
    /* animation: skew 75s linear infinite alternate; */
    transform-origin: left center;
  }

  .reel-item:nth-of-type(2n) {
    transform: translateY(50%);
  }
  .avatar-reel-col {
    animation: slide 100s linear infinite alternate;
    transform: translate3d(0px, 0%, 0px);
    display: flex;
    flex-wrap: wrap;
    width: calc(15vw * 4 + 60px);
  }

  .reel-item {
    background-color: rgba(255, 255, 255, 0.1);
    width: 15vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    height: 15vw;
    margin-left: 15px;
    margin-top: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .reel-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  @keyframes slide {
    0% {
      transform: translate3d(0px, 0%, 0px);
    }
    100% {
      transform: translate3d(0px, calc(100vh - 125vw - 150px), 0px);
    }
  }
  @keyframes skew {
    0% {
      transform: rotateY(15deg) scale(1.2);
    }
    100% {
      transform: rotateY(-15deg) scale(1.2);
    }
  }

  .perspective.light {
    background: #eee;
  }

  .perspective.light .login {
    background: linear-gradient(to left, #eee 65%, transparent 85%);
  }

  .perspective.light .login .card {
    background: #fff;
  }

  body,
  body * {
    box-sizing: border-box;
  }

  @media only screen and (max-width: 991px) {
    .avatar-reel-col {
      opacity: 0.5;
      animation: slide 50s linear infinite alternate;
      width: calc((25vw - 10px) * 4 + 40px);
    }
    .reel-item {
      width: calc(25vw - 10px);
      height: calc(25vw - 10px);
      margin-left: 10px;
      margin-top: 10px;
    }
    .login {
      width: 100%;
      padding: 0;
      background: transparent;
      align-items: flex-end;
    }
    .login .card {
      max-width: 100%;
      height: 60vh;
      border-radius: 50px 50px 0 0;
      box-shadow: 0 -6px 15px rgba(0, 0, 0, 0.25);
    }
    .perspective.light .login {
      background: transparent;
    }
  }
`;

const Container = styled.div`
.smallest-circle {
    position: relative;
    z-index: 2;
    cursor: pointer;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #ffffff 16.67%, rgba(255, 255, 255, 0) 145.2%);
    width: 94px;
    height: 94px;
    border-radius: 50px;
    &:active {
        background: radial-gradient(106.7% 106.7% at 50% 50%, #ffffff 0%, #e0e5ec 100%);
        box-shadow: inset -6px -6px 4px rgba(255, 255, 255, 0.25),
            inset 6px 6px 2px rgba(224, 229, 236, 0.15);
    }`;

export default Login;
