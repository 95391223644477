/** @format */

import axios from 'axios';
import {useMutation} from 'react-query';

import {logError} from '../../errorlogger/logError';
const BASE_URL = process.env.REACT_APP_Base_URL;

const createProject = async (data) => {
  try {
    const response = await axios(`${BASE_URL}/project/`, {
      method: 'POST',
      data,
    });

    return response.data;
  } catch (error) {
    logError(error, 3);
    return error.response.data;
  }
};

const useCreateProject = () => {
  const [createProjectMutation, {status, error, isLoading}] = useMutation(
    createProject,
  );

  return {createProjectMutation, isLoading, status, error};
};

export default useCreateProject;
