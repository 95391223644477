import {useContext, useState} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import {logError} from '../../errorlogger/logError';

const fetchSingleUploadStatus = async (key, id) => {
  if (id) {
    try {
      const res = await axios(`/upload-status/${id}`, {
        method: 'GET',
      });

      return res.data?.data;
    } catch (error) {
      logError(error, 15);
    }
  } else {
    console.log('testtt');
  }
};

const useGetSingleUploadStatus = () => {
  const {state, dispatch} = useContext(GlobalContext);

  const [intervalMs, setIntervalMs] = useState(1000);

  const {data: getSingleUploadStatus, error, isFetching} = useQuery(
    ['singleUploadStatus', state.fileId],
    fetchSingleUploadStatus,
    {
      // fetch when there is id
      enabled: state.fileId,
      // Refetch the data every second
      refetchInterval: intervalMs,
    },
  );

  return {
    getSingleUploadStatus,
    state,
    dispatch,
  };
};

export default useGetSingleUploadStatus;
