import {useState} from 'react';

const usePaginatedData = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [rangeA, setRangeA] = useState(null);
  const [rangeB, setRangeB] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [limit, setLimit] = useState(20);
  const [isCustomLoading, setIsCustomLoading] = useState(false);

  const setPaginationDataFn = (data) => {
    setIsCustomLoading(true);
    setPaginaionHelperStates(data);
    if (data.totalPage === data.currentPage) {
      setRangeForLastPage(data);
    } else {
      setRangeForOtherPage(data);
    }
    if (data.totalPage === 1) {
      setRangeForFirstPage(data);
    }

    setIsCustomLoading(false);
  };
  const setPaginaionHelperStates = (data) => {
    const pageData = data.paginateData || data.paginatedData;
    setData(pageData);
    setIsLastPage(data.totalPage === data.currentPage);
    setTotalCount(data.totalCount);
    setLastPage(data.totalPage);
  };

  const setRangeForLastPage = (data) => {
    setRangeA(data?.previous?.limit * data?.previous?.page + 1);
    setRangeB(data.totalCount);
  };

  const setRangeForFirstPage = (data) => {
    setRangeA(data.totalPage);
    setRangeB(data.currentPage * data?.currentCountPerPage);
  };

  const setRangeForOtherPage = (data) => {
    setRangeA(data.range - data.currentCountPerPage + 1);
    setRangeB(data.currentPage * data?.currentCountPerPage);
  };

  return {
    page,
    setPage,
    data,
    setData,
    isLastPage,
    setIsLastPage,
    totalCount,
    setTotalCount,
    rangeA,
    setRangeA,
    rangeB,
    setRangeB,
    lastPage,
    setLastPage,
    limit,
    setLimit,
    setPaginationDataFn,
    isCustomLoading,
  };
};

export default usePaginatedData;
