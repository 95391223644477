/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Button as BaseButton, KIND, SHAPE, SIZE} from 'baseui/button';
import {Theme} from 'baseui/theme';
import React from 'react';

import {CustomThemeType} from '../../../styles/theme/colors';
import ButtonLoading from '../ButtonLoading/ButtonLoading';

interface ButtonProps {
  onClick?: any;
  label: string;
  isDisabled?: boolean;
  type?: string;
  disabled?: any;
  isLoading?: boolean;
  kind?: string;
  size?: string;
  shape?: string;
  background?: string;
  color?: string;
  margin?: string;
  endEnhancer?: any;
  startEnhancer?: any;
  href?: any;
}

const StyledButton = styled.button<{theme: Theme & CustomThemeType; data}>`
  padding: 12px 22px;
  padding: ${(props) =>
    props.data.$size === 'mini' ? '8px 16px' : '12px 22px'};
  color: ${(props) =>
    props.data.$disabled
      ? props.theme.buttonInactiveTextColor
      : props.data.$color
      ? props.data.$color
      : props.theme.buttonActiveTextColor};
  margin: ${(props) => props.data.$margin};
  font-size: 14px;
  font-weight: 700;
  border: none;
  align-items: center;
  border-radius: ${(props) => (props.data.$shape === 'round' ? '25px' : '5px')};
  outline: none;
  appearance: none;
  cursor: ${(props) => (props.data.$disabled ? 'not-allowed' : 'pointer')};
  background: ${(props) =>
    props.data.$disabled
      ? props.theme.buttonInActiveBg
      : props.data.$background
      ? props.data.$background
      : props.theme.buttonActiveBg};
  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      + span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
      }
    }
  }
`;

export const ButtonCustomComponent = ({data, theme}) => {
  return (
    <StyledButton
      disabled={data.$disabled}
      data={data}
      onClick={data.onClick}
      theme={theme}
    >
      <div>
        {data.children?.props?.startEnhancer ? (
          <span> {data.children?.props?.startEnhancer()} </span>
        ) : null}
        <span>{data.children?.props?.children}</span>
        {data.children?.props?.endEnhancer ? (
          <span> {data.children?.props?.endEnhancer()} </span>
        ) : null}
      </div>
    </StyledButton>
  );
};

const Button = ({
  onClick,
  label,
  type,
  disabled,
  background,
  color,
  margin,
  isLoading,
  kind,
  size,
  startEnhancer,
  endEnhancer,
  shape,
  href,
}: ButtonProps) => {
  const [css, theme] = useStyletron();

  return (
    <BaseButton
      href={href}
      startEnhancer={startEnhancer}
      endEnhancer={endEnhancer}
      isLoading={isLoading}
      disabled={disabled}
      type={type === 'submit' ? 'submit' : 'button'}
      kind={KIND[`${kind}`]}
      size={SIZE[`${size}`]}
      shape={SHAPE[`${shape}`]}
      overrides={{
        LoadingSpinner: {
          component: () => <ButtonLoading />,
        },
        BaseButton: {
          component: (data) => {
            return (
              <ButtonCustomComponent
                data={{...data, $background: background, $color: color, $margin: margin}}
                theme={theme}
              />
            );
          },
        },
      }}
      onClick={onClick}
    >
      {label}
    </BaseButton>
  );
};

export default Button;
