import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const disableUserFn = async (data) => {
  try {
    const response = await axios({
      method: 'PUT',
      url: `/user/disable/${data.id}`,
      data: data.data,
    });

    return response;
  } catch (error) {
    logError(error, 2);

    return error.response?.data;
  }
};

const useDisableUser = () => {
  const [disableUserMutation, {status, data, error, isLoading}] = useMutation(
    disableUserFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('users');
      },
    },
  );

  return {disableUserMutation, isLoading, status, error};
};

export default useDisableUser;
