import React, {useEffect, useState} from 'react';
import XLSX from 'xlsx';

import useListLanguages from '../hooks/Language/useListLanguage';

const PplUk = (props) => {
  const {data, multiple, selectedIds} = props;
  const [albumTracks, setAlbumTracks] = useState([]);

  const {listLanguages} = useListLanguages();

  const gettracks = (getAlbumDetail) => {
    const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
    if (multiple) {
      const filterTracks = tracks.filter((item) => {
        if (selectedIds.includes(item.upc)) {
          return item;
        }
      });
      setAlbumTracks(filterTracks);
    } else {
      setAlbumTracks(
        tracks.filter((track) => track.album_id === getAlbumDetail?.id),
      );
    }
  };

  useEffect(() => {
    gettracks(data);
  }, [data]);

  if (!data) return null;

  const header = [
    'ISRC',
    'Band / Artist Name',
    'Recording Title',
    'Version Type',
    'Is Remastered',
    'Genre',
    'Content Type',
    '(P)Date (YYYY)',
    '(P)Name',
    'Primary Country of Recording',
    'Country of Commissioning',
    'Alternative Title',
    'Duration (mm:ss)',
    'Explicit',
    'Recording Date (DD/MM/YYYY)',
    'Language',
    'Country of First Publication',
    'Recording Venue',
    'Local Recording ID',
    'This Recording Uses a Sample',
    'This Recording Is a Medley',
    'My Line-up Default',
    'Number of Featured Performers',
    'Number of Non-Featured Performers',
    'I Own The Rights to This Recording',
    'Rightsholder ID 1',
    'Rights Ownership Type 1',
    'Rights Country Default 1',
    'Rights Begin Date 1',
    'Rights End Date 1',
    'Rights % 1',
    'Rightsholder ID 2',
    'Rights Ownership Type 2',
    'Rights Country Default 2',
    'Rights Begin Date 2',
    'Rights End Date 2',
    'Rights % 2',
    'Rightsholder ID 3',
    'Rights Ownership Type 3',
    'Rights Country Default 3',
    'Rights Begin Date 3',
    'Rights End Date 3',
    'Rights % 3',
    'Rightsholder ID 4',
    'Rights Ownership Type 4',
    'Rights Country Default 4',
    'Rights Begin Date 4',
    'Rights End Date 4',
    'Rights % 4',
    'Rightsholder ID 5',
    'Rights Ownership Type 5',
    'Rights Country Default 5',
    'Rights Begin Date 5',
    'Rights End Date 5',
    'Rights % 5',
    'Rightsholder ID 6',
    'Rights Ownership Type 6',
    'Rights Country Default 6',
    'Rights Begin Date 6',
    'Rights End Date 6',
    'Rights % 6',
  ];

  function formatDuration(totalSeconds: number): string {
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  // For every track, create a row with the track's data
  const rows = albumTracks.map((track) => {
    const formattedDuration = formatDuration(track.duration);
    const singers = track.singers.join(',').split(',');
    const date_release = new Date(track.album_release_date);

    // mapping language id to language name
    const language = listLanguages?.find(
      (lang) => lang.id === track.language_id,
    )?.language;
    return [
      track.isrc,
      track.singers,
      track.track_name,
      'Single',
      'No',
      'World music , Other',
      'Audio',
      date_release.getFullYear(),
      'Divo TV Private Limited',
      'India',
      'India',
      '',
      formattedDuration,
      'no',
      ('0' + date_release.getDate()).slice(-2) +
        '/' +
        ('0' + (date_release.getMonth() + 1)).slice(-2) +
        '/' +
        date_release.getFullYear(),
      language,
      '',
      'India',
      '',
      '',
      '',
      '',
      singers.length,
      '0',
      'Yes',
      '30098381',
      'Successor in Title',
      'Worldwide',
      ('0' + date_release.getDate()).slice(-2) +
        '-' +
        ('0' + (date_release.getMonth() + 1)).slice(-2) +
        '-' +
        date_release.getFullYear(),
      '',
      '100',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
  });

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = [header, ...rows];
    const worksheet = XLSX.utils.json_to_sheet(sheetData, {skipHeader: true});
    const wscols = [
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
    ];
    worksheet['!cols'] = wscols;
    const wsrows = [{hpt: 30}];

    worksheet['!rows'] = wsrows;
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'pplUk.xlsx');
  };

  return (
    <button className="button " onClick={handleDownload}>
      PPL UK
    </button>
  );
};
export default PplUk;
