/** @format */

import * as Yup from 'yup';

export const trackDetailsValidationSchema = Yup.object().shape({
  track_name: Yup.string()
    .required('Track/Song Name is required')
    .min(1, 'Please enter a name')
    .max(120, 'You have exceeded the max character length'),

  // track_order: Yup.number()
  //   .required('Track order must be greater than 0')
  //   .moreThan(0),
  isrc: Yup.string()
    .trim()
    .min(12, 'ISRC code must be exactly 12 characters')
    .max(12, 'ISRC code must be exactly 12 characters')
    .required('ISRC is required'),
  singers: Yup.array()
    .of(
      Yup.string()
        .min(1, 'Please enter a name')
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .max(120, 'You have exceeded the max character length')
        .required('Singers/Artists Name is required'),
    )
    .required('Singers/Artists Name is required'),
  lyricists: Yup.array()
    .of(
      Yup.string()
        .min(1, 'Please enter a name')
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .max(120, 'You have exceeded the max character length')
        .required('Lyricists Name is required'),
    )
    .required('Lyricists Name is required'),
  music_director: Yup.array()
    .of(
      Yup.string()
        .min(1, 'Please enter a name')
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .max(120, 'You have exceeded the max character length')
        .required('Music Director Name is required'),
    )
    .required('Music Director Name is required'),
  track_genre: Yup.string().required('Track Genre is required'),
  // track: Yup.string(),
  instruments: Yup.string().required('Instruments is required'),
});

export const trackDetailsUpdateValidationSchema = Yup.object().shape({
  track_name: Yup.string()
    .required('Track/Song Name is required')
    .min(1, 'Please enter a name')
    .max(120, 'You have exceeded the max character length'),
  track_order: Yup.number().required('Track order is required'),
  singers: Yup.array()
    .of(
      Yup.string()
        .min(1, 'Please enter a name')
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .max(120, 'You have exceeded the max character length')
        .required('Singers/Artists Name is required'),
    )
    .required('Singers/Artists Name is required'),
  lyricists: Yup.array()
    .of(
      Yup.string()
        .min(1, 'Please enter a name')
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .max(120, 'You have exceeded the max character length')
        .required('Lyricists Name is required'),
    )
    .required('Lyricists Name is required'),
  music_director: Yup.array()
    .of(
      Yup.string()
        .min(1, 'Please enter a name')
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .max(120, 'You have exceeded the max character length')
        .required('Music Director Name is required'),
    )
    .required('Music Director Name is required'),
  track_genre: Yup.string().required('Track Genre is required'),
});
