import styled from '@emotion/styled';
import {Theme} from 'baseui/theme';
import React from 'react';
import {BiBookContent} from 'react-icons/bi';
import {BsPersonBoundingBox} from 'react-icons/bs';
import {
  FaProjectDiagram,
  FaUserFriends,
  FaUserShield,
  FaUserTag,
} from 'react-icons/fa';
import {MdAlbum, MdVideoLabel} from 'react-icons/md';
import {RiAdminFill, RiChatNewFill, RiPlayList2Line} from 'react-icons/ri';

import {CustomThemeType} from '../../../styles/theme/colors';

const BASE_HEIGHT = 45;

const TabComponent = styled.div<{
  theme: Theme & CustomThemeType;
  data;
}>`
  width: 200px;
  height: ${BASE_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease-in;
  position: relative;
  background: ${(props) =>
    props.data.$active ? props.theme.bg : props.theme.tabInactiveBg};
  color: ${(props) =>
    props.data.$active
      ? props.theme.sidebarMenuText
      : props.theme.tabTextInActive};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-right: 10px;
  font-weight: ${(props) => (props.data.$active ? 'bold' : 'normal')};
  max-width: ${BASE_HEIGHT * 4}px;
  position: relative;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin: 0 14px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -2.5px;
    background: ${(props) =>
      props.data.$active ? props.theme.bg : 'transparent'};
    height: 5px;
    width: 100%;
  }
  svg {
    margin-right: 10px;
  }
  span {
    position: relative;
  }
  font-size: 14px;
`;

export const TabCustomComponent = ({data, theme}) => {
  const renderIcon = (iconName) => {
    switch (iconName) {
      case 'Albums':
        return <MdAlbum />;
      case 'Users':
        return <FaUserFriends />;
      case 'Roles':
        return <FaUserShield />;
      case 'Types':
        return <FaUserTag />;
      case "DSP's":
        return <RiPlayList2Line />;
      case 'Manage Label':
        return <MdVideoLabel />;
      case 'Manage Artist':
        return <BsPersonBoundingBox />;
      case 'Edit Request':
        return <RiChatNewFill />;
      case 'Bulk Uploads':
        return <RiAdminFill />;
      default:
        return null;
    }
  };

  return (
    <TabComponent onClick={data.onClick} data={data} theme={theme}>
      {renderIcon(data.children)}
      <div>
        <span>{data.children}</span>
      </div>
    </TabComponent>
  );
};

export const rootStyle = {
  style: () => {
    return {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0,
      width: '100%',
    };
  },
};

export const TabBar = {
  style: () => {
    return {
      backgroundColor: 'none',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0,
    };
  },
};

export const TabContent = {
  style: ({$theme}) => {
    return {
      backgroundColor: $theme.bg,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0,
      borderTop: `1px solid ${$theme.contentBg}`,
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '10px',
    };
  },
};
