import React from 'react';

import {Tab} from '../../../components';
import Albums from './Albums';

const AlbumsManagement = () => {
  const [activeKey, setActiveKey] = React.useState<string | number>('0');

  return (
    <>
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: `Albums`,
            component: <Albums />,
          },
        ]}
      />
    </>
  );
};
export default AlbumsManagement;
