import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const getSingleDspFn = async (key, dspId) => {
  if (dspId) {
    try {
      const res = await axios(`/dsp/${dspId}`, {
        method: 'GET',
      });

      return res.data?.data;
    } catch (error) {
      logError(error, 8);
      return error.response?.data;
    }
  }
};

const useGetSingleDsp = ({dspId}) => {
  const {data: getSingleDsp, isLoading} = useQuery(
    ['singleDsp', dspId],
    getSingleDspFn,
    {
      enabled: dspId,
    },
  );

  return {
    getSingleDsp,
    isLoading,
  };
};

export default useGetSingleDsp;
