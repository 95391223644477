import React, {useEffect, useState} from 'react';
import XLSX from 'xlsx';

import useListLanguages from '../hooks/Language/useListLanguage';

const PplIndia = (props) => {
  const {data, selectedIds, multiple} = props;

  const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
  const [albumTracks, setAlbumTracks] = useState([]);

  const {listLanguages} = useListLanguages();

  const gettracks = (getAlbumDetail) => {
    if (multiple) {
      const filterTracks = tracks.filter((item) => {
        return selectedIds.includes(item.upc);
      });
      setAlbumTracks(filterTracks);
    } else {
      setAlbumTracks(
        tracks.filter((track) => track.album_id === getAlbumDetail?.id),
      );
    }
  };

  useEffect(() => {
    gettracks(data);
  }, [data]);

  if (!data) return null;
  if (!albumTracks) return null;

  // Add a header row to the data
  const header = [
    'proprietary_id',
    'display_artist_band',
    'title',
    'version_title',
    'isrc',
    'duration',
    'year_of_recording',
    'country_of_recording',
    'language_of_performance',
    'composer',
    'conductor',
    'original_release_year',
    'original_release_label',
    'sub_label',
    'genre',
    'type',
    'display_release_names',
    'alt_lang',
    'alt_lang_display_artist_band',
    'alt_lang_title',
    'alt_lang_version_title',
    'alt_lang_composer',
    'alt_lang_conductor',
    'rights_owner',
    'payee',
    'start_date_of_rights',
    'end_date_of_rights',
    'share_of_rights',
    'is_ineligible',
  ];

  function formatDuration(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  // For every track, create a row with the track's data
  const rows = albumTracks.map((track) => {
    const formattedDuration = formatDuration(track.duration);
    const date_release = new Date(track.album_release_date);
    const current_date = new Date();
    const album_name = track.album_name;

    // mapping language id to language name
    const language = listLanguages?.find(
      (lang) => lang.id === track.language_id,
    )?.language_code;
    return [
      '',
      track.singers,
      track.track_name,
      'Original',
      track.isrc,
      formattedDuration,
      date_release.getFullYear(),
      'IN',
      language,
      track.music_director,
      track.music_director,
      date_release.getFullYear(),
      'Divo TV Private Limited',
      'Divo TV Private Limited',
      track.track_genre,
      'OST',
      album_name,
      '',
      '',
      '',
      '',
      '',
      '',
      'Divo TV Private Limited',
      'Divo TV Private Limited',
      date_release.getFullYear() +
        '-' +
        ('0' + (date_release.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date_release.getDate()).slice(-2),
      date_release.getFullYear() +
        50 +
        '-' +
        ('0' + (date_release.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date_release.getDate()).slice(-2),
      '100',
      '',
    ];
  });

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = [header, ...rows];
    const worksheet = XLSX.utils.json_to_sheet(sheetData, {skipHeader: true});
    const wscols = [
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
    ];
    worksheet['!cols'] = wscols;
    const wsrows = [{hpt: 30}];

    worksheet['!rows'] = wsrows;
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'pplInd.xlsx');
  };

  return (
    <button className="button " onClick={handleDownload}>
      PPL INDIA
    </button>
  );
};
export default PplIndia;
