export const formatDate = (d) => {
  var currentDate = new Date(d);
  var date = currentDate.getDate();
  var month = currentDate.getMonth(); //Be careful! January is 0 not 1
  var year = currentDate.getFullYear();
  var dateString = pad(date) + '-' + pad(month + 1) + '-' + year;
  return dateString;
};

function pad(n) {
  return n < 10 ? '0' + n : n;
}

export const apiDate = (d) => {
  var currentDate = new Date(d);
  var date = currentDate.getDate();
  var month = currentDate.getMonth(); //Be careful! January is 0 not 1
  var year = currentDate.getFullYear();
  var dateString = year + '-' + pad(month + 1) + '-' + pad(date);
  return dateString;
};

export const hrFormatDate = (d) => {
  if (d === null) {
    return null;
  } else {
    var currentDate = new Date(d);
    var hrs = currentDate.getHours();
    var min = currentDate.getMinutes();
    var AmOrPm = hrs >= 12 ? 'pm' : 'am';
    hrs = hrs % 12 || 12;
    var date = currentDate.getDate();
    var month = currentDate.getMonth(); //Be careful! January is 0 not 1
    var year = currentDate.getFullYear();
    // var dateString =
    //   year + '-' + pad(month + 1) + '-' + pad(date) + '  ' + hrs + ':' + min + " "  + AmOrPm;
    var dateString =
      pad(date) +
      '-' +
      pad(month + 1) +
      '-' +
      year +
      '  ' +
      pad(hrs) +
      ':' +
      pad(min) +
      '' +
      AmOrPm;
    return dateString;
  }
};
