import styled from '@emotion/styled';
import {AxiosResponse} from 'axios';
import Check from 'baseui/icon/check';
import React, {useState} from 'react';
import { FaPen } from 'react-icons/fa';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import {useLocation} from 'react-router-dom';

import {Button} from '../../../../components';
import ConfirmModal from '../../../../components/reusable/ConfirmModal/ConfirmModal';
import useNotification from '../../../../Contexts/useNotification';
import useGetAlbumsData from '../../../../hooks/AlbumDetail/useGetAlbumsData';
import useUpdateAlbum from '../../../../hooks/AlbumsManagement/useUpdateAlbumStatus';
import useUpdateTrack from '../../../../hooks/AlbumsManagement/useUpdateTrack';
import useModal from '../../../../hooks/Modal/useModal';
import useLocalStorage from '../../../../hooks/reusable/useLocalStorage';
import {COLUMN_LABELS} from '../../../../validation/CreateAlbum/FilmType';
import TrackForm from './TrackForm';

export default function AlbumForm({setIsOpen, selectedData, sidebarRef}) {
  const location = useLocation();
  const {isOpen, close, setIsOpen: modalSetter} = useModal();
  const {showToast} = useNotification();
  const [coverArt, setCoverArt] = useState(null);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [setEditTrack, setEditTrackStatus] = useState(false);

  const {
    updateTrackMutation,
    isLoading: isUpdateTrackLoading,
    error: updateTrackError,
  } = useUpdateTrack();
  const [btnLabel, setBtnLabel] = useState(null);
  // const {s3KeyGenerator} = useS3Key();

  const {getAlbumDetail} = useGetAlbumsData({
    albumId: selectedData && selectedData?.album_id,
  });

  React.useEffect(() => {
    if (getAlbumDetail?.cover_art) {
      setCoverArt(getAlbumDetail?.cover_art);
    }
  }, [getAlbumDetail]);

  const {
    updateAlbumMutation,
    isLoading: isUpdateAlbumLoading,
    error: updateAlbumError,
  } = useUpdateAlbum();

  const updateStatus = async (new_status: string): Promise<void> => {
    setBtnLabel(new_status);
    const {album_id, user_id, ...rest} = selectedData;
    const newData: Record<string, unknown> = {
      id: selectedData.id,
      data: {status: new_status, updated_by: currentUser.id},
    };

    const trackResponse: AxiosResponse<any> = await updateTrackMutation(
      newData,
    );
    if (new_status === 'declined') {
      if (trackResponse?.status === 200) {
        setIsOpen(false);

        showToast(`${selectedData?.track_name} has been declined`, 'warning');
        await updateAlbumStatus();
      }
    }

    if (new_status !== 'declined') {
      if (trackResponse?.status === 200) {
        setIsOpen(false);
        showToast(`${selectedData?.track_name} has been approved`, 'success');
      }
    }
  };

  const updateAlbumStatus = async () => {
    const albumData = {
      id: getAlbumDetail?.id,
      data: {
        status: 'declined',
        updated_by: currentUser.id,
      },
    };

    const response = await updateAlbumMutation(albumData);
    if (response?.status === 200) {
      setIsOpen(false);
    }
  };

  return (
    <AlbumForm.Container>
      <div className="track_details">
        {/* Open TrackForm */}
        {setEditTrack && (
          <TrackForm
            setIsOpen={setIsOpen}
            selectedData={selectedData}
          />
        )}
        {!setEditTrack && (
          <>
          <div style={{position: 'absolute', top: '12%', right: '6%'}} onClick={() => setEditTrackStatus(true)}>
            <FaPen size={24} color="#df3732" />
          </div>
        <fieldset>
          <legend>{COLUMN_LABELS.SONG_NAME}</legend>
          <p>{selectedData?.track_name}</p>
        </fieldset>
        <fieldset>
          <legend>{COLUMN_LABELS.SONG_ORDER}</legend>
          <p>{selectedData?.track_order}</p>
        </fieldset>
        <fieldset>
          <legend>{COLUMN_LABELS.ISRC}</legend>
          <p>{selectedData?.isrc}</p>
        </fieldset>
        {selectedData?.singers?.length !== 0 && (
          <fieldset>
            <legend>{COLUMN_LABELS.SINGER_ARTIST_NAME}</legend>
            <p>{selectedData?.singers?.join(', ')}</p>
          </fieldset>
        )}
        {selectedData?.lyricists?.length !== 0 && (
          <fieldset>
            <legend>{COLUMN_LABELS.LYRICIST_NAME}</legend>
            <p>{selectedData?.lyricists?.join(', ')}</p>
          </fieldset>
        )}
        <fieldset>
          <legend>{COLUMN_LABELS.MUSICAL_INSTRUMENTS}</legend>
          <p>{selectedData?.instruments ? 'Yes' : 'No'}</p>
        </fieldset>
        <fieldset>
          <legend>Music Director </legend>
          <p>{selectedData?.music_director?.join(', ')}</p>
        </fieldset>
        <fieldset>
          <legend>{COLUMN_LABELS.SONG_GENRE}</legend>
          <p>{selectedData?.track_genre}</p>
        </fieldset>
      </>
      )}
      </div>
      {location.pathname.includes('album-approval') && (
        <div className="track_footer">
          <Button
            disabled={
              selectedData?.status === 'approved' ||
              getAlbumDetail?.status === 'approved' ||
              selectedData?.status === 'declined'
            }
            isLoading={btnLabel === 'approved' && isUpdateTrackLoading}
            label="Approve"
            background="#34A139"
            onClick={() => updateStatus('approved')}
            endEnhancer={() => <Check size={24} />}
          />
          <Button
            disabled={
              selectedData?.status === 'declined' ||
              selectedData?.status === 'approved' ||
              getAlbumDetail?.status === 'approved'
            }
            label="Decline"
            onClick={() => modalSetter(true)}
          />
        </div>
      )}

      {isOpen && (
        <ConfirmModal
          sidebarRef={sidebarRef}
          isOpen={isOpen}
          close={close}
          title="Are you sure ?"
          renderBtn={
            <Button
              disabled={
                selectedData?.status === 'declined' ||
                selectedData?.status === 'approved' ||
                getAlbumDetail?.status === 'approved'
              }
              isLoading={btnLabel === 'declined' && isUpdateTrackLoading}
              label="Yes, Decline"
              onClick={() => updateStatus('declined')}
            />
          }
        />
      )}
    </AlbumForm.Container>
  );
}

AlbumForm.Container = styled.div`
  height: 100%;
  display: flex;
  margin: 35px 0;

  flex-direction: column;
  .album-details {
    display: flex;
    .col1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .col2 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      .title {
        font-size: 18px;
        font-weight: bold;
      }
      .artist {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }

  .track_details {
    fieldset {
      margin-bottom: 0.75rem;
      border: 1px solid #c1c1c1;
      border-radius: 5px;
      legend {
        font-size: 0.85rem;
        font-weight: 400;
      }
      p {
        padding: 0.55rem;
        margin: 0;
        text-align: center;
        color: #dc3831;
      }
    }
  }

  .track_footer {
    display: flex;
    > *:first-child {
      margin-right: 2rem;
    }
  }
`;
