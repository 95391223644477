import {useState} from 'react';

const useReUpload = () => {
  const [progressInfo, setProgressInfo] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  return {
    imageLoaded,
    setImageLoaded,
    progressInfo,
    setProgressInfo,
  };
};

export default useReUpload;
