import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';

const updateAlbumFn = async (data) => {
  const {id, ...rest} = data;
  try {
    const response = await axios({
      method: 'PUT',
      url: `/album/${id}`,
      data: rest,
    });
    return response.data;
  } catch (error) {
    return error.response?.data;
  }
};

const useUpdateAlbum = () => {
  const [updateAlbumMutation, {status, isLoading, error}] = useMutation(
    updateAlbumFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('albums');
        queryCache.invalidateQueries('singleAlbum');
        queryCache.invalidateQueries('albums-search');
      },
    },
  );
  return {
    updateAlbumMutation,
    isLoading,
    error,
  };
};

export default useUpdateAlbum;
