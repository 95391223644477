import * as Yup from 'yup';

export const createAlbumStep1 = Yup.object({
  album_type: Yup.string().required('Required'),
  product_type: Yup.string().required('Required'),
  album_name: Yup.string()
    .required('Album name is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  upc: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, 'UPC code must be a number')
    .min(12)
    .max(13)
    .required('UPC is required'),

  actors_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Actor (Male) Name is required'),
      )
      .required('Actor (Male) Name is required'),
  }),
  actress_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Actress (Female) Name is required'),
      )
      .required('Actor (Male) Name is required'),
  }),

  // actors_name: Yup.array().of(Yup.string().required('Required')),
  // actress_name: Yup.array().of(Yup.string().required('Required')),
  language_id: Yup.string().required('Album Language is required'),
  album_director_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Film Director Name is required'),
      )
      .notRequired(),
  }),
  album_producer_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Film Producer Name is required'),
      )
      .notRequired(),
  }),
  banner_production_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Production House Name is required'),
      )
      .notRequired(),
  }),
  label_name: Yup.string()
    .min(1, 'You need to enter a label name')
    .max(120, 'You have exceeded the max character length'),
  label_c_name: Yup.string()
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length')
    .required('C-Line is required'),
  p_line_name: Yup.string()
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length')
    .required('P-Line is required'),
  film_zone: Yup.string().required('Film Zone is required'),
  album_release_date: Yup.string().required('Album Release Date is required'),
}).test(
  'at-least-one-property',
  'You must provide at least one',
  (value) => !!(value.actress_name || value.actors_name),
);

export const createAlbumStep2 = Yup.object({
  territory: Yup.string().required('Required'),
  countries: Yup.array().when('territory', {
    is: (territory) =>
      territory?.toLowerCase() === 'exclude' ||
      territory?.toLowerCase() === 'include',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Production House Name is required'),
      )
      .notRequired(),
  }),
});
