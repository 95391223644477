import styled from '@emotion/styled';
import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../../../lotties/loading.json';

const LoadingSpinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <LottieContainer>
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        height={50}
        width={50}
      />
    </LottieContainer>
  );
};

const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default LoadingSpinner;
