import React from 'react';
import {FcCancel} from 'react-icons/fc';

import {formatDate} from '../../helpers/covertDate';
import {sortAlphabeticalOrderWithoutCase} from '../AlbumsManagement/albumColumnHelper';
import {TextWrap} from '../components/TableComponents';
import {dspData} from './dspTable';

export const dspColumnMeta = [
  {
    Header: dspData.dsp_name.Header,
    accessor: dspData.dsp_name.accessor,
    Cell: (data) => {
      return (
        <span style={{display: 'flex', alignItems: 'center'}}>
          {data?.row?.original?.dsp_name}
          {data?.row.original?.is_disabled ? (
            <FcCancel style={{marginLeft: '5px'}} />
          ) : null}
        </span>
      );
    },
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.dsp_name,
        rowB.original.dsp_name,
      );
    },
  },
  {
    Header: dspData.dsp_type.Header,
    accessor: dspData.dsp_type.accessor,
    Cell: (data) => {
      return <TextWrap> {data.value === 'ott' ? 'OTT' : data.value} </TextWrap>;
    },
  },
  {
    Header: dspData.point_of_contact.Header,
    accessor: dspData.point_of_contact.accessor,
  },
  {
    Header: dspData.email_poc.Header,
    accessor: dspData.email_poc.accessor,
    Cell: ({value}) => {
      return value && value.length > 1 ? (
        value.map((data, i) => {
          if (i === value.length - 1) {
            return <span key={i}> {data}</span>;
          } else {
            return <span key={i}> {data}, </span>;
          }
        })
      ) : (
        <span> {value} </span>
      );
    },
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.email_poc[0],
        rowB.original.email_poc[0],
      );
    },
  },

  {
    Header: dspData.phone_poc.Header,
    accessor: dspData.phone_poc.accessor,
  },
  {
    Header: dspData.delivery_type.Header,
    accessor: dspData.delivery_type.accessor,
    Cell: (data) => {
      return (
        <TextWrap> {data.value === 'sftp' ? 'SFTP' : data.value} </TextWrap>
      );
    },
  },
  {
    Header: dspData.created_at.Header,
    accessor: dspData.created_at.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
];
