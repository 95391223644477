import React, {useContext} from 'react';
import {GlobalContext} from '../../Contexts/GlobalProvider';

function useCallDispatch() {
  const {state, dispatch}: any = useContext(GlobalContext);
  const callDispatch = (type, property, value) => {
    dispatch({
      type: type,
      payload: {
        [property]: value,
      },
    });
  };
  return {
    callDispatch,
    state,
  };
}

export default useCallDispatch;
