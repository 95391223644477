import styled from '@emotion/styled';
import React, {} from 'react';

import useGetAlbumsData from '../../../../hooks/AlbumDetail/useGetAlbumsData';
import TrackDetails from './TrackApprovalForm';
import TrackForm from './TrackForm';

export default function AlbumDetailForm(props) {
  const {
    setIsOpen,
    selectedData,
    newFormTag,
    setIsrcCode,
    setTrackCreated,
    refetchAlbum,
    isrcRef,
  } = props;
  const {getAlbumDetail} = useGetAlbumsData({
    albumId: selectedData?.album_id,
  });

  const renderForms = () => {
    if ((newFormTag === 'add-track' || !selectedData)) {
      return (
        <TrackForm
          selectedData={null}
          setIsOpen={setIsOpen}
          setIsrcCode={setIsrcCode}
          setTrackCreated={setTrackCreated}
          refetchAlbums={refetchAlbum}
          isrcRef={isrcRef}
          newFormTag={newFormTag}
        />
      );
    } else if (
      (!['add-track'].includes(newFormTag) &&
        ['declined', 'pending', 'complete'].includes(selectedData?.status)) ||
      (getAlbumDetail?.status === 'declined' &&
        selectedData?.status === 'waiting_approval')
    ) {
      return (
        <React.Fragment>
          <TrackForm
            setIsOpen={setIsOpen}
            selectedData={selectedData}
            setIsrcCode={null}
            setTrackCreated={null}
            refetchAlbums={refetchAlbum}
          />
        </React.Fragment>
      );
    } else {
      return (
        <TrackDetails
          sidebarRef={null}
          setIsOpen={setIsOpen}
          selectedData={selectedData}
        />
      );
    }
  };

  return (
    <AlbumDetailForm.Container>
      {renderForms()}
    </AlbumDetailForm.Container>
  );
}

AlbumDetailForm.Container = styled.div`
  height: 100%;
  display: flex;
  margin: 40px 0;
  flex-direction: column;
  .album-details {
    display: flex;
    .col1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      border-radius: 5px;
    }
    .col2 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      .title {
        font-size: 18px;
        font-weight: bold;
      }
      .artist {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
`;
