/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {ErrorMessage, Field} from 'formik';
import React, {useEffect} from 'react';
import {FaInfoCircle} from 'react-icons/fa';

import Tooltip from '../ToolTip/ToolTip';
import DetailsError from './DetailsError';
import {LabelInAlbumCreate} from './DetailsSelect';

function Radio(props) {
  const [css, theme] = useStyletron();
  const {
    label,
    name,
    isRequired,
    labelName,
    setFieldValue,
    helpText = null,
    options,
    ...rest
  } = props;

  useEffect(() => {
    if (labelName) {
      setFieldValue(name, labelName);
    }
  }, [labelName]);

  return (
    <Radio.Container
      borderColor={theme.tabInactiveBorderColor}
      bgColor={theme.inputBg}
      textColor={theme.textColor}
    >
      <div className="input_item">
        <div className="left">
          <label htmlFor={name}>
            <LabelInAlbumCreate
              data={{children: label}}
              isRequired={isRequired}
            />
          </label>
        </div>
        <div className="right">
          <Field name={name}>
            {({ field }) => {
              return options.map((option) => {
                return (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      id={option.value}
                      {...field}
                      {...rest}
                      value={option.value}
                      checked={field.value === option.value}
                    />
                    <label htmlFor={option.value}>{option.key}</label>
                  </React.Fragment>
                );
              });
            }}
          </Field>
          {helpText && (
            <Tooltip isOpen={true} content={helpText} direction="right">
              <div className="info">
                <FaInfoCircle />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="input_item">
        <div className="left"></div>
        <div className="right error">
          <ErrorMessage name={name} component={DetailsError} />
        </div>
      </div>
    </Radio.Container>
  );
}

Radio.Container = styled.div`
  display: flex;
  flex-direction: column;
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
  .input_item {
    margin: 0.3rem 0.75rem;
    justify-content: center;
    align-items: center;
 

    .left {
      flex: 1;
      text-align: right;

      label {
        margin: 0;
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex: 2;
    }
  }

  label {
    margin: 0 0.65rem;
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.textColor};
  }

  input {
    font-size: 0.85rem;
    margin: 0.5rem;
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
`;

export default Radio;
