import {useQuery} from 'react-query';

export const fetchAllLanguages = () => {
  return [
    {
      "id": 12,
      "language": "bhojpuri",
      "language_code": "bho",
      "created_at": "2020-11-04 12:50:07.248",
      "user_id": "NULL"
    },
    {
      "id": 13,
      "language": "english",
      "language_code": "en",
      "created_at": "2020-11-04 12:50:07.435",
      "user_id": "NULL"
    },
    {
      "id": 14,
      "language": "hindi",
      "language_code": "hi",
      "created_at": "2020-11-04 12:50:07.551",
      "user_id": "NULL"
    },
    {
      "id": 15,
      "language": "kannada",
      "language_code": "kn",
      "created_at": "2020-11-04 12:50:07.67",
      "user_id": "NULL"
    },
    {
      "id": 16,
      "language": "korean",
      "language_code": "ko",
      "created_at": "2020-11-04 12:50:07.828",
      "user_id": "NULL"
    },
    {
      "id": 17,
      "language": "malayalam",
      "language_code": "ml",
      "created_at": "2020-11-04 12:50:07.937",
      "user_id": "NULL"
    },
    {
      "id": 18,
      "language": "malay",
      "language_code": "ms",
      "created_at": "2020-11-04 12:50:08.099",
      "user_id": "NULL"
    },
    {
      "id": 19,
      "language": "punjabi",
      "language_code": "pa",
      "created_at": "2020-11-04 12:50:08.21",
      "user_id": "NULL"
    },
    {
      "id": 20,
      "language": "tamil",
      "language_code": "ta",
      "created_at": "2020-11-04 12:50:08.32",
      "user_id": "NULL"
    },
    {
      "id": 21,
      "language": "telugu",
      "language_code": "te",
      "created_at": "2020-11-04 12:50:08.466",
      "user_id": "NULL"
    },
    {
      "id": 22,
      "language": "haryanvi",
      "language_code": "bgc",
      "created_at": "2020-11-13 05:20:51.746",
      "user_id": "NULL"
    },
    {
      "id": 23,
      "language": "bengali",
      "language_code": "bn",
      "created_at": "2020-11-13 05:20:51.965",
      "user_id": "NULL"
    },
    {
      "id": 24,
      "language": "tulu",
      "language_code": "dra",
      "created_at": "2020-11-23 09:33:12.431",
      "user_id": "NULL"
    },
    {
      "id": 25,
      "language": "marathi",
      "language_code": "mr",
      "created_at": "2020-11-23 09:33:12.857",
      "user_id": "NULL"
    },
    {
      "id": 26,
      "language": "gujarati",
      "language_code": "gu",
      "created_at": "2020-11-23 09:33:13.039",
      "user_id": "NULL"
    },
    {
      "id": 27,
      "language": "odia",
      "language_code": "or",
      "created_at": "2020-11-23 09:33:13.218",
      "user_id": "NULL"
    },
    {
      "id": 28,
      "language": "assamese",
      "language_code": "as",
      "created_at": "2020-11-23 09:33:13.396",
      "user_id": "NULL"
    },
    {
      "id": 29,
      "language": "konkani",
      "language_code": "kok",
      "created_at": "2020-11-23 09:33:13.655",
      "user_id": "NULL"
    },
    {
      "id": 30,
      "language": "rajasthani",
      "language_code": "raj",
      "created_at": "2020-11-23 09:33:13.844",
      "user_id": "NULL"
    },
    {
      "id": 31,
      "language": "chattisgarhi",
      "language_code": "hne",
      "created_at": "2020-11-23 09:33:14.025",
      "user_id": "NULL"
    },
    {
      "id": 32,
      "language": "khasi",
      "language_code": "kha",
      "created_at": "2020-11-23 09:33:14.204",
      "user_id": "NULL"
    },
    {
      "id": 33,
      "language": "garhwali",
      "language_code": "gbm",
      "created_at": "2020-11-23 09:33:14.381",
      "user_id": "NULL"
    },
    {
      "id": 34,
      "language": "maithili",
      "language_code": "mai",
      "created_at": "2020-11-23 09:33:14.552",
      "user_id": "NULL"
    },
    {
      "id": 35,
      "language": "awadhi",
      "language_code": "awa",
      "created_at": "2020-11-23 09:33:14.736",
      "user_id": "NULL"
    },
    {
      "id": 36,
      "language": "lambadi",
      "language_code": "lmn",
      "created_at": "2020-11-23 09:33:14.92",
      "user_id": "NULL"
    },
    {
      "id": 37,
      "language": "mishing",
      "language_code": "mrg",
      "created_at": "2020-11-23 09:33:15.207",
      "user_id": "NULL"
    },
    {
      "id": 38,
      "language": "nepali",
      "language_code": "ne",
      "created_at": "2020-11-23 09:33:15.489",
      "user_id": "NULL"
    },
    {
      "id": 39,
      "language": "pnar",
      "language_code": "pbv",
      "created_at": "2020-11-23 09:33:15.705",
      "user_id": "NULL"
    },
    {
      "id": 40,
      "language": "chinese",
      "language_code": "zh",
      "created_at": "2020-11-23 09:33:15.912",
      "user_id": "NULL"
    },
    {
      "id": 41,
      "language": "japanese",
      "language_code": "ja",
      "created_at": "2020-11-23 09:33:16.203",
      "user_id": "NULL"
    },
    {
      "id": 42,
      "language": "arabic",
      "language_code": "ar",
      "created_at": "2020-11-23 09:33:16.446",
      "user_id": "NULL"
    },
    {
      "id": 43,
      "language": "french",
      "language_code": "fr",
      "created_at": "2020-11-23 09:33:16.713",
      "user_id": "NULL"
    },
    {
      "id": 44,
      "language": "spanish",
      "language_code": "es",
      "created_at": "2020-11-23 09:33:16.885",
      "user_id": "NULL"
    },
    {
      "id": 45,
      "language": "german",
      "language_code": "de",
      "created_at": "2020-11-23 09:33:17.064",
      "user_id": "NULL"
    },
    {
      "id": 46,
      "language": "italian",
      "language_code": "it",
      "created_at": "2020-11-23 09:33:17.268",
      "user_id": "NULL"
    },
    {
      "id": 47,
      "language": "russian",
      "language_code": "ru",
      "created_at": "2020-11-23 09:33:17.482",
      "user_id": "NULL"
    },
    {
      "id": 48,
      "language": "swedish",
      "language_code": "sv",
      "created_at": "2020-11-23 09:33:17.737",
      "user_id": "NULL"
    },
    {
      "id": 49,
      "language": "chokri",
      "language_code": "nri",
      "created_at": "2020-11-23 09:33:18.005",
      "user_id": "NULL"
    },
    {
      "id": 50,
      "language": "sanskrit",
      "language_code": "sa",
      "created_at": "2020-12-21 14:43:16.173",
      "user_id": 291
    },
    {
      "id": 51,
      "language": "ceddeet",
      "language_code": "ce",
      "created_at": "2021-03-29 10:41:48.073",
      "user_id": "NULL"
    },
    {
      "id": 63,
      "language": "abkhazian",
      "language_code": "ab",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 64,
      "language": "afar",
      "language_code": "aa",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 65,
      "language": "afrikaans",
      "language_code": "af",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 66,
      "language": "akan",
      "language_code": "ak",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 67,
      "language": "albanian",
      "language_code": "sq",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 68,
      "language": "amharic",
      "language_code": "am",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 69,
      "language": "aragonese",
      "language_code": "an",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 70,
      "language": "armenian",
      "language_code": "hy",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 71,
      "language": "avaric",
      "language_code": "av",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 72,
      "language": "avestan",
      "language_code": "ae",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 73,
      "language": "aymara",
      "language_code": "ay",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 74,
      "language": "azerbaijani",
      "language_code": "az",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 75,
      "language": "bambara",
      "language_code": "bm",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 76,
      "language": "bashkir",
      "language_code": "ba",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 77,
      "language": "basque",
      "language_code": "eu",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 78,
      "language": "belarusian",
      "language_code": "be",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 79,
      "language": "bislama",
      "language_code": "bi",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 80,
      "language": "bosnian",
      "language_code": "bs",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 81,
      "language": "breton",
      "language_code": "br",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 82,
      "language": "bulgarian",
      "language_code": "bg",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 83,
      "language": "burmese",
      "language_code": "my",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 84,
      "language": "centralkhmer",
      "language_code": "km",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 85,
      "language": "chamorro",
      "language_code": "ch",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 86,
      "language": "chechen",
      "language_code": "ce",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 87,
      "language": "chuvash",
      "language_code": "cv",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 88,
      "language": "cornish",
      "language_code": "kw",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 89,
      "language": "corsican",
      "language_code": "co",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 90,
      "language": "cree",
      "language_code": "cr",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 91,
      "language": "croatian",
      "language_code": "hr",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 92,
      "language": "czech",
      "language_code": "cs",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 93,
      "language": "danish",
      "language_code": "da",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 94,
      "language": "dutch",
      "language_code": "nl",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 95,
      "language": "dzongkha",
      "language_code": "dz",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 96,
      "language": "esperanto",
      "language_code": "eo",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 97,
      "language": "estonian",
      "language_code": "et",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 98,
      "language": "ewe",
      "language_code": "ee",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 99,
      "language": "faroese",
      "language_code": "fo",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 100,
      "language": "fijian",
      "language_code": "fj",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 101,
      "language": "finnish",
      "language_code": "fi",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 102,
      "language": "fulah",
      "language_code": "ff",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 103,
      "language": "galician",
      "language_code": "gl",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 104,
      "language": "georgian",
      "language_code": "ka",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 105,
      "language": "greek",
      "language_code": "el",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 106,
      "language": "guarani",
      "language_code": "gn",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 107,
      "language": "haitian",
      "language_code": "ht",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 108,
      "language": "hausa",
      "language_code": "ha",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 109,
      "language": "hebrew",
      "language_code": "he",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 110,
      "language": "herero",
      "language_code": "hz",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 111,
      "language": "hirimotu",
      "language_code": "ho",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 112,
      "language": "hungarian",
      "language_code": "hu",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 113,
      "language": "icelandic",
      "language_code": "is",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 114,
      "language": "ido",
      "language_code": "io",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 115,
      "language": "igbo",
      "language_code": "ig",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 116,
      "language": "indonesian",
      "language_code": "id",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 117,
      "language": "inuktitut",
      "language_code": "iu",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 118,
      "language": "inupiaq",
      "language_code": "ik",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 119,
      "language": "irish",
      "language_code": "ga",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 120,
      "language": "javanese",
      "language_code": "jv",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 121,
      "language": "kanuri",
      "language_code": "kr",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 122,
      "language": "kashmiri",
      "language_code": "ks",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 123,
      "language": "kazakh",
      "language_code": "kk",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 124,
      "language": "kinyarwanda",
      "language_code": "rw",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 125,
      "language": "komi",
      "language_code": "kv",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 126,
      "language": "kongo",
      "language_code": "kg",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 127,
      "language": "kurdish",
      "language_code": "ku",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 128,
      "language": "lao",
      "language_code": "lo",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 129,
      "language": "latin",
      "language_code": "la",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 130,
      "language": "latvian",
      "language_code": "lv",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 131,
      "language": "lingala",
      "language_code": "ln",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 132,
      "language": "lithuanian",
      "language_code": "lt",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 133,
      "language": "luxembourgish",
      "language_code": "lb",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 134,
      "language": "macedonian",
      "language_code": "mk",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 135,
      "language": "malagasy",
      "language_code": "mg",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 136,
      "language": "maltese",
      "language_code": "mt",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 137,
      "language": "manx",
      "language_code": "gv",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 138,
      "language": "maori",
      "language_code": "mi",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 139,
      "language": "marshallese",
      "language_code": "mh",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 140,
      "language": "mongolian",
      "language_code": "mn",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 141,
      "language": "nauru",
      "language_code": "na",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 142,
      "language": "ndonga",
      "language_code": "ng",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 143,
      "language": "northernsami",
      "language_code": "se",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 144,
      "language": "norwegian",
      "language_code": "no",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 145,
      "language": "ojibwa",
      "language_code": "oj",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 146,
      "language": "oriya",
      "language_code": "or",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 147,
      "language": "oromo",
      "language_code": "om",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 148,
      "language": "pali",
      "language_code": "pi",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 149,
      "language": "persian",
      "language_code": "fa",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 150,
      "language": "polish",
      "language_code": "pl",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 151,
      "language": "portuguese",
      "language_code": "pt",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 152,
      "language": "quechua",
      "language_code": "qu",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 153,
      "language": "rundi",
      "language_code": "rn",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 154,
      "language": "samoan",
      "language_code": "sm",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 155,
      "language": "sango",
      "language_code": "sg",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 156,
      "language": "sardinian",
      "language_code": "sc",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 157,
      "language": "serbian",
      "language_code": "sr",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 158,
      "language": "shona",
      "language_code": "sn",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 159,
      "language": "sindhi",
      "language_code": "sd",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 160,
      "language": "sinhala",
      "language_code": "si",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 161,
      "language": "slovak",
      "language_code": "sk",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 162,
      "language": "slovenian",
      "language_code": "sl",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 163,
      "language": "somali",
      "language_code": "so",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 164,
      "language": "sundanese",
      "language_code": "su",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 165,
      "language": "swahili",
      "language_code": "sw",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 166,
      "language": "swati",
      "language_code": "ss",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 167,
      "language": "tagalog",
      "language_code": "tl",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 168,
      "language": "tahitian",
      "language_code": "ty",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 169,
      "language": "tajik",
      "language_code": "tg",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 170,
      "language": "tatar",
      "language_code": "tt",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 171,
      "language": "thai",
      "language_code": "th",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 172,
      "language": "tibetan",
      "language_code": "bo",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 173,
      "language": "tigrinya",
      "language_code": "ti",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 174,
      "language": "tsonga",
      "language_code": "ts",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 175,
      "language": "tswana",
      "language_code": "tn",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 176,
      "language": "turkish",
      "language_code": "tr",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 177,
      "language": "twi",
      "language_code": "tw",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 178,
      "language": "ukrainian",
      "language_code": "uk",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 179,
      "language": "urdu",
      "language_code": "ur",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 180,
      "language": "uzbek",
      "language_code": "uz",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 181,
      "language": "venda",
      "language_code": "ve",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 182,
      "language": "vietnamese",
      "language_code": "vi",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 183,
      "language": "walloon",
      "language_code": "wa",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 184,
      "language": "welsh",
      "language_code": "cy",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 185,
      "language": "wolof",
      "language_code": "wo",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 186,
      "language": "xhosa",
      "language_code": "xh",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 187,
      "language": "yiddish",
      "language_code": "yi",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 188,
      "language": "yoruba",
      "language_code": "yo",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    },
    {
      "id": 189,
      "language": "zulu",
      "language_code": "zu",
      "created_at": "2021-06-02 05:44:14.011",
      "user_id": "NULL"
    }
  ]
};

const useListLanguages = () => {
  const {data: listLanguages} = useQuery('languages', fetchAllLanguages);

  return {
    listLanguages,
  };
};

export default useListLanguages;
