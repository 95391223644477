import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const createDSPFn = async (data) => {
  try {
    const res = await axios(`/dsp`, {
      method: 'POST',
      data: data.data,
    });
    return res.data;
  } catch (error) {
    logError(error, 8);
    return error.response.data;
  }
};

const useCreateDSP = () => {
  const [createDSPMutation, {status, data, error, isLoading}] = useMutation(
    createDSPFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('dsp');
        queryCache.invalidateQueries('singleDsp');
      },
    },
  );

  return {createDSPMutation, isLoading, status, error};
};

export default useCreateDSP;
