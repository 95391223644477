import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import React from 'react';

import {CustomThemeType} from '../../../styles/theme/colors';
import ButtonLoading from '../ButtonLoading/ButtonLoading';

const StyledButton = styled.button<{theme: Theme & CustomThemeType; disabled}>`
  padding: 12px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.disabled
      ? props.theme.buttonInactiveTextColor
      : props.theme.buttonActiveTextColor};
  font-size: 14px;
  font-weight: 700;
  border: none;
  align-items: center;
  border-radius: 5px;
  outline: none;
  appearance: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${(props) =>
    props.disabled ? props.theme.buttonInActiveBg : props.theme.buttonActiveBg};

  > div {
    + div {
      margin-left: 6px;
    }
  }
`;

export const SubmitButton = ({label, disabled, type, isLoading}) => {
  const [css, theme] = useStyletron();

  return (
    <StyledButton type={type} disabled={disabled} theme={theme}>
      <div>{label}</div>
      {isLoading && <ButtonLoading />}
    </StyledButton>
  );
};
