import styled from '@emotion/styled';
import React from 'react';

import SuccessAnimation from '../SuccessAnimation/SuccessAnimation';

export const SuccessMessage = (props) => {
  const {count, tag, dspCount} = props;
  const renderMessage = (tag) => {
    let msg;
    if (tag === 'audio') {
      msg = `You have successfully uploaded ${count} track(s) , proceed to the next step to upload the meta file`;
    } else if (tag === 'cover') {
      msg = `You have successfully uploaded ${count} cover art image(s) , You can now review the albums and tracks which are created`;
    } else if (tag === 'override') {
      msg = `You have successfully placed order for ${count} albums to ${dspCount} dsp’s`;
    } else {
      msg = `You have successfully uploaded input file , You can go ahead and upload cover art(s) for the albums`;
    }
    return msg;
  };

  return (
    <UploadMessage>
      <div className="wrapper">
        <div className="success_animation">
          <SuccessAnimation />
        </div>

        <div className="success_message">
          {tag === 'override'
            ? 'Successfully Placed!'
            : 'Successfully Uploaded!'}
        </div>

        <div className="success_desc">{renderMessage(tag)}</div>
      </div>
    </UploadMessage>
  );
};

const UploadMessage = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }
  .btn {
    position: absolute;
    bottom: -25px;
    background-color: #11b37f;
    padding: 14px 58px;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.15);
  }
  .success_animation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
  }
  .success_message {
    font-weight: bold;
    font-size: 20px;
    // margin-top: 15px;
    color: #05b37e;
  }
  .success_desc {
    text-align: center;
    margin-top: 15px;
    width: 75%;
    color: #989898;
    font-size: 15px;
  }
`;
