import useLocalStorage from './useLocalStorage';

export default function useCheckForAdmin() {
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const checkForAdmin = () => {
    return [
      'Operations User',
      'Administrator',
      'Operations Super User',
    ].includes(currentUser.type?.name);
  };
  return {
    checkForAdmin,
  };
}
