import {useEffect, useState} from 'react';

import useLocalStorage from '../reusable/useLocalStorage';
import useChangeStatus from './useChangeStatus';
import useGetAlbumsData from './useGetAlbumsData';

const useStatusUpdateHandler = ({data}) => {
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const {getAlbumDetail} = useGetAlbumsData({
    albumId: data && data.length ? data[0].album_id : [],
  });

  const [status, setStatus] = useState('Pending');
  const [productType, setProductType] = useState('');

  const {updateStatus, albumLoading, trackLoading} = useChangeStatus();

  useEffect(() => {
    if (getAlbumDetail) {
      setStatus(getAlbumDetail.status);
      setProductType(getAlbumDetail.product_type);
    }
  }, [getAlbumDetail]);

  const updateStatusHandler = (statusData) => {
    if (data && data.length) {
      const albumId = data[0].album_id;
      const trackData = data.flatMap(({status, id}) =>
        !(status === 'approved') ? [{trackId: id}] : [],
      );

      if (getAlbumDetail) {
        const dataForStatusUpdate = {
          albumId,
          trackData,
          status: statusData,
          updated_by: currentUser.id,
        };

        updateStatus(dataForStatusUpdate);
      }
    }
  };
  return {status, productType, updateStatusHandler, albumLoading, trackLoading};
};

export default useStatusUpdateHandler;
