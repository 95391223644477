/** @format */
import {queryCache, useMutation} from 'react-query';

import {logError} from '../../errorlogger/logError';

const createAlbum = async (data) => {
  try {
    // Store in Local Storage into albumData Array
    const albumData = JSON.parse(localStorage.getItem('albumData')) || [];
    data.id = data.upc;
    data.Track = [];
    albumData.push(data);
    localStorage.setItem('albumData', JSON.stringify(albumData));
    return data;
  } catch (error) {
    logError(error, 3);
    return error.response?.data;
  }
  //
};

const useCreateAlbum = () => {
  const [
    createAlbumMutation,
    {status, data, error, isLoading, isSuccess},
  ] = useMutation(createAlbum, {
    onSuccess: () => {
      queryCache.invalidateQueries('albums');
    },
  });

  return {createAlbumMutation, isLoading, status, error};
};

export default useCreateAlbum;
