import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../lotties/cloud-music.json";
import styled from "@emotion/styled";

const LoadingMain = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<LottieContainer>
			<Lottie options={defaultOptions} height={200} width={200} />
		</LottieContainer>
	);
};

const LottieContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
`;

export default LoadingMain;
