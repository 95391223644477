import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {FaAngleDoubleRight, FaCheckCircle} from 'react-icons/fa';
import {RiErrorWarningFill} from 'react-icons/ri';

import useStringCompare from '../../../hooks/CreateAlbum/useStringCompare';

const Cell = styled.div`
  border-bottom: 1px solid silver;
  overflow: hidden;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderCell = styled(Cell)`
  position: sticky;
  top: 0;
  background-color: #eaeaea;
  font-weight: bold;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const invalidHeaderCol = [
  {
    label: 'Column',
    dataKey: 'col',
    width: 100,
    flex: false,
    columnStyle: {
      borderRight: '1px solid silver',
    },
  },
  {
    label: 'Value',
    dataKey: 'value',
    width: 100,
    flex: true,
  },
];

const invalidCodeCol = [
  {
    label: 'Value',
    dataKey: 'value',
    width: 100,
    flex: true,
  },
  {
    label: 'Row',
    dataKey: 'row',
    width: 100,
    alignRight: true,
    flex: true,
  },
];

const invalidSongOrderCol = [
  {
    label: 'Value',
    dataKey: 'value',
    width: 100,
    flex: true,
  },
  {
    label: 'Row',
    dataKey: 'row',
    width: 100,
    alignRight: true,
    flex: true,
  },
  {
    label: 'Correct value',
    dataKey: 'correct_value',
    width: 100,
    flex: true,
  },
];

const UPC_LABEL = `UPC(s)`;

const invalidReleaseData = [
  {
    label: UPC_LABEL,
    dataKey: 'value',
    width: 100,
    flex: true,
  },
];

export const pick = (object, keys) =>
  keys.reduce((collection, key) => [...collection, object[key]], []);

const columns = [
  {
    label: 'Column',
    dataKey: 'col',
    width: 100,
    flex: false,
    columnStyle: {
      borderRight: '1px solid silver',
    },
  },
  {
    label: 'Value',
    dataKey: 'value',
    width: 100,
    flex: true,
  },
  {
    label: 'Row',
    dataKey: 'row',
    width: 100,
    alignRight: true,
    flex: true,
  },
  {
    label: 'Correct value',
    dataKey: 'correct_value',
    width: 100,
    flex: true,
  },
];

const Grid = ({columns, data, code}) => {
  const {checkString} = useStringCompare();
  const dataKeys = columns.map(({dataKey}) => dataKey);
  const gridTemplateColumns = columns
    .map(({width, flex}) => `minmax(${width}px, ${flex ? 1 : 0}fr)`)
    .join(' ');
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffff',
        display: 'grid',
        gridTemplateColumns,
        gridAutoRows: 'max-content',
      }}
    >
      {data &&
        columns &&
        columns.map(({label, columnStyle}) => (
          <HeaderCell style={columnStyle}>{label}</HeaderCell>
        ))}

      {data &&
        data.map((row, rowIndex) =>
          pick(row, dataKeys).map((component, columnIndex) => (
            <Cell
              key={`${rowIndex}-${columnIndex}`}
              style={columns[columnIndex].columnStyle}
            >
              {code === 'Invalid Headers Present' && columnIndex === 1 ? (
                <>
                  <WrongContainer>
                    <div className="correct-value">
                      <div>{component}</div>
                      <RiErrorWarningFill />
                    </div>
                  </WrongContainer>
                  <CorrectContainer>
                    <FaAngleDoubleRight />

                    <div className="correct-value">
                      <div>{checkString(component).value}</div>
                      <FaCheckCircle />
                    </div>
                  </CorrectContainer>
                </>
              ) : columnIndex === 1 ? (
                <WrongContainer>
                  <div className="correct-value">
                    <div>{component}</div>
                    <RiErrorWarningFill />
                  </div>
                </WrongContainer>
              ) : code === 'Invalid Song Order Present' && columnIndex === 1 ? (
                <>
                  <WrongContainer>
                    <div className="correct-value">
                      <div>{component}</div>
                      <RiErrorWarningFill />
                    </div>
                  </WrongContainer>
                  <CorrectContainer>
                    <FaAngleDoubleRight />

                    <div className="correct-value">
                      <div>{component}</div>
                      <FaCheckCircle />
                    </div>
                  </CorrectContainer>
                </>
              ) : (
                <div>{component}</div>
              )}
            </Cell>
          )),
        )}
    </div>
  );
};

const CorrectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-left: 10px;
  }
  .correct-value {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(79, 138, 16);
    padding: 5px 15px;

    svg {
      margin-left: 10px;
    }
  }
`;

const WrongContainer = styled(CorrectContainer)`
  .correct-value {
    color: rgb(216, 0, 12);
  }
`;
export default function GridTable({data, code}) {
  const [columnState, setColumnState] = useState(columns);

  useEffect(() => {
    if (code === 'Invalid Headers Present') {
      setColumnState(invalidHeaderCol);
    } else if (
      code === 'Invalid ISRC Present' ||
      code === 'Invalid UPC Present' ||
      code === 'Invalid Release Date Format Present'
    ) {
      setColumnState(invalidCodeCol);
    } else if (code === 'Invalid Release Date Present') {
      setColumnState(invalidReleaseData);
    } else if (code === 'Invalid Song Order Present') {
      setColumnState(invalidSongOrderCol);
    } else {
      setColumnState(invalidCodeCol);
    }
  }, [code]);

  return (
    <Container>
      <Grid columns={columnState} data={data} code={code} />
    </Container>
  );
}
