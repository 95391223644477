import {useEffect} from 'react';
import React from 'react';

import useGetSingleUploadStatus from '../hooks/UploadStatus/useGetUploadStatus';

const GlobalState = () => {
  const {getSingleUploadStatus, dispatch} = useGetSingleUploadStatus();

  useEffect(() => {
    if (getSingleUploadStatus) {
      if (getSingleUploadStatus.file_status === 3) {
        dispatch({
          type: 'CHANGE_STATUS',
          payload: {
            ...getSingleUploadStatus,
          },
        });
        dispatch({
          type: 'SET_FILEID',
          payload: {
            fileId: null,
          },
        });
      }
      if (getSingleUploadStatus.file_status === 1) {
        dispatch({
          type: 'CHANGE_STATUS',
          payload: {
            ...getSingleUploadStatus,
          },
        });
        dispatch({
          type: 'SET_FILEID',
          payload: {
            fileId: null,
          },
        });
      }

      if (getSingleUploadStatus.file_status === 2) {
        dispatch({
          type: 'CHANGE_STATUS',
          payload: {
            ...getSingleUploadStatus,
          },
        });
      }
    }
  }, [getSingleUploadStatus]);

  return <></>;
};

export default GlobalState;
