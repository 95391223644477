/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import {Tab, Tabs} from 'baseui/tabs-motion';
import {Form, Formik} from 'formik';
import React from 'react';

import Button from '../../../components/reusable/Button/Button';
import ConfirmModal from '../../../components/reusable/ConfirmModal/ConfirmModal';
import FormikControl from '../../../components/reusable/Formik/FormikControl';
import SkeletonLoading from '../../../components/reusable/SkeletonLoading/SkeletonLoading';
import {SubmitButton} from '../../../components/reusable/SubmitButton/SubmitButton';
import useDisableDSP from '../../../hooks/DSPManagement/useDisableDSP';
import useDspForm from '../../../hooks/DSPManagement/useDspForm';
import useModal from '../../../hooks/Modal/useModal';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import {validationSchema} from '../../../validation/DSPManagement/dspManagementForm';

const DSPFormContainer = styled.div`
  margin: 0 -30px;

  .disable {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export default function DSPForm(props) {
  const {
    selectedData,
    setIsOpen,
    setSelectedData,
    useOnClickOutside,
    sidebarRef,
    onCloseHandler,
    refetch,
  } = props;

  const {disableDSPMutation, isLoading: isDisableLoading} = useDisableDSP();
  const {isOpen, close, setIsOpen: modalSetter} = useModal();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [tabActiveKey, setTabActiveKey] = React.useState('0');

  useOnClickOutside(sidebarRef, () => onCloseHandler());

  const {
    isLoading,
    initialValues,
    onSubmit,
    dspTypeOptions,
    contentTypeOptions,
    methodOfDeliveryOptions,
    langOptions,
    getSingleDsp,
    isUpdateDSPLoading,
    isCreateDSPLoading,
  } = useDspForm({
    selectedData,
    setIsOpen,
    currentUser,
    setSelectedData,
    refetch,
  });

  const disableDSPHandler = async () => {
    if (getSingleDsp?.id) {
      const data = {
        updated_by: currentUser.id,
      };
      const res = await disableDSPMutation({id: getSingleDsp?.id, data});

      if (res?.status === 204) {
        setIsOpen(false);
        setSelectedData({});
        refetch();
      }
    }
  };

  return (
    <DSPFormContainer>
      <Tabs
        activeKey={tabActiveKey}
        onChange={({activeKey}: any) => {
          setTabActiveKey(activeKey);
        }}
        activateOnFocus
        overrides={{
          TabHighlight: {
            style: {
              backgroundColor: '#DC3831',
            },
          },
        }}
      >
        <Tab
          title="Details"
          overrides={{
            Tab: {
              style: {
                background: 'white',
              },
            },
          }}
        >
          {getSingleDsp && !getSingleDsp.is_disabled ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                label="Disable"
                onClick={() => modalSetter(true)}
                size="mini"
              />
            </div>
          ) : null}
          <div
            className={getSingleDsp?.is_disabled ? 'disable' : ''}
            style={{
              padding: '16px',
            }}
          >
            {!isLoading ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div
                        style={
                          getSingleDsp && {
                            pointerEvents: 'none',
                            opacity: '0.7',
                          }
                        }
                      >
                        <FormikControl
                          control="baseinput"
                          type="text"
                          label="DSP Name"
                          name="dsp_name"
                          placeholder="e.g. Spotify"
                          initialValue={formik.initialValues.dsp_name}
                          setFieldValue={formik.setFieldValue}
                          setFieldTouched={formik.setFieldTouched}
                        />
                        <FormikControl
                          labelKey="label"
                          valueKey="value"
                          control="baseselect"
                          label="DSP Type"
                          name="dsp_type"
                          options={dspTypeOptions}
                          value={formik.values.dsp_type}
                          onChange={(name, value) => {
                            formik.setFieldTouched(name, true);
                            formik.setFieldValue(name, value);
                          }}
                          isSearchable={false}
                        />
                      </div>

                      <FormikControl
                        labelKey="label"
                        valueKey="value"
                        control="baseselect"
                        label="Content Type"
                        name="content_type"
                        placeholder="Content Type"
                        options={contentTypeOptions}
                        isRequired= {false}
                        value={formik.values.content_type}
                        onChange={(name, value) => {
                          formik.setFieldTouched(name, true);
                          formik.setFieldValue(name, value);
                        }}
                        isSearchable={false}
                        multi={true}
                      />
                      <FormikControl
                        control="baseinput"
                        type="text"
                        placeholder="e.g. John Doe"
                        label="Point of Contact"
                        name="point_of_contact"
                        initialValue={formik.initialValues.point_of_contact}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                      />

                      <FormikControl
                        control="dynamicInput"
                        name="email_poc"
                        label="Email ID (To)"
                        buttonName="Add Email"
                        placeholder="e.g. example@divomusic.com"
                        setFieldTouched={formik.setFieldTouched}
                        setFieldValue={formik.setFieldValue}
                      />

                      <FormikControl
                        control="dynamicInput"
                        name="email_cc"
                        label="Email ID (CC)"
                        buttonName="Add CC"
                        placeholder="e.g. example@divomusic.com"
                        setFieldTouched={formik.setFieldTouched}
                        setFieldValue={formik.setFieldValue}
                      />

                      <FormikControl
                        control="baseinput"
                        type="text"
                        isRequired={false}
                        label="Contact Number"
                        name="phone_poc"
                        startEnhancer="+91"
                        initialValue={formik.initialValues.phone_poc}
                        setFieldValue={formik.setFieldValue}
                        placeholder="e.g. 9999999999"
                        setFieldTouched={formik.setFieldTouched}
                      />
                      {formik.values?.dsp_type?.length &&
                      formik.values.dsp_type[0]?.value ===
                        'publisher' ? null : (
                        <FormikControl
                          labelKey="label"
                          valueKey="value"
                          control="baseselect"
                          label="Delivery Method"
                          name="delivery_type"
                          options={methodOfDeliveryOptions}
                          value={formik.values.delivery_type}
                          onChange={(name, value) => {
                            formik.setFieldTouched(name, true);
                            formik.setFieldValue(name, value);
                          }}
                          isSearchable={false}
                        />
                      )}

                      {formik.values?.dsp_type?.length &&
                      formik.values.dsp_type[0].value === 'publisher' ? null : (
                        <>
                          {formik.values?.delivery_type[0]?.value ===
                            'sftp' && (
                            <>
                              <FormikControl
                                control="baseinput"
                                type="text"
                                label="Host"
                                name="sftp_host"
                                placeholder="e.g. 192.167.278.1"
                                initialValue={formik.initialValues.sftp_host}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                              />
                              <FormikControl
                                control="baseinput"
                                type="text"
                                label="SFTP  username"
                                name="sftp_user_name"
                                placeholder="e.g. admin"
                                initialValue={
                                  formik.initialValues.sftp_user_name
                                }
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                              />
                              <FormikControl
                                control="baseinput"
                                type="password"
                                label="SFTP Password"
                                name="sftp_password"
                                placeholder="**********"
                                initialValue={
                                  formik.initialValues.sftp_password
                                }
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                              />
                              <FormikControl
                                control="baseinput"
                                type="text"
                                label="Port"
                                name="sftp_port"
                                initialValue={formik.initialValues.sftp_port}
                                setFieldValue={formik.setFieldValue}
                                placeholder="22"
                                setFieldTouched={formik.setFieldTouched}
                              />
                              <FormikControl
                                control="baseinput"
                                type="text"
                                label="File"
                                name="url_file_upload"
                                placeholder="e.g. /home/divo"
                                initialValue={
                                  formik.initialValues.url_file_upload
                                }
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                              />
                            </>
                          )}

                          {formik.values?.delivery_type[0]?.value ===
                            'email' && (
                            <FormikControl
                              control="dynamicInput"
                              name="delivery_email"
                              label="Delivery Email ID"
                              buttonName="Add Email"
                              placeholder="e.g. example@divomusic.com"
                              setFieldTouched={formik.setFieldTouched}
                              setFieldValue={formik.setFieldValue}
                            />
                          )}
                        </>
                      )}

                      <FormikControl
                        control="baseDesc"
                        placeholder="Description of DSP"
                        label="Description"
                        name="description"
                        initialValue={formik.initialValues.description}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                      />

                      {/* <FormikControl
                        disabled={selectedData && selectedData.is_disabled}
                        labelKey="value"
                        valueKey="id"
                        control="newMultiSelect"
                        label="Languages"
                        name="languages"
                        values={formik.values}
                        options={langOptions && langOptions}
                        initialValue={formik.initialValues.languages}
                        setFieldValue={formik.setFieldValue}
                      /> */}
                      <FormikControl
                        disabled={selectedData && selectedData.is_disabled}
                        labelKey="value"
                        valueKey="id"
                        control="transferList"
                        label="Languages"
                        name="languages"
                        values={formik.values}
                        options={langOptions && langOptions}
                        initialValue={formik.initialValues.languages}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                      />

                      <SubmitButton
                        disabled={
                          !formik.isValid ||
                          (Object.keys(formik.touched).length === 0 &&
                            formik.touched.constructor === Object)
                        }
                        type="submit"
                        label={`${
                          selectedData
                            ? isUpdateDSPLoading
                              ? 'Updating DSP'
                              : 'Update DSP'
                            : isCreateDSPLoading
                            ? 'Adding DSP'
                            : 'Add DSP'
                        } `}
                        isLoading={isCreateDSPLoading || isUpdateDSPLoading}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <SkeletonLoading />
            )}
          </div>
        </Tab>
      </Tabs>
      {isOpen && (
        <ConfirmModal
          sidebarRef={sidebarRef}
          isOpen={isOpen}
          close={close}
          title="Are you sure ?"
          renderBtn={
            <Button
              onClick={() => disableDSPHandler()}
              label={`Disable ${getSingleDsp?.dsp_name}`}
              size="default"
              isLoading={isDisableLoading}
            />
          }
        />
      )}
    </DSPFormContainer>
  );
}
