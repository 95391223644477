import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchAllDsps = async () => {
  try {
    const res = await axios(`/dsp`, {
      method: 'GET',
    });
    return res.data?.data;
  } catch (error) {
    logError(error, 8);
    return error.response?.data;
  }
};

const useListDsps = () => {
  const {data: listDsps} = useQuery('dsps', fetchAllDsps);

  return {
    listDsps,
  };
};

export default useListDsps;
