import {Button} from 'baseui/button';
import {ChevronDown} from 'baseui/icon';
import {StatefulMenu} from 'baseui/menu';
import {PLACEMENT, StatefulPopover} from 'baseui/popover';
import React from 'react';

import axios from '../../config/Axios';

const DownloadTemplateBtn = () => {
  const ITEMS = [{label: 'Film'}, {label: 'Non-Film'}];

  const downloadUrl = async (path) => {
    const res = await axios(`/download/${path}`, {
      method: 'GET',
    });
    if (res.request.responseURL !== `${res.config.baseURL}${res.config.url}`) {
      window.location.href = res.request.responseURL;
    }
  };

  return (
    <StatefulPopover
      focusLock
      placement={PLACEMENT.bottomRight}
      overrides={{
        Body: {
          style: {
            zIndex: 3,
          },
        },
      }}
      content={({close}) => (
        <StatefulMenu
          items={ITEMS}
          onItemSelect={({item}) => {
            if (item.label === 'Film') {
              downloadUrl('film');
            } else {
              downloadUrl('non-film');
            }
          }}
          overrides={{
            List: {style: {height: 'auto', width: '138px'}},
          }}
        />
      )}
    >
      <Button
        overrides={{
          Root: {
            style: () => {
              return {
                fontSize: '14px',
                paddingTop: '8px',
                paddingBottom: '8px',
              };
            },
          },
        }}
        shape="pill"
        size="default"
        endEnhancer={() => <ChevronDown size={24} />}
      >
        Download Template
      </Button>
    </StatefulPopover>
  );
};

export default DownloadTemplateBtn;
