import styled from '@emotion/styled';
import {Button as BaseButton} from 'baseui/button';
import React, {useState} from 'react';
import {BiRefresh} from 'react-icons/bi';
import {IoIosArrowForward} from 'react-icons/io';
import {useNavigate} from 'react-router';
import {useAsyncDebounce} from 'react-table';

import {TAGS} from '../../../constants/tags';
import Button from '../Button/Button';

const TableContainer = styled.div<{pendingAlbums}>`
  padding: 10px 25px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  .search-container,
  .button-container {
    align-items: center;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    .export-btn {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 10px;
      margin-right: 10px;
      color: #333;
      background-color: white;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background: #ebecf1;
      }
      svg {
        margin-left: 5px;
      }
    }
  }
  .group_btn {
    display: flex;
    .go-to-package {
      filter: ${(props) => (props.pendingAlbums > 0 ? 'grayscale(1)' : '')};
      pointer-events: ${(props) => (props.pendingAlbums > 0 ? 'none' : '')};
    }
  }
  .svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const renderPlaceholders = (tag) => {
  switch (tag) {
    case TAGS.USER:
      return TAGS.USER;
    case TAGS.ALBUMS:
      return TAGS.ALBUMS;
    case TAGS.LABEL:
      return TAGS.LABEL;
    case TAGS.TRACKS:
      return TAGS.TRACKS;
    case TAGS.ALBUM_APPROVAL:
      return TAGS.ALBUMS;
    case TAGS.TRACK_APPROVAL:
      return TAGS.TRACKS;
    case TAGS.UPC:
      return TAGS.UPC;
    case TAGS.DSP:
      return TAGS.DSP;
    case TAGS.ISRC:
      return TAGS.ISRC;
    default:
      return TAGS.ALBUMS;
  }
};

const TableFilter = (props) => {
  const {
    tag,
    actionBtn,
    setSearchStr,
    setSearchString,
    pendingAlbums,
  } = props;

  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [bubble, setBubble] = useState(0);
  const navigate = useNavigate();

  const onSearchChange = useAsyncDebounce((value) => {
    setSearchStr(value);
    setSearchString(value);
  }, 200);



  return (
    <>
      <TableContainer pendingAlbums={pendingAlbums}>
        <div className="search-container">
        </div>

        <div className="group_btn">
          <div className="button-container">{actionBtn}</div>
          {tag === TAGS.BULK_ADMIN && (
            <div className="go-to-package">
              {' '}
              <Button
                label="Go to Package Manager"
                onClick={() => navigate('package-manager')}
                shape="round"
                endEnhancer={() => <IoIosArrowForward />}
              />
            </div>
          )}
        </div>
      </TableContainer>
    </>
  );
};

export default TableFilter;
