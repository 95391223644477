export const COLUMN_LABELS = {
  UPC: 'UPC',
  ALBUM_NAME: 'Album Name',
  ALBUM_TYPE: 'Content Type',
  ALBUM_PRODUCT_TYPE: 'Product Type',
  ALBUM_RELEASE_DATE: 'Album Release Date (DD/MMM/YYYY)',
  ACTOR_MALE_NAME: 'Actor(s) (Male) Name',
  ACTRESS_FEMALE_NAME: 'Actress(s) (Female) Name',
  ALBUM_LANGUAGE: 'Album Language',
  FILM_ZONE: 'Film Zone',
  FILM_DIRECTOR_NAME: 'Film Director(s) Name',
  FILM_PRODUCER_NAME: 'Film Producer(s) Name',
  PRODUCTION_HOUSE_NAME: 'Production House(s) Name',
  ISRC: 'ISRC',
  SONG_ORDER: 'Song Order',
  SONG_NAME: 'Song Name',
  SINGER_ARTIST_NAME: 'Singer(s)/Artist(s) Name',
  LYRICIST_NAME: 'Lyricist(s) Name',
  COMPOSER_NAME: 'Composer(s) Name',
  C_LINE: 'C-Line',
  P_LINE: 'P-Line',
  SONG_GENRE: 'Song Genre',
  MUSICAL_INSTRUMENTS: 'Musical Instruments',
  DURATION: 'Duration',
};

export const ColumnHeaders = [
  COLUMN_LABELS.UPC,
  COLUMN_LABELS.ALBUM_NAME,
  COLUMN_LABELS.ALBUM_TYPE,
  COLUMN_LABELS.ALBUM_PRODUCT_TYPE,
  COLUMN_LABELS.ALBUM_RELEASE_DATE,
  COLUMN_LABELS.ACTOR_MALE_NAME,
  COLUMN_LABELS.ACTRESS_FEMALE_NAME,
  COLUMN_LABELS.ALBUM_LANGUAGE,
  COLUMN_LABELS.FILM_ZONE,
  COLUMN_LABELS.FILM_DIRECTOR_NAME,
  COLUMN_LABELS.FILM_PRODUCER_NAME,
  COLUMN_LABELS.PRODUCTION_HOUSE_NAME,
  COLUMN_LABELS.ISRC,
  COLUMN_LABELS.SONG_ORDER,
  COLUMN_LABELS.SONG_NAME,
  COLUMN_LABELS.SINGER_ARTIST_NAME,
  COLUMN_LABELS.LYRICIST_NAME,
  COLUMN_LABELS.COMPOSER_NAME,
  COLUMN_LABELS.C_LINE,
  COLUMN_LABELS.P_LINE,
  COLUMN_LABELS.SONG_GENRE,
  COLUMN_LABELS.MUSICAL_INSTRUMENTS,
  COLUMN_LABELS.DURATION,
];

export const MANDATORY_FILM_TYPE = [
  COLUMN_LABELS.UPC,
  COLUMN_LABELS.ALBUM_NAME,
  COLUMN_LABELS.ALBUM_TYPE,
  COLUMN_LABELS.ALBUM_PRODUCT_TYPE,
  COLUMN_LABELS.ALBUM_RELEASE_DATE,
  COLUMN_LABELS.ACTOR_MALE_NAME,
  COLUMN_LABELS.ACTRESS_FEMALE_NAME,
  COLUMN_LABELS.ALBUM_LANGUAGE,
  COLUMN_LABELS.FILM_ZONE,
  COLUMN_LABELS.FILM_DIRECTOR_NAME,
  COLUMN_LABELS.FILM_PRODUCER_NAME,
  COLUMN_LABELS.PRODUCTION_HOUSE_NAME,
  COLUMN_LABELS.ISRC,
  COLUMN_LABELS.SONG_ORDER,
  COLUMN_LABELS.SONG_NAME,
  COLUMN_LABELS.SINGER_ARTIST_NAME,
  COLUMN_LABELS.LYRICIST_NAME,
  COLUMN_LABELS.COMPOSER_NAME,
  COLUMN_LABELS.C_LINE,
  COLUMN_LABELS.P_LINE,
  COLUMN_LABELS.SONG_GENRE,
  COLUMN_LABELS.MUSICAL_INSTRUMENTS,
  COLUMN_LABELS.DURATION,
];

export const MANDATORY_NON_FILM_TYPE = [
  COLUMN_LABELS.UPC,
  COLUMN_LABELS.ALBUM_NAME,
  COLUMN_LABELS.ALBUM_TYPE,
  COLUMN_LABELS.ALBUM_PRODUCT_TYPE,
  COLUMN_LABELS.ALBUM_RELEASE_DATE,
  COLUMN_LABELS.ALBUM_LANGUAGE,
  COLUMN_LABELS.FILM_ZONE,
  COLUMN_LABELS.ISRC,
  COLUMN_LABELS.SONG_ORDER,
  COLUMN_LABELS.SONG_NAME,
  COLUMN_LABELS.SINGER_ARTIST_NAME,
  COLUMN_LABELS.LYRICIST_NAME,
  COLUMN_LABELS.COMPOSER_NAME,
  COLUMN_LABELS.C_LINE,
  COLUMN_LABELS.P_LINE,
  COLUMN_LABELS.SONG_GENRE,
  COLUMN_LABELS.MUSICAL_INSTRUMENTS,
  COLUMN_LABELS.DURATION,
];
