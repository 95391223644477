import React, {ReactElement} from 'react';

interface ThemeObj {
  divoBg: string;
  arrowBg: string;
}

interface Props {
  theme: ThemeObj;
}

export default function Logo({theme}: Props): ReactElement {
  return (
    <svg
      width="190"
      height="83"
      viewBox="0 0 190 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.313077 60.148C7.47349 90.4823 47.1815 89.3106 56.0346 60.148V11.7172L44.3174 0V54.029C44.3174 74.0783 12.9415 79.286 11.249 54.029C11.249 39.7081 25.2662 36.7571 31.5587 37.4948L33.9022 26.2985C2.96896 24.3196 -1.3794 48.0403 0.313077 60.148Z"
        fill={theme.divoBg}
      />
      <rect
        x="60.7214"
        y="26.5588"
        width="11.3266"
        height="55.9027"
        fill={theme.divoBg}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.9373 34.2387L85.7768 25.3872L106.578 64.7214L127.378 25.3872L136.218 34.2387L111.541 82.3439L111.497 82.3246V82.344H101.798V82.2646L101.614 82.3439L76.9373 34.2387Z"
        fill={theme.divoBg}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190 54.5098C190 70.1845 177.293 82.8913 161.619 82.8913C158.778 82.8913 156.034 82.4738 153.446 81.6969L156.939 70.5084C158.424 70.9419 159.994 71.1744 161.619 71.1744C170.823 71.1744 178.283 63.7135 178.283 54.51C178.283 45.3066 170.823 37.8457 161.619 37.8457C152.416 37.8457 144.955 45.3066 144.955 54.51C144.955 58.1577 146.127 61.5317 148.115 64.2759L136.543 67.8145C134.433 63.8462 133.237 59.3176 133.237 54.5098C133.237 38.8351 145.944 26.1283 161.619 26.1283C177.293 26.1283 190 38.8351 190 54.5098Z"
        fill={theme.divoBg}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.44 64.196L150.713 82.538L146.875 78.8142L139.84 80.7575L142.005 74.0894L137.711 69.9231L156.44 64.196Z"
        fill={theme.arrowBg}
      />
    </svg>
  );
}
