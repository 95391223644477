/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Button} from 'baseui/button';
import {Plus} from 'baseui/icon';
import {ErrorMessage, Field, FieldArray} from 'formik';
import React from 'react';
import {IoMdClose} from 'react-icons/io';

import {Label} from './BaseInput';
import DetailsError from './DetailsError';

function DynamicInput(props) {
  const [css, theme] = useStyletron();
  const {
    label,
    name,
    buttonName,
    placeholder,
    setFieldTouched,
    setFieldValue,
    isRequired,
    ...rest
  } = props;
  return (
    <DynamicInput.Container
      borderColor={theme.tabInactiveBorderColor}
      bgColor={theme.inputBg}
      textColor={theme.textColor}
      removeBg={theme.removeBtnBg}
    >
      <span className="label">
        <Label data={{children: label}} isRequired={isRequired} />
      </span>
      <div className="input_item">
        <div className="right">
          <FieldArray name={name}>
            {(fieldArrayProps) => {
              const {push, remove, form} = fieldArrayProps;
              const {values} = form;
              return (
                <div className="field">
                  {values[name] &&
                    values[name].map((item, index) => (
                      <div className="input-wrapper" key={index}>
                        <div className="input-parent">
                          <Field
                            placeholder={placeholder}
                            name={`${name}[${index}]`}
                            onChange={(e) => {
                              setFieldValue(
                                `${name}[${index}]`,
                                e.target.value,
                              );
                              if (setFieldTouched) {
                                setFieldTouched(`${name}[${index}]`, true);
                              }
                            }}
                            {...rest}
                          />
                        </div>
                        {index > 0 && (
                          <div
                            className="remove"
                            onClick={() => {
                              remove(index);
                              if (setFieldTouched) {
                                setFieldTouched(name, true);
                              }
                            }}
                          >
                            <IoMdClose />
                          </div>
                        )}
                        <div className="button-parent">
                          {index === 0 && (
                            <Button
                              type="button"
                              onClick={() => push('')}
                              size="compact"
                              shape="circle"
                            >
                              <Plus size={22} />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              );
            }}
          </FieldArray>
        </div>
      </div>
      <div className="input_item">
        <div className="left"></div>
        <div className="right error">
          <ErrorMessage name={name} component={DetailsError} />
        </div>
      </div>
    </DynamicInput.Container>
  );
}

DynamicInput.Container = styled.div`
  display: flex;
  flex-direction: column;

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    input {
      width: 100%;
    }
  }
  .input-parent {
    width: 80%;
  }

  .remove {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    color: white;
    background: ${(props) => props.removeBg};
    cursor: pointer;
    border-radius: 50px;
  }
  input {
    display: flex;
    font-size: 0.85rem;
    border-radius: 5px;
    background: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: none;
    // min-width: 90%;
    padding: 15px;

    &:focus {
      outline: none;
    }
  }
  .label {
    font-size: 14px;
    margin: 8px 0px;
    line-height: 16px;
    font-weight: 500;
  }
`;

export default DynamicInput;
