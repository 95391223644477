import React, {useState} from 'react';
import {MdAddCircle} from 'react-icons/md';

import Button from '../../../components/reusable/Button/Button';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import Table from '../../../components/reusable/Table/TablePagination';
import {TAGS} from '../../../constants/tags';
import useGetLabels from '../../../hooks/LabelManagement/useGetLabels';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import {labelColumnMeta} from '../../../tableColumns/LabelManagement/tableData';
import SkeletonLoading from '../../SkeletonPage';

const Label = () => {
  const [searchStr, setSearchStr] = useState('');

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    sError,
    sIsError,
    sIsFetching,
    sIsLoading,
    isCustomLoading,
    refetch,
  } = useGetLabels({searchStr});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const labels = React.useMemo(() => resolvedData, [resolvedData]);
  const columnData = React.useMemo(() => labelColumnMeta, [labelColumnMeta]);

  const openNewFormSidebarHandler = () => {
    setIsOpen(true);
    setNewFormTag(true);
  };

  if (isLoading) {
    return <SkeletonLoading />;
  }
  if (resolvedData?.totalPage === 0) {
    return (
      <NoRecords
        page="label"
        btnLabel="Create Label"
        onClick={openNewFormSidebarHandler}
      />
    );
  }

  return (
    <>
      <Table
        columns={columnData}
        data={labels ? labels : []}
        tag={TAGS.LABEL}
        setSidebarData={setSelectedData}
        onClickRow={setIsOpen}
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        isError={isError || sIsError}
        error={error}
        sError={sError}
        sIsError={sIsError}
        sIsFetching={sIsFetching}
        sIsLoading={sIsLoading}
        setSearchStr={setSearchStr}
        actionBtn={
          <Button
            label="Create Label"
            onClick={openNewFormSidebarHandler}
            shape="round"
            endEnhancer={() => <MdAddCircle />}
          />
        }
      />

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          newFormTag={newFormTag}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          tag={TAGS.LABEL}
        />
      )}
    </>
  );
};

export default Label;
