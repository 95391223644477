import styled from '@emotion/styled';
import {StatefulPopover} from 'baseui/popover';
import {Theme} from 'baseui/theme';
import {PLACEMENT} from 'baseui/toast';
import React, {useEffect, useState} from 'react';
import {GoogleLogout} from 'react-google-login';
import {BiLogOutCircle} from 'react-icons/bi';
import {useNavigate} from 'react-router';

import {clientId} from '../../hooks/reusable/useLogin';
import {CustomThemeType} from '../../styles/theme/colors';
import Icon from '../../styles/ui-component/Icon';

const UserProfileMenu = ({onLogoutSuccess}) => {
  return (
    <UserProfileStyledContainer>
      <GoogleLogout
        render={(props) => (
          <button onClick={props.onClick} className="logout-btn">
            <span>Logout</span>
            <BiLogOutCircle />
          </button>
        )}
        clientId={clientId}
        buttonText="Logout"
        onLogoutSuccess={onLogoutSuccess}
      ></GoogleLogout>
    </UserProfileStyledContainer>
  );
};

const UserProfileStyledContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  width: 120px;
  border-radius: 5px;
  color: #333;
  padding: 10px;
  cursor: pointer;
  height: 30px;
  .logout-btn {
    outline: none;
    border: none;
    background-color: white;
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    span {
      font-size: 14px;
    }
    svg {
      font-size: 18px;
    }
  }
`;

const UserInfo = (props) => {
  const {currentUser, setIsLoggedIn, menuRef} = props;
  const [isToggle, setIsToggle] = useState(false);

  const navigate = useNavigate();

  const onLogoutSuccess = () => {
    setIsLoggedIn(false);
    setIsToggle(false);
    navigate('/');
    window.localStorage.clear();
  };

  return (
    <StatefulPopover
      onOpen={() => setIsToggle(true)}
      onClose={() => setIsToggle(false)}
      dismissOnClickOutside
      focusLock={true}
      placement={PLACEMENT.bottomRight}
      content={({close}) => (
        <UserProfileMenu onLogoutSuccess={onLogoutSuccess} />
      )}
    >
      <UserInfoContainer
        ref={menuRef}
        isToggle={isToggle}
        onClick={() => setIsToggle(!isToggle)}
      >
        <div className="col1">
          <span className="username">
            {currentUser ? currentUser.email : 'Loading...'}
          </span>
          <span className="role">Admin</span>
        </div>

        <div className="col2">
          <div className="dp">
            {currentUser && currentUser.imageUrl ? (
              <img
                className="profileImage"
                src={currentUser.imageUrl}
                alt={currentUser.name}
                width="100%"
                height="100%"
              />
            ) : (
              <Icon name="user" size={18} />
            )}
          </div>
        </div>
      </UserInfoContainer>
    </StatefulPopover>
  );
};

const UserInfoContainer = styled.div<{
  theme: Theme & CustomThemeType;
  isToggle;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  transition: all 0.25s ease-in;
  cursor: pointer;
  background-color: white;
  margin-left: 15px;
  border-radius: 100px 30px 30px 100px;
  background-color: ${(props) => (props.isToggle ? 'rgb(37, 56, 88)' : 'none')};
  color: ${(props) => (props.isToggle ? 'white' : 'none')};
  user-select: none;
  flex-direction: row-reverse;
  height: 45px;
  .col1 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    color: ${(props) => (props.isToggle ? 'white' : 'none')};
    .username {
      font-size: 14px;
      letter-spacing: 1px;
    }

    .role {
      margin-top: 2.5px;
      font-size: 12px;
    }
  }
  .col2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    .dp {
      width: 40px;
      height: 40px;
      background-color: #ffffff;
      border-radius: 40px;
      display: flex;
      align-items: center;
      min-width: 40px;
      justify-content: center;
      .profileImage {
        object-fit: cover;
        border-radius: 40px;
      }
    }
  }
`;

export default UserInfo;
