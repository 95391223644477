import React from 'react';

const MultiSelectBox = (props) => {
  const {
    data,
    handleCheckbox,
    handleAllChecked,
    selectAllLeft,
    searchData,
    header,
    searchVal,
    searchSet,
    isEnabled,
  } = props;
  // const [searchVal, setSearchVal] = useState("");

  return (
    <div className={isEnabled ? 'left' : 'left disabled'}>
      <div className="header">{header}</div>

      <div className="all-select">
        <div className="search">
          <input
            onChange={(event) => {
              searchSet(event.target.value);
            }}
            type="search"
            value={searchVal}
            placeholder="Search"
          />
        </div>
        {data.length > 0 ? (
          <div className="check-all">
            <label className="container">
              Select All
              <input
                onChange={handleAllChecked}
                type="checkbox"
                checked={selectAllLeft}
              />
              <span className="check-mark"></span>
            </label>
          </div>
        ) : null}
      </div>
      {data.length === 0 ? (
        <div className="options">
          <div className="nodata">No data</div>
        </div>
      ) : (
        <div className="options">
          {data.map((item) => (
            <CheckBox
              disabled={isEnabled}
              handleCheckbox={handleCheckbox}
              {...item}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const CheckBox = (props) => {
  return (
    <label className="container-checkbox">
      {props.value}
      <input
        key={props.id}
        onClick={props.handleCheckbox}
        type="checkbox"
        checked={props.isChecked}
        value={props.value}
      />

      <span className="checkmark"></span>
    </label>
  );
};

export default MultiSelectBox;
