/* eslint-disable react/display-name */
/** @format */

import React from 'react';

import {formatDate} from '../../helpers/covertDate';
import {
  sortUserNameAlphabeticalOrderWithoutCase,
} from '../AlbumsManagement/albumColumnHelper';
import {userData} from './userTable';

export const userColumnMeta = [
  {
    Header: userData.name.Header,
    accessor: userData.name.accessor,
    width: 80,
    sortType: (rowA, rowB) => {
      return sortUserNameAlphabeticalOrderWithoutCase(
        rowA.original.name,
        rowB.original.name,
      );
    },
  },
  {
    Header: userData.email.Header,
    accessor: userData.email.accessor,
    width: 80,
  },
  {
    Header: userData.phone.Header,
    accessor: userData.phone.accessor,
    width: 70,
  },
  {
    Header: userData.created_at.Header,
    accessor: userData.created_at.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
    width: 80,
  },
];
