import {Card} from 'baseui/card';
import React, {ReactElement} from 'react';

import {borderNone} from '../../../../styles/base-ui-overrides/common';
import ApprovalForm from './AlbumsInReviewForm';

interface ApprovalsManagementFormsProps {
  selectedData: any;
  newFormTag?: string;
  setIsOpen?: any;
}

export default function ApprovalsManagementForms({
  selectedData,
}: ApprovalsManagementFormsProps): ReactElement {
  return (
    <div>
      <Card
        overrides={{
          Root: {
            style: ({$theme}) => {
              return {
                backgroundColor: $theme.bgDefault,
                ...borderNone,
              };
            },
          },
          Body: {
            style: {
              display: 'flex',
              justifyContent: 'flex-end',
            },
          },
        }}
      ></Card>
      {/* Render the User Form */}
      <ApprovalForm selectedData={selectedData} />
    </div>
  );
}
