/* eslint-disable react-hooks/exhaustive-deps */
/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/* eslint-disable @typescript-eslint/no-use-before-define */
import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import React, {useRef, useState} from 'react';
import {IoIosArrowBack} from 'react-icons/io';
import {useLocation, useNavigate} from 'react-router-dom';

import backCircle from '../../pages/AlbumsManagment/AlbumDetail/backcircle.svg';
import {CustomThemeType} from '../../styles/theme/colors';
import UserInfo from './UserInfo';

export const Title = ({title, navigate}) => {
  const BackNav = (title, route) => (
    <div className="title">
      <div>
        <span className="arrow-container">
          <IoIosArrowBack onClick={() => navigate(route)} />
        </span>
        <span>{title}</span>
      </div>
    </div>
  );

  if (title === 'Create Label') {
    return BackNav(title, '/label');
  } else {
    return <span className="title">{title}</span>;
  }
};

export default function Header(props) {
  const {setIsLoggedIn, currentUser} = props;
  const [pageName, setPageName] = useState('');
  const [check, setCheck] = useState(false);
  const [css, theme] = useStyletron();
  const location = useLocation();
  const navigate = useNavigate();

  const menuRef = useRef();

  React.useEffect(() => {
    checkRoute();
    const checking = location.pathname.includes('/albums/');
    setCheck(checking);
  }, [location.pathname]);

  const checkRoute = () => {
    switch (location.pathname) {
      case '/':
        return setPageName('Dashboard');
      case '/user-management':
        return setPageName('User Management');
      case '/create-album':
        return setPageName('Create Album');
      case '/albums':
        return setPageName('Album Management');
      case '/label':
        return setPageName('Label Management');
      case '/create-label':
        return setPageName('Create Label');
      case '/content-dashboard':
        return setPageName('Content Dashboard');
      case '/reports':
        return setPageName('Reports');
      case '/dsp-management':
        return setPageName('DSP Management');
      default:
        return setPageName('');
    }
  };

  return (
    <div className="header">
      <HeaderContainer theme={theme}>
        {check && (
          <div className="back" onClick={() => navigate('/albums')}>
            <img src={backCircle} alt="back" />
          </div>
        )}
        <div className={pageName === 'Album Management' ? 'back-title' : ''}>
          <Title title={pageName ? pageName : null} navigate={navigate} />
        </div>
        <HeaderWrapper>
          {/* <Button
            overrides={{
              Root: {
                style: ({$theme}) => {
                  return {
                    backgroundColor: $theme.themeButtonBg,
                  };
                },
              },
            }}
            size="mini"
            onClick={() =>
              setTheme(themeObj === THEME.light ? THEME.dark : THEME.light)
            }
            shape={SHAPE.round}
          >
            {themeObj === THEME.light ? <BsMoon /> : <BsSun />}
          </Button> */}

          <UserInfo
            setIsLoggedIn={setIsLoggedIn}
            menuRef={menuRef}
            currentUser={currentUser}
          />
        </HeaderWrapper>
      </HeaderContainer>
    </div>
  );
}

const HeaderContainer = styled.div<{theme: Theme & CustomThemeType}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0px 15px;
  background-color: ${(props) =>
    props.theme.contentBg && props.theme.contentBg};

  .back {
    position: absolute;
    img {
      width: 25px;
    }
  }
  .back-title {
    margin-left: 40px;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    color: ${(props) => props.theme.textColor && props.theme.textColor};
    div {
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow-container {
        background: ${(props) => props.theme.bg && props.theme.bg};
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 35px;
        margin-right: 10px;
      }
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
