import {Modal, ModalBody, ModalFooter, ModalHeader} from 'baseui/modal';
import React from 'react';

import Button from '../reusable/Button/Button';

export default function RevertModal(props) {
  const {isOpen, close, isAlbum, isTrack, revertFn, isLoading} = props;

  return (
    <Modal
      closeable={false}
      isOpen={isOpen}
      onClose={close}
      overrides={{
        Root: {
          style: {
            zIndex: 100,
          },
        },
        Dialog: {
          style: {
            borderRadius: '5px',
          },
        },
        DialogContainer: {
          style: {
            zIndex: 100,
          },
        },
      }}
    >
      <ModalHeader>
        {isAlbum && `The generated UPC [${isAlbum}] will be reverted !`}
        {isTrack && `The generated ISRC [${isTrack}] will be reverted !`}
      </ModalHeader>
      <ModalBody></ModalBody>
      <ModalFooter>
        <Button label="OKAY" onClick={revertFn} isLoading={isLoading} />
        {/* <button onClick={revertFn}>Okay</button> */}
      </ModalFooter>
    </Modal>
  );
}
