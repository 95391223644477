import React, {useState} from 'react';

const useModal = (initialValue = false) => {
  const [isOpen, setIsOpen] = useState(initialValue);

  const close = () => setIsOpen(!isOpen);

  return {
    isOpen,
    setIsOpen,
    close,
  };
};

export default useModal;
