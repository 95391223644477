import {Modal, ModalHeader, SIZE} from 'baseui/modal';
import * as React from 'react';

const BaseModal = ({closeable = true, isOpen, close, children, page}) => {
  return (
    <Modal
      closeable={closeable}
      onClose={close}
      isOpen={isOpen}
      size={SIZE.default}
      overrides={{
        Dialog: {
          style: {
            width: page === 'dsp-delivery' ? '75vw' : '50vw',
            minHeight: page === 'dsp-delivery' ? '55vh' : '40vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '5px',
            backgroundColor: 'white',
            margin: '0',
          },
        },
        Root: {
          style: {
            zIndex: 100,
          },
        },
      }}
    >
      {/* <ModalHeader></ModalHeader> */}
      {children}
    </Modal>
  );
};

export default BaseModal;
