/* eslint-disable react-hooks/exhaustive-deps */
import {FormControl} from 'baseui/form-control';
import {Select} from 'baseui/select';
import {ErrorMessage, Field} from 'formik';
import React from 'react';

import {camelToSentence} from '../../../helpers/convertSnakeCaseToUpperCase';
import {
  borderNone,
  setBorderRadius,
} from '../../../styles/base-ui-overrides/common';
import {Label} from './BaseInput';
import TextError from './TextError';

const BaseMultiSelect = (props) => {
  const {
    label,
    name,
    options,
    initialValue,
    segregatedRoleOptionData,
    setFieldValue,
    typeValue,
    labelKey,
    valueKey,
    currentRoles,
    roleIndex,
    disabled,
    isSearchable,
    isRequired,
    setFieldTouched,

    ...rest
  } = props;

  const [value, setValue] = React.useState(initialValue);
  const [roleO, setRoleO] = React.useState([]);

  React.useEffect(() => {
    if (currentRoles) {
      const data = currentRoles.map((role) => {
        return {
          id: role.id,
          name: role.name,
          // label: camelToSentence(role.name),
        };
      });

      setValue(data);
      setFieldValue(name, data);
    }
  }, [currentRoles]);

  React.useEffect(() => {
    const id = typeValue[0]?.id;
    if (options && id) {
      const filteredData = options.filter((option) => option.id === id);
      const data = filteredData[0]?.roles;
      setRoleO(data);

      if (roleIndex === -1) {
        if (data) {
          setValue(data);
          setFieldValue(name, data);
        }
      }
    }
  }, [typeValue, options, roleIndex]);

  const onChangeHandler = (value) => {
    setValue(value);
    setFieldTouched(name, true);
    setFieldValue(name, value);
  };

  return (
    <div>
      <Field id={name} name={name} {...rest}>
        {({field, form}) => (
          <FormControl
            label={`${label}`}
            overrides={{
              Label: (data) => <Label data={data} isRequired={isRequired} />,
            }}
          >
            <Select
              searchable={false}
              disabled={disabled}
              overrides={{
                ControlContainer: {
                  style: ({$theme}) => {
                    return {
                      ...borderNone,
                      backgroundColor: $theme.inputBg,
                      ...setBorderRadius(5),
                    };
                  },
                },
                Popover: {
                  props: {
                    overrides: {
                      Body: {
                        style: ({$theme}) => ({
                          zIndex: '3',
                        }),
                      },
                    },
                  },
                },
              }}
              creatable
              multi
              placeholder={label}
              options={roleO && roleO}
              labelKey={labelKey}
              valueKey={valueKey}
              onChange={({value}) => onChangeHandler(value)}
              value={value ? value : initialValue}
            />
          </FormControl>
        )}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default BaseMultiSelect;
