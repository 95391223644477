import ExcelJS from 'exceljs';
import React, {useEffect, useState} from 'react';

import useListLanguages from '../hooks/Language/useListLanguage';

const Raaga = (props) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const {data, multiple, selectedIds} = props;
  const [albumTracks, setAlbumTracks] = useState([]);

  const {listLanguages} = useListLanguages();

  const gettracks = (getAlbumDetail) => {
    const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
    if (multiple) {
      const filterTracks = tracks.filter((item) => {
        if (selectedIds.includes(item.upc)) {
          return item;
        }
      });
      setAlbumTracks(filterTracks);
    } else {
      setAlbumTracks(
        tracks.filter((track) => track.album_id === getAlbumDetail?.id),
      );
    }
  };
  useEffect(() => {
    gettracks(data);
  }, [data]);

  if (!data) return null;

  const header = [
    'S.No.',
    'Album Title',
    'Track No.',
    'Track Name',
    'Isrc No',
    'UPC Code',
    'Singer Name',
    'Star Cast',
    'Star Cast(Male)',
    'Star Cast(Female)',
    'Music Director',
    'Lyricist',
    'Genre',
    'Language',
    'Category',
    'Mood',
    'Album Release Date',
    'Banner',
    'Producer',
    'Director',
    'Vendor Name',
    'Pline',
    'Cline',
    'Duration',
  ];
  function formatDuration(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  // For every track, create a row with the track's data
  const rows = albumTracks.map((track) => {
    const upc = track.upc;
    const lyricists = track.lyricists || [];
    const directors = track.music_director || [];
    const formattedDuration = formatDuration(track.duration);
    const actor_male = track.actors_name || [];
    const actor_fem = track.actress_name || [];
    const album_director = track.album_director_name || [];
    const album_producer = track.album_producer_name || [];
    const banner_prod_name = track.banner_production_name || [];
    const c_name = track.label_c_name;
    const p_line = track.p_line_name;
    const date_release = new Date(track.album_release_date);
    const common = lyricists.filter((value) => directors.includes(value));
    const language = listLanguages?.find(
      (lang) => lang.id === track.language_id,
    )?.language;
    if (common.length > 0) {
      track.writer_role = 'C';
    } else {
      track.writer_role = 'A';
    }
    return [
      track.track_order,
      track.album_name,
      track.track_order,
      track.track_name,
      track.isrc,
      upc,
      track.singers,
      actor_male + ', ' + actor_fem,
      actor_male.toString(),
      actor_fem.toString(),
      track.music_director,
      track.lyricists.toString(),
      track.album_type,
      language,
      track.track_genre,
      track.track_genre,
      ('0' + date_release.getDate()).slice(-2) +
        '-' +
        months[date_release.getMonth()] +
        '-' +
        date_release.getFullYear().toString().substring(2, 4),
      banner_prod_name.toString(),
      album_producer.toString(),
      album_director.toString(),
      'Divo',
      p_line,
      c_name,
      formattedDuration,
    ];
  });

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.addRows([header, ...rows]);

    worksheet.getRow(1).font = {size: 12, color: {argb: '000000'}};
    // Add border to header
    worksheet.getRow(1).border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'},
    };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: '99ccff'},
    };

    worksheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center'};
    // each column width is 30
    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    worksheet.columns.forEach((column) => {
      column.alignment = {vertical: 'middle', horizontal: 'center'};
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'raaga.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button className="button" onClick={handleDownload}>
      RAAGA
    </button>
  );
};

export default Raaga;
