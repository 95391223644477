export const customDarkThemePalette = {
  primary: '#DC3831',
  primary50: '#CCD3D7',
  primary100: '#AAB5BD',
  primary200: '#8897A2',
  primary300: '#667A87',
  primary400: '#455C6B',
  primary500: '#243E50',
  primary600: '#00192C',
  primary700: '#001526',

  accent: '#DC3831',
  accent50: '#FBD4D2',
  accent100: '#F8B8B6',
  accent200: '#F49D9A',
  accent300: '#EF837E',
  accent400: '#EA6964',
  accent500: '#E3504A',
  accent600: '#CA2822',
  accent700: '#B51B15',

  textColor: '#fff',
  bgDefault: '#032034',
  bg: '#032034',
  bg100: '#032F4E',
  menuHover: '#1C3648',
  sidebarMenuText: '#FFFFFF',
  sidebarMenuInactiveText: '#818F99',
  arrowBg: '#E44752',
  divoBg: '#fff',
  tableBorderColor: '#243E50',
  tableOddRowBg: '#1C3648',
  tableEvenRowBg: '#032034',
  divoRed: '#DC3831',
  tabInactiveBorderColor: '#455C6B',
  tabInactiveBg: '#00192C',
  inputContainerBg: '#032034',
  inputBg: '#010f19',
  fileUploadBg: '#1C3648',
  // Table
  rowHover: '#001526',
  rowHoverText: '#fff',
  transparentTableGradient: 'rgba(3, 32, 52, 0)',
  opaqueTableGradient: 'rgba(3, 32, 52, 1)',
  tableHeadBg: '#001526',
  tableHeadColor: '#fff',
  contentBg: '#042F4E',

  upcBg: '#1c364866',
  // Tab
  tabTextActive: '#fff',
  tabTextInActive: '#818f99',
  // Button
  buttonActiveTextColor: '#fff',
  buttonInactiveTextColor: '#666666',
  buttonInActiveBg: '#cccccc',
  buttonActiveBg: '#DC3831',
  placeholderColor: '#8897a2',
  removeBtnBg: '#adadad',
};

export const customLightThemePalette = {
  primary: '#DC3831',
  primary50: '#EEEEEE',
  primary100: '#DDDDDD',
  primary200: '#CDCDCD',
  primary300: '#BCBCBC',
  primary400: '#ABABAB',
  primary500: '#9A9A9A',
  primary600: '#898989',
  primary700: '#787878',

  accent: '#243E50',
  accent50: '#FBD4D2',
  accent100: '#F8B8B6',
  accent200: '#F49D9A',
  accent300: '#EF837E',
  accent400: '#EA6964',
  accent500: '#E3504A',
  accent600: '#CA2822',
  accent700: '#B51B15',

  textColor: '#333333',
  bg: '#fff',
  bgDefault: '#fff',
  menuHover: '#FBEBEA',
  sidebarMenuText: '#DC3831',
  sidebarMenuInactiveText: '#333333',
  arrowBg: '#032034',
  divoBg: '#E44752',
  divoRed: '#DC3831',
  // Table
  tableBorderColor: 'rgb(226, 226, 226)',
  tableOddRowBg: '#FFF',
  tableEvenRowBg: '#0000000d',
  tableHeadBg: '#F4F4FB',
  tableHeadColor: '#333333',

  // Tab
  tabInactiveBorderColor: '#DDDDDD',
  tabInactiveBg: '#F8F9FA',
  contentBg: '#eff1f3',
  inputContainerBg: '#fff',
  inputBg: '#eff1f3',
  fileUploadBg: 'rgb(23, 26, 33, 0.02)',
  rowHover: '#f5f5f5',
  rowHoverText: '#DC3831',
  transparentTableGradient: 'rgba(255, 255, 255, 0)',
  opaqueTableGradient: 'rgba(185, 185, 185, 1)',
  // UPC Management Screen
  upcBg: '#f4f4f4',
  // Tab
  tabTextActive: '#fff',
  tabTextInActive: '#765252',
  // Button
  buttonActiveTextColor: '#fff',
  buttonInactiveTextColor: '#666666',
  buttonInActiveBg: '#cccccc',
  buttonActiveBg: '#DC3831',

  // Input
  placeholderColor: '#7f7f7f',

  removeBtnBg: '#adadad',
};

export interface CustomThemeType {
  themeButtonBg: string;
  bg: string;
  textColor: string;
  menuHover: string;
  sidebarMenuText: string;
  sidebarMenuInactiveText: string;
  arrowBg: string;
  divoBg: string;
  tableBorderColor: string;
  tableOddRowBg: string;
  divoRed: string;
  tabInactiveBorderColor: string;
  tabInactiveBg: string;
  inputContainerBg: string;
  tableEvenRowBg: string;
  bgDefault: string;
  inputBg: string;
  fileUploadBg: string;
  rowHover: string;
  rowHoverText: string;
  transparentTableGradient: string;
  opaqueTableGradient: string;
  upcBg: string;
  tabTextActive: string;
  tabTextInActive: string;
  contentBg: string;
  buttonActiveTextColor: string;
  buttonInactiveTextColor: string;
  buttonInActiveBg: string;
  buttonActiveBg: string;
  tableHeadBg: string;
  tableHeadColor: string;
  placeholderColor: string;
  removeBtnBg: string;
}
