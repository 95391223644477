import {ModalBody, ModalFooter} from 'baseui/modal';
import React from 'react';

import UploadCard from '../../pages/UploadComponent/UploadCard';
import BaseModal from '../reusable/Modal/BaseModal';

const ExcelFileModal = (props) => {
  const {
    file,
    isOpen,
    label,
    onRemoveFileHandler,
    progressInfo,
    isUploadStarted,
    complete,
    value,
    tag,
    close,
  } = props;

  return (
    <BaseModal closeable={false} page="files" isOpen={isOpen} close={close}>
      <ModalBody>
        <UploadCard
          label={label}
          size={file.size}
          name={file.name}
          onRemoveFileHandler={onRemoveFileHandler}
          progressInfo={progressInfo}
          isUploadStarted={isUploadStarted}
          complete={complete}
          value={value}
          tag={tag}
        />
      </ModalBody>
      {/* <ModalFooter>Close</ModalFooter> */}
    </BaseModal>
  );
};

export default ExcelFileModal;
