import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {Tab} from '../../../components';
import DownloadZipModal from '../../../components/AlbumsInReview/DownloadZipModal';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import LoadingSpinnerTransparent from '../../../components/reusable/LoadingSpinnerTransparent/LoadingSpinnerTransparent';
import {TAGS} from '../../../constants/tags';
import useNotification from '../../../Contexts/useNotification';
import useGetAlbumsData from '../../../hooks/AlbumDetail/useGetAlbumsData';
import useGetReuseIsrc from '../../../hooks/AlbumDetail/useGetReuseIsrc';
import useStatusUpdateHandler from '../../../hooks/AlbumDetail/useStatusUpdateHandler';
import useConnectIsrc from '../../../hooks/AlbumsManagement/useConnectIsrc';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import useMusicPlayer from '../../../hooks/reusable/useMusicPlayer';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import SkeletonAlbumDetail from '../../../pages/SkeletonAlbumDetail';
import Album from '../AlbumDetail/Album';
import AlbumInfo from '../AlbumDetail/AlbumInfo';

export const tracksColumnMeta = [
  {
    Header: 'Track Order',
    accessor: 'track_order',
    sortDescFirst: true,
  },
  {
    Header: 'Track Name',
    accessor: 'track_name',
  },
  {
    Header: 'Track Genre',
    accessor: 'track_genre',
  },
  {
    Header: 'UPC Code',
    accessor: 'track_upc',
  },
  {
    Header: 'ISRC Code',
    accessor: 'isrc',
  },
  {
    Header: 'Track Duration',
    accessor: 'duration',
    Cell: ({row}) => {
      function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.round(seconds % 60);
        return `${minutes}:${
          remainingSeconds < 10 ? '0' : ''
        }${remainingSeconds} min`;
      }
      return <span>{formatTime(row.original?.duration)}</span>;
    },
  },
];

const AlbumDetailNew = () => {
  const {albumId} = useParams();
  const [activeKey, setActiveKey] = React.useState<string | number>('0');
  const [tracks, setTracks] = useState(null);
  const [trackData, setTrackData] = useState(null);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [trackIsrc, setTrackIsrc] = useState(null);
  const [toggleDspModal, setToggleDspModal] = useState(false);
  const [musicDirectors, setMusicDirectors] = useState([]);
  const [productType, setProductType] = useState(null);

  const {getAlbumDetail, refetch, isFetching, isLoading} = useGetAlbumsData({
    albumId: albumId,
  });

  const [albumTracks, setAlbumTracks] = useState([]);

  const gettracks = (getAlbumDetail) => {
    const tracks = JSON.parse(localStorage.getItem('tracks')) || [];
    setAlbumTracks(
      tracks.filter((track) => track.album_id === getAlbumDetail?.id),
    );
  };

  useEffect(() => {
    const md = getAlbumDetail?.Track?.map((track) => track?.music_director);
    const mergedArr = Array.prototype.concat.apply([], md);
    const unique = [...new Set(mergedArr)];
    setMusicDirectors(unique);
  }, [getAlbumDetail]);

  useEffect(() => {
    if (getAlbumDetail) {
      setProductType(getAlbumDetail?.product_type);
      if (getAlbumDetail?.Track?.length > 0) {
        setTracks(getAlbumDetail.Track);
        const d = getAlbumDetail.Track.find(
          (track) => track.isrc === trackIsrc,
        );
        setTrackData(d);
      }
    }
    gettracks(getAlbumDetail);
  }, [getAlbumDetail, trackIsrc]);

  useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (window.location.pathname.indexOf('/albums') === -1) {
        window.localStorage.removeItem('tabArr');
      }
    };
  }, []);

  const {
    status,
    updateStatusHandler,
    albumLoading,
    trackLoading,
  } = useStatusUpdateHandler({data: getAlbumDetail?.Track});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const {handlePlayPause, duration, playing} = useMusicPlayer({
    isrcCode: trackIsrc,
  });

  const [dynamicTag, setDynamicTag] = useState(TAGS.TRACKS);
  const [isAlbumClick, setIsAlbumClick] = useState(false);
  const [reuseIsrcOpen, setReuseIsrcOpen] = useState(false);
  const [trackOrder, setTrackOrder] = useState();
  const [searchStr, setSearchStr] = useState('');
  const [trackUpc, setTrackUpc] = useState();
  const [trackId, setTrackId] = useState();
  const [reuseIsrcOpen2, setReuseIsrcOpen2] = useState(false);

  const connectIsrc = {
    track_id: trackId,
    data: {
      user_id: currentUser.id,
      track_order: trackOrder,
      album_id: getAlbumDetail?.id ? getAlbumDetail?.id : albumId,
      track_upc: trackUpc,
    },
  };
  useEffect(() => {
    if (getAlbumDetail) {
      setTrackUpc(getAlbumDetail?.upc);
    }
  }, [getAlbumDetail]);
  const {sendIsrc, reuseIsrcLoad} = useConnectIsrc();
  const {reuseIsrc, searchLoad} = useGetReuseIsrc({
    searchStr,
  });
  const {showToast} = useNotification();

  const content = [
    {
      title: `Track Details`,
      component: (
        <Album
          currentUser={currentUser}
          columns={tracksColumnMeta}
          setIsOpen={setIsOpen}
          data={albumTracks}
          setSelectedData={setSelectedData}
          setNewFormTag={setNewFormTag}
          tag={dynamicTag}
          setTrackIsrc={setTrackIsrc}
          handlePlayPause={handlePlayPause}
          playing={playing}
          duration={duration}
          trackIsrc={trackIsrc}
          hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
          status={status}
          productType={productType}
          getAlbumDetail={getAlbumDetail}
          isFetching={isFetching}
        />
      ),
    },
  ];

  const handleReuseIsrc = async () => {
    const res = await sendIsrc(connectIsrc);
    if (res?.statusCode === 200) {
      refetch();
      showToast('Track added successfully.', 'success');
      setReuseIsrcOpen2(false);
    }
    if (getAlbumDetail?.product_type === 'single') {
      setReuseIsrcOpen(false);
    }
    if (res.error && res.error?.length > 0) {
      showToast(res.error, 'error');
    }
  };
  const openAlbumForm = () => {
    if (getAlbumDetail) {
      setIsAlbumClick(true);
      setIsOpen(true);
      setSelectedData({original: getAlbumDetail});
    }
  };

  if (isLoading) {
    return <SkeletonAlbumDetail />;
  }
  return (
    <>
      {(albumLoading || trackLoading) && <LoadingSpinnerTransparent />}

      <AlbumInfo
        isOpen={isOpen}
        getAlbumDetail={getAlbumDetail}
        musicDirectors={musicDirectors}
        // openFlyMenu={openFlyMenu}
        setToggleDspModal={setToggleDspModal}
        updateStatusHandler={updateStatusHandler}
        hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
        hasNoTracks={getAlbumDetail?.Track?.length === 0 ? true : false}
        setIsAlbumClick={setIsAlbumClick}
        setIsOpen={setIsOpen}
        tag={dynamicTag}
        openAlbumForm={openAlbumForm}
      />
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={content}
      />

      {toggleDspModal && (
        <DownloadZipModal
          id={[getAlbumDetail?.id]}
          showModal={toggleDspModal}
          onCloseHandler={() => setToggleDspModal(false)}
          sidebarRef={null}
        />
      )}

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          newFormTag={newFormTag}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          setDynamicTag={setDynamicTag}
          tag={isAlbumClick ? TAGS.EDIT_ALBUMS : TAGS.TRACKS}
          setIsAlbumClick={setIsAlbumClick}
          closeable={false}
        />
      )}
    </>
  );
};
export default AlbumDetailNew;
