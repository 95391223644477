/** @format */

import styled from '@emotion/styled';
import React from 'react';

import SuccessAnimation from '../../reusable/SuccessAnimation/SuccessAnimation';

export const MessageContainer = ({count, btnLabel, onClickHandler, tag}) => {
  const renderMessage = (tag) => {
    let msg;
    if (tag === 'audio') {
      msg = `You have successfully uploaded ${count} track(s) , proceed to the next step to upload the meta file`;
    } else if (tag === 'cover') {
      msg = `You have successfully uploaded ${count} cover art image(s) , You can now review the albums and tracks which are created`;
    } else {
      msg = `You have successfully uploaded input file , You can go ahead and upload cover art(s) for the albums`;
    }
    return msg;
  };

  return (
    <UploadMessage>
      <div className="wrapper">
        <div className="success_animation">
          <SuccessAnimation />
        </div>

        <div className="success_message">Successfully Uploaded!</div>

        <div className="success_desc">{renderMessage(tag)}</div>
      </div>

      <div onClick={onClickHandler} className="btn">
        {btnLabel}
      </div>
    </UploadMessage>
  );
};

const UploadMessage = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 300px;
  border-radius: 5px;
  padding: 2rem;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.1);
  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }
  .btn {
    position: absolute;
    bottom: -25px;
    background-color: #11b37f;
    padding: 14px 58px;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.15);
  }
  .success_animation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
  }
  .success_message {
    font-weight: bold;
    font-size: 26px;
    margin-top: 15px;
    color: #05b37e;
  }
  .success_desc {
    text-align: center;
    margin-top: 15px;
    width: 75%;
    color: #989898;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .next_button-container {
    display: flex;
    justify-content: flex-end;
    margin: 2rem;
  }
`;
