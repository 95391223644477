import styled from '@emotion/styled';
import React, {useState} from 'react';
import {CgClose} from 'react-icons/cg';

import sizeInMb from '../../helpers/sizeInMb';

const FileStatus = (props) => {
  const {status} = props;

  return (
    <FileStatusStyled status={status}>
      <div>{status}</div>
    </FileStatusStyled>
  );
};

const ProgressBar = (props) => {
  const {progressInfo} = props;
  return (
    <ProgressBarStyled percent={progressInfo}>
      <div
        className="innerprogress-bar"
        style={{width: `${progressInfo}%`}}
      ></div>
    </ProgressBarStyled>
  );
};

const FileStatusStyled = styled.div`
  background: ${(props) =>
    props.status === 'uploaded'
      ? '#d2ffce'
      : props.status === 'pending'
      ? '#FFEDD2'
      : '#CEFFF0'};

  color: ${(props) =>
    props.status === 'uploaded'
      ? '#3db320'
      : props.status === 'pending'
      ? '#E2800C'
      : '#00A198'};
  border-radius: 3px;
  text-align: center;
  width: 110px;
  padding: 2px;
  margin-left: 5px;
  line-height: 1;
`;

const ProgressBarStyled = styled.div`
  height: 8px;
  background: #cecece;
  width: 100%;
  border-radius: 50px;
  .innerprogress-bar {
    background-color: #d21919;
    height: 8px;
    border-radius: 50px;
    width: ${(props) => (props.percent ? props.percent : 0)}%;
    transition: width 2s;
  }
`;

const UploadCardTrack = (props) => {
  const {
    label,
    size,
    name,
    onRemoveFileHandler,
    progressInfo,
    isUploadStarted,
    complete,
    value,
    tag,
    page,
  } = props;

  const renderStatus = () => {
    if (progressInfo === 100) {
      return 'uploaded';
    }
    if (progressInfo > 0 && progressInfo < 100) {
      return 'uploading';
    } else {
      return 'pending';
    }
  };

  return (
    <div style={{margin: '20px 0'}}>
      <UploadCardContainer percent={progressInfo}>
        <div className="file-ext">{label}</div>
        <div className="file-detailes">
          <div className="basic-detailes">
            <div className="left">
              <div className="file-name">{name}</div>
              <div className="file-size">{sizeInMb(size)}MB</div>
            </div>
            <div className="right">
              {progressInfo && (
                <div className="file-progress">{progressInfo}%</div>
              )}
              <FileStatus status={renderStatus()} />
            </div>
          </div>
          <ProgressBar progressInfo={progressInfo} />
        </div>
        {/* <div className="close-btn" onClick={onRemoveFileHandler}>
          <CgClose />
        </div> */}
      </UploadCardContainer>
    </div>
  );
};
const UploadCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 12.0708px 75.4427px rgba(0, 0, 0, 0.15);
  border-radius: 15.0885px;
  padding: 15px;
  display: flex;
  .file-ext {
    background: #d21919;
    border-radius: 6.03542px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .file-detailes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 70px);
    margin-left: 10px;
    .basic-detailes {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .left {
        display: flex;
        .file-name {
          border-right: 1px solid red;
          margin-right: 10px;
          padding-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 24px;
        }
        .file-size {
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .right {
        display: flex;

        .file-progress {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
  .close-btn {
    background: #d21919;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 20px;
    transform: translateY(-24px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
`;

export default UploadCardTrack;
