import React from 'react';
import {GoogleLogout} from 'react-google-login';
import {clientId} from '../../hooks/reusable/useLogin';
import styled from '@emotion/styled';
import ErrorAnimation from './ErrorToken';
export default function TokenError({setIsLoggedIn}) {
  const onLogoutSuccess = () => {
    window.localStorage.clear();
    setIsLoggedIn(false);
    window.location.pathname = '/';
  };

  return (
    <Container>
      <div className="modal">
        <div className="animate">
          <ErrorAnimation />
        </div>
        <div className="content">
          <p>
            The login session has been expired. You Will be logged out of the
            system.
          </p>
        </div>
        <div className="logout">
          <GoogleLogout
            render={(props) => (
              <button onClick={props.onClick} className="logout-btn">
                <span>OKAY</span>
              </button>
            )}
            clientId={clientId}
            buttonText="Logout"
            onLogoutSuccess={onLogoutSuccess}
          ></GoogleLogout>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(0.5);
  margin: -8px;

  .modal {
    display: flex;
    align-items: center;
    width: 550px;
    height: 50vh;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgb(255 255 255 / 10%) 0px 1px 1px 0px inset,
      rgb(50 50 93 / 25%) 0px 50px 100px -20px,
      rgb(0 0 0 / 30%) 0px 30px 60px -30px;
    background: white;
    animation: flip_horz 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
    &.alert {
      animation: shake 0.5s;
    }

    .content {
      font-size: 20px;
      font-weight: 500;
      font-family: 'DM Sans';
      color: rgb(51, 51, 51);
      line-height: 36px;
      width: 425px;
    }
    .animate {
      height: 100px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
      .css-r29tpk {
        width: 100px;
        height: 100px;
      }
    }
    .logout {
      align-self: flex-end;
      margin-right: 30px;
      button {
        padding: 12px 22px;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: 700;
        border: none;
        display: flex;
        align-items: center;
        border-radius: 5px;
        outline: none;
        appearance: none;
        cursor: pointer;
        background: rgb(220, 56, 49);
      }
    }
  }
`;
