import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import GlobalProvider from './Contexts/GlobalProvider';

// initialize sentry
Sentry.init({
  enabled: true,
  dsn: process.env.REACT_APP_Sentry_Dsn,
  environment: process.env.REACT_APP_ENV,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'console') {
      return null;
    }
    return breadcrumb;
  },
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      // beacon: true,
      console: true,
      dom: true,
      fetch: true,
      history: true,
      sentry: true,
      xhr: true,
      // axios: true
    }),
  ],
});

ReactDOM.render(
  <GlobalProvider>
    <App />
  </GlobalProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
