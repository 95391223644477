import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const updateUserFn = async (data) => {
  if (data.id) {
    try {
      const response = await axios({
        method: 'PUT',
        url: `/user/${data.id}`,
        data: data.data,
      });
      return response.data;
    } catch (error) {
      logError(error, 2);
      return error.response.data;
    }
  }
};

const useUpdateUser = () => {
  const [updateUserMutation, {status, isLoading, error}] = useMutation(
    updateUserFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('users');
        queryCache.invalidateQueries('singleUser');
        queryCache.invalidateQueries('users-search');
      },
    },
  );
  return {updateUserMutation, isLoading, status, error};
};

export default useUpdateUser;
