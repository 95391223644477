import {
  Img01,
  Img02,
  Img03,
  Img04,
  Img05,
  Img06,
  Img07,
  Img08,
  Img09,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,
  Img24,
  Img25,
  Img26,
  Img27,
  Img28,
  Img29,
  Img30,
  Img31,
  Img32,
  Img33,
  Img34,
  Img35,
  Img36,
  Img37,
  Img38,
  Img39,
  Img40,
  Img41,
  Img42,
  Img43,
  Img44,
  Img45,
  Img46,
  Img47,
  Img48,
  Img49,
  Img50,
  Img51,
  Img52,
  Img53,
  Img54,
  Img55,
  Img56,
  Img57,
  Img58,
  Img59,
  Img60,
} from '../../assets/artists';

export const imgData = {
  images: [
    {data: Img01},
    {data: Img02},
    {data: Img03},
    {data: Img04},
    {data: Img05},
    {data: Img06},
    {data: Img07},
    {data: Img08},
    {data: Img09},
    {data: Img10},
    {data: Img11},
    {data: Img12},
    {data: Img13},
    {data: Img14},
    {data: Img15},
    {data: Img16},
    {data: Img17},
    {data: Img18},
    {data: Img19},
    {data: Img20},
    {data: Img21},
    {data: Img22},
    {data: Img23},
    {data: Img24},
    {data: Img25},
    {data: Img26},
    {data: Img27},
    {data: Img28},
    {data: Img29},
    {data: Img30},
    {data: Img31},
    {data: Img32},
    {data: Img33},
    {data: Img34},
    {data: Img35},
    {data: Img36},
    {data: Img37},
    {data: Img38},
    {data: Img39},
    {data: Img40},
    {data: Img41},
    {data: Img42},
    {data: Img43},
    {data: Img44},
    {data: Img45},
    {data: Img46},
    {data: Img47},
    {data: Img48},
    {data: Img49},
    {data: Img50},
    {data: Img51},
    {data: Img52},
    {data: Img53},
    {data: Img54},
    {data: Img55},
    {data: Img56},
    {data: Img57},
    {data: Img58},
    {data: Img59},
    {data: Img60},
  ],
};
