import React from 'react';

import DSP from './DSP';
import Tab from '../../components/reusable/Tab/Tab';

const DSPManagement = () => {
  const [activeKey, setActiveKey] = React.useState<string | number>('0');

  return (
    <>
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: "DSP's",
            component: <DSP />,
          },
        ]}
      />
    </>
  );
};

export default DSPManagement;
