import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const updateDSPFn = async (data) => {
  try {
    const response = await axios({
      method: 'PUT',
      url: `/dsp/${data.id}`,
      data: data.data,
    });

    return response.data;
  } catch (error) {
    logError(error, 8);
    return error.response.data;
  }
};

const useUpdateDSP = () => {
  const [updateDSPMutation, {status, isLoading, error}] = useMutation(
    updateDSPFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('dsp');
        queryCache.invalidateQueries('singleDsp');
        queryCache.invalidateQueries('dsp-search');
      },
    },
  );
  return {updateDSPMutation, isLoading, status, error};
};

export default useUpdateDSP;
