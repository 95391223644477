import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const createUserFn = async (data) => {
  try {
    const response = await axios(`/user`, {
      method: 'POST',
      data: data.data,
    });
    return response.data;
  } catch (error) {
    logError(error, 2);
    return error.response?.data;
  }
};

const useCreateUser = () => {
  const [createUserMutation, {status, data, error, isLoading}] = useMutation(
    createUserFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('users');
      },
    },
  );

  return {createUserMutation, isLoading, status, error};
};

export default useCreateUser;
