import styled from '@emotion/styled';
import {FileUploader} from 'baseui/file-uploader';
import {ProgressBar} from 'baseui/progress-bar';
import React, {useContext} from 'react';
import {HiCloudUpload} from 'react-icons/hi';
import {IoMdCloseCircle, IoMdRefresh} from 'react-icons/io';
import {MdAdd} from 'react-icons/md';

import {GlobalContext} from '../../../Contexts/GlobalProvider';
import sizeInMb from '../../../helpers/sizeInMb';
import ExcelFileModal from '../../BulkUpload/ExcelFileModal';

const DragAndDrop = ({data, file}) => {
  const {state, dispatch} = useContext(GlobalContext);
  return (
    <DragAndDropStyled isUploadStarted={state.uploadStatus === 2}>
      <div
        className="drag-container"
        onChange={data.onChange}
        onDrop={data.onDrop}
      >
        {/* {data.children} */}
        <div className="icon-container">
          <HiCloudUpload />
          <div className="content">
            <span className="text1">Drag files here or</span>
            <span
              className="text2"
              onClick={data?.children[0]?.props.children[2].props.onClick}
            >
              Browse
            </span>
          </div>
        </div>
        <div
          onClick={data?.children[0]?.props.children[2].props.onClick}
          className="fab"
        >
          {file ? <IoMdRefresh /> : <MdAdd />}
        </div>
      </div>
    </DragAndDropStyled>
  );
};

const DragAndDropStyled = styled.div`
  background: #faecea;
  height: 172px;
  padding: 20px;
  .drag-container {
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px dashed rgba(220, 56, 49, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .fab {
      pointer-events:${(props) => props.isUploadStarted ? 'none':''};
      filter: ${(props) => props.isUploadStarted ? 'grayscale(1)':''};
      cursor: pointer;
      position: absolute;
      bottom: -28px;
      right: 45px;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background: #dc3831;
      box-shadow: 0px 4px 20px rgba(220, 56, 49, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      svg {
        font-size: 24px;
      }
    }
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .content {
        .text1 {
          color: #d7b0ae;
        }
        .text2 {
          color: #dc3831;
          margin-left: 5px;
          font-weight: bold;
          cursor: pointer;
          pointer-events:${(props) => props.isUploadStarted ? 'none':''};
          filter: ${(props) => props.isUploadStarted ? 'grayscale(1)':''};
        }
      }
      svg {
        font-size: 24px;
        color: #dc3831;
      }
    }
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  background: #ffeded;
  color: #d20000;
  margin-top: 20px;
`;

export default function FileUploadNewNew(props) {
  const {state, dispatch} = useContext(GlobalContext);
  const {
    accept,
    errorMessage,
    isMultiple,
    onCancel,
    onChange,
    file,
    onRemoveFileHandler,
    onClickUpload,
    progressInfo,
    tag,
    isUploadStarted,
    complete,
    value,
    label,
  } = props;

  return (
    <div>
      <DragAndDropContainer>
        <FileUploader
          overrides={{
            FileDragAndDrop: {
              style: () => {
                return {
                  marginRight: '0px',
                };
              },
              component: (data) => <DragAndDrop data={data} file={file} />,
            },
          }}
          onCancel={onCancel}
          onChange={(file) => onChange(file)}
          onDrop={(file) => onChange(file)}
          accept={accept}
          multiple={isMultiple}
          errorMessage={errorMessage}
        />
        <div className="accepted-files">Accepted File Types: {accept} only</div>
      </DragAndDropContainer>
      {file && !state.error && (
        <ExcelFileModal
          label={label}
          file={file}
          onRemoveFileHandler={onRemoveFileHandler}
          progressInfo={progressInfo}
          isUploadStarted={isUploadStarted}
          complete={complete}
          value={value}
          tag={tag}
          close={null}
        />
      )}

      {state.error && <ErrorMessage>{state.error}</ErrorMessage>}
      {/* <BtnContainer>
        <button
          onClick={onClickUpload}
          disabled={!file || state.error || isUploadStarted}
        >
          Upload
        </button>
      </BtnContainer> */}
    </div>
  );
}

const BtnContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  > button {
    width: calc(100% - 42px);
    padding: 12px 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border: none;
    align-items: center;
    border-radius: 5px;
    outline: none;
    appearance: none;
    cursor: pointer;
    background: #dc3831;

    &:disabled,
    &[disabled] {
      background-color: #cccccc;
      color: #666666;
    }
  }
`;

const LabelStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = (data, value) => {
  return (
    <>
      {data.value?.percent > 0 && (
        <LabelStyle>
          {data.value?.percent}% | {data.value?.label}
        </LabelStyle>
      )}
    </>
  );
};

const SelectedFileList = ({
  name,
  size,
  onRemoveFileHandler,
  isUploadStarted,
  value,
  tag,
  progressInfo,
  label,
}) => {
  return (
    <SelectedFileListContainer>
      <div className="row1">
        <div className="icon">
          <span>{label}</span>
        </div>

        <div className="file-name">{name}</div>

        <div className="size">{sizeInMb(size)}MB</div>
        {!isUploadStarted && (
          <div onClick={onRemoveFileHandler} className="close">
            <IoMdCloseCircle />
          </div>
        )}
      </div>
      {(tag === 'cover' || tag === 'upc') && isUploadStarted ? (
        <div className="row2">
          <div className="progress-container">
            <ProgressBar value={progressInfo} successValue={100} showLabel />
          </div>
        </div>
      ) : (
        <>
          {isUploadStarted ? (
            <div className="row2">
              <div className="progress-container">
                <ProgressBar
                  overrides={{
                    Label: (data) => <Label data={data} value={value} />,
                  }}
                  value={value.percent}
                  successValue={100}
                  showLabel
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </SelectedFileListContainer>
  );
};

const SelectedFileListContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 120px;
  width: 100%;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.05);
  margin-top: 25px;
  .row1 {
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .file-name,
  .size {
    color: #878787;
    margin-right: 10px;
    font-size: 14px;
  }
  .close {
    margin-right: 10px;
    cursor: pointer;

    svg {
      font-size: 22px;
      color: #dc3831;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #3fc2eb;
    border-radius: 15px;
    margin-left: 10px;

    span {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
    }
  }
`;

const DragAndDropContainer = styled.div`
  position: relative;
  width: 448px;
  height: 236px;
  .accepted-files {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    color: rgba(103, 103, 103, 0.5);
  }
`;
