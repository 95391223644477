import styled from '@emotion/styled';
import {ModalBody} from 'baseui/modal';
import React, {useEffect, useState} from 'react';
import {AiFillInfoCircle} from 'react-icons/ai';
import {IoMdRefreshCircle} from 'react-icons/io';

import ott from '../../assets/icons/ott.png';
import publisher from '../../assets/icons/publisher.png';
import radio from '../../assets/icons/radio.png';
import Fuga from '../../excel/fuga';
import Iprs from '../../excel/iprs';
import PplIndia from '../../excel/ppl_india';
import PplUk from '../../excel/ppl_uk';
import Raaga from '../../excel/raaga';
import SonyAtv from '../../excel/sony_atv';
import sizeInMb from '../../helpers/sizeInMb';
import useGetAlbumsData from '../../hooks/AlbumDetail/useGetAlbumsData';
import usePreviewLink from '../../hooks/AlbumDetail/usePreviewLink';
import useGetDsps from '../../hooks/DSPManagement/useGetDsps';
import useRetryEmailFn from '../../hooks/DSPManagement/useRetryEmail';
import useRetrySftpFnn from '../../hooks/DSPManagement/useRetrySFTP';
import useGetLabels from '../../hooks/LabelManagement/useGetLabels';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import ButtonLoading from '../reusable/ButtonLoading/ButtonLoading';
import BaseModal from '../reusable/Modal/BaseModal';

export const ResendBtn = ({sftpLoading, emailLoading, resendHandler, dsp}) => {
  return (
    <div
      onClick={() => resendHandler(dsp.dsp_delivery_type)}
      className="resend-btn"
    >
      {sftpLoading || emailLoading ? (
        <ButtonLoading />
      ) : (
        <>
          <div className="text">Resend</div>
          <IoMdRefreshCircle />
        </>
      )}
    </div>
  );
};

export const ImageComponent = ({src}) => {
  return (
    <div className="img-container">
      <img src={src} width="100%" height="100%" alt="icon" />
    </div>
  );
};

export const DspList = (props) => {
  const {
    dsp,
    s3Key,
    status,
    upc,
    isEmailSendRequired,
    s3KeyGeneratorForGroup,
    groupId,
    mode,
  } = props;
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const {downloadLink} = usePreviewLink({key: s3Key});
  const [zipUrl, setZipUrl] = useState(null);
  const {isLoading: sftpLoading} = useRetrySftpFnn();
  const {isLoading: emailLoading} = useRetryEmailFn();

  useEffect(() => {
    if (downloadLink) {
      setZipUrl(downloadLink);
    }
  }, [downloadLink]);

  const retrySftpHandler = async () => {
    const data = {
      from: 'no_reply@divomusic.com',
      bucketName: 'divo-source-staging',
      fileName: s3Key, // `${project_id}/zip/${album_upc}/${dsp_name}`
      filepath: dsp.file_path, // `/home/ec2-user/${dsp_name}.zip` //this is temporary
      album_id: dsp.album_id,
      sftp_host: dsp.sftp_host, //this is temporary
      sftp_port: dsp.sftp_port, //this is temporary
      sftp_user_name: dsp.sftp_user_name,
      sftp_password: dsp.sftp_password,
      user_email: currentUser.email,
      album_dsp_id: dsp.id,
      dsp_name: dsp.dsp_name,
      album_dsp_email: dsp.dsp_email,
      album_dsp_email_cc: dsp.dsp_email_cc,
    };
  };

  const retryEmailHandler = async () => {
    const data = {
      zippedFileKey: s3Key,
      order_id: status[0]?.id,
    };
  };

  const renderIcons = (type) => {
    switch (type) {
      case 'ott':
        return <ImageComponent src={ott} />;
      case 'radio':
        return <ImageComponent src={radio} />;
      default:
        return <ImageComponent src={publisher} />;
    }
  };

  const resendHandler = (type) => {
    if (type === 'email') {
      retryEmailHandler();
    } else {
      retrySftpHandler();
    }
  };

  return (
    <>
      {status.length > 0 ? (
        <div className="processing">
          <div className="first_flex">
            {renderIcons(dsp.dsp_type)}
            {dsp.dsp_type === 'publisher' ? null : (
              <div className="method">
                <span className="tiny_text">Method</span>
                <p className="big_text">
                  {dsp?.dsp_delivery_type?.toUpperCase()}
                </p>
              </div>
            )}
            <div className="status">
              <span className="tiny_text">Status</span>

              <div className="big_text">
                <>
                  <span>{dsp?.dsp_name?.toUpperCase()} </span>
                  <span
                    className={
                      dsp.dsp_type === 'publisher' ||
                      status[0].delivery_status === 'completed'
                        ? `success`
                        : status[0].delivery_status === 'pending'
                        ? `warning`
                        : `error`
                    }
                  >
                    {status[0].delivery_status === 'completed' ? (
                      `| The package has been sent succesfully`
                    ) : status[0].delivery_status === 'failed' ? (
                      `| The package delivery has failed`
                    ) : dsp.dsp_type === 'publisher' ? (
                      <PublisherCheck downloadLink={downloadLink} />
                    ) : !isEmailSendRequired &&
                      dsp.dsp_delivery_type === 'email' ? null : (
                      `| Pending Delivery`
                    )}
                  </span>
                </>
              </div>
            </div>
          </div>

          <div className="action-btn-container">
            {dsp.dsp_type !== 'publisher' ? (
              dsp.dsp_delivery_type === 'sftp' ? (
                dsp.delivery_status === 'failed' && (
                  <ResendBtn
                    resendHandler={resendHandler}
                    dsp={dsp}
                    sftpLoading={sftpLoading}
                    emailLoading={emailLoading}
                  />
                )
              ) : !isEmailSendRequired &&
                dsp.dsp_delivery_type === 'email' ? null : (
                <ResendBtn
                  resendHandler={resendHandler}
                  dsp={dsp}
                  sftpLoading={sftpLoading}
                  emailLoading={emailLoading}
                />
              )
            ) : null}
            {dsp.dsp_name === 'Fuga' && (
              <DownloadMetadataBtn
                s3Key={s3KeyGeneratorForGroup(
                  mode === 'manual' ? upc : !groupId ? upc : 'output',
                  mode === 'manual' ? 'output' : groupId ? groupId : 'output',
                  'fuga',
                )}
              />
            )}

            <div className="btn-zip">
              {dsp.dsp_type === 'publisher' ? (
                <a href={zipUrl?.url}>
                  Download Excel |{' '}
                  {isNaN(zipUrl?.ContentLength) ? (
                    <ButtonLoading />
                  ) : (
                    ` ${sizeInMb(zipUrl?.ContentLength)} MB`
                  )}
                </a>
              ) : status[0].zipping_status === 'completed' ? (
                <a href={zipUrl?.url}>
                  Download Package |
                  {isNaN(zipUrl?.ContentLength) ? (
                    <ButtonLoading />
                  ) : (
                    ` ${sizeInMb(zipUrl?.ContentLength)} MB`
                  )}
                </a>
              ) : status[0].zipping_status === 'pending' ? (
                <a href="#">Zipping in progress</a>
              ) : (
                <>
                  <a href="#" style={{marginRight: '5px'}}>
                    Zipping Failed
                  </a>
                  <AiFillInfoCircle color="white" />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="processing">
          <div className="first_flex">
            {renderIcons(dsp.dsp_type)}
            <div className="method">
              <span className="tiny_text"> Method</span>
              <p className="big_text">{dsp?.delivery_type?.toUpperCase()}</p>
            </div>
            <div className="status">
              <span className="tiny_text">Status</span>
              <p className="big_text error">Data unavailable</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DownloadMetadataBtn = ({s3Key}) => {
  const {downloadLink} = usePreviewLink({key: s3Key});

  return (
    <a
      className="fuga-btn"
      style={{
        marginRight: '20px',
      }}
      href={downloadLink?.url}
    >
      Download Metadata |{` ${sizeInMb(downloadLink?.ContentLength)} MB`}
    </a>
  );
};

const PublisherCheck = ({downloadLink}) => {
  return (
    <>
      {downloadLink && downloadLink.statusCode === 404 ? (
        <span className="warning">| File is getting ready to download</span>
      ) : (
        <span>| File is ready to be downloaded</span>
      )}
    </>
  );
};

const GetDsp = () => {
  const [searchStr, setSearchStr] = useState('');
  const {resolvedData} = useGetDsps({searchStr});

  return resolvedData;
};

interface IDownloadZipModal {
  showModal: boolean;
  onCloseHandler: () => void;
  id: any[];
  sidebarRef: any;
  multiple?: boolean;
}

const DownloadZipModal = ({
  showModal,
  onCloseHandler,
  id,
  sidebarRef,
  multiple,
}: IDownloadZipModal) => {
  const albumsData = JSON.parse(localStorage.getItem('albumData'));
  const album = albumsData?.find((album) => album.id == id[0]);
  const [searchStr, setSearchStr] = useState('');
  const {resolvedData} = useGetLabels({searchStr});

  const label = resolvedData?.find(
    (item) => item.label_name === album?.label_id,
  );
  const labelDsp = label?.dsps;

  // For each dsp in labelDsp, get the dsp details

  const dsp = GetDsp();

  const finalDsp = [];

  dsp?.forEach((item) => {
    labelDsp?.forEach((dsp) => {
      if (item.dsp_name === dsp.dsp_name) {
        finalDsp.push(item);
      }
    });
  });

  return (
    <BaseModal page="dsp-delivery" isOpen={showModal} close={onCloseHandler}>
      <ModalBody style={{backgroundColor: 'white'}}>
        <Container ref={sidebarRef}>
          <div className="modal_header">DSP Delivery Details</div>
          <table id="table1">
            <tbody>
              {[finalDsp[0]]?.map((dsp) => (
                <tr>
                  <td>
                    <PplIndia
                      data={album}
                      multiple={multiple}
                      selectedIds={id}
                    />
                  </td>
                  <td>
                    <Iprs data={album} multiple={multiple} selectedIds={id} />
                  </td>
                  <td>
                    <PplUk data={album} multiple={multiple} selectedIds={id} />
                  </td>
                </tr>
              ))}

              {[finalDsp[0]]?.map((dsp) => (
                <tr>
                  <td>
                    <SonyAtv
                      data={album}
                      multiple={multiple}
                      selectedIds={id}
                    />
                  </td>
                  <td>
                    <Fuga data={album} multiple={multiple} selectedIds={id} />
                  </td>
                  <td>
                    <Raaga data={album} multiple={multiple} selectedIds={id} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      </ModalBody>
    </BaseModal>
  );
};

const Container = styled.div`
  .modal_header {
    margin-top: 18px;
    font-weight: 650;
    font-size: 20px;
    margin-bottom: 1.6rem;
  }

  .refresh-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  td {
    // border-bottom: 5px solid #f9f9f9;
    padding: 15px;
  }
  .button {
    background-color: white;
    border: 2px solid #bec0bf;
    color: black;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    justify-content: end;
    padding: 17px;
    padding-top: 28px;
    padding-bottom: 28px;
    width: 270px;
    // box-shadow: 1px 1px 1px 1px #bec0bf;
  }
  .button:hover {
    background-color: white;
    border: 2px solid #df3732;
    color: black;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    justify-content: end;
    padding: 17px;
    padding-top: 28px;
    padding-bottom: 28px;
    width: 270px;
  }
  .format {
    font-size: 14px;
    font-weight: 650;
    padding-right: 580px;
    padding-left: 40px;
  }
  .processing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    max-height: 40px;

    .first_flex {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .tiny_text {
      color: #949393;
      font-size: 0.65rem;
    }
    .status {
      margin-left: 1.5rem;
      p {
        color: #229d18;
      }
      .fail {
        color: #dc3831;
      }
    }

    .method {
      margin-left: 1.5rem;
    }

    .big_text {
      margin: 0;
      font-weight: 650;
    }
    .success {
      color: #229d18;
      font-weight: normal;
    }
    .error {
      color: red !important;
      font-weight: normal;
    }

    .warning {
      color: #ff6f00;
      font-weight: normal;
    }

    .img-container {
      height: 60px;
      width: 60px;
      img {
        border-radius: 5px;
      }
    }
  }
  .btn-zip,
  .resend-btn {
    background-color: #dc3831;
    cursor: pointer;
    padding: 6px 11px;
    font-size: 12px;
    border-radius: 25px;
    .text {
      color: white;
    }
    a {
      display: flex;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .fuga-btn {
    background-color: #dc3831;
    cursor: pointer;
    padding: 6px 11px;
    font-size: 12px;
    border-radius: 25px;
    text-decoration: none;
    color: white;
  }

  .action-btn-container {
    display: flex;
    align-items: center;

    .resend-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      svg {
        margin-left: 5px;
        color: white;
      }
    }
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .rotating {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }
  }
`;

export default DownloadZipModal;
