import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import React from 'react';
import {MdKeyboardArrowLeft} from 'react-icons/md';

import {Logo} from '../../../components';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import SideBarMenuItem from './SideBarMenuItem';
const menuData = [
  {
    iconName: 'dashboard',
    menuTitle: 'Dashboard',
    route: '/',
  },
  {
    iconName: 'user',
    menuTitle: 'User management',
    route: '/user-management',
  },
  // {
  // 	iconName: "reports",
  // 	menuTitle: "Reports",
  // 	route: "/reports",
  // },
  {
    iconName: 'artistLabel',
    menuTitle: 'Label Management',
    route: '/label',
  },
  {
    iconName: 'dsp',
    menuTitle: 'DSP Management ',
    route: '/dsp-management',
  },
  {
    iconName: 'createProject',
    menuTitle: 'Album Management',
    route: '/albums',
  },
];

const Sidebar = ({toggleSidebar, setToggleSidebar}) => {
  const [css, theme] = useStyletron();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  return (
    <SidebarContainer
      toggleSidebar={toggleSidebar}
      className={css({
        backgroundColor: theme.bg,
        zIndex: 2,
      })}
    >
      <SidebarMenu>
        <ToggleIconContainer
          theme={theme}
          toggleSidebar={toggleSidebar}
          onClick={() => setToggleSidebar(!toggleSidebar)}
        >
          {/* <AiOutlineLeftCircle /> */}
          <div>
            <MdKeyboardArrowLeft />
          </div>
        </ToggleIconContainer>
        {menuData.map((item, i) => {
          return (
            <SideBarMenuItem
              toggleSidebar={toggleSidebar}
              iconColor={theme.textColor}
              menuHover={theme.menuHover}
              sidebarMenuText={theme.sidebarMenuText}
              sidebarMenuInactiveText={theme.sidebarMenuInactiveText}
              item={item}
              i={i}
            />
          );
        })}
      </SidebarMenu>
      <LogoMainContainer>
        <SideBarLogoContainer>
          <Logo theme={theme} />
        </SideBarLogoContainer>
        {process.env.REACT_APP_Version && (
          <VersionComponent toggleSidebar={toggleSidebar} />
        )}
      </LogoMainContainer>
    </SidebarContainer>
  );
};

const VersionComponent = ({toggleSidebar}) => {
  return (
    <>
      {!toggleSidebar ? (
        <span className="version">Version {process.env.REACT_APP_Version}</span>
      ) : (
        <span className="version-small">v {process.env.REACT_APP_Version}</span>
      )}
    </>
  );
};

const SidebarContainer = styled.div`
  height: 100vh;
  color: #fff;
  display: flex;
  position: fixed;
  transition: ${(props) =>
    props.toggleSidebar
      ? `all 0.25s cubic-bezier(.25,.75,.5,1.25)`
      : `all 0.15s cubic-bezier(.25,.75,.5,1.25)`};

  width: ${(props) => (props.toggleSidebar ? `60px` : `235px`)};
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  @media (max-width: 411px) {
    display: none;
  }
`;

const ToggleIconContainer = styled.div`
  color: ${(props) => props.theme.sidebarMenuText};
  cursor: pointer;
  transition: all 0.2s ease-in;
  position: absolute;
  right: ${(props) => (props.toggleSidebar ? `-12px` : `-12px`)};
  top: calc(100vh / 2);
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border-radius: 20px;
    border: 1px solid ${(props) => props.theme.tableBorderColor};
    width: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 100ms linear 0s, color 100ms linear 0s;

    background-color: ${(props) => props.theme.bg};

    &:hover {
      color: white;
      background-color: ${(props) => props.theme.divoRed};
    }
    svg {
      transform: ${(props) =>
        props.toggleSidebar ? `rotate(180deg)` : `rotate(0deg)`};
      font-size: 18px;
    }
  }
`;

const SidebarMenu = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  list-style: none;
  width: 100%;
  padding-left: 0px;
  margin: 0;
  margin-top: 20px;
`;
const LogoMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .version {
    color: #737373;
    margin-bottom: 5px;
    font-size: 12px;
    margin-top: -10px;
    transition: all 0.5s ease-in;
  }
  .version-small {
    color: #737373;
    margin-bottom: 5px;
    font-size: 10px;
    margin-top: -24px;
    transition: all 0.5s ease-in;
  }
`;

const SideBarLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

export default Sidebar;
