import styled from '@emotion/styled';
import {ErrorMessage, Field} from 'formik';
import React from 'react';

import TextError from './TextError';

export const Label = ({data, isRequired = true}) => {
  return (
    <LabelStyle>
      <div>{data}</div>
      {isRequired && <span>*</span>}
    </LabelStyle>
  );
};

export const LabelStyle = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 4px 0;
  color: #333333;
  font-weight: 500;

  span {
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-left: 5px;
    color: #dc3832;
    font-weight: bold;
  }
`;

const RadioButtons = (props) => {
  const {label, name, options, isRequired, ...rest} = props;
  return (
    <Control>
      <Label data={label} isRequired={isRequired} />
      <Field name={name}>
        {({field}) => {
          return options.map((option) => {
            return (
              <React.Fragment key={option.key}>
                <input
                  type="radio"
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={
                    field?.value?.toLowerCase() === option?.value?.toLowerCase()
                  }
                />
                <label htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </Control>
  );
};

const Control = styled.div`
  input {
    margin: 1rem 0.85rem;
  }
`;
export default RadioButtons;
