/* eslint-disable react-hooks/exhaustive-deps */
/** @format */

import styled from '@emotion/styled';
import {Card} from 'baseui/card';
import React, {useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import RevertModal from '../components/CreateProject/RevertModal';
import {borderNone} from '../styles/base-ui-overrides/common';
import GlobalStyle from '../styles/Global';
import Header from './components/Header';
import Sidebar from './components/Sidebar/Sidebar';
import GlobalState from './GlobalState';

const DashboardLayout = (props) => {
  const {
    isOpen,
    setIsOpen,
    setTheme,
    theme,
    setIsLoggedIn,
    currentUser,
    isAlbum,
    isTrack,
    setIsAlbum,
    setIsTrack,
  } = props;
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const location = useLocation();
  return (
    <>
      <GlobalStyle />
      <Container toggleSidebar={toggleSidebar}>
        <Sidebar
          toggleSidebar={toggleSidebar}
          setToggleSidebar={setToggleSidebar}
        />
        <GlobalState />
        <Header
          setIsLoggedIn={setIsLoggedIn}
          setTheme={setTheme}
          themeObj={theme}
          currentUser={currentUser}
        />
        <Card
          overrides={{
            Root: {
              style: {
                gridArea: 'main',
                ...borderNone,
              },
            },
          }}
        >
          <Outlet />
        </Card>
        {isOpen ? (
          <RevertModal
            setIsOpen={setIsOpen}
            close={() => setIsOpen(false)}
            isOpen={isOpen}
            isAlbum={isAlbum}
            isTrack={isTrack}
            setIsAlbum={setIsAlbum}
            setIsTrack={setIsTrack}
            setHasCloseClicked={null}
            setIsSidebarOpen={null}
          />
        ) : null}
      </Container>
    </>
  );
};

const Container = styled.div<{toggleSidebar: boolean}>`
  display: grid;

  grid-template-columns: ${(props) =>
    props.toggleSidebar ? `60px 1fr` : `235px 1fr`};
  grid-template-rows: 80px 1fr;

  grid-template-areas:
    'sidebar head head head'
    'sidebar main main main'
    'sidebar main main main'
    'sidebar main main main';

  /* grid-container height = 100% of viewport height */
`;

export default DashboardLayout;
