import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import {Button} from 'baseui/button';
import React from 'react';
import {RiHome2Fill} from 'react-icons/ri';
import {useLocation, useNavigate} from 'react-router';

import ErrorLottie from '../components/reusable/ErrorLottie/ErrorLottie';
import {logError} from './logError';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null, eventId: null};
  }
  componentDidCatch(error, errorInfo) {
    this.setState({error});
    const user = JSON.parse(window.localStorage.getItem('currentUser'));
    logError(error)
  }
  goToHome = () => {
    this.props.navigate(`/`);
    window.location.reload();
  };
  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <ErrorContainer>
          <div className="error-icon">
            <ErrorLottie />
          </div>
          {/* <a
            onClick={() =>
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }
          >
            Report feedback
          </a> */}
          <div className="error-message">
            <span>Oops.. Something went wrong, Please try again Later</span>
            <Button onClick={this.goToHome} endEnhancer={<RiHome2Fill />}>
              Go Home
            </Button>
            {/* <button onClick={this.goToHome}>Click here to go Home</button> */}
          </div>
        </ErrorContainer>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

function withRouter(Child) {
  return (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Child {...props} navigate={navigate} location={location} />;
  };
}

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  span {
    color: #464646;
  }
  .error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
  }
  .error-message {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 20px;
    }
  }
`;
export default withRouter(ErrorBoundary);
